import { AxiosResponse } from "axios";
import { apisiseci, apisso } from "./api";
import { UserData, UserDataMti } from "../interfaces/user";
import getCookie from "../utils/getCookies";
import { format } from "date-fns";

// função auxiliar para setar cookies
const setCookie = (cname: string, cvalue: string, exdays: number): void => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const saveTokens = (tokenSSO: string): void => {
  document.cookie = `gov_access_token_sso=${tokenSSO};path=/`;
  setCookie("gov_access_token_sso", tokenSSO, 7);
};

export const clearStorage = (): void => {
  document.cookie =
    "gov_access_token_sso=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie =
    "pathname_redirect_gov=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie =
    "gov_user_data=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie =
    "gov_user_data_adicional=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const getPathToRedirectLogin = (): string => {
  if (process.env.REACT_APP_SSO_LOGIN_PROVIDER === "primary") {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${process.env.REACT_APP_SSO}/sso/cidadao?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  }
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${process.env.REACT_APP_SSO_SECUNDARY}/auth?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=${process.env.REACT_APP_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
};

export const loginRedirectPrivider = (pathname: string): void => {
  clearStorage();
  document.cookie = `pathname_redirect_gov=${pathname};path=/`;
  (window as any).open(getPathToRedirectLogin(), "_self");
};

export const logout = async () => {
  if (process.env.REACT_APP_SSO_LOGIN_PROVIDER === "primary") {
    clearStorage();
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (window as any).open(
      `${process.env.REACT_APP_SSO}/sso/sair?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${window.location.href}`,
      "_self"
    );
  } else {
    clearStorage();
    (window as any).open(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${process.env.REACT_APP_SSO_SECUNDARY}/logout?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=${process.env.REACT_APP_RESPONSE_TYPE}&redirect_uri=${window.location.href}`,
      "_self"
    );
  }
};

export const getDataUserSso = (token: string): Promise<AxiosResponse> =>
  apisso.get("/cidadaos/pro/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const getAdditionalDataUserSso = (
  token: string,
  cpf: string
): Promise<AxiosResponse> =>
  apisso.get(`/cidadaos/complementar/${cpf}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getDataUserMti = (
  token: string,
  access_token_mti: string
): Promise<AxiosResponse> =>
  apisiseci.get("/profile/externo/", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Authorization-Externo": access_token_mti,
    },
  });

export const validateUserSSO = (code: string): Promise<AxiosResponse> =>
  apisso.post(
    "/validar_cidadao/",
    {
      code,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const loginSigp = (
  code: string,
  redirect_uri?: string
): Promise<AxiosResponse> => {
  if (process.env.REACT_APP_SSO_LOGIN_PROVIDER === "primary") {
    return apisiseci.post("/login-sigp/", {
      code,
    });
  }
  return apisiseci.post(
    "/login/externo/",
    {
      code,
      redirect_uri: redirect_uri || process.env.REACT_APP_REDIRECT_URI,
    },
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        Authorization: process.env.REACT_APP_API_SISECI_KEY,
      },
    }
  );
};

const mtiDataUserAdapter = (data: UserDataMti): UserData => {
  const dataF = {
    nome: data.name || "",
    data_nascimento: data.dataNascimento || "",
    nome_mae: data.nomeMae || "",
    cpf: data.preferred_username || data.username || data.cpf || "",
    contato: {
      email: data.email || "",
    },
  };
  return dataF as UserData;
};

export const getUserData = async (token: string, access_token_mti: string) => {
  if (process.env.REACT_APP_SSO_LOGIN_PROVIDER === "primary") {
    const { data } = await getDataUserSso(token);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data[0];
  }

  const { data } = await getDataUserMti(access_token_mti, token);
  return mtiDataUserAdapter(data);
};

export const getUserDataAdicional = (token: string): Promise<AxiosResponse> =>
  apisso.get("/admin/meus_dados/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const checkCPF = (cpf: string): Promise<AxiosResponse> =>
  apisso.post("/admin/checar/cpf/", { cpf });

export const sendCodeForSISECI = async (code: string, history: any) => {
  const pathname = getCookie("pathname_redirect_gov");
  try {
    const { data } = await loginSigp(
      code,
      `${window.location.origin}${window.location.pathname}`
    );

    if (data.access_token) {
      saveTokens(data.access_token);
      const spathname = pathname === "/" ? "/acesso-rapido" : pathname;
      history.push(spathname || "/acesso-rapido", {
        message: data.message ? data.message : "",
      });
    }
  } catch (err) {
    clearStorage();
    history.push(pathname || "/", {
      message: "Erro ao processar o login",
    });
  }
};

export const sendImageProfile = async (token: string, file: File) => {
  const formData = new FormData();
  formData.append("arquivo", file);

  return apisso.post("cidadaos/foto/", formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getImageProfile = async (token: string) => {
  return apisso.get("cidadaos/foto/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const sendAdditionalData = (
  token: string,
  cpf: string,
  nome_pai: string,
  nome_mae: string,
  nome_social: string,
  orientacao_sexual: string,
  data_nascimento: Date,
  complementar: {
    raca: string;
    estado_civil: string;
    escolaridade: string;
    naturalidade: string;
    nacionalidade: string;
    pcd?: boolean;
    tipo_pcd?: string;
    numero: string;
    bairro: string;
    cidade: string;
    cep: string;
    estado: string;
    endereco: string;
    complemento: string;
  },
  contato: {
    telefone: string;
    email: string;
  },
  identidade_genero: string
): Promise<AxiosResponse> =>
  apisso.put(
    `/cidadaos/complementar/${cpf}/`,
    {
      token,
      nome_pai,
      nome_mae,
      nome_social,
      orientacao_sexual,
      identidade_genero,
      data_nascimento: format(new Date(data_nascimento), "yyyy-MM-dd"),
      complementar,
      contato,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getPreferencias = async (token: string) => {
  return apisso.get("/admin/notificacao/preferencias/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getDependentes = async (token: string, cpf: string) => {
  return apisso.get(`/cidadaos/dependente/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const sendPreferencias = async (token: string, email: boolean) => {
  return apisso.post(
    "/admin/notificacao/",
    {
      email,
      push: false,
      sms: false,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const sendDependentes = async (
  token: string,
  nomeDependente: string,
  birthDateDependente: Date,
  isDeficiente: boolean,
  parentesco: string,
  cpfDependente: string,
  emailDependente: string
) => {
  const dateObject = new Date(birthDateDependente);
  const year = dateObject.getFullYear();
  const month = `0${dateObject.getMonth() + 1}`.slice(-2);
  const day = `0${dateObject.getDate()}`.slice(-2);

  const formattedDate = `${year}-${month}-${day}`;
  return apisso.post(
    `/cidadaos/dependente/`,
    {
      nome_completo: nomeDependente,
      data_nascimento: formattedDate,
      pcd: isDeficiente,
      parentesco,
      cpf: cpfDependente,
      email: emailDependente,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const deleteDependentes = async (token: string, id: number) => {
  return apisso.delete(`/cidadaos/dependente/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
