/* eslint-disable import/prefer-default-export */
import React, {
  ReactNode, createContext, useCallback, useState,
} from 'react';

interface SchedulerStepProviderProps {
  children: ReactNode;
}

export type ListPublic = Array<'Cidadão' | 'Empresa' | 'Servidor'>;


interface SchedulerStepContextType {
  currentStep: number;
  cnpjNumber: string | null;
  companyCountry: string | null;
  userProfile: 'Cidadão' | 'Empresa' | null;
  serviceType: 'presencial' | 'online' | null;
  isVisibleButton: boolean;
  handleCheckPublic: (list: ListPublic) => void;
  setCurrentStep: (value: number) => void;
  onChangeCountry: (value: string) => void;
  onChangeCNPJ: (value: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeStep: (value: 'next' | 'prev') => void;
  handleChangeProfile: (value: 'Cidadão' | 'Empresa') => void;
  handleChangeSetServiceType: (value: 'online' | 'presencial') => void;
  handleResetSchedulerProviderValues: () => void;
}

export const SchedulerStepContext = createContext({} as SchedulerStepContextType);

export function SchedulerStepProvider({ children }: SchedulerStepProviderProps): JSX.Element {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [cnpjNumber, setCnpjNumber] = useState<string | null>(null);
  const [companyCountry, setCompanyCountry] = useState<string | null>(null);
  const [userProfile, setUserProfile] = useState<'Cidadão' | 'Empresa' | null>(null);
  const [serviceType, setServiceType] = useState<
    'online' | 'presencial' | null
  >();

  const handleCheckPublic = (listPublic: ListPublic) => {
    if (listPublic?.length) {
      const countPublic = listPublic.filter((publ) => publ !== 'Cidadão').length;

      if (countPublic === 0) {
        setCurrentStep(1);
      }
    }
  };

  const handleStep = (type: 'next' | 'prev') => {
    setCurrentStep((oldState) => {
      if (type === 'next') {
        return oldState + 1;
      }
      return oldState - 1;
    });
  };

  const handleChangeCountry = useCallback((value: string) => {
    setCompanyCountry(value);
  }, []);

  const handleSetCNPJ = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 18) {
      setCnpjNumber(event.target.value);
    }
  }, []);

  const handleChangeProfile = (current: 'Cidadão' | 'Empresa') => {
    setUserProfile(current);
  };

  const handleChangeSetServiceType = (value: 'presencial' | 'online' | null) => {
    setServiceType(value);
    handleStep('next');
  };

  const handleResetSchedulerProviderValues = () => {
    setCurrentStep(0);
    setCompanyCountry(null);
    setCnpjNumber(null);
    setUserProfile(null);
  }

  const isVisibleButton = userProfile === 'Cidadão' || (userProfile === 'Empresa' && (typeof companyCountry) === 'string' && companyCountry === 'SALVADOR');

  return (
    <SchedulerStepContext.Provider
      value={{
        cnpjNumber,
        companyCountry,
        currentStep,
        userProfile,
        serviceType,
        isVisibleButton,
        handleCheckPublic: handleCheckPublic,
        setCurrentStep: setCurrentStep,
        handleChangeStep: handleStep,
        onChangeCNPJ: handleSetCNPJ,
        onChangeCountry: handleChangeCountry,
        handleChangeProfile,
        handleChangeSetServiceType,
        handleResetSchedulerProviderValues
      }}
    >
      {children}
    </SchedulerStepContext.Provider>
  );
}
