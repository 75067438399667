import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  AccordionDetails,
  Box,
  Typography,
  Switch
} from "@material-ui/core";
import { MdTextsms } from "react-icons/md";

type Props = {
  setEmailNotification: React.Dispatch<React.SetStateAction<boolean>>;
  emailNotification: boolean

};


const AccordionPreferencias: React.FC<Props> = ({ setEmailNotification, emailNotification,
}) => {

  const classes = useStyles();

  return (
    <AccordionDetails>
      <Box className={classes.container}>
        <Box className={classes.boxesPreferencias} >
          <Box className={classes.boxPrefencias}>
            <Box className={classes.contentBox}>
              <MdTextsms className={classes.icon} />
              <Box className={classes.textBoxPreferencias}>
                <Typography className={classes.title}>
                  Email
                </Typography>
                <span className={classes.textSpan}>Mensagem de notificação por email</span>
              </Box>
            </Box>

            <Switch checked={emailNotification}  color="primary"
              onChange={() => { setEmailNotification(!emailNotification) }} />
          </Box>
        </Box>
      {  /*<Box className={classes.boxesPreferencias}>
          <Box className={classes.boxPrefencias}>
            <Box className={classes.contentBox}>
              <MdTextsms className={classes.icon} />
              <Box className={classes.textBoxPreferencias}>
                <Typography className={classes.title}>
                  SMS
                </Typography>
                <span className={classes.textSpan}>Mensagem de notificação por SMS</span>
              </Box>
            </Box>

            <Switch color="primary"
              onChange={() => { setSmsNotification(!smsNotification) }} />
          </Box>

  </Box>*/}
      </Box>
    </AccordionDetails>
  )
}

export default AccordionPreferencias;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: "24px 0px",
    color: "#DDD",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: ' 20px',
  },
  boxesPreferencias: {
    border: "1px solid #d3d3d3",
    padding: '24px',
  },
  boxPrefencias: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  icon: {
    width: 24,
    height: 24,
    color: '#706E7A'
  },
  textBoxPreferencias: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: "Roboto",
    color: "#333333",
  },
  textSpan: {
    color: '#706E7A'
  },
  contentBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
  },
  switchCheckbox: {
    color: 'blue',
    '&.Mui-checked': {
     color: 'red'
    },
  }
}));
