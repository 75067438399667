import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Breadcrumbs, Typography, useMediaQuery } from "@material-ui/core";
import { MdHome } from 'react-icons/md';
import { Link } from "react-router-dom";

interface Props {
  links: Array<{
    name: string;
    redirectTo: string;
  }>;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    padding: "0px 10px",
    width: "100%",
  },
  breadcrumbs: {
    flexGrow: 1,
  },
  LinkIcon: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 18,
    height: 18,
  },
  textBreadcrumbs: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,

    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));

export default function Breadcrumb({ links }: Props): JSX.Element {
  const classes = useStyles();
  const matchesTablet = useMediaQuery("(max-width:770px)");

  return (
    <Box style={{ marginTop: matchesTablet && '20px' }} className={classes.main}>
      <Box
        display="flex"
        maxWidth="1250px"
        alignItems="center"
        width="100%"
        flexWrap="wrap"
      // justifyContent="center"
      >
        <Breadcrumbs separator="/" className={classes.textBreadcrumbs}>
          <Link to="/workspace" className={classes.LinkIcon}>
            <MdHome className={classes.icon} />
          </Link>
          {links.map(({ name, redirectTo }, i) => (
            <Link key={i} to={redirectTo} className={classes.textBreadcrumbs}>
              <Typography className={classes.textBreadcrumbs}>
                {name}
              </Typography>
            </Link>
          ))}
        </Breadcrumbs>
      </Box>
    </Box>
  );
}
