import { makeStyles, TableRow, Theme, Typography } from '@material-ui/core'
import { StyledTableCell } from '../..'

const StatusSolicitations = ({ request }) => {

  const listStatusRequests = [
    "Falha",
    "Complementação Cidadão",
    "Restituído",
    "Concluído",
    "Encaminhado",
    "Em Atendimento",
    "Reagendou",
    "Prorrogado",
    "Em Aberto",
    "Em Andamento",
    "Cancelado pelo atendente",
    "Arquivado",
    "Não Compareceu",
    "Cancelado",
    "Cancelado pelo usuário",
    "Compareceu",
    "Finalizado",
    "Respondido",
    "Reaberto",
    "Respondido(Reaberto)",
    "Aguardando",
    "Recurso",
    "Solicitado",
  ];
  const useStyles = makeStyles((theme: Theme) => ({
    status: {
      fontSize: 14,
      fontFamily: "Roboto",
      fontWeight: 500,
      textTransform: "capitalize",
      padding: "5px 16px",
      borderRadius: 25,
      [theme.breakpoints.down("sm")]: {
        width: "fit-content",
        marginBottom: 24,
        alignSelf: "center",
      },
    },
  }));
  const classes = useStyles();
  const getStatusStyles = (status) => {
    if (status === "Falha") {
      return {
        color: "#f00",
        border: "1px solid rgba(5, 51, 150, 0.05)",
        background: "rgb(255, 127, 127, 0.2)",
      };
    }
    if (status === "Solicitado" || status === "Recurso" || status === "Complementação Cidadão") {
      return {
        color: "#053396",
        border: "1px solid rgba(5, 51, 150, 0.05)",
        background: "rgba(5, 51, 150, 0.05)",
      };
    }
    if (status === "Aguardando" || status === "Respondido(Reaberto)" || status === "Reaberto" || status === "Respondido") {
      return {
        color: "#373F41",
        border: "1px solid rgba(55, 63, 65, 0.05)",
        background: "rgba(55, 63, 65, 0.05)",
      };
    }
    if (status === "Finalizado") {
      return {
        color: "#43A047",
        border: "1px solid rgba(67, 160, 71, 0.08)",
        background: "rgba(67, 160, 71, 0.08)",
      };
    }
    if (status === "Compareceu") {
      return {
        color: "#009207",
        border: "1px solid rgba(67, 160, 71, 0.08)",
        background: "rgba(67, 160, 71, 0.08)",
      };
    }
    if (status === "Cancelado pelo usuário" || status === "Cancelado" || status === "Não Compareceu" || status === "Arquivado" || status === "Cancelado pelo atendente") {
      return {
        color: "#F83245",
        border: "1px solid rgba(248, 50, 69, 0.08)",
        background: "rgba(248, 50, 69, 0.08)",
      };
    }
    if (status === "Em Andamento" || status === "Em Aberto" || status === "Prorrogado" || status === "Reagendou" || status === "Em Atendimento") {
      return {
        color: "#0F6FB7",
        border: "1px solid rgba(15, 111, 183, 0.05)",
        background: "rgba(15, 111, 183, 0.05)",
      };
    }
    if (status === "Encaminhado") {
      return {
        color: "#1f90e7",
        border: "1px solid rgba(15, 111, 183, 0.05)",
        background: "rgba(15, 111, 183, 0.05)",
      };
    }
    if (status === "Concluído") {
      return {
        color: "#3CD278",
        border: "1px solid rgba(60, 210, 120, 0.10)",
        background: "rgba(60, 210, 120, 0.10)",
      };
    }
    if (status === "Restituído") {
      return {
        color: "#06AED4",
        border: "1px solid rgba(60, 210, 120, 0.10)",
        background: "rgb(6, 174, 212, 0.10)",
      };
    }
    return {
      color: "#333",
      border: "1px solid rgba(5, 51, 150, 0.05)",
      background: "rgba(5, 51, 150, 0.05)",
    };
  };

  const getStatusText = (status) => {
    const statusMap = {
      'Aguardando': 'Solicitado',
      'Em Atendimento': 'Atendimento',
      'Complementação Cidadão': 'Complemento Cidadão',
    };

    return statusMap[status] || status; 
  };

  return (
    <>
      <StyledTableCell align="center">
        <Typography className={classes.status} style={getStatusStyles(request.status)}>
          {getStatusText(request?.status)}
        </Typography>
      </StyledTableCell>
    </>
  )
}

export default StatusSolicitations