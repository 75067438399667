import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Radio,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import QrCode from 'qrcode.react';
import { HistoryI } from '../../../../interfaces/agendamentos';
import { cancelar } from '../../../../services/agendamentoOrganico';
import dateFormatToBR from '../../../../utils/dateFormat';
import { cpfMask } from '../../../../utils/cpfFormat';
import { useToastValue } from '../../../../contexts/toastMessage';
import getCookie from '../../../../utils/getCookies';

export interface SimpleDialogProps {
  open: boolean;
  schedule: HistoryI;
  handleClose: () => void;
  onReload: (page: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxHeader: {
    backgroundColor: '#E53535',
  },
  textName: {
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  textCpf: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'capitalize',
  },
  textNumberCounter: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.1px',

  },
  textCounter: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    textTansform: 'uppercase',
  },
  textUnidade: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    textTransform: 'capitalize',
    color: '#737B7D',
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  textService: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#373F41',
  },
  textOrgan: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#373F41',
    paddingBottom: '20px',

  },
  textDateTime: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#373F41',
  },
  textTicket: {
    paddingTop: '20px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#373F41',
    paddingBottom: '20px',
  },
  btnConfirm: {
    backgroundColor: '#3CD278',
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '0',

    padding: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#00a04b',
    },
  },
  btnClose: {
    backgroundColor: '#b0bec5',
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '0',

    padding: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#808e95',
    },
  },
  boxCards: {
    marginTop: 48,
    display: 'flex',
    gap: 16,
    marginBottom: 80,
    [theme.breakpoints.down(660)]: {
      marginTop: 32,
      padding: '0px 10px',
      marginBottom: 50,
    },
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #DAE1EF',
    borderRadius: 4,
    padding: 0,
    cursor: 'pointer',
    height: 48,
    [theme.breakpoints.down(660)]: {
      padding: '22px 22px 32px 22px',
    },
  },
  boxContentModal: {
    width: '100%',
    padding: '20px 32px',
  },
  titleModal: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    lineHeight: '16px',
    textAlign: 'left',
    textTransform: 'uppercase',
    color: '#1F2937'
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  cardChecked: {
    borderColor: theme.palette.primary.main,
  },
  radioChecked: {
    color: theme.palette.primary.main,
  },
  radioInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 16,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    [theme.breakpoints.down(660)]: {
      fontSize: theme.typography.pxToRem(16),
    },
    fontFamily: 'Roboto',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#1F2937'
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    display: 'block',
    color: '#4F5766',
    lineHeight: '24px',
    [theme.breakpoints.down(660)]: {
      fontSize: theme.typography.pxToRem(14),
      maxWidth: '350px',
    },
  },
}));

export default function CancelDialog({ open, handleClose, onReload, schedule }: SimpleDialogProps) {
  const classes = useStyles();
  const { setToast } = useToastValue();
  const [loading, setLoading] = useState<boolean>(false);
  const [steps, setSteps] = useState<number>(0);
  const [typeCancel, setTypeCancel] = useState<{ title: string; key: string }>({
    title: '',
    key: ''
  });

  const handleCancel = async (id: number) => {
    const token: string | null = getCookie('gov_access_token_sso');

    setLoading(true);
    if (token) {
      try {
        const { data } = await cancelar(token, id);
        setLoading(false);
        if (data) {
          handleClose();
          onReload(1);
          setToast({
            visible: true,
            message: 'Cancelamento realizado com sucesso!',
            type: 'success'
          });
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const handleNextStep = useCallback(() =>
    setSteps((oldStep) => oldStep + 1), []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth

    >
      <DialogTitle
        id="alert-dialog-title"
        className={classes.boxHeader}

      >
        <Typography className={classes.textName}>Deseja cancelar o agendamento?</Typography>

      </DialogTitle>
      {steps === 0 && (
        <>
          <DialogContent style={{ borderRadius: '0' }}>
            <DialogContentText>

              <Grid container>
                <Grid item style={{ paddingBottom: '20px' }}>
                  <Typography
                    className={classes.textUnidade}
                  >
                    {schedule.unidade.nome.toLowerCase()}
                  </Typography>
                  <Typography
                    className={classes.textService}
                  >
                    {schedule.servico.titulo}
                  </Typography>

                  <Typography
                    className={classes.textOrgan}
                  >
                    {`Órgão: ${schedule.orgao_responsavel}`}
                  </Typography>

                  <Typography className={classes.textDateTime}>
                    Data da solicitação:

                    <Typography component="strong">
                      {' '}
                      {` ${dateFormatToBR(schedule.data)}`}
                    </Typography>
                  </Typography>

                  <Typography className={classes.textDateTime}>
                    Horário da solicitação:
                    {' '}
                    <Typography component="strong">{schedule.hora.slice(0, 5)}</Typography>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-around"
                alignItems="flex-start"
              >
                <Grid item xs={12} sm={12} md={8}>
                  {schedule.hora_inicio && (
                    <>
                      <Typography className={classes.textDateTime}>
                        Data do  atendimento:
                        <Typography component="strong">
                          {' '}
                          {` ${dateFormatToBR(schedule.data)}`}
                        </Typography>
                      </Typography>
                      <Typography className={classes.textDateTime}>
                        Horário  do  atendimento:
                        <Typography component="strong">
                          {' '}
                          {schedule.hora_inicio.slice(0, 5)}
                        </Typography>
                      </Typography>
                    </>
                  )}

                  <Typography className={classes.textDateTime} style={{ marginBottom: 20 }}>
                    Dependente: <Typography component="strong">{schedule.dependentes ? 'Sim' : 'Não'}</Typography>
                    {schedule.dependentes && (
                      <>
                        <Typography className={classes.textDateTime}>
                          Nome: <Typography component="strong">{schedule.dependentes.nome}</Typography>
                        </Typography>
                        <Typography className={classes.textDateTime}>
                          CPF: <Typography component="strong">{cpfMask(schedule.dependentes.cpf)}</Typography>
                        </Typography>
                        <Typography className={classes.textDateTime}>
                          Grau de parentesco: <Typography component="strong">{schedule.dependentes.grau_parentesco}</Typography>
                        </Typography>
                      </>
                    )}
                  </Typography>

                  <Typography className={classes.textDateTime}>
                    Status do atendimento:
                    <Typography component="strong">
                      {' '}
                      {schedule.status}
                    </Typography>
                  </Typography>
                  <Typography className={classes.textTicket}>{schedule.ticket}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <QrCode value={`${process.env.REACT_APP_URL}/comprovante/agendamento/${schedule.ticket}`} size={180} />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {loading ? (
              <Box
                marginBottom="60px"
                width="100%"
                display="flex"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Button variant="contained" color="primary" className={classes.btnConfirm} onClick={handleNextStep}>
                  Sim, eu quero cancelar
                </Button>
                <Button onClick={handleClose} variant="contained" color="primary" className={classes.btnClose}>
                  Não, eu não quero cancelar
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
      {steps === 1 && (
        <>
          <Box className={classes.boxContentModal}>
            <DialogContentText>
              <Typography className={classes.titleModal}>
                Informe o motivo do cancelamento.
              </Typography>
            </DialogContentText>
            <Grid container className={classes.boxCards}>
              <Grid item xs={12}>
                <Box
                  className={`
                  ${typeCancel.key === 'desistir' ?
                      `${classes.card} ${classes.cardChecked}` :
                      classes.card}`}
                  onClick={() => setTypeCancel({
                    title: 'Desisti do atendimento',
                    key: 'desistir'
                  })}
                >
                  <Box className={classes.cardHeader}>
                    <Radio
                      color="default"
                      checked={typeCancel.key === 'desistir'}
                      onClick={() => setTypeCancel({
                        title: 'Desisti do atendimento',
                        key: 'desistir'
                      })}
                      value="user"
                      name="radio-button"
                      classes={{ checked: classes.radioChecked }}
                    />
                    <Box className={classes.radioInfo}>
                      <Typography className={classes.title}>
                        Desisti do atendimento
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  className={`
                  ${typeCancel.key === 'atendido' ?
                      `${classes.card} ${classes.cardChecked}` :
                      classes.card}`}
                  onClick={() => setTypeCancel({
                    title: 'Já fui atendido',
                    key: 'atendido'
                  })}
                >
                  <Box className={classes.cardHeader}>
                    <Radio
                      color="default"
                      checked={typeCancel.key === 'atendido'}
                      onClick={() => setTypeCancel({
                        title: 'Já fui atendido',
                        key: 'atendido'
                      })}
                      value="Anônimo"
                      name="radio-button"
                      classes={{ checked: classes.radioChecked }}
                    />
                    <Box className={classes.radioInfo}>
                      <Typography className={classes.title}>Já fui atendido</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  className={`
                   ${typeCancel.key === 'servico_errado' ?
                      `${classes.card} ${classes.cardChecked}` :
                      classes.card}`}
                  onClick={() => setTypeCancel({
                    title: 'Escolhi o serviço errado',
                    key: 'servico_errado'
                  })}
                >
                  <Box className={classes.cardHeader}>
                    <Radio
                      color="default"
                      checked={typeCancel.key === 'servico_errado'}
                      onClick={() => setTypeCancel({
                        title: 'Escolhi o serviço errado',
                        key: 'servico_errado'
                      })}
                      value="Anônimo"
                      name="radio-button"
                      classes={{ checked: classes.radioChecked }}
                    />
                    <Box className={classes.radioInfo}>
                      <Typography className={classes.title}>Escolhi o serviço errado</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  className={`
                  ${typeCancel.key === 'data_errada' ?
                      `${classes.card} ${classes.cardChecked}` :
                      classes.card}`}
                  onClick={() => setTypeCancel({
                    title: 'Data inadequada',
                    key: 'data_errada'
                  })}
                >
                  <Box className={classes.cardHeader}>
                    <Radio
                      color="default"
                      checked={typeCancel.key === 'data_errada'}
                      onClick={() => setTypeCancel({
                        title: 'Data inadequada',
                        key: 'data_errada'
                      })}
                      value="Anônimo"
                      name="radio-button"
                      classes={{ checked: classes.radioChecked }}
                    />
                    <Box className={classes.radioInfo}>
                      <Typography className={classes.title}>Data inadequada</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <DialogActions>
            {loading ? (
              <Box
                marginBottom="60px"
                width="100%"
                display="flex"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btnConfirm}
                  onClick={() => handleCancel(schedule.id)}
                  disabled={!typeCancel.key}
                >
                  confirmar
                </Button>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  className={classes.btnClose}
                >
                  desfazer cancelamento
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
