import React from 'react';
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Radio,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Person, Group } from '@material-ui/icons';

interface Props {
  open: boolean;
  onClose: () => void;
  isForMy: boolean;
  setIsForMy: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    margin: 40,
  },
  contentTitle: {
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'center',
  },
  titleDialog: {
    color: '#333333',
    fontSize: 24,
    fontFamily: 'Roboto',
    fontWeight: 700,
    margin: 0,
  },
  contentSubTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  subTitle: {
    color: '#7A7786',
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(16),
  },
  boxActions: {
    display: 'flex',
    gap: '5px',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32,
  },
  contentActions: {
    maxWidth: 252,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 18px',
    border: `1px solid ${theme.palette.primary.main}`,
    height: 56,
    borderRadius: 4,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  contentActionsDisabled: {
    cursor: 'pointer',
    maxWidth: 252,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 18px',
    border: `1px solid #E1E6EF`,
    height: 56,
    borderRadius: 4,
    color: '#706E7A',
  },
  titleOption: {
    color: '#333333',
    fontSize: theme.typography.pxToRem(16),
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  icon: {
    fontSize: 22,
    marginRight: 20,
  },
  contentIconWithText: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    height: 48,
    marginTop: 32,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    }
  },
  textButton: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
    color: '#fff',
    fontWeight: 500,
  },
  radio: {
    color: theme.palette.primary.main,
  }
}));


export default function RequestModal({
  open,
  onClose,
  isForMy,
  setIsForMy
}: Props): JSX.Element {
  const classes = useStyles();
  const matchesMobile = useMediaQuery("(min-width:660px)");

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box className={classes.main}>
        <div className={classes.contentTitle}>
          <Typography className={classes.titleDialog}>Para quem é esta solicitação?</Typography>
        </div>
        <Box className={classes.contentSubTitle}>
          <Typography className={classes.subTitle}>
            Selecione um perfil abaixo para continuar
          </Typography>
        </Box>
        <Box className={classes.boxActions} style={{ flexDirection: matchesMobile ? 'row' : 'column' }}>
          <Box onClick={() => setIsForMy(true)} className={(isForMy !== undefined && isForMy) ? classes.contentActions : classes.contentActionsDisabled}>
            <div className={classes.contentIconWithText}>
              <Person className={classes.icon} />
              <Typography className={classes.titleOption}>Para mim</Typography>
            </div>
            <Radio color="primary" checked={(isForMy !== undefined && isForMy) ? true : false} />
          </Box>
          <Box onClick={() => setIsForMy(false)} className={(isForMy !== undefined && !isForMy) ? classes.contentActions : classes.contentActionsDisabled}>
            <div className={classes.contentIconWithText}>
              <Group className={classes.icon} />
              <Typography className={classes.titleOption}>Outra pessoa</Typography>
            </div>
            <Radio color="primary" checked={(isForMy !== undefined && !isForMy) ? true : false} />
          </Box>
        </Box>
        <Button
          className={classes.button}
          onClick={(): void => onClose()}
          disabled={!(isForMy !== undefined)}
          variant='contained'
        >
          <Typography className={classes.textButton}>selecionar</Typography>
        </Button>
      </Box>
    </Dialog>
  );
}
