import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Grid,
  Container,
  IconButton,
  Paper,
  Tabs,
  Tab,
  Icon,
} from '@material-ui/core';
import { MdArrowBack } from "react-icons/md";
import { Breadcrumb, NavMenu } from '../../Components';
import { AttendenceRequest, SchedulingRequest, SchedulingInternal } from '..';
import OrganicSchedulingRequest from '../OrganicSchedulingRequest';

type Props = {
  history: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  gridMain: {
    margin: 0
  },
  tabsInfo: {
    justifyContent: 'center',
    minWidth: 960,
    height: 47,
    borderRadius: 4,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  boxIndicatorsBack: {
    width: '100%',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  indicatorsBack: {
    display: 'flex',
    width: '100%',
    color: '#373F41',
    maxWidth: '1250px',
    alignItems: 'center',
  },
  tableInformation: {
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  flexContainer: {
    alignItems: 'center',
    height: '56px',
    background: theme.palette.text.primary,
    color: theme.palette.primary.main,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  tabContainer: {
    minWidth: 300,
    height: 56,
    marginTop: 0,
    padding: 5,
    borderRadius: 4,
    [theme.breakpoints.down('md')]: {
      minWidth: 56,
    },

    '& .MuiTab-wrapper': {
      flexDirection: 'row',
    },
  },
  labelIcon: {
    paddingTop: 0,
    minHeight: 0,
  },
  indicator: {
    width: 0,
    height: 0,
  },
  textLabeltab: {
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '20px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  tabIcon: {
    marginRight: 11,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
}));

export default function MyScheduling({ history }: Props): JSX.Element {
  const classes = useStyles();
  const [selectTab, setSelectTab] = useState(0);

  const handleChange = (value, newValue) => {
    setSelectTab(newValue);
  };

  const linksBreadcrumb = [
    {
      name: 'Minha conta',
      redirectTo: '/workspace',
    },
    {
      name: 'Meus agendamentos',
      redirectTo: '#',
    },
  ];
  return (
    <>
      <NavMenu />
      <Container>
        <Grid container spacing={3} className={classes.gridMain}>
          <Grid item xs={12}>
            <Breadcrumb links={linksBreadcrumb} />
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <IconButton
                  onClick={() => history.push("/workspace")}
                >
                  <MdArrowBack />
                </IconButton>
                <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                  Meus agendamentos
                </Typography>
              </Box>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Paper variant="outlined" elevation={0}>
                <Box>
                  <Tabs
                    value={selectTab}
                    indicatorColor="primary"
                    centered={true}
                    textColor="inherit"
                    scrollButtons="off"
                    onChange={handleChange}
                    classes={{
                      flexContainer: classes.flexContainer,
                    }}
                    TabIndicatorProps={{ className: classes.indicator }}
                  >
                    <Tab
                      classes={{
                        selected: classes.selected,
                        labelIcon: classes.labelIcon,
                      }}
                      className={classes.tabContainer}
                      icon={
                        <Icon
                          className={`${classes.tabIcon} material-icons-round`}
                        >
                          event
                        </Icon>
                      }
                      label={
                        <Typography className={classes.textLabeltab}>
                          agendamentos presenciais
                        </Typography>
                      }
                    />

                    <Tab
                      classes={{
                        selected: classes.selected,
                        labelIcon: classes.labelIcon,
                      }}
                      className={classes.tabContainer}
                      icon={
                        <Icon
                          className={`${classes.tabIcon} material-icons-round`}
                        >
                          support_agent
                        </Icon>
                      }
                      label={
                        <Typography className={classes.textLabeltab}>
                          agendamentos online
                        </Typography>
                      }
                    />
                    <Tab
                      classes={{
                        selected: classes.selected,
                        labelIcon: classes.labelIcon,
                      }}
                      className={classes.tabContainer}
                      icon={
                        <Icon
                          className={`${classes.tabIcon} material-icons-round`}
                        >
                          move_to_inbox
                        </Icon>
                      }
                      label={
                        <Typography className={classes.textLabeltab}>
                          agendamentos internos
                        </Typography>
                      }
                    />
                    <Tab
                      classes={{
                        selected: classes.selected,
                        labelIcon: classes.labelIcon,
                      }}
                      className={classes.tabContainer}
                      icon={
                        <Icon
                          className={`${classes.tabIcon} material-icons-round`}
                        >
                          date_range
                        </Icon>
                      }
                      label={
                        <Typography className={classes.textLabeltab}>
                          Atendimento espontâneo
                        </Typography>
                      }
                    />
                  </Tabs>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          {selectTab === 0 && <SchedulingRequest />}
          {selectTab === 1 && <AttendenceRequest />}
          {selectTab === 2 && <SchedulingInternal />}
          {selectTab === 3 && <OrganicSchedulingRequest />}

        </Grid>
      </Container>
    </>
  );
}
