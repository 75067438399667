import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons'

interface Props {
  open: boolean;
  slugService: string;
  onClose: () => void;
  onReload: () => void;
  requestError: any;
  servicoAtivo: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    margin: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  contentTitle: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  titleDialog: {
    color: '#333333',
    fontSize: theme.typography.pxToRem(24),
    fontFamily: 'Roboto',
    fontWeight: 700,
    margin: 0,
  },
  aviso: {
    marginTop: '15px',
    color: '#b71f1f',
    fontSize: theme.typography.pxToRem(16),
    fontFamily: 'Roboto',
    fontWeight: 700,
    margin: 0,
  },
  contentSubTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  subTitle: {
    color: '#7A7786',
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(16),
  },
  button: {
    width: '100%',
    height: 48,
    marginTop: 32,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    }
  },
  textButton: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
    color: '#fff',
    fontWeight: 500,
  },
  textSimple: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 500,
    textDecoration: 'none',
    marginTop: 20
  },
  InfoIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(60)
  },
}));


export default function ReloadModal({
  open,
  slugService,
  onClose,
  onReload,
  requestError,
  servicoAtivo
}: Props): JSX.Element {
  const classes = useStyles();

  const retry = useMemo(() => {
    if (requestError?.retry === false) { return false }
    return true
  }, [requestError]);
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box className={classes.main}>
        <InfoRounded className={classes.InfoIcon} />
        <div className={classes.contentTitle}>
          <Typography
            className={classes.titleDialog}>
            Detectamos uma falha de comunicação.
          </Typography>
          {requestError && !servicoAtivo && (
            <>
              <Typography
                className={classes.aviso}>
                O período de envio da declaração está indisponível.
                Entre em contato com o SEGEP do seu órgão para mais informações.
              </Typography>
            </>
          )}

        </div>
        {retry && (<>
          <Button
            className={classes.button}
            onClick={(): void => {
              onReload();
              onClose();
            }}
            variant='contained'
          >
            <Typography className={classes.textButton}>Tentar novamente</Typography>
          </Button>
        </>)}

        <a className={classes.textSimple} href={`/servico/${slugService}`}>
          Retornar para o serviço
        </a>
      </Box>
    </Dialog>
  );
}
