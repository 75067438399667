import { useState } from "react";
import {
  Recommendation,
  Recommendations,
} from "../../interfaces/recommendations";
import { UserData } from "../../interfaces/user";
import { getRecommendations } from "../../services/recommendations";
import getCookie from "../../utils/getCookies";

export const useMySpace = () => {
  const user: UserData = JSON.parse(getCookie("gov_user_data"));
  const token: string | null = getCookie("gov_access_token_sso");
  const [items_size] = useState<number>(3);
  const [page, setPage] = useState<number>(1);
  const [recommendation, setRecommendation] = useState<Recommendations | null>(
    null
  );
  const [recommendations, setRecommendations] = useState<
    Recommendation[] | null
  >(null);

  const handleGetRecommendations = async () => {
    try {
      const response = await getRecommendations(token, { page, items_size });
      if (response.status === 200) {
        const value: Recommendations = response.data;
        setRecommendation(response.data);
        setRecommendations((prev) => {
          if (prev) {
            return [...prev, ...value.results];
          }
          return value.results;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    user,
    page,
    setPage,
    recommendation,
    recommendations,
    handleGetRecommendations,
  };
};
