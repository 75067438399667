// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createTheme, Theme } from '@material-ui/core';

function theme(): Theme {
  const materialTheme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: process.env.REACT_APP_COLOR_PRIMARY,
        dark: process.env.REACT_APP_COLOR_PRIMARY,
      },
    },
    overrides: {

      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
        },
        toolbarBtn: {
          color: process.env.REACT_APP_COLOR_PRIMARY,
        },
      },
      MuiPickersDay: {
        day: {
          color: '#333',
        },
        daySelected: {
          backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
          color: '#fff',
        },
        dayDisabled: {
          color: '#d4d4d4',
        },
        current: {
          color: '#333',
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: '#333',
        },
      },
    },
  });
  return materialTheme;
};

export { theme as default };
