import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from '@material-ui/core';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './services/theme-service';

(window as any).renderWorkspace = (containerId: string, history: any, themeWorkspace: any) => {


  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById(containerId),
  );
};

(window as any).unmountWorkspace = (containerId: string) => {
  ReactDOM.unmountComponentAtNode((document as any).getElementById(containerId));
};

if (!document.getElementById('Workspace-container')) {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>,
    document.getElementById('root'),
  );
}

serviceWorker.unregister();
