import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Iterm, ItermsTerms } from '../../../../interfaces/termos';
import { MdClose } from "react-icons/md";
import { sanitizeHtml } from '../../../../utils/sanitizeHtml';

interface Props {
  term: ItermsTerms | undefined;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  textHeaderModal: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    width: '90%',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 700,
  },
  header: {
    background: theme.palette.primary.main,
  },
  buttonClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: '#fff',
  },
  boxText: {
    color: '#373F41',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0.25,
  }
}));


export default function TermoDialog({ term, setOpen, open }: Props): JSX.Element {
  const classes = useStyles();
  const [termSelected, setTermSelected] = useState<Iterm>();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (term?.termo) {
      return setTermSelected(term?.termo);
    }
    return setTermSelected(undefined);
  }, [term]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        onClick={handleClose}
        className={classes.buttonClose}
      >
        <MdClose />
      </IconButton>
      <DialogTitle
        className={classes.header}
      >
        <Typography className={classes.textHeaderModal}>
          Termos de aceite
        </Typography>
      </DialogTitle>
      <DialogContent>
        {termSelected ? (
          <Box key={termSelected?.id}>
            <Typography variant="h4">
              {termSelected?.titulo}
              {' '}
              - Versão (
              {termSelected?.versao}
              )
            </Typography>
            <Box dangerouslySetInnerHTML={{ __html: sanitizeHtml(termSelected?.texto) }} className={classes.boxText} />
          </Box>
        )
          : 'Sem descrição!'}
      </DialogContent>
    </Dialog>
  );
}
