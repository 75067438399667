export const listStatusHistory = [
  "Falha",
  "Aguardando",
  "Em Andamento",
  "Arquivado",
  "Cancelado",
  "Encaminhado",
  "Em Aberto",
  "Complementação Cidadão",
  "Reaberto",
  "Recebido",
  "Concluído",
  "Solicitado",
  "Respondido",
  "Respondido(Reaberto)",
  "Restituído",
  "Complementação Cidadão",
];
