import React, { useContext, useEffect, useRef } from 'react';
import { getCEPByLatLng } from '../../../../services/geoLocation';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import colorContext from '../../../../contexts/colorContext';


interface Geolocation {
  latitude: number;
  longitude: number;
}

interface MapComponentProps {
  geolocation: Geolocation;
  setCepNumber?: any,
  editavel?: boolean
  precision: boolean;
  setPrecision: (precision: boolean | ((prev: boolean) => boolean)) => void;
  setGeolocation: (geolocation: Geolocation) => void;
  setAddressMapa: (address) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  inputCheckbox: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Roboto',
  },
}));

export default function MapComponent({
  geolocation,
  setCepNumber,
  editavel,
  precision,
  setPrecision,
  setGeolocation,
  setAddressMapa
}: MapComponentProps): JSX.Element {
  const { colors } = useContext(colorContext);
  const classes = useStyles();
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const getCEP = async (latitude, longitude) => {
    try {
      const { data } = await getCEPByLatLng(latitude, longitude);
      if (data) {
        setCepNumber(data.address.postcode);
        setAddressMapa(prevState => ({
          ...prevState,
          bairro: data.address.suburb,
          município: data.address.city,
          logradouro: data.address.road
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addMarkerAndCenterMap = (lat: number, lng: number, map: any) => {
    if (markerRef?.current) {
      map.removeLayer(markerRef?.current);
    }
    markerRef.current = (window as any).L.marker([lat, lng]).addTo(map);

    map.setView([lat, lng], 16);
    getCEP(lat, lng);
  };



  useEffect(() => {
    const L = (window as any).L;
    if (!L) {
      console.error('Leaflet não está disponível.');
      return;
    }
    if (!mapRef.current) {
      console.error('Elemento de referência do mapa não está disponível.');
      return;
    }
    const map = L.map(mapRef.current).setView(
      [geolocation.latitude || -12.9714, geolocation.longitude || -38.5014],
      16
    );
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);
    const bounds = [
      [-16.0, -39.0],
      [-11.0, -37.0],
    ];
    map.setMaxBounds(bounds);
    map.on('drag', () => map.panInsideBounds(bounds, { animate: false }));
    if (geolocation.latitude && geolocation.longitude) {
      addMarkerAndCenterMap(geolocation.latitude, geolocation.longitude, map);
    }
    const handleMapClick = (e: any) => {
      const { lat, lng } = e.latlng;
      if (lat !== geolocation.latitude || lng !== geolocation.longitude) {
        setGeolocation({ latitude: lat, longitude: lng });
      }
      addMarkerAndCenterMap(lat, lng, map);
    };
    map.on('click', handleMapClick);
    return () => {
      map.off('click', handleMapClick);
      if (markerRef.current) {
        map.removeLayer(markerRef.current);
      }
      map.remove();
    };
  }, [geolocation, precision]);



  return (
    <>
      <FormControlLabel
        className={classes.inputCheckbox}
        control={
          <Checkbox
            checked={precision}
            onChange={() => setPrecision((prevState) => !prevState)}
            name="precision"
            style={{ color: colors.accentColor }}
            disabled={!editavel}
          />
        }
        label=" Selecionar local exato no mapa."
      />
      {precision && (<div ref={mapRef} style={{ height: '250px', width: '100%' }}></div>)}

    </>
  )
}
