import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  makeStyles
} from '@material-ui/core';
import { SearchI } from '../../../../../../interfaces/servico';

interface defaultLocal {
  nome: string;
  orgao: number;
  slug_unidade: string;
}

interface FieldLocalProps {
  defaultLocal: defaultLocal
  service: SearchI
  getSchedulers: (date: Date, value: string) => Promise<void>
  selectedDate: Date | null
  handleGetUnitySelected: (value: string) => void
}

export default function FieldLocal(
  {
    defaultLocal,
    service,
    getSchedulers,
    selectedDate,
    handleGetUnitySelected,
  }: FieldLocalProps): JSX.Element {

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: '#d3d3d3',
        },
      },
      '& .MuiSelect-iconOutlined': {
        height: 24,
        width: 24,
      },
    },
    formControl: {
      width: '100%',
    },
    menuItem: {
      color: theme.palette.primary.main,
    },
  }))

  const classes = useStyles()

  return (
    <FormControl
      classes={{ root: classes.root }}
      variant="outlined"
      className={classes.formControl}
    >
      <InputLabel id="demo-simple-select-outlined-label">
        Selecione o local de atendimento
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        onChange={(event): void => {
          handleGetUnitySelected(event.target.value as string);
          getSchedulers(selectedDate, event.target.value as string)
        }}
        defaultValue={defaultLocal.slug_unidade}
        label="Selecione o local de atendimento"
        classes={{
          select: classes.menuItem,
        }}
      >
        {service && service.servicosUnidade
          ?.filter((unity) => unity.unidade.ativo)
          ?.map((unity) => (
            <MenuItem
              key={unity.id}
              className={classes.menuItem}
              value={unity.unidade.slug_unidade}
            >
              {unity.unidade.nome}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}