import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogContentText,
  Typography,
  Box,
  IconButton,
  DialogActions,
  CircularProgress,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MdClose } from 'react-icons/md';
import { BiCalendarEvent } from 'react-icons/bi';
import { format, isValid } from 'date-fns';
import { FieldDate, FieldLocal, FieldTime } from './components';
import { SearchI } from '../../../../interfaces/servico';
import { getService, } from '../../../../services/servico';
import ToastMessage from '../../../../Components/ToastMessage';
import { useToastValue } from '../../../../contexts/toastMessage';
import { ToastMessageProvider } from '../../../../contexts/toastMessage';
import { HistoryI } from "../../../../interfaces/agendamentos";
import SummaryModal from './components/SummaryModal';

import { checarHorarios, sendReschedule } from '../../../../services/agendamento';
import { UserData } from '../../../../interfaces/user';
import getCookie from '../../../../utils/getCookies';
import { IMyAttendences } from '../../../../interfaces/atendimento';

export interface Attendence {
  horarios: string[];
}

export interface SavedAttendenceProps {
  cpf: string;
  data: string;
  data_solicitacao: string;
  guiche: number;
  hora: string;
  hora_inicio: string;
  id: number;
  para_dependente: boolean;
  prioridade: boolean;
  servico: number;
  setor: string;
  status: string;
  ticket: string;
  tipo: string;
  unidade: string;
  updated_at: string;
  user: number;
}

export interface SimpleDialogProps {
  open: boolean;
  attendence: IMyAttendences | HistoryI;
  handleClose: () => void
  handleCompletedScheduled: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    fontFamily: 'Roboto',
    fontWeight: 600,
    color: '#1F2937',
    padding: '24px 24px 8px 24px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      '& > h2': {
        fontSize: theme.typography.pxToRem(16)
      }
    }
  },
  contentText: {
    fontFamily: 'Roboto',
    padding: 0,
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      '& > p': {
        fontSize: theme.typography.pxToRem(12)
      }

    }
  },
  dialogActions: {
    padding: '8px 24px 24px 24px',
    gap: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  cancelBtn: {
    color: theme.palette.primary.main,
    padding: '12px 16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }

  },
  confirmBtn: {
    padding: '12px 16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  boxHeader: {
    backgroundColor: theme.palette.primary.main,
  },

  boxIconAndTitle: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
  },

  title: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '22.4px',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: '#ffff'
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.0),
    top: theme.spacing(0.0),
    color: '#FFFFFF',
  },
  boxDocuments: {
    padding: '1.5rem 2rem',
    marginBottom: '1.5rem',
    borderBottom: '1px solid #DAE1EF'
  },
  documentsTitle: {
    fontFamily: 'Roboto',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    textTransform: 'uppercase',

  },
  documentsService: {
    fontFamily: 'Roboto',
    color: '#1F2937',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
  },
  orgaoAndService: {
    fontFamily: 'Roboto',
    color: '#1F2937A3',
    fontSize: theme.typography.pxToRem(17),
    fontWeight: 500,
    lineHeight: '24.4px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '2rem 2rem 2rem 2rem',
  },
  formTitle: {
    textTransform: 'uppercase',
    color: '#333333',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: '0.5rem'
  },
  textField: {
    maxWidth: '33.5rem',
  },
  input: {
    color: theme.palette.primary.main
  },
  radioGroup: {
    color: '#1F2937A3',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: 1.5,
  },
  gridContent: {
    padding: '25px 20px',
    borderBottom: '1px solid #DAE1EF'
  },
  boxOrganAndService: {
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: ' 5px',
  },
  infoDialogBox: {
    border: '1px solid #DAE1EF',
    margin: '20px 0px',
    color: '#333333'
  },
  boxButtonInfo: {
    display: 'flex',
    width: '100%',
    alignItens: 'center',
    justifyContent: 'center',
  }

}));

export default function ReAttendenceDialog(
  { open,
    attendence,
    handleClose,
    handleCompletedScheduled,
  }: SimpleDialogProps) {
  const { toast, setToast } = useToastValue()
  const [openAttendance, setOpenAttendance] = useState(false)
  const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const [openSummaryModal, setOpenSummaryModal] = useState(false)
  const [service, setService] = useState<SearchI>();
  const [attendenceService, setAttendenceService] = useState<
    string | Attendence | undefined
  >();
  const [checked, setChecked] = useState(false);
  const [attendenceServiceUser, setAttendenceServiceUser] = useState<
    SavedAttendenceProps | string | undefined
  >();
  const [selectedTime, setSelectedTime] = useState('');

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [unitySelected, setUnitySelected] = useState('');
  const [paraDependente, setParaDependente] = useState<boolean>();
  const [loading, setLoading] = useState(false)

  const classes = useStyles();
  
  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleGetUnitySelected = (value: string) => {
    setUnitySelected(value)
  }
  const handleParaDependente = (value: string) => {
    if (value === 'titular') {
      setParaDependente(false)
    } else if (value === 'dependente') {
      setParaDependente(true)
    }
  }


  const handleGetSeletedDate = (date: Date) => {
    setSelectedDate(date)
  }

  const handleGetSeletedTime = (time: string) => {
    setSelectedTime(time)
  }

  const getServiceEspecific = async () => {
    setService(undefined);

    try {
      const { data } = await getService(attendence.servico.slug);
      setService(data.results[0]);
    } catch (err) {
    }
  };

  const getAttendencers = async (date: Date, unity: string): Promise<void> => {
    setAttendenceService(undefined);
    setAttendenceServiceUser(undefined);

    if (isValid(date)) {
      try {
        const { data } = await checarHorarios(date, unity, service.id, attendence.tipo);
        setAttendenceService(data);
      } catch (err) {
        if (err.response) {
          setAttendenceService(err.response.data.message);
        } else {
          setAttendenceService('Algo inesperado ocorreu');
        }
      }
    } else {
      setAttendenceService(undefined);
    }
  };


  const handleSendReschudle = async () => {
    setLoading(true)

    const token: string | null = getCookie(
      'gov_access_token_sso',
    );


    let dependentes = undefined
    if (attendence.dependentes) {

      const dataStorage: string | null = getCookie("gov_user_data");
      const userData: UserData | null = dataStorage
        ? JSON.parse(dataStorage)
        : null;
      const { cpf, nome, data_nascimento, grau_parentesco } = attendence.dependentes
      dependentes = {
        cpf,
        nome,
        data_nascimento: format(new Date(data_nascimento), 'yyyy-MM-dd'),
        cpf_solicitante: userData.cpf,
        grau_parentesco,
      }
    }
    const selectedDateFormated = format(new Date(selectedDate), 'yyyy-MM-dd')

    try {
      const { status } = await sendReschedule(
        token,
        attendence.id,
        service.id,
        unitySelected,
        selectedDateFormated,
        selectedTime,
        attendence.tipo,
        !!attendence.dependentes,
        dependentes
      );
      if (status === 201) {
        setLoading(false)
        setToast(
          {
            message: 'Concluído',
            description: 'Reagendamento realizado com sucesso!',
            type: 'success',
            visible: true
          })
      }

    } catch (err) {
      setLoading(false)
      if (err?.response?.data?.message) {
        setAttendenceServiceUser(err.response.data.message);
      } else if (err?.response?.data?.length > 0) {
        console.log(err.response.data);
        setAttendenceServiceUser(err.response.data[0]);
      } else {
        setAttendenceServiceUser('Algo inesperado ocorreu.');
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getServiceEspecific();
    setSelectedTime('');
    setUnitySelected(attendence.unidade.slug_unidade)
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth>
        <DialogTitle
          id="alert-dialog-title"
          className={classes.boxHeader}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <MdClose style={{ width: '1.5rem', height: '1.5rem' }} />
          </IconButton>
          <Box component='header' className={classes.boxIconAndTitle} >
            <BiCalendarEvent style={{ width: '1.5rem', height: '1.5rem' }} />
            <Typography className={classes.title}>
              REAGENDAMENTO
            </Typography>
          </Box>
        </DialogTitle>
        <Box className={classes.gridContent}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Box className={classes.boxOrganAndService}>
                <Typography
                  component='p' className={classes.orgaoAndService}>
                  <strong style={{ color: 'black' }}>Orgão: </strong>{attendence.orgao_responsavel}
                </Typography>
                <Typography
                  component='h4'
                  className={classes.orgaoAndService}>
                  <strong style={{ color: 'black' }}>Serviço: </strong>{attendence.servico.titulo}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                <FormControl className={classes.radioGroup}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="titular"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel disabled checked={!attendence.dependentes} value={'titular'} control={<Radio />} label="Titular" />

                    <FormControlLabel disabled checked={!attendence.dependentes === false} value={'dependente'} control={<Radio />} label="Dependente" />


                  </RadioGroup>
                </FormControl>
              </Box>

            </Grid>
          </Grid>
        </Box>


        <Box className={classes.form}>
          <Typography
            component='h2'
            className={classes.formTitle}>
            Faça seu reagendamento
          </Typography>
          <FieldLocal
            defaultLocal={attendence.unidade}
            service={service}
            getAttendencers={getAttendencers}
            selectedDate={selectedDate}
            handleGetUnitySelected={handleGetUnitySelected}
          />
          <FieldDate
            attendenceService={attendenceService}
            attendenceServiceUser={attendenceServiceUser}
            handleGetSeletedDate={handleGetSeletedDate}
            getAttendencers={getAttendencers}
            unitySelected={unitySelected}
            selectedDate={selectedDate} />
          <FieldTime
            attendenceService={attendenceService}
            attendenceServiceUser={attendenceServiceUser}
            handleGetSeletedTime={handleGetSeletedTime}
            unitySelected={unitySelected}
            selectedDate={selectedDate} />
          <DialogActions
            className={classes.dialogActions}
            style={{ marginTop: '0.5rem', padding: 0 }}>
            <Button
              type='button'
              variant='contained'
              color='primary'
              disabled={!selectedDate || !selectedTime}
              onClick={() => setOpenAttendance(true)}
              className={classes.confirmBtn}>
              {loading ? <CircularProgress color='inherit' />
                : 'Reagendar'
              }
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={openAttendance}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth>
        <DialogTitle className={classes.dialogTitle}>Reagendar atendimento</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={classes.contentText}>
            Você tem certeza que deseja reagendar este atendimento?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant='text'
            onClick={handleClose}
            className={classes.cancelBtn}>
            não
          </Button>
          <Button variant='contained'
            color='primary'
            onClick={() => { setOpenInfoDialog(!openInfoDialog) }}
            className={classes.confirmBtn}>
            sim, desejo reagendar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openInfoDialog}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth>
        <DialogTitle
          id="alert-dialog-title"
          className={classes.boxHeader}>
          <Box component='header' className={classes.boxIconAndTitle} >
            <BiCalendarEvent style={{ width: '1.5rem', height: '1.5rem' }} />
            <Typography className={classes.title}>
              INFORMAÇÃO
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={classes.contentText}>
            <Box className={classes.infoDialogBox}>
              <Checkbox
                checked={checked}
                onChange={handleChangeCheckBox}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              Li os documentos necessários ao atendimento
            </Box>

          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Box className={classes.boxButtonInfo}>
            <Button variant='contained'
              color='primary'
              onClick={() => {
                handleSendReschudle()
                setOpenSummaryModal(true)
              }}
              className={classes.confirmBtn}
              disabled={!checked}
            >
              Agendar
            </Button>
          </Box>

        </DialogActions>
      </Dialog>
      <ToastMessageProvider>
        <ToastMessage />
      </ToastMessageProvider>
      <SummaryModal
        openSummaryModal={openSummaryModal}
        handleClose={handleClose}
        orgao={attendence.orgao_responsavel}
        servico={attendence.servico.titulo}
        dependente={attendence?.dependentes?.nome}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        paraDependente={paraDependente}
        user={attendence.user.first_name}
        handleCompletedScheduled={handleCompletedScheduled}
      />
    </>
  );
}
