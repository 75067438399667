import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Grid,
  Container,
  IconButton,
  FormControl,
  Button,
  OutlinedInput,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import { MdArrowBack, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Breadcrumb } from '../../Components';

type Props = {
  history: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
  },
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  boxIndicatorsBack: {
    width: '100%',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  indicatorsBack: {
    display: 'flex',
    width: '100%',
    color: '#373F41',
    maxWidth: '1250px',
    alignItems: 'center',
    margin: 'auto',
  },
  cardData: {
    maxWidth: 680,
    width: '100%',
    backgroundColor: '#FFFFFF',
    paddingLeft: 50,
    paddingRight: 50,
  },
  avatar: {
    height: 32,
    width: 32,
    backgroundColor: theme.palette.primary.main,
    marginRight: 10,
    border: `1px solid #FFFFFF`,
  },
  boxInput: {
    width: '100%',
    maxWidth: 660,
    minHeight: 398,
    borderRadius: 4,
    padding: 50,
    backgroundColor: '#FFFFFF',
  },
  inputTextColor: {
    color: '#333',
  },
  boxActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 40,
  },
  buttonBack: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: '#ffffff',
    color: theme.palette.primary.main,
    borderRadius: 4,
  },
  buttonText: {
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
  },
  iconSecurity: {
    height: 24,
    width: 24,
  },
}));

export default function Security({ history }: Props): JSX.Element {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const linksBreadcrumb = [
    {
      name: 'Minha conta',
      redirectTo: '/workspace',
    },
    {
      name: 'Segurança',
      redirectTo: '#',
    },
  ];
  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Box className={classes.boxIndicatorsBack}>
        <Box className={classes.indicatorsBack}>
          <IconButton onClick={() => history.push('/workspace')}>
            <MdArrowBack />
          </IconButton>
          <Typography style={{ fontFamily: 'Roboto', fontWeight: 700 }}>
            Segurança
          </Typography>
        </Box>
      </Box>
      <Container style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container className={classes.boxInput}>
          <FormControl
            variant='outlined'
            fullWidth
            classes={{ root: classes.root }}
          >
            <InputLabel htmlFor='outlined-adornment-senha-atual'>
              Digite sua senha atual
            </InputLabel>
            <OutlinedInput
              id='outlined-adornment-senha-atual'
              type={showPassword ? 'text' : 'password'}
              autoFocus
              label='Digite sua senha atual'
              className={classes.inputTextColor}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <MdVisibility className={classes.iconSecurity} /> : <MdVisibilityOff className={classes.iconSecurity} />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl
            variant='outlined'
            fullWidth
            classes={{ root: classes.root }}
          >
            <InputLabel htmlFor='outlined-adornment-nova-senha'>
              Nova senha
            </InputLabel>
            <OutlinedInput
              id='outlined-adornment-nova-senha'
              type={showPassword ? 'text' : 'password'}
              autoFocus
              label='Nova senha'
              className={classes.inputTextColor}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <MdVisibility className={classes.iconSecurity} /> : <MdVisibilityOff className={classes.iconSecurity} />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl
            variant='outlined'
            fullWidth
            classes={{ root: classes.root }}
          >
            <InputLabel htmlFor='outlined-adornment-confirm-senha'>
              Confirme a senha
            </InputLabel>
            <OutlinedInput
              id='outlined-adornment-confirm-senha'
              type={showPassword ? 'text' : 'password'}
              autoFocus
              label='Confirme a senha'
              className={classes.inputTextColor}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <MdVisibility className={classes.iconSecurity} /> : <MdVisibilityOff className={classes.iconSecurity} />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Box className={classes.boxActions}>
            <Button
              variant='outlined'
              className={classes.buttonBack}
              onClick={() => history.push('/workspace')}
            >
              <Typography className={classes.buttonText}>voltar</Typography>
            </Button>
            <Button
              variant='contained'
              style={{ marginLeft: 7 }}
              color='primary'
            >
              <Typography className={classes.buttonText}>Salvar</Typography>
            </Button>
          </Box>
        </Grid>
      </Container>
    </>
  );
}
