import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Theme,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { Breadcrumb, NavMenu } from "../../Components";
import { useHistory } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import Filter from "../../Components/Filter";
import StatusSolicitations from "./components/StatusSolicitations";
import { createStyles, withStyles } from "@material-ui/styles";
import ButtonAction from "./components/ButtonActions";
import { PaginationRequest } from "../MyRequests/components";
import { getAllRequests } from "../../services/form-flow";
import getCookie from "../../utils/getCookies";

import { extractDateFormat, extractHourFormat } from "../../utils/dateFormat";

export interface Dados {
  count: 35;
  current: 1;
  next: 2;
  page_size: 10;
  page_size_query: "items_size";
  previous: null;
  query_param: "?page=";
  results: Resultado[];
  total_pages: number;
}

export interface Resultado {
  avaliacao?: [
    {
      avaliacao_atendimento: string | null;
      avaliacao_consumo: string | null;
      comentario_atendimento: string | null;
      comentario_consumo: string | null;
      created_at: string;
      demanda: string;
      id: string;
      pendente: boolean;
      servico_titulo: string | null;
      updated_at: string;
    }
  ];
  cpf: string;
  created_at: string;
  data_hora: number;
  descricao: null | string;
  id: string;
  informacao_util: boolean;
  motivo_insatisfacao: null | string;
  nome: string;
  orgao: string;
  orgao_nome: string;
  orgao_slug: string;
  para: string;
  protocolo: string;
  protocolo_relacionado: null | string;
  servico_slug: string;
  servico_titulo: string;
  status: string;
  tipo:
  | "Manifestação"
  | "Solicitação"
  | "E-SIC"
  | "Simplifique"
  | "Agendamento";
  updated_at: string;
  permitir_cancelamento_cidadao: boolean;
}

export interface HistoryData {
  id: number;
  descricao: string;
  status: string;
  user: any;
  hash: null | string;
  created_at: string;
  updated_at: string;
}
export interface TypeFilter {
  title: string;
  value: string;
}

const listStatus = [
  {
    id: 0,
    title: "Em Andamento",
    value: "Em Andamento",
  },
  {
    id: 1,
    title: "Concluído",
    value: "Concluído",
  },
  {
    id: 2,
    title: "Em Aberto",
    value: "Em Aberto",
  },
  {
    id: 3,
    title: "Cancelado",
    value: "Cancelado",
  },
  {
    id: 4,
    title: "Solicitado",
    value: "Solicitado",
  },
  {
    id: 5,
    title: "Recurso",
    value: "Recurso",
  },
  {
    id: 6,
    title: "Complementação Cidadão",
    value: "Complementação Cidadão",
  },
  {
    id: 7,
    title: "Arquivado",
    value: "Arquivado",
  },
  {
    id: 8,
    title: "Prorrogado",
    value: "Prorrogado",
  },
  {
    id: 9,
    title: "Reagendou",
    value: "Reagendou",
  },
];
export interface RestitutionSelected {
  solicitacao: number;
  id: string;
}

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgba(15, 111, 183, 0.05)",
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      fontWeight: 500,
      fontFamily: "Roboto",
    },
    body: {
      fontSize: 14,
      color: "#333333",
    },
  })
)(TableCell);

const useStyles = makeStyles((theme: Theme) => ({
  indicatorsBack: {
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
    display: "flex",
  },
  boxIndicatorsBack: {
    width: "100%",
    justifyContent: "center",
    margin: "20px 0px",
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: 700,
  },
  flexContainer: {
    alignItems: "center",
    height: "56px",
    background: theme.palette.text.primary,
    color: theme.palette.primary.main,
  },
  indicator: {
    width: 0,
    height: 0,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  labelIcon: {
    paddingTop: 0,
    minHeight: 0,
  },
  tabContainer: {
    minWidth: "33.3%",
    height: 56,
    marginTop: 0,
    padding: 5,
    borderRadius: 4,
    "& .MuiTab-wrapper": {
      flexDirection: "row",
    },
  },
  tabIcon: {
    marginRight: 11,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  textLabeltab: {
    fontSize: 16,
    fontFamily: "Roboto",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      fontFamily: "Roboto",
      fontStyle: "normal",
      lineHeight: "15px",
    },
  },
  boxAccess: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    height: 225,
    [theme.breakpoints.down("xs")]: {
      height: 294,
    },
  },
  cardContent: {
    padding: 30,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      padding: "20px 10px 0px 10px",
    },
  },
  iconAccess: {
    color: theme.palette.primary.main,
    marginBottom: 20,
    marginTop: 8,
    fontSize: 50,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      marginTop: 0,
      marginBottom: 0,
    },
  },
  textCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 16,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      textAlign: "center",
    },
  },
  subTextCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 5,
    fontSize: 16,
    letterSpacing: 0.3,
    fontWeight: "normal",
    marginRight: 147.5,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 16,
      marginRight: 0,
      textAlign: "center",
    },
  },
  boxButton: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  button: {
    height: 44,
  },
  boxIndicatorsBackHome: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  indicatorsBackHome: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  status: {
    fontSize: 14,
    fontFamily: "Roboto",
    fontWeight: 500,
    textTransform: "capitalize",
    padding: "5px 16px",
    borderRadius: 25,
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      marginBottom: 24,
      alignSelf: "center",
    },
  },
  titleInfo: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    margin: "24px 0px",
  },
  textTable: {
    color: "#333333",
    textAlign: "center",
  },
  attachText: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    textDecoration: "none",
    color: "#0F6FB7",
  },
  textLink: {
    margin: "5px 0px 5px 14px",
    textDecoration: "none",
    color: "#0F6FB7",
  },
  buttonCancel: {
    color: "#E53535",
    borderColor: "rgba(229,53,53, 0.7)",
    borderRadius: 4,
    margin: "0px 7px",
    padding: "12px 24px",
    height: 48,
    "&:hover": {
      backgroundColor: "rgba(229,53,53, 0.1)",
      borderColor: "#E53535",
    },
  },
  actionsButtonText: {
    textTransform: "uppercase",
    fontFamily: "Roboto",
  },
  textButtonGuiche: {
    textTransform: "uppercase",
    fontFamily: "Roboto",
    margin: "0px 7px",
    width: 131,
    height: 48,
    borderRadius: 4,
    padding: "12px 24px",
  },
  boxActions: {
    margin: "30px 0px",
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  boxInfoCancel: {
    width: "100%",
  },
  infoCancelText: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
  },
  iconRequests: {
    height: 24,
    width: 24,
  },
  card: {
    marginTop: 20,
  },
}));

export default function AllMyRequests(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  const [listRequests, setListRequests] = useState<Dados>();
  const [currentFilter, setCurrentFilter] = useState<TypeFilter | null>();
  const [openDialog, setOpenDialog] = useState(false);
  const [scheduleSelected, setScheduleSelected] = useState();

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setScheduleSelected(undefined);
  };


  const [selectTab, setSelectTab] = useState(2);
  const [loading, setLoading] = useState(false);

  const handleChange = (value, newValue) => {
    setSelectTab(newValue);
    switch (newValue) {
      case 0:
        onSearch("status", "Em Andamento");
        break;
      case 1:
        onSearch("status", "Concluído");
        break;
      default:
        onSearch("status", " ");
        break;
    }
  };

  const linksBreadcrumb = [
    {
      name: "Minhas Solicitações",
      redirectTo: "#",
    },
  ];

  const getAllRequestsList = async ({
    page=1,
    filtro,
  }: {
    page?: number;
    filtro?: { title: string; value: string };
  }) => {
    setLoading(true);
    try {
      let user = JSON.parse(getCookie("gov_user_data") || "{}");
      if (user?.cpf) {
        const { data } = await getAllRequests({
          page,
          filtro: filtro?.title,
          search: filtro?.value,
        });
        setListRequests(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const onSearch = useCallback((type, searchText) => {
    let titleFilter = "";
    if (type && searchText) {
      if (type === "status") {
        titleFilter = "status";
      } else if (type === "date_schedule") {
        const minhaDataString = searchText;
        const partesData = minhaDataString.split("-");
        const ano = parseInt(partesData[0], 10);
        const mes = parseInt(partesData[1], 10) - 1;
        const dia = parseInt(partesData[2], 10);

        const data = new Date(ano, mes, dia);
        data.setHours(0, 0, 0, 0);
        const dataTimeStamp = data.getTime();
        searchText = dataTimeStamp;
        titleFilter = "data_inicio";
      } else {
        titleFilter = type;
      }
      const filterData = {
        title: titleFilter,
        value: searchText,
      };
      setCurrentFilter(filterData);
      getAllRequestsList({ page: 1, filtro: filterData });
    }
  }, []);

  useEffect(() => {
    getAllRequestsList({ page: 1 });
  }, []);

  return (
    <>
      <NavMenu />
      <Container>
        <Box>
          <Breadcrumb links={linksBreadcrumb} />
          <Box className={classes.boxIndicatorsBack}>
            <Box className={classes.indicatorsBack}>
              <IconButton onClick={() => history.push("/workspace")}>
                <MdArrowBack />
              </IconButton>
              <Typography className={classes.title}>
                Minhas solicitações
              </Typography>
            </Box>
          </Box>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: 20 }}
        >
          <Paper style={{ width: "97%" }} variant="outlined" elevation={0}>
            <Box>
              <Tabs
                value={selectTab}
                indicatorColor="primary"
                centered={true}
                textColor="inherit"
                scrollButtons="off"
                onChange={handleChange}
                classes={{
                  flexContainer: classes.flexContainer,
                }}
                TabIndicatorProps={{ className: classes.indicator }}
              >
                <Tab
                  classes={{
                    selected: classes.selected,
                    labelIcon: classes.labelIcon,
                  }}
                  className={classes.tabContainer}
                  label={
                    <Typography className={classes.textLabeltab}>
                      Em andamento
                    </Typography>
                  }
                />
                <Tab
                  classes={{
                    selected: classes.selected,
                    labelIcon: classes.labelIcon,
                  }}
                  className={classes.tabContainer}
                  label={
                    <Typography className={classes.textLabeltab}>
                      Concluído
                    </Typography>
                  }
                />

                <Tab
                  classes={{
                    selected: classes.selected,
                    labelIcon: classes.labelIcon,
                  }}
                  className={classes.tabContainer}
                  label={
                    <Typography className={classes.textLabeltab}>
                      Todos
                    </Typography>
                  }
                />
              </Tabs>
            </Box>
          </Paper>
          <Container>
            <Box>
              <Filter
                filters={{
                  protocolo: true,
                  demanda: true,
                  status: selectTab === 0 || selectTab === 1 ? false : true,
                  data: true,
                }}
                onSearch={onSearch}
                onLoadList={getAllRequestsList}
                onSearchProtocol={() => { }}
                listStatus={listStatus}
                currentFilter={setCurrentFilter}
                setSelected={setSelectTab}
              />
            </Box>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                {/* <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel htmlFor="outlined-adornment-search-service">
                Buscar um serviço
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search-service"
                type="text"
                label="Buscar um serviço"
                style={{ color: "#373F41" }}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton>
                      <MdSearch className={classes.iconRequests} color="primary" />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl> */}
              </CardContent>
              {loading ? (
                <Box
                  marginBottom="60px"
                  width="100%"
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {listRequests?.results?.length && !loading ? (
                    <>
                      <TableContainer
                        component={Paper}
                        style={{ padding: "0px 30px" }}
                      >
                        <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">
                                situação
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                protocolo
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Serviço
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                órgão
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                demanda
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                para
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Criado dia
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Atualizado dia
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Ações
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {listRequests.results.map((request) => (
                              <Fragment key={request.id}>
                                <TableRow>
                                  <StatusSolicitations
                                    request={request} />
                                  <StyledTableCell align="center">
                                    {request.protocolo}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {request.servico_titulo
                                      ? request.servico_titulo
                                      : "Não atribuído"}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {request.orgao_nome}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {request.tipo}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {request.para}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {`${extractDateFormat(
                                      request.created_at
                                    )} às ${extractHourFormat(
                                      request.created_at
                                    )}`}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">{`${extractDateFormat(
                                    request.updated_at
                                  )} às ${extractHourFormat(
                                    request.updated_at
                                  )}`}</StyledTableCell>
                                  <StyledTableCell align="center">
                                    <ButtonAction request={request} />
                                  </StyledTableCell>
                                </TableRow>
                              </Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <PaginationRequest
                        style={{
                          padding: 0,
                          marginBottom: 0,
                          margin: "20px 0px",
                        }}
                        getSearch={getAllRequestsList}
                        listSearch={listRequests}
                        filter={currentFilter}
                      />
                    </>
                  ) : (
                    <Box
                      marginBottom="60px"
                      minHeight="100px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                    >
                      <Typography style={{ textAlign: "center" }} variant="h4">
                        Resultado não localizado. Favor ligar para o 156.
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Card>
          </Container>
        </Grid>
        {/* <ModalSchedule
          open={openDialog}
          schedule={scheduleSelected}
          handleClose={handleCloseDialog}
                  />*/}
      </Container>
    </>
  );
}
