import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  Icon,
} from "@material-ui/core";
import { NavMenu } from "../../Components";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPerson,
  faHeadset,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { useMySpace } from "./useMySpace";

const useStyles = makeStyles((theme: Theme) => ({
  recommendations: {
    width: "100%",
    marginTop: "70px",
    fontFamily: "Roboto",
    padding: "90px 30px 40px 30px",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    gap: "3rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  recommendation: {
    width: "100%",
    height: "200px",
    padding: "20px",
    display: "flex",
    cursor: "pointer",
    maxWidth: "450px",
    borderRadius: "8px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: theme.palette.common.white,
  },
  servicesContainer: {
    gap: "3rem",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
  },
  categoryIcon: {
    fontSize: "32px",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  titleService: {
    textAlign: "center",
    lineHeight: "24px",
    fontSize: "16px",
    fontWeight: 400,
    color: "#1F2937",
  },
  publicoContainer: {
    gap: "1rem",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  titleCt: {
    fontSize: 28,
    width: "100%",
    fontWeight: 700,
    marginBottom: 16,
    textAlign: "start",
    textTransform: "uppercase",
  },
  publico: {
    gap: "0.5rem",
    display: "flex",
    padding: "5px 10px 5px 5px",
    alignItems: "center",
    flexShrink: 0,
    flexDirection: "row",
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.main,
  },
  publicoText: {
    fontSize: "13px",
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
  },
  iconPublico: {
    padding: "5px",
    fontSize: "16px",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
  },
  buttonViewMore: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  gridMain: {
    margin: 0,
  },
  boxIndicatorsBack: {
    width: "100%",
    margin: "20px 0px",
  },
  indicatorsBack: {
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  boxAccess: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    height: 225,
    [theme.breakpoints.down("xs")]: {
      height: 294,
    },
  },
  cardContent: {
    padding: 30,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      padding: "20px 10px 0px 10px",
    },
  },
  iconAccess: {
    color: theme.palette.primary.main,
    marginBottom: 20,
    marginTop: 8,
    fontSize: 50,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      marginTop: 0,
      marginBottom: 0,
    },
  },
  textCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 16,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      textAlign: "center",
    },
  },
  subTextCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 5,
    fontSize: 16,
    letterSpacing: 0.3,
    fontWeight: "normal",
    marginRight: 147.5,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 16,
      marginRight: 0,
      textAlign: "center",
    },
  },
  boxButton: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  button: {
    height: 44,
  },
  boxIndicatorsBackHome: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  indicatorsBackHome: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
}));

export default function MySpace(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const {
    user,
    page,
    setPage,
    recommendation,
    recommendations,
    handleGetRecommendations,
  } = useMySpace();

  useEffect(() => {
    handleGetRecommendations();
  }, [page]);

  return (
    <>
      <NavMenu />
      <Container>
        <Grid container spacing={3} className={classes.gridMain}>
          <Grid item xs={12}>
            {
              //<Breadcrumb links={linksBreadcrumb} />
            }
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    fontSize: 28,
                    marginBottom: 16,
                  }}
                >
                  Minha conta
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    fontSize: 18,
                    color: "#373F41",
                    letterSpacing: 0.3,
                  }}
                >
                  Gerencie suas informações, privacidade e segurança para que
                  possamos atender suas necessidades.
                </Typography>
              </Box>
              <Box className={classes.boxIndicatorsBackHome}></Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography className={classes.textCard}>
                    MEUS DADOS
                  </Typography>
                  <Typography className={classes.subTextCard}>
                    Veja os seus dados básicos.
                  </Typography>
                </Box>
                <Box>
                  <Icon
                    className={`${classes.iconAccess} material-icons-round`}
                  >
                    badge
                  </Icon>
                </Box>
              </CardContent>
              <Box component="span" m={4} className={classes.boxButton}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => history.push("/workspace/meus-dados")}
                >
                  VER MAIS
                </Button>
              </Box>
            </Card>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography className={classes.textCard}>
                    segurança
                  </Typography>
                  <Typography className={classes.subTextCard}>
                    Aumente a segurança da sua conta.
                  </Typography>
                </Box>
                <Box>
                  <Icon
                    className={`${classes.iconAccess} material-icons-round`}
                  >
                    https
                  </Icon>
                </Box>
              </CardContent>
              <Box component="span" m={4} className={classes.boxButton}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => history.push("/workspace/seguranca")}
                >
                  VER MAIS
                </Button>
              </Box>
            </Card>
          </Grid> */}

          <Grid item xs={12} md={6}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography className={classes.textCard}>
                    MINHAS SOLICITAÇÕES
                  </Typography>
                  <Typography className={classes.subTextCard}>
                    Veja o status das suas solicitações.
                  </Typography>
                </Box>
                <Box>
                  <Icon
                    className={`${classes.iconAccess} material-icons-round`}
                  >
                    post_add
                  </Icon>
                </Box>
              </CardContent>
              <Box component="span" m={4} className={classes.boxButton}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => history.push("/workspace/minhas_solicitacoes")}
                >
                  VER MAIS
                </Button>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography className={classes.textCard}>
                    privacidade
                  </Typography>
                  <Typography className={classes.subTextCard}>
                    Gerencie seus dados e aumente a confiabilidade da sua conta.
                  </Typography>
                </Box>
                <Box>
                  <Icon
                    className={`${classes.iconAccess} material-icons-round`}
                  >
                    admin_panel_settings
                  </Icon>
                </Box>
              </CardContent>
              <Box component="span" m={4} className={classes.boxButton}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => history.push("/workspace/privacidade")}
                >
                  VER MAIS
                </Button>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography className={classes.textCard}>
                    MEUS SERVIÇOS FAVORITOS
                  </Typography>
                  <Typography className={classes.subTextCard}>
                    Veja a lista de seus serviços favoritos.
                  </Typography>
                </Box>
                <Box>
                  <Icon
                    className={`${classes.iconAccess} material-icons-round`}
                  >
                    bookmark_added
                  </Icon>
                </Box>
              </CardContent>
              <Box component="span" m={4} className={classes.boxButton}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => history.push("/workspace/favoritos")}
                >
                  VER MAIS
                </Button>
              </Box>
            </Card>
          </Grid>

          {user.servidor.length > 0 && (
            <Grid item xs={12} md={6}>
              <Card className={classes.boxAccess}>
                <CardContent className={classes.cardContent}>
                  <Box>
                    <Typography className={classes.textCard}>
                      Área do servidor
                    </Typography>
                    <Typography className={classes.subTextCard}>
                      Encontre todas as funcionalidades para servidores.
                    </Typography>
                  </Box>
                  <Box>
                    <Icon
                      className={`${classes.iconAccess} material-icons-round`}
                    >
                      headset_mic
                    </Icon>
                  </Box>
                </CardContent>
                <Box component="span" m={4} className={classes.boxButton}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => history.push("/workspace/area-do-servidor")}
                  >
                    VER MAIS
                  </Button>
                </Box>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
      {recommendations && recommendations.length > 0 && (
        <Box className={classes.recommendations}>
          <Container className={classes.container}>
            <Typography variant="h1" className={classes.titleCt}>
              Serviços recomendados para você
            </Typography>
            <Box className={classes.servicesContainer}>
              {recommendations.length > 0 &&
                recommendations
                  .filter(
                    (recommendation, index, self) =>
                      index ===
                      self.findIndex(
                        (recommend) =>
                          recommend.servico.id === recommendation.servico.id
                      )
                  )
                  .map((recommendation) => (
                    <Box
                      key={recommendation.id}
                      className={classes.recommendation}
                      onClick={() =>
                        (window.location.href = `/servico/${recommendation.servico.slug}`)
                      }
                    >
                      <span
                        className={`material-icons ${classes.categoryIcon}`}
                      >
                        {recommendation.servico.categoria_icone}
                      </span>
                      <Typography variant="h2" className={classes.titleService}>
                        {recommendation.servico.titulo}
                      </Typography>
                      <Box className={classes.publicoContainer}>
                        {recommendation.servico.publico_especifico.map(
                          (publico, index) => (
                            <Box key={index} className={classes.publico}>
                              <FontAwesomeIcon
                                className={classes.iconPublico}
                                icon={
                                  publico === "Cidadão"
                                    ? faPerson
                                    : publico === "Empresa"
                                    ? faBuilding
                                    : faHeadset
                                }
                              />
                              <Typography className={classes.publicoText}>
                                {publico}
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>
                    </Box>
                  ))}
            </Box>
            {recommendation.next !== null && recommendation.next > 1 && (
              <Button
                variant="contained"
                className={classes.buttonViewMore}
                onClick={() => setPage(page + 1)}
              >
                Ver mais
              </Button>
            )}
          </Container>
        </Box>
      )}
    </>
  );
}
