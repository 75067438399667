import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface SimpleDialogCancelProps {
  open: boolean;
  isLoading: boolean;
  legendTitle: string;
  onRequestCancel: () => void;
  handleClose: () => void
}

const useStyles = makeStyles(() => ({
  boxHeader: {
    backgroundColor: '#E53535',
  },
  textName: {
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  textUnidade: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    textTransform: 'capitalize',
    color: '#737B7D',
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  btnConfirm: {
    backgroundColor: '#3CD278',
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '0',
    padding: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#00a04b',
    },
  },
  btnClose: {
    backgroundColor: '#b0bec5',
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '0',

    padding: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#808e95',
    },
  },
  containerLoading: {
    marginBottom: "60px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  }
}));

export default function ModalCancel({
  open,
  isLoading,
  legendTitle,
  handleClose,
  onRequestCancel
}: SimpleDialogCancelProps) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle
        id="alert-dialog-title"
        className={classes.boxHeader}
      >
        <Typography className={classes.textName}>
          {legendTitle}
        </Typography>
      </DialogTitle>
      <DialogActions>
        {isLoading ? (
          <Box
            className={classes.containerLoading}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnConfirm}
              onClick={onRequestCancel}
            >
              Sim, eu quero cancelar
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              className={classes.btnClose}
            >
              Não, eu não quero cancelar
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
