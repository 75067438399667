import { AxiosResponse } from 'axios';
import { apisso } from './api';
import getCookie from '../utils/getCookies';
const token: string | null = getCookie('gov_access_token_sso');

export const requestCodeSign = (cpf: string, email: string): Promise<AxiosResponse> => apisso.post("/admin/assinatura/code/", {
  cpf,
  email,
}, {
  headers: {
    Authorization: `Bearer ${token}`,
  }
});

type AssinaturaProps = {
  code: string,
  state: string,
  identificador: string,
  tipo: string,
  internal_state: string,
  client_id: string,
}

export const sendCodeSignToValidate = (params: AssinaturaProps): Promise<AxiosResponse> => apisso.post("/admin/assinatura/validar_codigo/", params, {
  headers: {
    Authorization: `Bearer ${token}`,
  }
});



export const checkCodeSignIsValid = (identificador: string, hash: string): Promise<AxiosResponse> => apisso.post("/admin/assinatura/checar/", {
  identificador,
  hash,
}, {
  headers: {
    Authorization: `Bearer ${token}`,
  }
});