import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  Avatar,
  Popover,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { FaBell, FaEnvelope, FaSms, FaUserCircle } from "react-icons/fa";
import { useHistory, useLocation, Link as LinkRouter } from "react-router-dom";
import { MdAccountCircle, MdExitToApp, MdExpandMore } from "react-icons/md";
import {
  getUserData as getUserDataRequest,
  clearStorage,
  loginRedirectPrivider,
  logout,
  // logout,
  getImageProfile,
} from "../../services/auth";

// eslint-disable-next-line import/extensions
import { UserData } from "../../interfaces/user";
import { Container } from "@material-ui/core";
// import { MdCircleNotifications } from "react-icons/md";
import { nameSplitOne, nameSplitTwo } from "../../utils/nameFormat";
import { SearchServices } from "..";
import { cpfMask } from "../../utils/cpfFormat";
import { useToastValue } from "../../contexts/toastMessage";
import ToastMessage from "../ToastMessage";
import { Helmet } from 'react-helmet';
import getCookie from "../../utils/getCookies";
import { Notifications } from "@material-ui/icons";
import { NotificationsProps } from "../../interfaces/notifications";
import { getAllNotifications, requestPostMarkedAllNotificationAsSeen } from "../../services/notifications";
import { extractDateFormat } from "../../utils/dateFormat";
import { format } from "date-fns";
import { DialogNotify } from "../DialogNotify";

interface DataNotificationsUnique {
  id: string;
  titulo: string;
  texto: string;
  lido: boolean;
  tipo: string;
  created_at: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 10px",
    background: theme.palette.primary.main,
    position: "inherit",
  },
  text: {
    marginRight: 20,
  },
  textButton: {
    fontWeight: 700,
    fontStyle: "normal",
    marginLeft: 3,
    fontFamily: "Roboto",
  },
  textName: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    [theme.breakpoints.down(400)]: {
      display: 'none',
    },
  },
  avatar: {
    height: 32,
    width: 32,
    backgroundColor: theme.palette.primary.main,
    marginRight: 10,
    border: `1px solid #FFFFFF`,
  },
  menuItem: {
    padding: '18px 37px 18px 20px',
    color: "#737B7D",
    minWidth: 236,
  },
  textMenuItem: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    marginLeft: 10,
    fontFamily: 'Roboto',
    color: '#333333',
  },
  textCpf: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    textAlign: "start",
    fontFamily: 'Roboto',
    [theme.breakpoints.down(400)]: {
      display: 'none',
    },
  },
  searchBar: {
    flex: 0.4,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    textAlign: "center",
    display: "flex",
    paddingLeft: 8,
    color: "#ffffff",
    border: "1px #ffffff solid",
    alignContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  searchBarInput: {
    backgroundColor: "transparent",
    border: "none",
    fontSize: theme.typography.pxToRem(16),
    color: "#FFF",
    minWidth: "18vw",
    height: 47,
    paddingLeft: 10,
    outline: 0,
  },
  navContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
  },
  boxNotifications: {
    marginRight: 24,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  badge: {
    backgroundColor: '#F03D3D',
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    minWidth: 20,
    width: 'auto',
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(12),
    color: '#fff'
  },
  cardNotification: {
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    borderBottom: '1px #EAECF0 solid',
    paddingBlock: 16,
    paddingInline: 24,
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.primary.main,
      '& .titleMainCard': {
        color: '#FFFFFF'
      },
      '& .subTitleMainCard': {
        color: 'rgba(255,255,255, 0.8)'
      },
      '& .boxIconMessageNotification': {
        background: '#FFFFFF'
      },
      '& .iconNotify': {
        color: '#053396'
      }
    },
  },
  buttonNotification: {
    all: 'unset',
    cursor: 'pointer',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    justifyContent: 'center',
    position: 'relative',
    width: 48,
    height: 48,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  indicatorNotificationNotViewed: {
    position: 'absolute',
    top: 10,
    right: 8,
    width: 8,
    height: 8,
    backgroundColor: '#F03D3D',
    borderRadius: '100%'
  },
  containerNotification: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    width: 400,
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 0px 4px 4px'
  },
  indicatorNotificationNotViewedIcon: {
    width: 8,
    height: 8,
    backgroundColor: '#F03D3D',
    borderRadius: '100%'
  },
  indicatorNotificationViewedIcon: {
    width: 8,
    height: 8,
    backgroundColor: '#EAECF0',
    borderRadius: '100%'
  },
  itemMessageNotification: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center'
  },
  boxIconMessageNotification: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    minWidth: 40,
    height: 40,
    // background: 'linear-gradient(90deg, #192844 61.11%, #472774 100%)',
    borderRadius: '100%'
  },
  iconNotify: {
    color: '#053396'
  },
  boxInformationNotification: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 4
  },
  gridMain: {
    display: 'grid',
    width: '100%',
    gridTemplateRows: 'repeat(3,80px)'
  },
  asideLeftIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 16
  },
  footerNotify: {
    all: 'unset',
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    height: 48,
    alignItems: 'center',
    justifyContent: 'center'
  },
  footerTitleNotify: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 400,
    background: theme.palette.primary.main,
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    color: 'transparent'
  },
  iconProgress: {
    fontSize: theme.typography.pxToRem(50),
    color: theme.palette.primary.main,
  },
  textAlert: {
    fontSize: theme.typography.pxToRem(18),
    color: '#333',
    fontFamily: 'Roboto',
    fontWeight: 500,
    padding: '20px',
    textAlign: 'center'
  },
  titleMainCard: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    color: '#1D2939',
    pointerEvents: 'none',
    transition: 'color 0.3 ease',
  },
  subTitleMainCard: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(13),
    color: 'rgba(29,41,57,0.8)',
    transition: 'color 0.3 ease',
  },
  contentNotificationTitle: {
    width: '100%',
    height: 48,
    marginBottom: 8,
    padding: '0px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  notificationText: {
    fontSize: 16,
    fontWeight: 700,
    color: '#333333',
    fontFamily: 'Roboto'
  },
  markAllNotificationText: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontFamily: 'Roboto'
  }
}));

export default function Header(): JSX.Element {
  const { toast, setToast } = useToastValue();
  const classes = useStyles();
  const history = useHistory();
  const [anchorElUser, setAnchorElUser] =
    React.useState<HTMLButtonElement | null>(null);
  const matchesMobile = useMediaQuery("(min-width:660px)");
  const [userData, setUserData] = useState<UserData | null>();
  const [, setMessage] = useState<string>("");
  const [avatarUser, setAvatarUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingNotificationsMarkAll, setLoadingNotificationsMarkAll] = useState(false);
  const [page,] = useState<number>(1);


  const [anchorElNotify, setAnchorElNotify] =
    React.useState<HTMLButtonElement | null>(null);
  const [notifications, setNotifications] = useState<NotificationsProps>();
  const [dataNotificationsById, setDataNotificationsById] = useState<DataNotificationsUnique>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);


  const location = useLocation();

  const token: string | null = getCookie("gov_access_token_sso");
  const tokenSiseci: string | null = getCookie(
    "gov_access_token_sso"
  );

  useEffect(() => {
    const { state } = location;
    if (state as any) {
      setMessage((state as any).message);
    }
  }, [location]);

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      setLoading(true);
      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive);

      setUserData(data);
      document.cookie = "gov_user_data=" + JSON.stringify(data) + "; path=/";
    } catch (err) {
      clearStorage();
      if (err?.response?.data) {
        setMessage(err?.response?.data?.detail);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci);
    }
  }, [token, tokenSiseci]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: "user" | "access"
  ) => {
    if (type === "user") {
      setAnchorElUser(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorElUser(null);
  };

  const logoutUser = () => {
    logout();
    setUserData(undefined);
    setAnchorElUser(null);
  };

  const loginRedirect = () => {
    const { pathname } = location;
    loginRedirectPrivider(pathname);
  };

  const clickMenuItem = (to: string) => {
    history.push(to);
    handleClose();
  };

  function closeModalNotify() {
    setModalOpen(false);
    return;
  }

  function openModalNotify(id: string) {
    setModalOpen(true);
    const filterNotifyById = notifications?.results.filter((item) => item.id === id)[0];
    setDataNotificationsById(filterNotifyById);
    return;
  }

  const handleClickPoperNotifications = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElNotify(event.currentTarget);
  };

  const handleClosePoperNotifications = () => {
    setAnchorElNotify(null);
  };

  const unreadNotifications = useMemo(() => {
    if (notifications?.results?.length > 0) {
      return notifications?.results?.filter((notify) => !notify.lido).length > 0;
    }
  }, [notifications]);

  const modifyListNotificationWithSeenWhenClickButton = useCallback((id: string) => {
    const modifyNotification = notifications.results
      .map((item) => {
        if (item.id === id) {
          return ({
            ...item,
            lido: true
          })
        }
        return ({
          ...item
        })
      });
    const notifyDifferentOfSeen = modifyNotification.filter((item) => item.lido !== true)
    setNotifications({ ...notifications, results: notifyDifferentOfSeen });
  }, [notifications]);

  const notificationHasData = notifications && notifications?.results?.length > 0;
  const notificationHasNotData = notifications?.results?.length < 1;

  const onMarkAllNotificationAsSee = async () => {
    try {
      setLoadingNotificationsMarkAll(true)
      const data = await requestPostMarkedAllNotificationAsSeen(token)

      if (data?.status === 200) {
        handleClosePoperNotifications()
        setTimeout(() => {
          history.go(0)
        }, 2000)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingNotificationsMarkAll(false)
    }
  }

  useEffect(() => {
    const handleGetImageProfile = async () => {
      const { data } = await getImageProfile(token);

      if (data?.results?.length > 0) {
        const image = data.results[0].arquivo;
        setAvatarUser(image);
      }
    };
    handleGetImageProfile();
  }, [token]);

  useEffect(() => {
    const listNofications = async () => {
      try {
        setLoading(true);
        const { data } = await getAllNotifications({ token, page });
        const notifyDifferentOfSeen = data.results.filter((item) => item.lido !== true)
        setNotifications({ ...data, results: notifyDifferentOfSeen });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    listNofications();
  }, [page, token]);

  return (
    <>
      {modalOpen && (<DialogNotify data={dataNotificationsById} onCloseModal={closeModalNotify} />)}
      <Box className={classes.main}>
        <Helmet>
          <title>Portal de Serviços</title>
        </Helmet>
        <Container className={classes.navContent}>
          <LinkRouter to="/">
            <img
              height="50px"
              alt="Unidade"
              src={`${process.env.REACT_APP_LOGO_WORKSPACE}`}
            />
          </LinkRouter>
          {matchesMobile && <SearchServices />}

          <Box display="flex" alignItems="center">
            <Button
              onClick={(e): void => handleClickPoperNotifications(e)}
              className={classes.buttonNotification}>
              <Box className={unreadNotifications ? classes.indicatorNotificationNotViewed : null} />
              <Notifications />
            </Button>

            <Popover
              open={Boolean(anchorElNotify)}
              anchorEl={anchorElNotify}
              onClose={handleClosePoperNotifications}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box className={classes.containerNotification}>
                <Box className={classes.contentNotificationTitle}>
                  <Typography className={classes.notificationText}>
                    Notificações
                  </Typography>
                  {notificationHasData && (
                    <Button
                      disabled={loadingNotificationsMarkAll}
                      onClick={onMarkAllNotificationAsSee}>
                      {loadingNotificationsMarkAll ? <CircularProgress size={20} /> : (
                        <Typography className={classes.markAllNotificationText}>Marcar todas como lidas</Typography>
                      )}
                    </Button>
                  )}
                </Box>
                <Box className={classes.gridMain}>
                  {!notificationHasData && notificationHasNotData && (
                    <Typography className={classes.textAlert}>Nenhum resultado foi encontrado!</Typography>
                  )}
                  {loading ? <CircularProgress className={classes.iconProgress} /> : (
                    <>
                      {notificationHasData && notifications.results.map((item) => {
                        return (
                          <Button onClick={() => {
                            handleClosePoperNotifications()
                            openModalNotify(item.id)
                            modifyListNotificationWithSeenWhenClickButton(item.id)
                          }} disableRipple key={item.id} className={classes.cardNotification}>
                            <Box className={classes.itemMessageNotification}>
                              <Box className={`${classes.boxIconMessageNotification} boxIconMessageNotification`}>
                                {item.tipo === "EMAIL" && <FaEnvelope size={24} className={`${classes.iconNotify} iconNotify`} />}
                                {item.tipo === "SMS" && <FaSms size={24} className={`${classes.iconNotify} iconNotify`} />}
                                {item.tipo === "PUSH" && <FaBell size={24} className={`${classes.iconNotify} iconNotify`} />}
                              </Box>
                              <Box className={classes.boxInformationNotification}>
                                <Typography className={`${classes.titleMainCard} titleMainCard`}>
                                  {item.titulo}
                                </Typography>
                                <Typography className={`${classes.subTitleMainCard} subTitleMainCard`}>
                                  {item?.created_at ? (`${extractDateFormat(item?.created_at)} - ${format(new Date(item?.created_at), 'kk:mm')}h`) : '03/09/2023 - 10:13h'}
                                </Typography>
                              </Box>
                            </Box>
                            <Box className={classes.asideLeftIcon}>
                              <Box
                                className={
                                  item.lido ? classes.indicatorNotificationViewedIcon : classes.indicatorNotificationNotViewedIcon
                                } />
                            </Box>
                          </Button>
                        )
                      })}
                    </>
                  )}

                </Box>
                {notifications && (
                  <Button
                    title="Visualizar notificações"
                    onClick={() => { handleClosePoperNotifications(); history.push("/workspace/notificacoes") }}
                    disableRipple
                    className={classes.footerNotify}>
                    <Typography className={classes.footerTitleNotify}>
                      Ver todas
                    </Typography>
                  </Button>
                )}
              </Box>
            </Popover>

            <Popover
              open={Boolean(anchorElUser)}
              anchorEl={anchorElUser}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              style={{
                top: matchesMobile ? 25 : 0,
              }}
            >
              <Box>
                {userData && (
                  <MenuItem
                    style={{
                      backgroundColor: "#F3F6FA",
                    }}
                  >
                    <Avatar src={avatarUser || null} className={classes.avatar} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Typography className={classes.textName} color="primary">
                        {matchesMobile
                          ? nameSplitTwo(userData?.nome?.toLowerCase())
                          : nameSplitOne(userData?.nome?.toLowerCase())}
                      </Typography>
                      <Typography color="primary" className={classes.textCpf}>
                        {cpfMask(userData.cpf)}
                      </Typography>
                    </Box>
                  </MenuItem>
                )}

                <MenuItem onClick={() => clickMenuItem("/workspace")} className={classes.menuItem}>
                  <MdAccountCircle style={{ width: 24, height: 24, color: '#053396' }} />
                  <Typography
                    className={classes.textMenuItem}

                  >
                    Minha conta
                  </Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem} onClick={logoutUser}>
                  <MdExitToApp style={{ width: 24, height: 24, color: '#F03D3D' }} />
                  <Typography className={classes.textMenuItem}>Sair</Typography>
                </MenuItem>
              </Box>
            </Popover>
            {loading ? (
              <CircularProgress
                style={{
                  width: 25,
                  height: 25,
                  color: "#fff",
                }}
              />
            ) : (
              <>
                {userData ? (
                  <>
                    {/* <Box className={classes.boxNotifications}>
                    <MdCircleNotifications size={32} style={{ color: "#fff" }} />
                    <span className={classes.badge}>2</span>
                  </Box> */}
                    <Button
                      color="primary"
                      onClick={(e): void => handleClick(e, "user")}
                    >
                      <Avatar src={avatarUser || null} className={classes.avatar} />
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography
                          className={classes.textName}
                          color="textPrimary"
                        >
                          {matchesMobile
                            ? nameSplitTwo(userData?.nome?.toLowerCase())
                            : nameSplitOne(userData?.nome?.toLowerCase())}
                        </Typography>
                        <Typography color="textPrimary" className={classes.textCpf}>
                          {cpfMask(userData.cpf)}
                        </Typography>
                      </Box>
                      <MdExpandMore
                        style={{ color: "#fff", width: 24, height: 24 }}
                      />
                    </Button>
                  </>
                ) : (
                  <Button
                    style={{
                      marginLeft: 20,
                      padding: "10px 20px",
                      border: "1px solid #fff",
                    }}
                    color="default"
                    variant="outlined"
                    onClick={loginRedirect}
                  >
                    <FaUserCircle style={{ height: 20, width: 20 }} />
                    <Typography className={classes.textButton}>Entrar</Typography>
                  </Button>
                )}
              </>
            )}
          </Box>
        </Container>
        <Container>{!matchesMobile && <SearchServices />}</Container>
        {toast.visible && <ToastMessage />}
      </Box>
    </>
  );
}
