import React from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { RouteLayout, ScrollToTop } from './Components';
import {
  Home,
  MyData,
  Terms,
  MySpace,
  Permission,
  SchedulingRequest,
  AttendenceRequest,
  Activity,
  Favorite,
  Privacy,
  DigitalService,
  DetailDigitalService,
  Security,
  AttendanceOnlineChat,
  MyScheduling,
  TempForm,
  EvaluationAttPresential,
  AllMyRequests,
  InfoSolicitation,
} from './Pages';
import WithHeader from './Components/Layouts/WithHeader';
import EvaluationAttInterno from './Pages/EvaluationAttInterno';
import EvaluationSchedule from './Pages/EvaluationSchedule';
import EvaluationAttOnline from './Pages/EvaluationAttOnline';
import EvaluationAttOrganic from './Pages/EvaluationAttOrganic';
import BlankLayout from './Components/Layouts/BlankLayout';
import DocRequestView from './Pages/DocRequestView';
import MyManifestation from './Pages/MyManifestation';
import { MyListServices } from './Pages/MyListServices';
import { ToastMessageProvider } from './contexts/toastMessage';
import { EvaluationEsic } from './Pages/EvaluationEsic';
import ManifestationDetail from './Pages/ManifestationDetail';
import ManifestationDetailPrint from './Pages/ManifestationDetailPrint';
import MyEsic from './Pages/MyEsic';
import { EvaluationManifestation } from './Pages/EvaluationManifestation';
import { EvaluationDemand } from './Pages/EvaluationDemand';
import { EvaluationFlow } from './Pages/EvaluationFlow';
import { SchedulerStepProvider } from './contexts/ShedulerStepContext';
import Notifications from './Pages/Notifications';
import AttendanceOnlineMobileRedirect from './Pages/AttendanceOnlineMobileRedirect';
import AuthenticationByToken from './Pages/TempForm/components/AuthenticationByToken';
import RedirectDocView from './Pages/RedirectDocView';
import EmployeeArea from './Pages/EmployeeArea';

function App(): JSX.Element {
  return (
    <ToastMessageProvider>
      <SchedulerStepProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <Redirect exact from="/" to="/workspace" />
            {<RouteLayout
              path="/workspace"
              exact
              isAuth
              component={MySpace}
              layout={WithHeader}
            />}
            <RouteLayout
              path="/workspace/termos"
              exact
              isAuth
              component={Terms}
              layout={WithHeader}
            />
            <RouteLayout
              path="/workspace/permissoes"
              exact
              isAuth
              component={Permission}
              layout={WithHeader}
            />
            <RouteLayout
              path="/workspace/agendamentos_solicitados"
              exact
              isAuth
              layout={WithHeader}
              component={SchedulingRequest}
            />
            <RouteLayout
              path="/workspace/atividades"
              exact
              isAuth
              component={Activity}
              layout={WithHeader}
            />

            <RouteLayout
              path="/workspace/servico-digital"
              exact
              isAuth
              component={DetailDigitalService}
              layout={WithHeader}
            />

            <RouteLayout
              path="/workspace/formularios/:slug/:protocolo?"
              exact
              isAuth
              component={TempForm}
              layout={WithHeader}
            />

            <RouteLayout
              path="/workspace/servico-digital/:tema_slug/:tema"
              exact
              isAuth
              component={DigitalService}
              layout={WithHeader}
            />

            <RouteLayout
              path="/workspace/favoritos"
              exact
              isAuth
              component={Favorite}
              layout={WithHeader}
            />

            <RouteLayout
              path="/workspace/meus-dados"
              exact
              isAuth
              component={MyData}
              layout={WithHeader}
            />
            <RouteLayout
              path="/workspace/privacidade"
              exact
              isAuth
              component={Privacy}
              layout={WithHeader}
            />
            <RouteLayout
              path="/workspace/area-do-servidor"
              exact
              isAuth
              component={EmployeeArea}
              layout={WithHeader}
            />
            <RouteLayout
              path="/workspace/atendimento_online/:id"
              exact
              isAuth
              component={AttendanceOnlineChat}
              layout={WithHeader}
            />
            <RouteLayout
              exact
              path="/workspace/mobile/atendimento_online/:id/receive_token/:tokenSSO"
              layout={BlankLayout}
              component={AttendanceOnlineMobileRedirect}
            />
            <RouteLayout
              exact
              path="/workspace/mobile/atendimento_online/:id"
              layout={BlankLayout}
              component={AttendanceOnlineChat}
            />
            <RouteLayout
              exact
              path="/workspace/mobile/formularios/:slug/:protocolo?"
              component={TempForm}
              layout={BlankLayout}
            />
            <RouteLayout
              path="/workspace/formularios/mobile/:slug/receive_token/:tokenSSO"
              exact
              component={AuthenticationByToken}
              layout={BlankLayout}
            />
            <RouteLayout
              path="/workspace/atendimentos_solicitados"
              exact
              isAuth
              component={AttendenceRequest}
              layout={WithHeader}
            />
            <RouteLayout
              path="/workspace/seguranca"
              exact
              isAuth
              component={Security}
              layout={WithHeader}
            />

            <RouteLayout
              path="/workspace/minhas_solicitacoes"
              exact
              isAuth
              component={AllMyRequests}
              layout={WithHeader}
            />
            <RouteLayout
              path="/workspace/minhas_solicitacoes/:tipo/:protocolo"
              exact
              isAuth
              component={InfoSolicitation}
              layout={WithHeader}
            />
            <RouteLayout
              path="/workspace/avaliar_atendimento_presencial/:ticket"
              exact
              layout={WithHeader}
              component={EvaluationAttPresential}
            />
            <RouteLayout
              path="/workspace/avaliar_atendimento_interno/:ticket"
              exact
              layout={WithHeader}
              component={EvaluationAttInterno}
            />
            <RouteLayout
              path="/workspace/avaliar_atendimento_online/:ticket"
              exact
              layout={WithHeader}
              component={EvaluationAttOnline}
            />
            <RouteLayout
              path="/workspace/avaliar/:ticket"
              exact
              layout={WithHeader}
              component={EvaluationSchedule}
            />
            <RouteLayout
              path="/workspace/avaliar_atendimento_diario/:ticket"
              exact
              layout={WithHeader}
              component={EvaluationAttOrganic}
            />
            <RouteLayout
              path="/workspace/avaliar_esic/:id"
              exact
              layout={BlankLayout}
              component={EvaluationEsic}
            />
            <RouteLayout
              path="/workspace/avaliar_manifestacao/:id"
              exact
              layout={BlankLayout}
              component={EvaluationManifestation}
            />
            <RouteLayout
              path="/workspace/privacidade"
              exact
              isAuth
              component={Privacy}
              layout={WithHeader}
            />
            <RouteLayout
              path="/workspace/notificacoes"
              exact
              isAuth
              component={Notifications}
              layout={WithHeader}
            />
            <RouteLayout
              path="/workspace/atendimento_online/:id"
              exact
              isAuth
              component={AttendanceOnlineChat}
              layout={WithHeader}
            />
            <RouteLayout
              path="/workspace/atendimentos_solicitados"
              exact
              isAuth
              component={AttendenceRequest}
              layout={WithHeader}
            />
            <RouteLayout
              path="/workspace/seguranca"
              exact
              isAuth
              component={Security}
              layout={WithHeader}
            />

            <RouteLayout
              path="/workspace/meus_servicos/view_doc/:uuid_doc/:identificador_solcitacao"
              exact
              isAuth
              component={DocRequestView}
              layout={BlankLayout}
            />
            <RouteLayout
              path="/workspace/meus_servicos/mobile/view_doc/:uuid_doc/:identificador_solcitacao"
              exact
              isAuth
              component={DocRequestView}
              layout={BlankLayout}
            />
            <RouteLayout
              path="/workspace/mobile/receive_token/:tokenSSO/:uuid_doc/:identificador_solcitacao"
              exact
              component={RedirectDocView}
              layout={BlankLayout}
            />
            <RouteLayout
              path="/workspace/avaliar_atendimento_presencial/:ticket"
              exact
              layout={WithHeader}
              component={EvaluationAttPresential}
            />
            <RouteLayout
              path="/workspace/avaliar_atendimento_interno/:ticket"
              exact
              layout={WithHeader}
              component={EvaluationAttInterno}
            />
            <RouteLayout
              path="/workspace/avaliar_atendimento_online/:ticket"
              exact
              layout={WithHeader}
              component={EvaluationAttOnline}
            />
            <RouteLayout
              path="/workspace/avaliar_atendimento_diario/:ticket"
              exact
              layout={WithHeader}
              component={EvaluationAttOrganic}
            />
            <RouteLayout
              path="/workspace/avaliar_esic/:id"
              exact
              layout={BlankLayout}
              component={EvaluationEsic}
            />
            <RouteLayout
              path="/workspace/avaliar_manifestacao/:id"
              exact
              layout={BlankLayout}
              component={EvaluationManifestation}
            />

            <RouteLayout
              path="/workspace/avaliar_solicitacao_servico/:id"
              exact
              layout={BlankLayout}
              component={EvaluationDemand}
            />

            <RouteLayout
              path="/workspace/avaliar_fluxo/:id"
              exact
              layout={WithHeader}
              component={EvaluationFlow}
            />

            <RouteLayout
              exact
              path="/detalhes_manifestacao/numero=:protocolo&codigo=:codigo"
              layout={WithHeader}
              component={ManifestationDetail}
            />
            <RouteLayout
              layout={BlankLayout}
              path="/detalhes_manifestacao/:codigo/:protocolo/imprimir"
              exact
              component={ManifestationDetailPrint}
            />
          </Switch>
        </BrowserRouter>
      </SchedulerStepProvider>
    </ToastMessageProvider>
  );
}

export default App;

