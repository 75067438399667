import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Icon,
  Button,
  Link,

} from "@material-ui/core";

import { SearchI } from "../../../../interfaces/servico";

const useStyles = makeStyles((theme: Theme) => ({
  mainCard: {
    color: "#373F41",
  },
  cardContent: {
    padding: 30,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      padding: "20px 10px 0px 10px",
    },
  },
  boxAccess: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    height: 225,
    [theme.breakpoints.down("xs")]: {
      height: 294,
    },
  },
  textCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 16,
    marginBottom: 10,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      textAlign: "center",
    },
  },
  subTextCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 5,
    fontSize: 16,
    letterSpacing: 0.3,
    fontWeight: "normal",
    marginRight: 147.5,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 16,
      marginRight: 0,
      textAlign: "center",
    },
  },
  iconAccess: {
    color: theme.palette.primary.main,
    marginBottom: 20,
    marginTop: 8,
    fontSize: 50,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      marginTop: 0,
      marginBottom: 0,
    },
  },
  boxButton: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  button: {
    height: 44,
  },
}));

interface Props {
  service: SearchI;
}

export default function CardService({ service }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6}>
      <Card className={classes.mainCard}>
        <Card className={classes.boxAccess}>
          <CardContent className={classes.cardContent}>
            <Box>
              <Typography className={classes.textCard}>
                {service.titulo}
              </Typography>
              <Typography className={classes.subTextCard}>
                Órgão: {service.orgao_nome}
              </Typography>
            </Box>
            <Box>
              <Icon
                className={`${classes.iconAccess} material-icons-round`}
              >
                business_center
              </Icon>
            </Box>
          </CardContent>
          <Box component="span" m={4} className={classes.boxButton}>
            <Link
              href={`/servico/${service.slug}`}
              target="_new"
            >
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                    VER MAIS
              </Button>
            </Link>
          </Box>
        </Card>
      </Card>
    </Grid>
  );
}
