import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { apisiseci } from './api';
import { apiatendimento156 } from './api';

export const incluirDependente = (
  id_atendimento: number,
  cpf: string,
  nome: string,
  data_nascimento: Date,
  cpf_solicitante: string,
  grau_parentesco: string,
  token: string
): Promise<AxiosResponse> => {
  const data = format(data_nascimento, 'yyyy-MM-dd');
  return apisiseci.post(
    'agendamento/dependente/',
    {
      atendimento: id_atendimento,
      cpf,
      nome,
      data_nascimento: data,
      cpf_solicitante,
      grau_parentesco,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const anexarArquivo = (
  token: string,
  titulo: string,
  descricao: string,
  arquivo: File,
  agendamento: string,
  resposta?: string
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('titulo', titulo);
  formData.append('descricao', descricao);
  formData.append('arquivo', arquivo);
  formData.append('resposta', resposta || '');
  formData.append('agendamento', agendamento);
  return apisiseci.post('agendamento/anexar_agendamento/', formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const anexarArquivoResposta = (
  token: string,
  titulo: string,
  descricao: string,
  arquivo: File,
  resposta: string
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('titulo', titulo);
  formData.append('descricao', descricao);
  formData.append('arquivo', arquivo);
  formData.append('resposta', resposta);
  return apisiseci.post('agendamento/anexar_resposta/', formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const atendimentoPorId = (
  token: string,
  id: string
): Promise<AxiosResponse> =>
  apisiseci.get(`agendamento/historico/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const enviarMensagem = (
  token: string,
  id: string,
  message: string,
  haveAnexo?: boolean
): Promise<AxiosResponse> =>
  apisiseci.post(
    'agendamento/responder/',
    {
      agendamento: id,
      conteudo: message,
      anexo: haveAnexo,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const meusAtendimentos = (
  token: string,
  page: number,
  params?: { status: string }
): Promise<AxiosResponse> =>
  apisiseci.get(`agendamento/meus_atendimentos/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const cancelar = (
  token: string,
  id: string | number,
  justification?: string | undefined
): Promise<AxiosResponse> =>
  apisiseci.put(
    `agendamento/cancelar/${id}/`,
    {
      id,
      status: 'Cancelado',
      justificativa_cancelamento: justification,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const mensagemLida = (
  token: string,
  resposta_id: number
): Promise<AxiosResponse> =>
  apisiseci.put(
    `agendamento/marcar_resposta/${resposta_id}/`,
    {
      lido: true,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const avaliacao = (
  hash: string,
  pendente: boolean,
  token: string,
  avaliacao_consumo: number,
  avaliacao_atendimento: number,
  comentario_consumo: string,
  comentario_atendimento: string
): Promise<AxiosResponse> =>
  apiatendimento156.put(
    `/avaliacao_cidadao/${hash}/?id=${hash}`,
    {
      id: hash,
      pendente,
      token,
      avaliacao_consumo,
      avaliacao_atendimento,
      comentario_consumo,
      comentario_atendimento,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getAvaliacao = (
  token: string,
  hash: string
): Promise<AxiosResponse> =>
  apisiseci.get(`agendamento/avaliacao/?id=${hash}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
