import { Dialog, IconButton, makeStyles, Theme, DialogContent, DialogActions, Button, Box, Typography } from '@material-ui/core'
import { MdClose, MdSupervisorAccount } from 'react-icons/md';
import { IMyAttendences } from '../../interfaces/atendimento';
import { HistoryI } from '../../interfaces/agendamentos';


interface DependentDataDialogProps {
  open: boolean,
  schedule: IMyAttendences | HistoryI
  handleClose: () => void;
}

export default function DependentDataDialog({open, schedule, handleClose} : DependentDataDialogProps): JSX.Element {
  const useStyles = makeStyles((theme: Theme ) => ({
    boxHeader: {
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between',
      width:'100%',
      backgroundColor: theme.palette.primary.main,
      padding: "4px 20px",
    },

    boxDepedent: {
      display:'flex',
      alignItems:'center',
      gap:theme.typography.pxToRem(18),
      fontSize: theme.typography.pxToRem(14),
      fontWeight:500
    },

    icon:{
      fontSize: theme.typography.pxToRem(24),
    },

    closeIcon: {
      color: '#FFFFFF',
      fontSize: theme.typography.pxToRem(24),
    },

    content: {
      padding: '24px',
    },

    subtitle: {
      width:'100%',
      marginBottom:theme.typography.pxToRem(16),
      textAlign: 'left',
      fontSize:theme.typography.pxToRem(20),
      fontWeight:700,
      color:'#333333',
      textShadow:'none',
    },

   table: {
    width:'100%',
    border:'1px solid #EAECF0',
    borderRadius:'4px',
    marginBottom:theme.typography.pxToRem(23),
   },

   row: {
    display:'flex',
    alignItems:'center',
    height:theme.typography.pxToRem(48),
    borderBottom:'1px solid #EAECF0',
   },

   cellName: { 
    display:'flex',
    alignItems:'center',
    width:'100%',
    height:'100%',
    maxWidth:theme.typography.pxToRem(156),
    paddingLeft:theme.typography.pxToRem(16),
    borderRight:'1px solid #EAECF0',
    backgroundColor:'#F9FBFE',
    fontSize:theme.typography.pxToRem(14),
    color:'#333333',
    fontWeight:'bold',
   },

   cellInfo: {
    display:'flex',
    alignItems:'center',
    width:'100%',
    height:'100%',
    paddingLeft:theme.typography.pxToRem(16),
    fontSize:theme.typography.pxToRem(14),
    color:"#706E7A",
    fontWeight:400,
   },

    closeButton: {
      width:'100%',
      maxWidth:'85px',
      borderRadius:'4px',
      fontSize:theme.typography.pxToRem(14),
      fontWeight:'bold',
      marginLeft:'auto'
    }
  }))

  const classes = useStyles()

  return (
    <Dialog open={open} 
      onClose={handleClose}  
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <Box component='div' id="alert-dialog-title" className={classes.boxHeader}>
        <Box component='div' className={classes.boxDepedent}> 
          <MdSupervisorAccount className={classes.icon} /> 
          <span>DEPENDENTE</span>
        </Box>
        <IconButton aria-label="close" className={classes.closeIcon} onClick={handleClose}>
            <MdClose style={{ width: 24, height: 24 }} />
        </IconButton>
      </Box>
      <DialogContent className={classes.content}>
        <Typography variant='h2' component='h2' className={classes.subtitle}>
            Dados do dependente
        </Typography>
        <Box className={classes.table}>
          <div className={classes.row}>
            <p className={classes.cellName}>Nome</p>
            <p className={classes.cellInfo}>{schedule.dependentes.nome}</p>
          </div>
          <div className={classes.row}>
            <p className={classes.cellName}>CPF</p>
            <p className={classes.cellInfo}>{schedule.dependentes.cpf}</p>
          </div>
          <div className={classes.row} style={{borderBottom:'none',}}>
            <p className={classes.cellName}>Grau de parentesco</p>
            <p className={classes.cellInfo}>{schedule.dependentes.grau_parentesco}</p>
          </div>
        </Box>
        <DialogActions style={{padding:0}}>
          <Button 
              color="primary"
              variant="outlined" 
              onClick={handleClose} 
              className={classes.closeButton}>
            FECHAR
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}