import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

import { IoMdSearch } from "react-icons/io";
import { Button } from "@material-ui/core";
import Result from "./components/Result";

const useStyles = makeStyles((theme: Theme) => ({
  searchBar: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    textAlign: "center",
    display: "flex",
    color: "#ffffff",
    border: "2px #ffffff solid",
    alignContent: "center",
    alignItems: "center",
    borderRadius: 4,
    justifyContent: "space-between",
    padding: 0,
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
    },
  },
  searchBarInput: {
    width: "100%",
    border: "none",
    fontSize: theme.typography.pxToRem(16),
    minWidth: "18vw",
    height: 47,
    outline: 0,
    margin: 0,
    paddingLeft: 20,
  },
  btnSearch: {
    background: "#FFF",
    height: 47,
    margin: 0,
    padding: 0,
    borderRadius: "0",
    "&:hover": {
      background: "#FFF",
      opacity: 0.8,
    },
  },
  icon: {
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
  },
}));

export default function SearchServices(): JSX.Element {
  const classes = useStyles();
  const [textSearch, setTextSearch] = useState<string | undefined>();
  const [showModalResult, setShowModalResult] = useState<boolean>(false);

  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    if (textSearch) {
      setShowModalResult(true);
    }
  };

  useEffect(() => {
    if (!textSearch) {
      setShowModalResult(false);
    }
  }, [textSearch]);

  return (
    <>
      <form
        className={classes.searchBar}
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          aria-label="description"
          placeholder="Buscar..."
          className={classes.searchBarInput}
          id="searchBarInput"
          value={textSearch}
          onChange={(e) => {
            setShowModalResult(false);
            setTextSearch(e.target.value);
          }}
        />
        <Button className={classes.btnSearch} onClick={handleSubmit}>
          <IoMdSearch className={classes.icon} />
        </Button>
      </form>
      {showModalResult && textSearch && (
        <Result
          show={showModalResult}
          setShow={setShowModalResult}
          termsForSearch={textSearch}
        />
      )}
    </>
  );
}
