import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { useEffect, useRef, useState } from "react";

type DocumentProps = {
  url: string;
};

export default function Document({ url }: DocumentProps) {
  const objRef = useRef<HTMLObjectElement>(null);
  const [shouldReload, setShouldReload] = useState(false);

  const urlBaseView = `https://docs.google.com/gview?url=${url}&embedded=true`;

  useEffect(() => {
    if (shouldReload) {
      setTimeout(() => {
        setShouldReload(false);
      }, 3000);
    } else {
      setTimeout(() => {
        if (objRef.current?.contentDocument?.location.href === "about:blank") {
          setShouldReload(true);
        }
      }, 2000);
    }
  }, [shouldReload]);

  if (shouldReload) {
    return (
      <div
        style={{
          gap: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: window.innerHeight - 300,
        }}
      >
        <Typography style={{ color: "#000", textAlign: "center" }}>
          Carregando...
        </Typography>
        <CircularProgress />
      </div>
    );
  }

  return (
    <object
      ref={objRef}
      width="100%"
      height={window.innerHeight - 300}
      type="application/pdf"
      data={urlBaseView}
    >
      <p
        style={{
          color: "#000",
          height: window.innerHeight - 300,
          textAlign: "center",
        }}
      >
        Não foi possível carregar o documento. Você pode{" "}
        <a href={urlBaseView} target="_blank" rel="noopener noreferrer">
          clicar aqui para baixar o arquivo
        </a>
        .
      </p>
    </object>
  );
}
