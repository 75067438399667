import React, { Fragment } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
  makeStyles,
  Theme,
  Box,
} from "@material-ui/core";
import { MdCalendarToday, MdSchedule } from "react-icons/md";
import { Atividade } from "../../../../../interfaces/atividades";
import { extractDateFormat } from "../../../../../utils/dateFormat";
import { Link } from "react-router-dom";
import format from "date-fns/format";

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    color: "#373F41",
    height: "auto",
  },
  textActivit: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    marginBottom: 15,
  },
  subTextActivit: {
    display: "flex",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    alignContent: "center",
  },
  iconSubText: {
    padding: 5,
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
  },
  linkToAll: {
    fontFamily: "Roboto",
    letterSpacing: 0.3,
    fontWeight: 400,
    color: theme.palette.primary.main,
    fontSize: 16,
    textDecoration: "none",
  },
  boxActionsIcon: {
    width: 40,
    height: 40,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
interface Props {
  atividades?: Array<Atividade>;
}

export default function TabActions({ atividades }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <>
      <List
        style={{
          padding: 0,
        }}
      >
        {atividades &&
          atividades.map(({ titulo, created_at }) => (
            <Fragment key={`key-${titulo}`}>
              <ListItem className={classes.listItem}>
                <Box>
                  <Typography className={classes.textActivit}>
                    {titulo}
                  </Typography>
                  <Typography className={classes.subTextActivit}>
                    <MdCalendarToday className={classes.iconSubText} />{" "}
                    {extractDateFormat(created_at)}
                    <MdSchedule className={classes.iconSubText} />{" "}
                    {format(new Date(created_at), "HH:mm")}
                  </Typography>
                </Box>
                <ListItemSecondaryAction />
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        <ListItem>
          <Link to="/workspace/atividades" className={classes.linkToAll}>
            Ver todos
          </Link>
        </ListItem>
      </List>
    </>
  );
}
