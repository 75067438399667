import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { MdEventAvailable } from "react-icons/md";
import theme from "../../../../services/theme-service";
import { getTotalConsumo } from "../../../../services/servico";
import getCookie from "../../../../utils/getCookies";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    height: 140,
    color: "#373F41",
    padding: 10,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    height: 40,
  },
  textHeader: {
    fontSize: theme.typography.pxToRem(14),
    textTransform: "uppercase",
    fontWeight: 700,
    fontFamily: "Roboto",
  },
  textCount: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: 500,
    fontFamily: "Roboto",
    color: theme.palette.primary.main,
  },
}));

export default function CountMessage(): JSX.Element {
  const classes = useStyles();
  const [countConcluded, setCountConcluded] = useState<number>(0);
  const tokenSiseci: string | null = getCookie(
    "gov_access_token_sso"
  );

  const getCount = async () => {
    if (tokenSiseci) {
      try {
        const { data } = await getTotalConsumo(tokenSiseci);
        setCountConcluded(data.concluidos);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card
      className={classes.main}>
      <CardContent style={{
        padding: 10,
      }}>
        <Box className={classes.header}>
          <Typography className={classes.textHeader}>
            Agendamentos concluídos
          </Typography>
          <MdEventAvailable
            size={30}
            style={{ color: theme.palette.primary.main }}
          />
        </Box>
        <Typography className={classes.textCount}>
          {countConcluded}
        </Typography>
      </CardContent>
    </Card>
  );
}
