import { Box, Dialog, DialogContent, IconButton, Theme, Typography, makeStyles } from "@material-ui/core";
import { IoMdClose, IoMdStar } from "react-icons/io";
import EvaluationMainContent from "../EvaluationMainContent";
import { useCallback, useMemo, useState } from "react";
import { updateCurrentEvaluation } from "../../services/ouvidoria";
import { MdDone } from "react-icons/md";
import { useToastValue } from "../../contexts/toastMessage";
import { avaliacao } from "../../services/atendimentos";
import getCookie from "../../utils/getCookies";


export type evaluationType =
  'simplifique' |
  'esic' |
  'solicitacao_servico' |
  'demanda' |
  'manifestacao' |
  'presencial' |
  'online' |
  'agendamento' |
  'espontaneo' |
  'complementar' |
  'formflow';

export const evaluationMap: Record<evaluationType, string> = {
  simplifique: 'avaliacao_simplifique',
  esic: 'avaliacao_esic',
  demanda: 'avaliacao_demanda',
  manifestacao: 'avaliacao_manifestacao',
  solicitacao_servico: 'avaliacao_demanda',
  formflow: 'flow/avaliar-solicitacao',
  presencial: 'avaliacao_presencial',
  online: 'avaliacao_online',
  espontaneo: 'diario',
  complementar: 'complementar',
  agendamento: 'agendamento',
};

export const routeToEvaluationType: Record<string, evaluationType> =
  Object.entries(evaluationMap).reduce((acc, [key, value]) => {
    acc[value] = key as evaluationType;
    return acc;
  }, {} as Record<string, evaluationType>);

interface Props {
  open: boolean;
  handleCloseOrOpenDialog: () => void;
  type: evaluationType;
  evaluationIndicator: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderRadius: '8px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '0.5rem 1.625rem',
    justifyContent: 'space-between'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(17),
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: '0.3px',
    textTransform: 'uppercase'
  },
  closeIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 'bold'
  },
  subtitle: {
    color: '#373F41',
    fontSize: theme.typography.pxToRem(20),
    textShadow: 'none',
  },
  boxSuccess: {
    width: '100%',
    maxWidth: 680,
    marginTop: 60,
    marginBottom: 40,
    margin: 'auto',
    borderRadius: 4,
    flexDirection: 'column',
    backgroundColor: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  check: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  boxIcon: {
    width: 56,
    height: 56,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  titleInfo: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 16,
    color: "#333"
  },
  subTitleInfo: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'center',
    margin: '0px 10px',
    color: "#333"
  },

}));

export function EvaluationDialog({
  open,
  handleCloseOrOpenDialog,
  type,
  evaluationIndicator
}: Props): JSX.Element {
  const { setToast } = useToastValue();
  const [loadingSave, setLoadingSave] = useState<boolean>();
  const [ratingService, setRatingService] = useState<number>(0);
  const [ratingAttendance, setRatingAttendance] = useState<number>(0);
  const [commentService, setCommentService] = useState<string>();
  const [commentAttendance, setCommentAttendance] = useState<string>();
  const [evaluationData, setEvaluationData] = useState(null);
  const [negativeRatingOptions, setNegativeRatingOptions] = useState<string[]>([]);

  const classes = useStyles();


  const token: string | null = getCookie('gov_access_token_sso');

  const validateToSave = useMemo(() => {
    if (type === 'manifestacao' || type === 'solicitacao_servico' || type === 'esic') {
      return (!evaluationIndicator || !ratingService);
    } else {
      return (!evaluationIndicator || !ratingAttendance || !ratingService);
    }
  }, [evaluationIndicator, ratingAttendance, ratingService, type]);

  async function updatedCurrentEvaluation() {
    if (validateToSave) {
      throw new Error("Dados não preenchidos.");
    };

    try {
      setLoadingSave(true);
      if (type === 'agendamento') {
        const { data } = await avaliacao(
          evaluationIndicator,
          false,
          token,
          ratingService,
          ratingAttendance,
          commentService,
          commentAttendance
        );

        setEvaluationData(data);
        setLoadingSave(false);
        setToast({
          visible: true,
          message: 'Avaliação realizada com sucesso!',
          type: 'success'
        });
      } else {
        const { data } = await updateCurrentEvaluation(
          evaluationIndicator,
          false,
          token,
          ratingService,
          type,
          negativeRatingOptions?.length > 0 ? negativeRatingOptions.join(';') : commentService,
        );
        setEvaluationData(data);
        setLoadingSave(false);
        setToast({
          visible: true,
          message: 'Avaliação realizada com sucesso!',
          type: 'success'
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingSave(false);
      setToast({
        visible: true,
        message: 'Avaliação não foi realizada!',
        type: 'error'
      });
    }
  }

  const handleRatingService = useCallback((value: number) =>
    setRatingService(value), []);

  const handleRatingAttendance = useCallback((value: number) =>
    setRatingAttendance(value), []);

  const handleServiceComments = useCallback((event: any) =>
    setCommentService(event.target.value), []);

  const handleAttendanceComments = useCallback((event: any) =>
    setCommentAttendance(event.target.value), []);


  const handleOnCloseAndUpdated = useCallback(() => {
    if (evaluationData) {
      handleCloseOrOpenDialog();
    }
    handleCloseOrOpenDialog();
  }, [evaluationData, handleCloseOrOpenDialog]);

  return (
    <Dialog
      open={open}
      onClose={handleOnCloseAndUpdated}
      fullWidth
      maxWidth='sm'
      className={classes.dialog}>
      <Box
        component='header'
        className={classes.header}>
        <Box className={classes.title}>
          <IoMdStar size={20} />
          <span>Avaliação</span>
        </Box>
        <IconButton
          className={classes.closeIcon}
          onClick={handleOnCloseAndUpdated}>
          <IoMdClose />
        </IconButton>
      </Box>
      <DialogContent>
        {!evaluationData && (
          <EvaluationMainContent
            type={type}
            handleSetAttendanceComments={handleAttendanceComments}
            handleSetServiceComments={handleServiceComments}
            handleSetRatingService={handleRatingService}
            handleSetRatingAttendance={handleRatingAttendance}
            isLoading={loadingSave}
            ratingAttendance={ratingAttendance}
            ratingService={ratingService}
            handleSaved={updatedCurrentEvaluation}
            withHeader={false}
            handleNegativeOptions={setNegativeRatingOptions}
            negativeOptions={negativeRatingOptions}
          />
        )}
        {loadingSave === false && evaluationData && (
          <Box className={classes.boxSuccess}>
            <Box className={classes.check}
            >
              <Box className={classes.boxIcon} style={{ backgroundColor: '#0BB07B' }}>
                <MdDone style={{ color: '#fff', height: 30, width: 30 }} />
              </Box>
            </Box>
            <Box style={{
              margin: '40px 0px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Typography className={classes.titleInfo}>Avaliação realizada com sucesso!</Typography>
              <Typography className={classes.subTitleInfo}>Obrigado pela sua contribuição.</Typography>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
