/* eslint-disable react/require-default-props */
import React, { FormEvent, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MdClose } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { searchManifestation } from '../../../../services/ouvidoria';

interface Props {
  handleModal: () => void;
  openModal: boolean;
}

export default function ModalSearchManifestation({
  handleModal,
  openModal,
}: Props): JSX.Element {
  const useStyles = makeStyles((theme: Theme) => ({
    boxActions: {
      padding: '20px 20px 0px 20px',
    },
    buttonIcon: {
      border: 'none',
      background: 'none',
      cursor: 'pointer',
    },
    icon: {
      width: 24,
      height: 24,
      color: '#7A7786',
    },
    boxTitle: {
      padding: '0px 40px',
      marginBottom: 31,
      [theme.breakpoints.down('xs')]: {
        padding: '0px 24px',
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(24),
      color: '#333',
      fontWeight: 600,
      marginBottom: 10,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(20),
      },
    },
    content: {
      padding: '8px 40px',
      [theme.breakpoints.down('xs')]: {
        padding: '8px 24px',
      },
    },
    dialog: {
      borderRadius: 8,
    },
    grid: {},
    secretCheck: {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingLeft: 12,
      paddingBottom: 12,
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    labelSecretCheck: {
      fontSize: theme.typography.pxToRem(15),
      color: 'rgba(31, 41, 55, 0.87)',
      fontWeight: 400,
    },
    boxButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 40,
      marginBottom: 32,
    },
    button: {
      maxWidth: 113,
      width: '100%',
      padding: '8px 0px',
      border: 'none',
      borderRadius: 4,
      textTransform: 'uppercase',
      lineHeight: '24px',
      fontSize: theme.typography.pxToRem(14),
      cursor: 'pointer',
      fontWeight: 400,
      fontFamily: 'Roboto',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'none',
      },
    },
    forwardButton: {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    error: {
      color: '#f00',
    },
    helperText: {
      marginTop: 5,
      marginLeft: 5,
    },
  }));

  const classes = useStyles();
  const [protocol, setProtocol] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const history = useHistory();

  const checkManifestation = async (cod, protocol) => {
    try {
      setLoading(true);
      const { data } = await searchManifestation(cod, protocol);
      if (data[0]) {
        setIsNotFound(false);
        return true;
      } else {
        setIsNotFound(true);
      }
      return false;
    } catch (error) {
      setIsNotFound(true);
      return false;
    } finally {
      setLoading(false);
    }

  };

  const submitForm = async (event: FormEvent) => {
    event.preventDefault();

    if (code.length > 5 && protocol.length > 10) {
      if (!await checkManifestation(code, protocol)) return;
      history.push(`/detalhes_manifestacao/numero=${protocol}&codigo=${code}`);
    }
  };


  return (
    <Dialog
      fullWidth
      open={openModal}
      onClose={handleModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ root: classes.dialog }}
    >
      <DialogActions className={classes.boxActions}>
        <button
          type="button"
          onClick={handleModal}
          className={classes.buttonIcon}
        >
          <MdClose className={classes.icon} />
        </button>
      </DialogActions>
      <Box>
        <DialogTitle className={classes.boxTitle}>
          <Typography className={classes.title}>Acompanhe sua manifestação anônima</Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <form onSubmit={submitForm}>
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  label="Número de Protocolo"
                  fullWidth
                  required
                  error={protocol.length >= 1 && protocol.length < 11}
                  value={protocol || ''}
                  onChange={(event) => setProtocol(event.target.value)}
                  variant="outlined"
                  inputProps={{
                    style: {
                      color: '#333',
                    },
                  }}
                />
                <FormHelperText
                  className={
                    protocol.length >= 1 && protocol.length < 11
                      ? `${classes.helperText} ${classes.error}`
                      : classes.helperText
                  }
                >
                  Seu número de protocolo contém no mínimo 11 caracteres.
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  label="Código de acesso"
                  fullWidth
                  required
                  error={code.length >= 1 && code.length <= 5}
                  value={code || ''}
                  onChange={(event) => setCode(event.target.value)}
                  variant="outlined"
                  inputProps={{
                    style: {
                      color: '#333',
                    },
                  }}
                />
                <FormHelperText
                  className={
                    code.length >= 1 && code.length <= 5
                      ? `${classes.helperText} ${classes.error}`
                      : classes.helperText
                  }
                >
                  Seu código contém no mínimo 6 caracteres.
                </FormHelperText>
              </Grid>
            </Grid>

            {isNotFound && (
              <Grid item xs={12}>
                <Typography
                  color='error'
                  style={{
                    fontSize: 20,
                    marginTop: 10
                  }}>
                  Manifestação não encontrada!
                </Typography>
                <Typography
                  color='error'
                  style={{
                    fontSize: 12
                  }}>
                  Verifique o número de protocolo e código de acesso fornecidos e tente novamente!
                </Typography>
              </Grid>
            )}

            <Box className={classes.boxButton}>
              <button
                type="submit"
                className={`${classes.button} ${classes.forwardButton}`}
              >
                {loading ? <CircularProgress size={16} style={{ color: '#FFFFFF' }} /> : 'Consultar'}
              </button>
            </Box>
          </form>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
