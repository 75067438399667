import { AxiosResponse } from "axios";
import { apisiseci } from "./api";
import { Recommendations } from "../interfaces/recommendations";

export const getRecommendations = async (
  token: string,
  params?: { page: number; items_size: number }
): Promise<AxiosResponse> => {
  return apisiseci.get<Recommendations>("/cms/servicos_recomendados", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};
