import { AxiosResponse } from 'axios';
import { apiforFlow, apiatendimento156 } from './api';
import getCookie from '../utils/getCookies';
const tokenSSO: string | null = getCookie('gov_access_token_sso');

export const getFluxoFlowBySlug = (slug: string): Promise<AxiosResponse> =>
  apiforFlow.get(`flow/fluxo/?slug=${slug}`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });
export const getFormBySlug = (slug: string): Promise<AxiosResponse> =>
  apiforFlow.get(`/flow/formularios/?slug=${slug}`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getFormBySlugFluxo = (slug: string): Promise<AxiosResponse> =>
  apiforFlow.get(`/flow/formularios/?flow_slug=${slug}`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getRequests = (
  page?: number | 1,
  protocolo?: string
): Promise<AxiosResponse> =>
  apiforFlow.get(`/flow/respostas-fluxo/?page=${page}&protocolo=${protocolo}`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getAllRequests = (params: {
  page?: number | 1;
  filtro?: string;
  search?: string;
}): Promise<AxiosResponse> => {
  const filteredParams = params.filtro ? {
    [params.filtro]: params.search,
  } : {}
  
  if (params.filtro === 'data_inicio') {
    var data = new Date(params.search);
    data.setHours(23, 59, 59, 999);
    var fimDoDiaTimestamp = data.getTime();
    return apiatendimento156.get(
      `/demandas_cidadao/?page=${params.page}&data_fim=${fimDoDiaTimestamp}&tipo=Agendamento`,
      {
        headers: {
          Authorization: `Bearer ${tokenSSO}`,
        },
        params: filteredParams,
      }
    );
  } else {
    return apiatendimento156.get(
      `/demandas_cidadao/?page=${params.page}`,
      {
        headers: {
          Authorization: `Bearer ${tokenSSO}`,
        },
        params: filteredParams,
      }
    );
  }
};
export const getAvaliaçãoById = (id: string): Promise<AxiosResponse> => {
  return apiatendimento156.get(`/avaliacao_cidadao/?id=${id}`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });
};
export const cancelRequest = (
  status: string,
  request_id?: number
): Promise<AxiosResponse> =>
  apiforFlow.put(
    `/flow/respostas-fluxo/${request_id}/`,
    {
      status,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const startRequestFlow = (
  flow: string,
  cpf: string
): Promise<AxiosResponse> =>
  apiforFlow.post(
    '/flow/responder-fluxo/',
    {
      flow,
      cpf,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

type answerProps = {
  flow_resposta: number;
  formulario: string;
  cpf: string;
  resposta: any;
};

export const answerForm = (answer: answerProps): Promise<AxiosResponse> =>
  apiforFlow.post('/flow/responder-formulario/', answer, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getAnswerForm = (params: {
  identificador?: number;
  cpf?: string;
}): Promise<AxiosResponse> =>
  apiforFlow.get('/flow/respostas-formulario/', {
    params,
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getDataIncompletedForm = (protocolo): Promise<AxiosResponse> =>
  apiforFlow.get('/flow/carregar-respostas-formularios/', {
    params: { protocolo: protocolo },
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getStatusFormFlow = (params: {
  identificador?: number;
  cpf?: string;
}): Promise<AxiosResponse> =>
  apiforFlow.get('/flow/rastreamento', {
    params,
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getDocumentosResposta = (
  idDoc: string,
  params: { identificador_solicitacao: string; cpf: string }
): Promise<AxiosResponse> =>
  apiforFlow.get(`/flow/respostas-documento/${idDoc}`, {
    params,
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const sendFile = (
  arquivo,
  onlyImage = true,
  confidencial?: boolean,
  serviceSlug?: string
) => {
  const formData = new FormData();
  formData.append('arquivo', arquivo);
  if (confidencial) {
    formData.append('fluxo', serviceSlug);
  }
  const headers = {
    'content-type': 'multipart/form-data',
    Authorization: `Bearer ${tokenSSO}`,
  };

  const path = confidencial
    ? onlyImage
      ? '/flow/confidencial/imagens-upload/'
      : '/flow/confidencial/arquivo-upload/'
    : onlyImage
    ? '/flow/imagens-upload/'
    : '/flow/arquivo-upload/';

  return apiforFlow.post(path, formData, { headers });
};

export const getAllEvaluationFlows = (id: string): Promise<AxiosResponse> =>
  apiforFlow.get('/flow/avaliar-solicitacao/', {
    params: {
      id,
    },
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const updatedEvaluationFlow = (
  id: string,
  avaliacao_informacao: number,
  avaliacao_atendimento: number,
  comentario_informacao?: string,
  comentario_atendimento?: string
): Promise<AxiosResponse> =>
  apiforFlow.put(
    `/flow/avaliar-solicitacao/${id}/?id=${id}`,
    {
      avaliacao_informacao,
      comentario_informacao,
      avaliacao_atendimento,
      comentario_atendimento,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const onTermFlowAccept = (
  solicitacao: string,
  termo: string
): Promise<AxiosResponse> =>
  apiforFlow.post(
    `/flow/termo-fluxo-concordar/`,
    {
      solicitacao,
      termo,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const onConfirmProcessingWithoutSignature = (
  identificador: string,
  tipo: string,
  internal_state: string
): Promise<AxiosResponse> =>
  apiforFlow.post(
    '/flow/confirmar-tramitacao-sem-assinatura-v2',
    {
      identificador,
      tipo,
      internal_state,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const removeIncompletForm = (
  protocolo: number | string
): Promise<AxiosResponse> =>
  apiatendimento156.delete(
    `/remover-solicitacao-em-preenchimento/${protocolo}/`,
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const resendSolicitation = (
  protocolo: string
): Promise<AxiosResponse> => {
  return apiforFlow.post(
    `/flow/reenviar-solicitacao-workflow/?protocolo=${protocolo}`,
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );
};
