import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  useMediaQuery,
} from '@material-ui/core';
import { Breadcrumb, ModalNotAuth } from '../../Components';
import { useHistory } from 'react-router-dom';
import { avaliacao } from '../../services/atendimentos';
import getCookie from '../../utils/getCookies';
import { getAvaliaçãoById } from '../../services/form-flow';
import { useToastValue } from '../../contexts/toastMessage';
import { updateCurrentEvaluation } from '../../services/ouvidoria';
import EvaluationMainContent from '../../Components/EvaluationMainContent';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    padding: "50px 10px",
  },
  infoService: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    width: 680,
    height: 123,
    borderRadius: 4,
  },
  titleService: {
    margin: '26px 0px 8px 0px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 20,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
}));

type Params = {
  ticket: string;
};
type DadosAvaliacao = {
  avaliacao_atendimento: null | number;
  avaliacao_consumo: null | number;
  comentario_atendimento: null | string;
  comentario_consumo: null | string;
  created_at: string;
  demanda: string;
  id: string;
  pendente: boolean;
  servico_titulo: string;
  tipo_demanda: string;
  updated_at: string;
}

export default function EvaluationSchedule(): JSX.Element {
  const classes = useStyles();
  const { ticket }: Params = useParams();
  const [isAuth, setIsAuth] = useState(false);
  const [commentService, setCommentService] = useState<string>("");
  const [commentAttendance, setCommentAttendance] = useState<string>("");
  const [ratingService, setRatingService] = useState<number>(0);
  const [ratingAttendance, setRatingAttendance] = useState<number>(0);
  const [schedule, setSchedule] = useState<undefined | DadosAvaliacao>();
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [evaluationData, setEvaluationData] = useState(null);
  const [negativeRatingOptions, setNegativeRatingOptions] = useState<string[]>([]);
  const { setToast } = useToastValue();
  const history = useHistory();
  const matchesMobile = useMediaQuery("(max-width:458px)");
  const token: string | null = getCookie("gov_access_token_sso");

  const linksBreadcrumb = [
    {
      name: "Minhas Solicitações",
      redirectTo: "/workspace/minhas_solicitacoes",
    },
    {
      name: "Avaliação",
      redirectTo: "#",
    },
  ];



  const getAvaliação = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await getAvaliaçãoById(id);
      setSchedule(data.results[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ticket) {
      getAvaliação(ticket);
    }
  }, [ticket]);





  const validateToSave = useMemo(() => {
    if (schedule?.tipo_demanda === 'Manifestação' || schedule?.tipo_demanda === 'Solicitação' || schedule?.tipo_demanda === 'E-SIC') {
      return (!schedule?.id || !ratingService);
    } else {
      return (!schedule?.id || !ratingAttendance || !ratingService);
    }
  }, [schedule?.id, ratingAttendance, ratingService, schedule?.tipo_demanda]);

  async function updatedCurrentEvaluation() {
    if (validateToSave) {
      throw new Error("Dados não preenchidos.");
    };

    try {
      setLoadingSave(true);
      if (schedule?.tipo_demanda === 'Agendamento') {
        const { data } = await avaliacao(
          schedule?.id,
          false,
          token,
          ratingService,
          ratingAttendance,
          commentService,
          commentAttendance
        );

        setEvaluationData(data);
        setLoadingSave(false);
        setToast({
          visible: true,
          message: 'Avaliação realizada com sucesso!',
          type: 'success'
        });
        setTimeout(() => {
          history.push('/workspace/minhas_solicitacoes')
        }, 3000);
      } else {
        const type = schedule.tipo_demanda === "Solicitação" ? 'solicitacao_servico' : schedule.tipo_demanda === 'Manifestação' ? 'manifestacao' : schedule.tipo_demanda === 'E-SIC' ? 'esic' : 'agendamento'
        const { data } = await updateCurrentEvaluation(
          schedule?.id,
          false,
          token,
          ratingService,
          type,
          negativeRatingOptions?.length > 0 ? negativeRatingOptions.join(';') : commentService,
        );
        setEvaluationData(data);
        setLoadingSave(false);
        setToast({
          visible: true,
          message: 'Avaliação realizada com sucesso!',
          type: 'success'
        });
        setTimeout(() => {
          history.push('/workspace/minhas_solicitacoes')
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      setLoadingSave(false);
      setToast({
        visible: true,
        message: 'Avaliação não foi realizada!',
        type: 'error'
      });
    }
  }

  const handleRatingService = useCallback((value: number) =>
    setRatingService(value), []);

  const handleRatingAttendance = useCallback((value: number) =>
    setRatingAttendance(value), []);

  const handleServiceComments = useCallback((event: any) =>
    setCommentService(event.target.value), []);

  const handleAttendanceComments = useCallback((event: any) =>
    setCommentAttendance(event.target.value), []);


  useEffect(() => {
    const token: string | null = getCookie(
      'gov_access_token_sso'
    );
    if (token) {
      setIsAuth(true)
    } else {
      setIsAuth(false)
    }
  }, []);

  return (
    <>
      <ModalNotAuth
        open={!isAuth}
        isToRedirectUrl
        onClose={() => setIsAuth(true)} />
      {token && <Breadcrumb links={linksBreadcrumb} />}
      <Container className={classes.main}>

        {!evaluationData && schedule && (
          <>
            <Box className={classes.infoService}>
              <Typography className={classes.titleService}>
                avaliação de {schedule.tipo_demanda}
              </Typography>
            </Box>
            <EvaluationMainContent
              type={schedule.tipo_demanda === "Solicitação" ? 'solicitacao_servico' : schedule.tipo_demanda === 'Manifestação' ? 'manifestacao' : schedule.tipo_demanda === 'E-SIC' ? 'esic' : 'agendamento'}
              handleSetAttendanceComments={handleAttendanceComments}
              handleSetServiceComments={handleServiceComments}
              handleSetRatingService={handleRatingService}
              handleSetRatingAttendance={handleRatingAttendance}
              isLoading={loadingSave}
              ratingAttendance={ratingAttendance}
              ratingService={ratingService}
              handleSaved={updatedCurrentEvaluation}
              withHeader={false}
              handleNegativeOptions={setNegativeRatingOptions}
              negativeOptions={negativeRatingOptions}
            />
          </>

        )}
        {!loading && !schedule && <h2>Sem avaliações disponíveis!</h2>}
      </Container>
    </>
  );
}