import React, { useCallback, useEffect, useState } from "react";

import { MdMoreHoriz } from "react-icons/md";
import {
  Box,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
} from "@material-ui/core";
import { SearchI, UnavailabilityI } from "../../../../interfaces/servico";
import ModalAttendenceOnline from "../ModalAttendenceOnline";
import ModalShedulingPresential from "../ModalShedulingPresential";
import { getService } from "../../../../services/servico";
import UnavailabilityModal from "../../../UnavailabilityModal";

interface Props {
  service: SearchI;
  setShowSearchResult: (set: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxActionsIcon: {
    width: 40,
    height: 40,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    height: 24,
    width: 24,
  },
}));

export default function MoreLink({
  service,
  setShowSearchResult,
}: Props): JSX.Element {
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [serviceSelected, setServiceSelected] = useState<SearchI | undefined>();
  const [agendavelDependente, setAgendavelDependente] = useState<boolean | undefined>();
  const [showModalAttendenceOnline, setShowModalAttendenceOnline] =
    useState<boolean>(false);
  const [showModalShedulingPresential, setShowModalShedulingPresential] =
    useState<boolean>(false);
  const [hasScheduled, setHasScheduled] = useState<boolean>(false);
  const [openUnavailabilityModal, setOpenUnavailabilityModal] = useState<boolean>(false);
  const [currentUnavailabilityData, setCurrentUnavailabilityData] = useState<UnavailabilityI>();

  const onGetHasScheduler = useCallback(() => {
    setHasScheduled(!hasScheduled);
  }, [hasScheduled]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSearch = async (slug: string) => {
    try {
      const { data } = await getService(slug);
      setAgendavelDependente(data.results[0].dependente)
    } catch (err) {
      console.log(err)
    }
  };

  const handleSchedulingPresential = (service: SearchI) => {
    const unavailabilityItem = checkUnavailabilityList(service?.indisponibilidades || [])
    if (unavailabilityItem.agendamento) {
      setCurrentUnavailabilityData(unavailabilityItem)
      setOpenUnavailabilityModal(true);
      return;
    }
    setServiceSelected(service);
    setShowModalShedulingPresential(true);
  }

  const handleSchedulingOnline = (service: SearchI) => {
    const unavailabilityItem = checkUnavailabilityList(service?.indisponibilidades || [])
    if (unavailabilityItem.agendamento) {
      setCurrentUnavailabilityData(unavailabilityItem)
      setOpenUnavailabilityModal(true);
      return;
    }
    setServiceSelected(service);
    setShowModalAttendenceOnline(true);
  }
  
  const checkAvailableDate = (fromDate: string, toDate: string) => {
    const parsedFromDate = Date.parse(fromDate);
    const parsedToDate = Date.parse(toDate);
    const today = new Date().getTime();
    return (today <= parsedToDate && today >= parsedFromDate)
  }

  const checkUnavailabilityList = (data: UnavailabilityI[]) => {
    const filteredList = data.filter((item) => item.ativo)
    const isUnavailable = filteredList.find((item) => checkAvailableDate(item.data_hora_inicio, item.data_hora_fim))
    if (isUnavailable) {
      return isUnavailable;
    }
    return { agendamento: false, solicitacao: false } as UnavailabilityI
  }

  useEffect(() => {
    getSearch(service.slug)
  }, [service])
  return (
    <>
      <Box>
        <Box className={classes.boxActionsIcon}>
          <IconButton
            aria-label="comments"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MdMoreHoriz className={classes.icon} style={{ color: "#fff" }} />
          </IconButton>
        </Box>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            <Link href={`/servico/${service.slug}`} target="_new">
              Visualizar
            </Link>
          </MenuItem>
          {(process.env.REACT_APP_AGENDAMENTO_ATIVO === "true") && <>
            {(service.agendavel && process.env.REACT_APP_AGENDAMENTO_PRESENCIAL_ATIVO === 'true') && (
              <MenuItem
                onClick={() => {
                  handleSchedulingPresential(service)
                }}
              >
                <Link href="#" onClick={preventDefault}>
                  Agendamento presencial
                </Link>
              </MenuItem>
            )}
            {(service.online && process.env.REACT_APP_AGENDAMENTO_ONLINE_ATIVO === 'true') && (
              <MenuItem
                onClick={() => {
                  handleSchedulingOnline(service);
                }}
              >
                <Link href="#" onClick={preventDefault}>
                  Agendamento online
                </Link>
              </MenuItem>
            )}
          </>}

        </Menu>
        <UnavailabilityModal
          currentUnavailabilityData={currentUnavailabilityData}
          handleClose={() => setOpenUnavailabilityModal(false)}
          openUnavailabilityModal={openUnavailabilityModal}
        />
      </Box>
      {serviceSelected && (
        <ModalAttendenceOnline
          handleClose={() => {
            setShowModalAttendenceOnline(false);
          }}
          open={showModalAttendenceOnline}
          type="Online"
          serviceSelected={serviceSelected}
          setShowResult={setShowSearchResult}
          agendavelDependente={agendavelDependente}
          handleHasScheduler={onGetHasScheduler}
          hasScheduled={hasScheduled}
        />
      )}
      {serviceSelected && (
        <ModalShedulingPresential
          handleClose={() => {
            setShowModalShedulingPresential(false);
          }}
          open={showModalShedulingPresential}
          serviceSelected={serviceSelected}
          setShowResult={setShowSearchResult}
          agendavelDependente={agendavelDependente}
          handleHasScheduler={onGetHasScheduler}
          hasScheduled={hasScheduled}
        />
      )}
    </>
  );
}
