import { Box, CircularProgress, Theme, makeStyles } from "@material-ui/core";
interface Props {
  isLoading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
    flexDirection: 'column',
    width: '100%',
    height: '100vh'
  },
  icon: {
    fontSize: theme.typography.pxToRem(50),
    color: theme.palette.primary.main,
  }
}));

export default function LoadingCallback({
  isLoading,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {isLoading && (
        <Box className={classes.main}>
          <CircularProgress className={classes.icon} />
        </Box>
      )}
    </>
  )
}