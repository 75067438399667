import { Box, Button, CircularProgress, IconButton, Theme, Typography, makeStyles } from "@material-ui/core";
import { MdArrowBack } from "react-icons/md";
import { useCallback, useEffect, useState } from "react";
import { PaginationService } from "../../Components";
import { NotificationsProps } from "../../interfaces/notifications";
import { FaEnvelope, FaBell, FaSms } from 'react-icons/fa';
import { DialogNotify } from "../../Components/DialogNotify";
import { extractDateFormat } from "../../utils/dateFormat";
import { format } from "date-fns";
import getCookie from "../../utils/getCookies";
import { getAllNotifications } from "../../services/notifications";


type Props = {
  history: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 12,
    width: '100%',
  },
  main: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateRows: 'repeat(auto-fit,1fr)',
    gridTemplateColumns: 'repeat(1,minmax(0px, 824px))',
    borderRadius: 6,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 4px rgba(11, 31, 77, 0.08)',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginInline: 16,
    },
    [theme.breakpoints.only('md')]: {
      marginInline: 32,
    }
  },
  itemCardNotification: {
    all: 'unset',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 1,
    borderBottomColor: '#EAECF0',
    borderBottomStyle: 'solid',
    padding: 24,
    transition: 'background 0.3 ease',
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.primary.main,
      '& .titleMainCard': {
        color: '#FFFFFF'
      },
      '& .subTitleMainCard': {
        color: 'rgba(255,255,255, 0.8)'
      },
      '& .boxIconMessageNotification': {
        background: '#FFFFFF'
      },
      '& .iconNotify': {
        color: '#053396',
      }
    },
  },
  iconNotify: {
    color: '#053396'
  },
  titleMainCard: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    color: '#1D2939',
    pointerEvents: 'none',
    transition: 'color 0.3 ease',

  },
  subTitleMainCard: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(13),
    color: 'rgba(29,41,57,0.8)',
    transition: 'color 0.3 ease',
  },
  itemMessageNotification: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center'
  },
  boxIconMessageNotification: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    minWidth: 40,
    height: 40,
    background: '#fff',
    borderRadius: '100%'
  },
  boxInformationNotification: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 4
  },
  boxIndicatorsBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0px 24px 24px',
    [theme.breakpoints.only("md")]: {
      padding: '20px 0px 24px 0px',
    },
    [theme.breakpoints.down("sm")]: {
      padding: '20px 0px 24px 0px',
    },
  },
  indicatorsBack: {
    display: 'flex',
    width: '100%',
    color: '#1D2939',
    maxWidth: '1250px',
    alignItems: 'center',
  },
  indicatorNotificationNotViewed: {
    width: 8,
    height: 8,
    backgroundColor: '#F03D3D',
    borderRadius: '100%'
  },
  indicatorNotificationViewed: {
    width: 8,
    height: 8,
    backgroundColor: '#EAECF0',
    borderRadius: '100%'
  },
  rootPaginationComponent: {
    marginBlock: 24,
  },
  itemPaginationComponent: {
    background: theme.palette.primary.main,
  },
  iconPaginationComponent: {
    width: 20,
    height: 20,
  },
  iconProgress: {
    fontSize: theme.typography.pxToRem(50),
    color: theme.palette.primary.main,
  }
}));

interface DataNotificationsUnique {
  id: string;
  titulo: string;
  texto: string;
  lido: boolean;
  tipo: string;
  created_at: string;
}

export default function Notifications({ history }: Props): JSX.Element {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const token: string | null = getCookie("gov_access_token_sso");
  const [notifications, setNotifications] = useState<NotificationsProps>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [dataNotificationsById, setDataNotificationsById] = useState<DataNotificationsUnique>();
  const notificationHasData = notifications?.results?.length > 0;
  const notificationHasNotData = notifications?.results?.length < 1;
  function closeModalNotify() {
    setModalOpen(false);
    return;
  }
  function openModalNotify(id: string) {
    setModalOpen(true);
    const filterNotifyById = notifications?.results.filter((item) => item.id === id)[0];
    setDataNotificationsById(filterNotifyById);
    return;
  }
  const modifyListNotificationWithSeenWhenClickButton = (id: string) => {
    const modifyNotification = notifications?.results.length > 0 && notifications.results
      .map((item) => {
        if (item.id === id) {
          return ({
            ...item,
            lido: true
          })
        }
        return ({
          ...item
        })
      });
    setNotifications({ ...notifications, results: modifyNotification });
  };

  useEffect(() => {
    const listNofications = async () => {
      try {
        setLoading(true);
        const { data } = await getAllNotifications({ token, page });
        setNotifications(data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    listNofications();
  }, [page, token]);


  return (
    <>
      {modalOpen && (<DialogNotify data={dataNotificationsById} onCloseModal={closeModalNotify} />)}
      <Box className={classes.boxIndicatorsBack}>
        <Box className={classes.indicatorsBack}>
          <IconButton onClick={() => history.push('/workspace')}>
            <MdArrowBack color="#1D2939" />
          </IconButton>
          <Typography style={{ fontFamily: "Roboto", fontWeight: 700, fontSize: 20 }}>
            Notificações
          </Typography>
        </Box>
      </Box>
      <Box className={classes.container}>
        {!notificationHasData && notificationHasNotData && (
          <Typography>Nenhum resultado foi encontrado!</Typography>
        )}
        {loading ? <CircularProgress className={classes.iconProgress} /> : (
          <>
            <Box className={classes.main}>
              {notificationHasData && notifications.results.map((item) => {
                return (
                  <Button onClick={() => {
                    openModalNotify(item.id)
                    modifyListNotificationWithSeenWhenClickButton(item.id)
                  }} disableRipple key={item.id} className={classes.itemCardNotification}>
                    <Box className={classes.itemMessageNotification}>
                      <Box className={`${classes.boxIconMessageNotification} boxIconMessageNotification`}>
                        {item.tipo === "EMAIL" && <FaEnvelope size={24} className={`${classes.iconNotify} iconNotify`} />}
                        {item.tipo === "SMS" && <FaSms size={24} className={`${classes.iconNotify} iconNotify`} />}
                        {item.tipo === "PUSH" && <FaBell size={24} className={`${classes.iconNotify} iconNotify`} />}
                      </Box>
                      <Box className={classes.boxInformationNotification}>
                        <Typography className={`${classes.titleMainCard} titleMainCard`}>
                          {item.titulo}
                        </Typography>
                        <Typography className={`${classes.subTitleMainCard} subTitleMainCard`}>
                          {item?.created_at ? (`${extractDateFormat(item?.created_at)} - ${format(new Date(item?.created_at), 'kk:mm')}h`) : '03/09/2023 - 10:13h'}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        className={
                          item.lido ?
                            classes.indicatorNotificationViewed :
                            classes.indicatorNotificationNotViewed
                        } />
                    </Box>
                  </Button>
                )
              })}
            </Box>
          </>
        )}
        {notificationHasData && !loading && (
          <PaginationService
            setPage={setPage}
            listSearch={notifications}
          />
        )}
      </Box>
    </>
  )
}