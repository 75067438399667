import { createContext, useContext, useState } from "react";

type TypeProps = 'success' | 'error' | 'warning' | 'info';

type TypeToast = {
  visible: boolean;
  type: TypeProps | null;
  message: string | null;
  description?: string | null;
  isLoading?: boolean;
}

interface ToastContextData {
  toast: TypeToast,
  setToast: (toast: TypeToast) => void;
}

const initialContext = {
  toast: {
    visible: false,
    type: null,
    message: null,
  },
  setToast: () => { }
}

const ToastContext = createContext<ToastContextData>(initialContext);

export function ToastMessageProvider({ children }) {
  const [toast, setToast] = useState(initialContext.toast);

  return (
    <ToastContext.Provider value={{ toast, setToast }}>
      {children}
    </ToastContext.Provider>
  )
}

export const useToastValue = () => useContext(ToastContext);