import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderColor: "#d3d3d3",
            },
        },
        "& .MuiSelect-iconOutlined": {
            height: 24,
            width: 24,
        },
    },
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 10px",
    },
    boxIndicatorsBack: {
        width: "100%",
        margin: "20px 0px",
    },
    indicatorsBack: {
        width: "100%",
        color: "#373F41",
        maxWidth: "1250px",
        alignItems: "center",
        display: 'flex',
    },
    textCard: {
        fontFamily: "Roboto",
        color: "#373F41",
        marginTop: 16,
        fontSize: 16,
        letterSpacing: 0.3,
        textTransform: "uppercase",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
            paddingTop: 0,
            textAlign: "center",
        },
    },
    button: {
        height: 56,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: '0.7',
        }
    },
    status: {
        fontSize: 14,
        fontFamily: 'Roboto',
        fontWeight: 500,
        textTransform: 'capitalize',
        padding: '5px 16px',
        borderRadius: 25,
        [theme.breakpoints.down('sm')]: {
            width: 'fit-content',
            alignSelf: 'center',
        },
    },
    value: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        color: '#1F2937',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    text: {
        fontFamily: 'Roboto',
        fontSize: theme.typography.pxToRem(14),
        color: '#1F2937',
        fontWeight: 400,
        lineHeight: '20.02px',
    },
    openModalLink: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'inline-block',
        fontFamily: 'Roboto',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
        lineHeight: '20.02px',
    },
    icon: {
        width: 18,
        height: 18,
        color: '#053396',
    },
    filter: {
        width: 250,
        color: "#373F41",
    },
    input: {
        color: '#333',
    },
    boxInfo: {
        marginBottom: '25px'
    },
}));