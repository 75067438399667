import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";

import { SearchI } from "../../../../interfaces/servico";

const useStyles = makeStyles((theme: Theme) => ({
  mainCard: {
    color: "#373F41",
    minHeight: 100,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  boxContent: {},
  textTitle: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 8,
    overflow: "auto",
    paddingLeft: 20,
  },
  textSubTitle: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: 14,
    fontWeight: 400,
    opacity: 0.6,
    height: 40,
    overflow: "auto",
    paddingLeft: 20,
  },
  bookmark: {
    color: theme.palette.primary.main,
    width: 60,
  },
  linkToAccess: {
    paddingLeft: 20,
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));

interface Props {
  service: SearchI;
}

export default function CardService({ service }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Card className={classes.mainCard}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.boxContent}>
          <Typography className={classes.textTitle}>
            {service.titulo}
          </Typography>
          <Typography className={classes.textSubTitle}>
            Órgão: {service.orgao_nome}
          </Typography>
          {/* <Link to={`/workspace/servico-digital`} className={classes.linkToAccess}>
            Acessar
          </Link> */}
        </Box>
      </CardContent>
    </Card>
  );
}
