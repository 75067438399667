import { MdClose } from "react-icons/md";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import { IoMdTransgender } from "react-icons/io";
import { IconType } from "react-icons";
import { Box, Typography, Modal, IconButton, Divider } from "@material-ui/core";
import NonBinaryIcon from "../../../../../../Components/NonBinaryIcon";
import theme from "../../../../../../services/theme-service";

interface Props {
  openModalMoreInfo: boolean;
  setOpenModalMoreInfo: (value: boolean) => void;
}

interface GenderIdentityItemProps {
  description: string;
  title: string;
  Icon: IconType;
}

const GenderIdentityItem = ({
  description,
  title,
  Icon,
}: GenderIdentityItemProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 3,
      gridGap: 2,
    }}
  >
    <Icon size={30} color="#706E7A" />
    <Box sx={{ padding: 2, maxWidth: 400 }}>
      <Typography variant="body1">{title}</Typography>
      <Typography variant="caption" style={{ color: "#706E7A" }}>
        {description}
      </Typography>
    </Box>
  </Box>
);

export default function GenderIdentityListModal({
  openModalMoreInfo,
  setOpenModalMoreInfo,
}: Props) {
  return (
    <Modal
      open={openModalMoreInfo}
      onClose={() => setOpenModalMoreInfo(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: theme.palette.background.paper,
          maxWidth: "800px",
          boxShadow: "24px",
          padding: 4,
          borderRadius: 4,
          color: "#000",
          paddingBottom: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Informações
          </Typography>
          <IconButton onClick={() => setOpenModalMoreInfo(false)}>
            <MdClose />
          </IconButton>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <GenderIdentityItem
            Icon={BsGenderFemale}
            title="Mulher Cisgênero"
            description="Mulher que se identifica com o gênero igual ao do sexo de nascimento."
          />
          <GenderIdentityItem
            Icon={BsGenderMale}
            title="Homem Cisgênero"
            description="Homem que se identifica com o gênero igual ao do sexo de nascimento."
          />
          <GenderIdentityItem
            Icon={IoMdTransgender}
            title="Mulher Transgênero"
            description="Mulher que se identifique com um gênero diferente ao do sexo de nascimento."
          />
          <GenderIdentityItem
            Icon={IoMdTransgender}
            title="Homem Transgênero"
            description="Homem que se identifique com um gênero diferente ao do sexo de nascimento."
          />
          <GenderIdentityItem
            Icon={NonBinaryIcon}
            title="Pessoa Não Binária"
            description="Pessoa que não se identifica ou não se sente pertencente a nenhum gênero ou se identifica e se sente pertencente a todos os gêneros."
          />
        </Box>
      </Box>
    </Modal>
  );
}
