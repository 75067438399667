/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  clearStorage,
  getDataUserSso,
  getUserData as getUserDataRequest,
  saveTokens,
} from "../../../../services/auth";

export default function AuthenticationByToken(): JSX.Element {
  const history = useHistory();
  const { slug, tokenSSO } = useParams<{
    slug: string;
    tokenSSO: string;
  }>();
  const [message, setMessage] = useState<string | null>(null);

  const setUserDataLocal = (data: any) => {
    document.cookie = "gov_user_data=" + JSON.stringify(data) + "; path=/";
  };

  const getUserData = async (tokenSso: string) => {
    try {
      setMessage("Carregando...");
      const { data } = await getDataUserSso(tokenSso);
      data && clearStorage();
      data && saveTokens(tokenSSO);
      data && setUserDataLocal(data[0]);
      return data;
    } catch (err) {
      console.log(err);
      setMessage("Erro ao carregar dados do usuário");
      return null;
    }
  };

  useEffect(() => {
    const redirect = async () => {
      const data = await getUserData(tokenSSO);
      data && window.location.replace(`/workspace/mobile/formularios/${slug}`);
    };

    redirect();
  }, [slug, tokenSSO]);

  return <h3>{message}</h3>;
}
