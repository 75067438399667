import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Stepper,
  Step,
  StepLabel,
  Button,
  FormControl,
  TextField,
  CircularProgress,
  useMediaQuery,
  Link
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { Breadcrumb } from '../../Components';
import { MdStar, MdDone, MdErrorOutline } from "react-icons/md";
import { StepIconProps } from '@material-ui/core/StepIcon';
import { MdCheck } from 'react-icons/md'
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { avaliacao_interno, getAvaliacaoInterno } from '../../services/agendamento';
import { Evaluation } from '../../interfaces/agendamentos';
import getCookie from '../../utils/getCookies';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxTitle: {
    display: 'flex',
    width: '100%',
    marginTop: 40,
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 24,
    color: '#333333'
  },
  boxInfoService: {
    marginTop: 80,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  infoService: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    width: 680,
    height: 163,
    borderRadius: 4,
  },
  titleService: {
    margin: '26px 0px 8px 0px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 20,
    textAlign: 'center',
  },
  detailsService: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 8,
  },
  ticket: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 30,
  },
  boxEvaluation: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  evaluation: {
    margin: '16px 0px 200px 0px',
    width: 680,
    height: 618,
    borderRadius: 4,
    backgroundColor: theme.palette.text.primary
  },
  infoSlider: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  evaluationService: {
    with: '100%',
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 18,
    color: '#333333',
    textAlign: 'center',
  },
  boxRating: {
    margin: '16px 0px 24px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  buttonRating: {
    padding: 14,
    margin: '0px 8px',
    borderRadius: 4,
    border: '1px solid #E1E6EF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInfoRating: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
    color: '#706E7A',
    marginTop: 20,
  },
  description: {
    width: '100%',
    marginTop: 20,
    maxWidth: 600,
    borderRadius: 4,
    minHeight: '142px auto',
    margin: '0px 10px',
    border: '1px solid #E1E6EF',
    fontFamily: 'Roboto',
  },
  buttonNext: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    width: 94,
    height: 44,
    borderRadius: 4,
    textTransform: 'uppercase'
  },
  buttonReturn: {
    fontFamily: 'Roboto',
    width: 94,
    fontSize: 14,
    fontWeight: 500,
    height: 44,
    borderRadius: 4,
    textTransform: 'uppercase',
    backgroundColor: '#fff',
    marginRight: 10,
  },
  boxActions: {
    padding: '0px 40px',
    margin: '50px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  input: {
    color: '#333',
  },
  boxSuccess: {
    width: '100%',
    maxWidth: 680,
    marginTop: 60,
    marginBottom: 40,
    margin: 'auto',
    borderRadius: 4,
    flexDirection: 'column',
    backgroundColor: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  check: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  boxIcon: {
    width: 56,
    height: 56,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleInfo: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 16,
  },
  subTitleInfo: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'center',
    margin: '0px 10px',
  },
  actions: {
    flexWrap: 'wrap',
    with: '100%',
    display: 'flex',
    padding: '0px 40px',
    marginBottom: 40,
  },
  buttonSchedule: {
    width: 185,
    fontSize: 14,
    textTransform: 'uppercase',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    borderRadius: 4,
    padding: 10,
    margin: '10px 0px',
  },
  buttonReturnAll: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 500,
    margin: '10px 0px',
  },
  iconStar: {
    height: 24,
    width: 24,
  }
}));

type Params = {
  ticket: string;
};


const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    color: '#ccc',
    padding: 5,
    textAlign: 'center',
    borderRadius: '50%',
    width: 30,
    height: 30,
  },
  active: {
    border: '1px solid #0BB07B',
    backgroundColor: '#0BB07B',
    color: '#fff',
  },
  completed: {
    border: '1px solid #0BB07B',
    backgroundColor: '#0BB07B',
    color: '#fff',
  },
});

const ColorlibStepIcon = (props: StepIconProps) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <MdCheck style={{ height: 20, width: 20 }} /> : props.icon}
    </div>
  );
}

export default function EvaluationAttInterno(): JSX.Element {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const { ticket }: Params = useParams();
  const [commentService, setCommentService] = useState<string>('');
  const [commentAttendance, setCommentAttendance] = useState<string>('');
  const [ratingService, setRatingService] = useState<number>(0);
  const [ratingAttendance, setRatingAttendance] = useState<number>(0);
  const [schedule, setSchedule] = useState<undefined | Evaluation>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isOk, setIsOk] = useState<boolean>(false);
  const history = useHistory();
  const matchesMobile = useMediaQuery("(max-width:458px)");
  const token: string | null = getCookie('gov_access_token_sso');


  const linksBreadcrumb = [
    {
      name: "Meus Agendamentos",
      redirectTo: "/workspace/minhas_solicitacoes"
    },
    {
      name: "Avaliação de atendimento interno",
      redirectTo: "#",
    }
  ];

  const steps = [
    {
      id: "SERVICO",
      title: "Avaliar o consumo"
    },
    {
      id: "ATENDENTE",
      title: "Avaliar o atendente"
    },
  ];

  const handleNext = () => {
    setActiveStep((oldStep) => oldStep + 1);
  }

  const handleEvaluationAttendanceInterno = async () => {
    try {
      if (schedule) {
        const { data } = await avaliacao_interno(
          schedule.id,
          false,
          ratingService,
          ratingAttendance,
          commentService,
          commentAttendance
        );
        if (data) {
          setIsOk(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getSchedule = async (ticket: string) => {
    try {
      setLoading(true);
      const { data } = await getAvaliacaoInterno(token, ticket);
      if (data) {
        setSchedule(data[0]);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    if (ticket) {
      getSchedule(ticket);
    }
  }, [ticket]);

  return (
    <>
      {token && <Breadcrumb links={linksBreadcrumb} />}
      {!loading ? (
        <Container>
          {!schedule ? (
            <Box className={classes.boxSuccess}>
              <Box className={classes.check}
              >
                <Box className={classes.boxIcon} style={{ backgroundColor: '#0F6FB7', }}>
                  <MdErrorOutline style={{ width: 35, height: 35, color: '#fff' }} />
                </Box>
              </Box>
              <Box style={{
                margin: '40px 0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <Typography className={classes.titleInfo}>A avaliação não pode ser mais realizada!</Typography>
                <Typography className={classes.subTitleInfo}>O período de avaliação expirou.</Typography>
              </Box>
              <Box
                width="100%"
                className={classes.actions}
                justifyContent='center'
              >
                <Link
                  className={classes.buttonSchedule}
                  href="/"
                  style={{ fontSize: 15 }}
                >Ir para o portal</Link>
              </Box>
            </Box>
          ) : (
            <>
              {isOk ? (
                <Box className={classes.boxSuccess}>
                  <Box className={classes.check}
                  >
                    <Box className={classes.boxIcon} style={{ backgroundColor: '#0BB07B' }}>
                      <MdDone style={{ color: '#fff', height: 30, width: 30 }} />
                    </Box>
                  </Box>
                  <Box style={{
                    margin: '40px 0px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <Typography className={classes.titleInfo}>Avaliação realizada com sucesso!</Typography>
                    <Typography className={classes.subTitleInfo}>Obrigado pela sua contribuição.</Typography>
                  </Box>
                  <Box
                    width="100%"
                    className={classes.actions}
                    justifyContent={matchesMobile || !token ? 'center' : 'space-between'}
                  >
                    {token ? (
                      <>
                        <Button
                          variant='text'
                          size='small'
                          className={classes.buttonReturnAll}
                          onClick={() => history.push('/workspace')}
                        >VOLTAR PARA O INÍCIO</Button>
                        <Button
                          className={classes.buttonSchedule}
                          variant='contained'
                          color='primary'
                          size='small'
                          onClick={() => history.push('/workspace/minhas_solicitacoes')}
                        >meus agendamentos</Button>
                      </>
                    ) : (
                      <Link
                        className={classes.buttonSchedule}
                        href="/"
                        style={{ fontSize: 15 }}
                      >Ir para o portal</Link>
                    )}
                  </Box>
                </Box>
              ) : (
                <>
                  <Box className={classes.boxTitle}>
                    <Typography className={classes.title}>Avaliação de atendimento interno</Typography>
                  </Box>
                  <Box className={classes.boxInfoService}>
                    <Box className={classes.infoService}>
                      {!loading ?
                        (<>
                          <Typography className={classes.titleService}>
                            {schedule && schedule.servico_titulo}
                          </Typography>
                          <Typography className={classes.detailsService}>
                            {`Horário do atendimento: 
                          ${schedule && schedule.data_agendamento} 
                          às 
                          ${schedule && schedule.hora_agendamento}`}
                          </Typography>
                          <Typography className={classes.ticket}>Ticket: {schedule && schedule.ticket_agendamento}</Typography>
                        </>) : (<CircularProgress style={{ color: "#FFFFFF" }} />)}
                    </Box>
                  </Box>
                  <Box className={classes.boxEvaluation}>
                    <Box className={classes.evaluation}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label.id}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                      {steps[activeStep].id === 'SERVICO' && (
                        <>
                          <Typography className={classes.infoSlider}>{steps[activeStep].title}</Typography>
                          <Box className={classes.evaluationService}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                              <Typography className={classes.text}>Como você avalia sua experiência com o serviço?</Typography>
                              <Box className={classes.boxRating}>
                                <Rating
                                  size="large"
                                  value={ratingService}
                                  name="simple-controlled"
                                  icon={(
                                    <Box className={classes.buttonRating} style={{ backgroundColor: '#0F6FB7' }}>
                                      <MdStar className={classes.iconStar} style={{ color: '#fff', backgroundColor: '#0F6FB7' }} />
                                    </Box>
                                  )}
                                  emptyIcon={(
                                    <Box className={classes.buttonRating} style={{ backgroundColor: '#fff' }}>
                                      <MdStar className={classes.iconStar} style={{ color: '#BDBDBD' }} />
                                    </Box>
                                  )}
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setRatingService(newValue);
                                    }
                                  }}
                                />
                                <Typography className={classes.textInfoRating}>Em uma escala de 1 a 5 estrelas</Typography>
                              </Box>
                              <Box
                                width="100%"
                                display='flex'
                                justifyContent='center'
                              >
                                <TextField
                                  className={classes.description}
                                  id="outlined-multiline-static"
                                  label="Deixe seu comentário (opcional)"
                                  multiline
                                  variant="outlined"
                                  rows={5}
                                  onChange={(e) => setCommentService(e.target.value)}
                                  InputProps={{
                                    className: classes.input,
                                  }}
                                />
                              </Box>
                            </FormControl>
                          </Box>
                        </>
                      )}
                      {steps[activeStep].id === 'ATENDENTE' && (
                        <>
                          <Typography className={classes.infoSlider}>{steps[activeStep].title}</Typography>
                          <Box className={classes.evaluationService}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                              <Typography className={classes.text}>Como você avalia sua experiência com o atendimento?</Typography>
                              <Box className={classes.boxRating}>
                                <Rating
                                  size="large"
                                  value={ratingAttendance}
                                  name="simple-controlled"
                                  icon={(
                                    <Box className={classes.buttonRating} style={{ backgroundColor: '#0F6FB7' }}>
                                      <MdStar className={classes.iconStar} style={{ color: '#fff', backgroundColor: '#0F6FB7' }} />
                                    </Box>
                                  )}
                                  emptyIcon={(
                                    <Box className={classes.buttonRating} style={{ backgroundColor: '#fff' }}>
                                      <MdStar className={classes.iconStar} style={{ color: '#817e7e' }} />
                                    </Box>
                                  )}
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setRatingAttendance(newValue);
                                    }
                                  }}
                                />
                                <Typography className={classes.textInfoRating}>Em uma escala de 1 a 5 estrelas</Typography>
                              </Box>
                              <Box
                                width="100%"
                                display='flex'
                                justifyContent='center'
                              >
                                <TextField
                                  className={classes.description}
                                  id="outlined-multiline-static"
                                  label="Deixe seu comentário (opcional)"
                                  multiline
                                  variant="outlined"
                                  rows={5}
                                  onChange={(e) => setCommentAttendance(e.target.value)}
                                  InputProps={{
                                    className: classes.input,
                                  }}
                                />
                              </Box>
                            </FormControl>
                          </Box>
                        </>
                      )}
                      <Box className={classes.boxActions}>
                        {activeStep === 1 && (
                          <Button
                            variant='contained'
                            size='small'
                            className={classes.buttonReturn}
                            onClick={() => setActiveStep(activeStep - 1)}
                          >Voltar</Button>
                        )}
                        {activeStep < steps.length - 1 && (
                          <Button className={classes.buttonNext}
                            variant='contained'
                            color='primary'
                            size='small'
                            disabled={!ratingService ? true : false}
                            onClick={handleNext}
                          >
                            Próximo
                          </Button>
                        )}
                        {activeStep === steps.length - 1 && (
                          <Button className={classes.buttonNext}
                            variant='contained'
                            color='primary'
                            size='small'
                            disabled={!ratingAttendance ? true : false}
                            onClick={handleEvaluationAttendanceInterno}
                          >
                            Salvar
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </Container>
      ) : (
        <Box style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress color="primary" />
        </Box>
      )}
    </>
  )
}