import { AxiosResponse } from 'axios';
import { apisso } from './api';

export const allConcessions = (token: string, page: number): Promise<AxiosResponse> =>
  apisso.get(`/admin/concessoes/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const removeConcession = (token: string, concession_id: number): Promise<AxiosResponse> =>
  apisso.delete(`/admin/concessoes/${concession_id}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });