import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { FilterAttendence } from "./components";
import {
  Box,
  Typography,
  Grid,
  Container,
  CircularProgress,
  Badge,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CancelDialog from "./components/CancelDialog";
import PrintDialog from "./components/PrintDialog";
import {
  MdToday,
  MdSchedule,
  MdSupervisorAccount,
  MdStar,
} from "react-icons/md";
import { IParamsHistory, historico } from "../../services/agendamento";
import {
  IAttendenceResponse,
  IMyAttendences,
  IRespost,
} from "../../interfaces/atendimento";
import { extractDateFormat } from "../../utils/dateFormat";
import { PaginationService } from "../../Components";
import { useHistory } from "react-router-dom";
import { addDays, isAfter } from "date-fns";
import { HistoryDialog, RescheduleDialog } from "./components";
import DependentDataDialog from '../../Components/DependentDataDialog';
import getCookie from "../../utils/getCookies";

const useStyles = makeStyles((theme: Theme) => ({
  boxIndicatorsBack: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  indicatorsBack: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  boxTermsList: {
    backgroundColor: "#fff",
    padding: 30,
    borderRadius: 4,
    margin: "20px 0px",
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      gap: 10,
      padding: '26px 24px',
    },
  },
  boxInformationTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      gap: 24,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Roboto",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  subTitle: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    margin: "16px 0px 12px 0px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  description: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    color: "#373F41",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  status: {
    fontSize: 14,
    fontFamily: "Roboto",
    fontWeight: 500,
    textTransform: "capitalize",
    padding: "5px 16px",
    borderRadius: 25,
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      alignSelf: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  boxInfo: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#373F41",
    marginTop: "46px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: 'center',
      gap: 18,
      marginTop: 25,
    },
  },
  textInfo: {
    display: "flex",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 16,
    fontStyle: "normal",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 26,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: 7,
    width: 20,
    height: 20,
    [theme.breakpoints.down("xs")]: {
      width: 16,
      height: 16,
    },
  },
  iconStar: {
    color: theme.palette.primary.main,
    marginRight: 5,
    width: 20,
    height: 20,
    [theme.breakpoints.down("xs")]: {
      width: 16,
      height: 16,
    },
  },
  infoNumberGuiche: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  infoTextGuiche: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    textTransform: "uppercase",
  },
  actionsButtonText: {
    textTransform: "uppercase",
    fontFamily: "Roboto",
    borderRadius: 4,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  textButtonGuiche: {
    textTransform: "uppercase",
    fontFamily: "Roboto",
    borderRadius: 4,
    padding: "12px 24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  buttonCancel: {
    color: "#E53535",
    borderColor: "rgba(229,53,53, 0.7)",
    borderRadius: 4,
    padding: "12px 24px",
    "&:hover": {
      backgroundColor: "rgba(229,53,53, 0.1)",
      borderColor: "#E53535",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  notificationCounter: {
    position: "absolute",
    top: -10,
    right: -10,
    padding: "3px 9px",
    borderRadius: "50%",
    backgroundColor: "#F5365C",
    color: theme.palette.text.primary,
    fontSize: 13,
  },
  boxButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 16,
      marginTop: 20,
    },
  },

  dependentsButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    fontStyle: "normal",
    marginRight: 26,
    borderRadius: '6px',
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  }
}));

export default function AttendenceRequest(): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<IParamsHistory | null>(null);
  const [myAttendencesData, setMyAttendencesData] =
    useState<IAttendenceResponse>();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [attendenceSelected, setAttendenceSelected] =
    useState<IMyAttendences>();
  const [openDependentDialog, setOpenDependentDialog] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRescheduleDialog, setOpenRescheduleDialog] = useState(false);
  const [completedReschedule, setCompletedReschedule] = useState(false);
  const [currentPage, setCurrentPage] = useState<number | null>(1);

  const handlePrintDialog = (attendence: IMyAttendences) => {
    setOpenDialog(true);
    setAttendenceSelected(attendence);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAttendenceSelected(undefined);
  };

  const handleRescheduleDialog = (attendence: IMyAttendences) => {
    setOpenRescheduleDialog(true);
    setAttendenceSelected(attendence);
    setCompletedReschedule(false)
  };

  const handleDependentDataDialog = (attendence: IMyAttendences) => {
    setOpenDependentDialog(true)
    setAttendenceSelected(attendence);
  }

  const handleCloseDependentDataDialog = () => {
    setOpenDependentDialog(false)
    setAttendenceSelected(undefined);
  }


  const handleCancelDialog = (attendence: IMyAttendences) => {
    setOpenCancelDialog(true);
    setAttendenceSelected(attendence);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setAttendenceSelected(undefined);
  };
  const handleToAttendence = (id: string) => {
    history.push(`/workspace/atendimento_online/${id}`);
  };

  const handleHistoryDialog = (attendence: IMyAttendences) => {
    setOpenHistoryDialog(true);
    setAttendenceSelected(attendence);
  }

  const handleCloseCancelDialogHistory = () => {
    setOpenHistoryDialog(false);
    setAttendenceSelected(undefined);
  };

  const handleCloseRescheduleDialog = () => {
    setOpenRescheduleDialog(false);
    setAttendenceSelected(undefined);
  };

  const handleCompletedScheduled = () => {
    setCompletedReschedule(true)
  }

  const getMyAttendences = async (
    page: number,
    params?: IParamsHistory
  ): Promise<void> => {
    (window as any).scrollTo(0, 0);
    setMyAttendencesData(undefined);
    const token: string | null = getCookie(
      "gov_access_token_sso"
    );
    setLoading(true);
    if (token) {
      try {
        const { data } = await historico(token, page, 'Online', params);
        setMyAttendencesData(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (filterParams || currentPage) {
      getMyAttendences(currentPage, filterParams);
    } else {
      getMyAttendences(1);
    }
  }, [currentPage, filterParams]);

  useEffect(() => {
    if (completedReschedule) {
      getMyAttendences(1);
    }
  }, [completedReschedule])

  const countNotReadResp = (respostas: IRespost[], user_name: string) => {
    let count = 0;
    respostas.forEach(({ lido, user }) => {
      if (!lido && user.username !== user_name) {
        count += 1;
      }
    });
    return count;
  };

  const handleToEvaluetion = (ticket: string) => {
    history.push(`/workspace/avaliar_atendimento_online/${ticket}`);
  };

  const handleEvaluationDate = (schedule: IMyAttendences) => {
    if (!schedule?.avaliacao_atendimento) {
      return;
    }
    const date = new Date(schedule.avaliacao_atendimento.created_at);
    const now = new Date();
    const afterOneWeek = addDays(date, 7);
    if (isAfter(afterOneWeek, now) && schedule.avaliacao_atendimento.pendente) {
      return (
        <Button
          className={classes.textButtonGuiche}
          color="primary"
          variant="outlined"
          onClick={() => handleToEvaluetion(schedule.avaliacao_atendimento.id)}
        >
          <Typography className={classes.actionsButtonText}>
            Avaliar
          </Typography>
        </Button>
      );
    }
    return;
  };

  return (
    <>
      <Grid container>
        <FilterAttendence setFilter={setFilterParams} />
      </Grid>

      {loading ? (
        <Box
          marginBottom="60px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {myAttendencesData && myAttendencesData.results.length > 0 ? (
            myAttendencesData.results.map((attendence) => (
              <Fragment key={attendence.id}>
                <Container>
                  <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="space-between"
                    md={12}
                    className={classes.boxTermsList}
                    key={attendence.id}
                  >
                    <Box className={classes.boxInformationTitle}>
                      <Typography className={classes.title}>
                        {attendence.unidade.nome}
                        {/* (Guichê{" "}
                        {attendence.guiche.ordem}) */}
                      </Typography>
                      {attendence.status === "Cancelado" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#F44336",
                            border: "1px solid rgba(248, 50, 69, 0.08)",
                            background: "rgba(248, 50, 69, 0.08)",
                          }}
                        >
                          Cancelado
                        </Typography>
                      )}
                      {attendence.status === "Concluído" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#43A047",
                            border: "1px solid rgba(67, 160, 71, 0.08)",
                            background: "rgba(67, 160, 71, 0.08)",
                          }}
                        >
                          Concluído
                        </Typography>
                      )}
                      {attendence.status === "Aguardando" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#0F6FB7",
                            border: "1px solid rgba(15, 111, 183, 0.08)",
                            background: "rgba(15, 111, 183, 0.08)",
                          }}
                        >
                          Aguardando
                        </Typography>
                      )}
                      {attendence.status === "Em Andamento" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#0F6FB7",
                            border: "1px solid rgba(15, 111, 183, 0.08)",
                            background: "rgba(15, 111, 183, 0.08)",
                          }}
                        >
                          Em Andamento
                        </Typography>
                      )}
                      {attendence.status === "Não Compareceu" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#851d15",
                            border: "1px solid rgba(141, 18, 30, 0.08)",
                            background: "rgba(141, 18, 30, 0.08)",
                          }}
                        >
                          Não Compareceu
                        </Typography>
                      )}
                    </Box>
                    <Grid item xs={12} sm={12} md={7}>
                      <Typography className={classes.subTitle}>
                        {attendence.servico.titulo}
                      </Typography>
                      <Typography className={classes.description}>{`Órgão: ${attendence.orgao_responsavel || "Sem descrição"
                        }`}</Typography>
                      {attendence?.protocolo && (
                        <Typography className={classes.subTitle}>
                          {`Protocolo: ${attendence.protocolo}`}
                        </Typography>
                      )}
                      {attendence?.anexos?.length > 0 && (
                        <Box
                          display="flex"
                          gridGap="8px"
                          alignItems="center"
                          justifyContent={matchesMobile ? 'center' : 'flex-start'}
                          flexWrap="wrap"
                        >
                          <Typography className={classes.description}>
                            Anexos:
                          </Typography>
                          {attendence.anexos.map((anexo, index) => (
                            <Typography
                              className={classes.description}
                              onClick={() => (window as any).open(anexo.arquivo)}
                              style={{
                                cursor: 'pointer',
                                color: theme.palette.primary.main,
                                fontWeight: 600,
                              }}
                            >
                              Anexo {index + 1}{index === attendence.anexos.length - 1 ? '' : ','}
                            </Typography>
                          ))}
                        </Box>
                      )}
                      <Box display="flex" className={classes.boxInfo}>
                        <Button
                          color="primary"
                          variant="outlined"
                          disabled={!attendence.dependentes}
                          onClick={() => handleDependentDataDialog(attendence)}
                          className={classes.dependentsButton}>
                          {attendence.dependentes ? (
                            <>
                              <MdSupervisorAccount className={classes.icon} />
                              <Typography className={classes.textInfo}>
                                Dependentes
                              </Typography>
                            </>
                          ) : (
                            <>
                              <MdSupervisorAccount className={classes.icon} style={{ color: '#e0e0e0' }} />
                              <Typography className={classes.textInfo}>
                                Sem dependentes
                              </Typography>
                            </>
                          )}
                        </Button>
                        <Typography className={classes.textInfo}>
                          <MdToday className={classes.icon} />
                          {extractDateFormat(attendence.data)}
                        </Typography>
                        <Typography className={classes.textInfo}>
                          <MdSchedule className={classes.icon} />
                          {attendence.hora}
                        </Typography>
                        {attendence?.avaliacao_atendimento?.pendente === false && (
                          <Typography className={classes.textInfo}>
                            <MdStar className={classes.iconStar} />
                            {`${attendence.avaliacao_atendimento?.avaliacao_consumo} estrelas`}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <Box className={classes.boxButton}>
                        {handleEvaluationDate(attendence)}
                        {attendence.status === "Aguardando" ? (
                          <>
                            <Button
                              className={classes.textButtonGuiche}
                              color="primary"
                              variant="outlined"
                              onClick={() => handlePrintDialog(attendence)}
                            >
                              <Typography className={classes.actionsButtonText}>
                                Imprimir
                              </Typography>
                            </Button>
                            <Button
                              className={classes.textButtonGuiche}
                              color="primary"
                              variant="outlined"
                              onClick={() => handleRescheduleDialog(attendence)}
                            >
                              <Typography className={classes.actionsButtonText}>
                                Reagendar
                              </Typography>
                            </Button>
                            <Button
                              variant="outlined"
                              color="inherit"
                              className={classes.buttonCancel}
                              onClick={() => handleCancelDialog(attendence)}
                            >
                              <Typography className={classes.actionsButtonText}>
                                Cancelar
                              </Typography>
                            </Button>
                          </>
                        ) : (
                          <>
                            {countNotReadResp(
                              attendence.respostas,
                              attendence.user.username
                            ) ? (
                              <Badge
                                badgeContent={countNotReadResp(
                                  attendence.respostas,
                                  attendence.user.username
                                )}
                                color="error"
                              >
                                <Button
                                  className={classes.textButtonGuiche}
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    handleToAttendence(attendence.id)
                                  }
                                >
                                  <Typography className={classes.actionsButtonText}>
                                    Mensagem
                                  </Typography>
                                </Button>
                              </Badge>
                            ) : (
                              <>
                                {attendence.status !== ("Cancelado") && (
                                  <Button
                                    className={classes.textButtonGuiche}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() =>
                                      handleToAttendence(attendence.id)
                                    }
                                  >
                                    <Typography className={classes.actionsButtonText}>
                                      Ver conversa
                                    </Typography>
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {attendence.status === "Concluído" && (
                          <Button
                            className={classes.textButtonGuiche}
                            color="primary"
                            variant="outlined"
                            onClick={() => handleHistoryDialog(attendence)}
                          >
                            <Typography className={classes.actionsButtonText}>
                              Ver histórico

                            </Typography>
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Fragment>
            ))
          ) : (
            <Box
              marginBottom="60px"
              minHeight="100px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <Typography style={{ textAlign: "center" }} variant="h4">
                Nenhum resultado foi encontrado.
              </Typography>
            </Box>
          )}
          {attendenceSelected && (
            <PrintDialog
              open={openDialog}
              attendence={attendenceSelected}
              handleClose={handleCloseDialog}
            />
          )}
          {attendenceSelected?.dependentes && (
            <DependentDataDialog
              open={openDependentDialog}
              schedule={attendenceSelected}
              handleClose={handleCloseDependentDataDialog}
            />
          )}
          {attendenceSelected && (
            <CancelDialog
              open={openCancelDialog}
              attendence={attendenceSelected}
              handleClose={handleCloseCancelDialog}
              onReload={getMyAttendences}
            />
          )}
          {attendenceSelected && (
            <HistoryDialog
              open={openHistoryDialog}
              schedule={attendenceSelected}
              handleClose={handleCloseCancelDialogHistory}
            />
          )}
          {attendenceSelected && (
            <RescheduleDialog
              open={openRescheduleDialog}
              attendence={attendenceSelected}
              handleClose={handleCloseRescheduleDialog}
              handleCompletedScheduled={handleCompletedScheduled}
            />
          )}
        </>
      )}

      {myAttendencesData && myAttendencesData.results.length ? (
        <PaginationService
          setPage={setCurrentPage}
          listSearch={myAttendencesData}
        />
      ) : (
        ""
      )}
    </>
  );
}
