import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    CircularProgress,
    Box,
    IconButton,
} from '@material-ui/core';
import { MdClose, MdTextSnippet } from 'react-icons/md';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useStyles } from './styles';
import { useState } from 'react';
import { DocumentType } from './types';
import Document from './Document';

type ModalDocumentsProps = {
    open: boolean;
    handleClose: () => void;
    title: string;
    isLoading: boolean;
    documents: Array<DocumentType> | Error;
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function ModalDocuments({
    open,
    handleClose,
    title,
    isLoading,
    documents,
}: ModalDocumentsProps) {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth
        >
            <DialogTitle
                id="alert-dialog-title"
                className={classes.boxHeader}
            >
                <div className={classes.titleContainer}>
                    <MdTextSnippet size={30} />
                    <Typography className={classes.textName}>
                        {title}
                    </Typography>
                </div>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <MdClose />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {documents instanceof Error ? (
                    <Box className={classes.container} style={{ height: window.innerHeight - 300 }}>
                        <Typography style={{ color: '#000', textAlign: 'center' }}>
                            {documents.message}
                        </Typography>
                    </Box>
                ) : (
                    isLoading ? (
                        <Box className={classes.container} style={{ flexDirection: 'column', rowGap: 10, height: window.innerHeight - 300 }}>
                            <Typography style={{ color: '#000', textAlign: 'center' }}>
                                Aguarde, carregando documentos...
                            </Typography>
                            <Typography style={{ color: '#000', textAlign: 'center' }}>
                                Isso pode levar algum tempo
                            </Typography>
                            <Typography style={{ color: '#000', textAlign: 'center' }}>
                                Se preferir, você pode fechar essa janela e verificar mais tarde
                            </Typography>
                            <CircularProgress />
                        </Box>
                    ) : (
                        documents.length === 0 ? (
                            <Box className={classes.container} style={{ height: window.innerHeight - 300 }}>
                                <Typography style={{ color: '#000', textAlign: 'center' }}>
                                    Não há documentos disponíveis
                                </Typography>
                            </Box>
                        ) : (
                            <div className={classes.root}>
                                <AppBar position="static" color="default">
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                    >
                                        {documents.map((document, index) => (
                                            <Tab
                                                key={`tab-${document.id}`}
                                                label={document.assunto}
                                                {...a11yProps(index)}
                                            />
                                        ))}
                                    </Tabs>
                                </AppBar>
                                {documents.map((document, index) => (
                                    <TabPanel
                                        key={`tab-panel-${document.id}`}
                                        index={index}
                                        value={value}
                                    >
                                        <Document url={document.arquivo} />
                                        <div
                                            style={{
                                                marginTop: 5,
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: 10,
                                            }}
                                        >
                                            <Button
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                className={classes.btnConfirm}
                                                onClick={() => window.open(document.arquivo, '_blank')}
                                            >
                                                Baixar
                                            </Button>
                                        </div>
                                    </TabPanel>
                                ))}
                            </div>
                        )
                    )
                )}
            </DialogContent>
        </Dialog>
    );
}