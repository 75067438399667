import React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#264259",
      },
    },
  },
  input: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  menuItem: {
    color: theme.palette.primary.main,
    fontSize: 14,
    textTransform: 'capitalize'
  },
}));

interface TextSelectProps {
  itens: any[];
  getOptionLabel: (option: any) => string;
}

const SubSelect: React.FC<TextFieldProps & TextSelectProps> = (props) => {
  const { getOptionLabel, ...rest } = props;
  const classes = useStyles();

  return (
    <TextField
      select
      InputProps={{
        className: classes.input,
      }}
      className={classes.textField}
      variant="outlined"
      fullWidth
      {...rest}
    >
      {props.itens.map((a) => {
        return a?.map((item) => (
          <MenuItem
            key={item?.value}
            value={item?.value || " "}
            className={classes.menuItem}
          >
            {item?.label}
          </MenuItem>
        ))
      })}
    </TextField>
  );
};

export default SubSelect;
