import { AxiosResponse } from 'axios';
import { apisso } from './api';

type NotificationsParams = {
  page: number;
  token?: string;
};

type NotificationsInputPostMarked = {
  notificationId: string;
  token: string;
};

export const getAllNotifications = ({
  token,
  page,
}: NotificationsParams): Promise<AxiosResponse> =>
  apisso.get(`/admin/notificacao/listar/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const requestPostMarkedNotificationAsSeen = ({
  token,
  notificationId,
}: NotificationsInputPostMarked): Promise<AxiosResponse> =>
  apisso.post(
    '/admin/notificacao/marcar/',
    {
      notification_id: notificationId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const requestPostMarkedAllNotificationAsSeen = (
  token: string
): Promise<AxiosResponse> =>
  apisso.post(
    '/admin/notificacao/marcar/?mark_all=true',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
