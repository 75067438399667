import {
  AccordionDetails,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Button,
  Box,
  CircularProgress,
  FormHelperText,
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
  IconButton,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { MdEvent } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import themeCalendarProvider from '../../../../services/calendarTheme';
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { addDays, isValid, parseISO } from "date-fns";
import { cpfMask } from "../../../../utils/cpfFormat";
import { removeCpfMask } from "../../../../utils/cpfFormat";
import { checkCPF } from "../../../../services/auth";
import { MdSearch } from "react-icons/md";
import theme from "../../../../services/theme-service";

const listaParentesco = [
  {
    id: 0,
    option: "Filho(a)",
  },
  {
    id: 1,
    option: "Pai/Mãe",
  },
  {
    id: 2,
    option: "Avós",
  },
  {
    id: 3,
    option: "Cônjugue",
  },
  {
    id: 4,
    option: "Neto(a)",
  },
  {
    id: 5,
    option: "Outros",
  },
];

type Props = {
  nomeConjuge: string;
  setNomeConjuge: React.Dispatch<React.SetStateAction<String>>;
  birthDateConjuge: Date;
  setBirthDateConjuge: React.Dispatch<React.SetStateAction<Date>>;
  quantidadeFilhos: string;
  setQuantidadeFilhos: React.Dispatch<React.SetStateAction<string>>;
  birthDateDependente: Date;
  setBirthDateDependente: React.Dispatch<React.SetStateAction<Date>>;
  nomeDependente: string;
  setNomeDependente: React.Dispatch<React.SetStateAction<string>>;
  isDeficiente: boolean | string;
  setIsDeficiente: React.Dispatch<React.SetStateAction<boolean | string>>;
  parentesco: string;
  setParentesco: React.Dispatch<React.SetStateAction<string>>;
  cpfDependente: string;
  setCpfDependente: React.Dispatch<React.SetStateAction<string>>;
  emailDependente: string;
  setEmailDependente: React.Dispatch<React.SetStateAction<string>>;
  dependentes: any;
  setDependentes: React.Dispatch<React.SetStateAction<any>>;
  removeDependente: (id: number) => Promise<void>
  errorCpfDependente: string;
  setErrorCpfDependente: React.Dispatch<React.SetStateAction<string>>;
  errorNameDependente: string;
  setErrorNameDependente: React.Dispatch<React.SetStateAction<string>>;
  isSave: boolean;
  handleSaveAdditionalDatas: () => Promise<void>
};

const AccordionDependentes: React.FC<Props> = ({
  nomeConjuge,
  setNomeConjuge,
  birthDateConjuge,
  setBirthDateConjuge,
  quantidadeFilhos,
  setQuantidadeFilhos,
  birthDateDependente,
  setBirthDateDependente,
  nomeDependente,
  setNomeDependente,
  isDeficiente,
  setIsDeficiente,
  parentesco,
  setParentesco,
  emailDependente,
  setEmailDependente,
  dependentes,
  setDependentes,
  cpfDependente,
  setCpfDependente,
  removeDependente,
  errorNameDependente,
  setErrorNameDependente,
  errorCpfDependente,
  setErrorCpfDependente,
  isSave,
  handleSaveAdditionalDatas
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const generateClassName = createGenerateClassName({ productionPrefix: 'birthDate', disableGlobal: true });
  const muiTheme = themeCalendarProvider();
  const matchesMobile = useMediaQuery("(max-width:500px)");


  const handleCheckCPF = async () => {
    const cpf = removeCpfMask(cpfDependente);
    if (cpf.length === 11) {
      try {
        setLoading(true);
        const { data } = await checkCPF(removeCpfMask(cpfDependente));

        if (data?.dados_sefaz.Erro) {
          setErrorCpfDependente(data?.dados_sefaz.Erro.Mensagem);
          setLoading(false);
        }

        setNomeDependente(data?.nome || '');
        setBirthDateDependente(data?.dados_sefaz?.DataNascimento ? addDays(new Date(data?.dados_sefaz?.DataNascimento), 1) : null);
        // eslint-disable-next-line no-empty
      } catch (e) {

      } finally {
        setLoading(false);
      }
    }
  };

  const SearchButton = () => (
    <IconButton onClick={handleCheckCPF}>
      <MdSearch className={classes.icon} />
    </IconButton>
  );

  const addDependente = () => {
    setNomeDependente('')
    setEmailDependente('')
    setCpfDependente('')
    setParentesco('')
    setIsDeficiente(null)
    setBirthDateDependente(new Date())
    setDependentes((state) => [
      ...state, {
        nome_completo: '',
        cpf: '',
        dataNascimento: new Date(),
        email: '',
        parentesco: '',
        isDeficiente: null,
      }
    ]);
  };
  return (
    <AccordionDetails className={classes.mainContainer}>
      {loading ? (<Box style={{ marginTop: 30, textAlign: 'center' }}>
        <CircularProgress color="primary" />
      </Box>) : (
        <>
        </>
      )}
      {dependentes?.map((dependente, index) => (
        <Fragment key={index}>
          <div className={classes.inputForm}>
            <div>

              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div className={classes.insideForm}>
                    <FormControl required variant="outlined" fullWidth error={typeof errorCpfDependente === 'string'}>
                      <TextField
                        id="outlined-adornment-cpf"
                        type="text"
                        label="CPF do dependente"
                        autoFocus
                        required
                        variant="outlined"
                        onBlur={() => handleCheckCPF()}
                        className={classes.colorInputText}
                        disabled={dependente.cpf ? true : false}
                        value={cpfMask(dependente.cpf ? dependente.cpf : cpfDependente)}
                        onChange={(e) => {
                          const newCpf = removeCpfMask(e.target.value);

                          if (newCpf.length <= 11) {
                            setCpfDependente(newCpf);
                          }
                        }}
                        InputProps={{
                          endAdornment: <SearchButton />,
                          style: {
                            color: theme.palette.primary.main
                          },
                          classes: {
                            root: '#1D2939',
                            focused: '#1D2939',
                          },
                        }}
                        error={typeof errorCpfDependente === 'string' && dependentes.length - 1 === index || isSave && !cpfDependente && dependentes.length - 1 === index}
                        helperText={dependentes.length - 1 === index ? errorCpfDependente : ''}
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.insideForm}>
                    <FormControl required variant="outlined" fullWidth
                    >
                      <TextField
                        id="outlined-adornment"
                        type="text"
                        label="Nome completo do dependente"
                        autoFocus
                        required
                        variant="outlined"
                        disabled={dependente.nome_completo ? true : false}
                        value={dependente.nome_completo ? dependente.nome_completo : nomeDependente}
                        className={classes.colorInputText}
                        onChange={(e) => {
                          setNomeDependente(e.target.value);
                          setErrorNameDependente(undefined);
                        }}
                        InputProps={{
                          style: {
                            color: theme.palette.primary.main
                          },
                        }}
                        helperText={dependentes.length - 1 === index ? errorNameDependente : ''}
                        error={typeof errorNameDependente === 'string'  && dependentes.length - 1 === index || isSave && !nomeDependente && dependentes.length - 1 === index}
                      />
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={3}>
                  <div className={classes.insideForm}>
                    <FormControl
                      classes={{ root: classes.root }}
                      variant="outlined"
                      required
                      className={classes.formControl}
                    >
                      <StylesProvider generateClassName={generateClassName}>
                        <ThemeProvider theme={muiTheme}>
                          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              keyboardIcon={<MdEvent className={classes.icon} />}
                              InputProps={{
                                className: classes.menuItem,
                                classes: { adornedEnd: classes.adornedEnd },
                              }}
                              autoOk
                              variant="inline"
                              inputVariant="outlined"
                              maxDate={new Date()}
                              label="Data de nascimento"
                              minDateMessage="A data não pode ser superior ao mês atual"
                              invalidDateMessage="A data informada é inválida"
                              format="dd/MM/yyyy"
                              disabled={dependente.data_nascimento ? true : false}
                              value={dependente.data_nascimento ? parseISO(dependente.data_nascimento) : birthDateDependente}
                              InputAdornmentProps={{
                                position: "end",
                                color: "#000",
                              }}
                              onChange={(date) => {
                                if (date && isValid(date)) {
                                  setBirthDateDependente(date);
                                }
                              }}
                            />

                          </MuiPickersUtilsProvider>
                        </ThemeProvider>
                      </StylesProvider>
                    </FormControl>
                  </div>
                </Grid>


                <Grid item xs={6}>
                  <div className={classes.insideForm}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment">
                        Email
                      </InputLabel>
                      <OutlinedInput
                        id="outlined"
                        type="text"
                        label="Email"
                        autoFocus
                        className={classes.colorInputText}
                        disabled={dependente.id ? true : false}
                        value={dependente.id ? dependente.email : emailDependente}
                        onChange={(e) => {
                          setEmailDependente(e.target.value);
                        }}
                        labelWidth={70}
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.insideForm}>
                    <div id="input-parentesco">
                      <FormControl
                        classes={{ root: classes.root }}
                        variant="outlined"
                        required
                        className={classes.formControl}
                        error={isSave && !parentesco && dependentes.length - 1 === index}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Parentesco
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={(event): void => {
                            setParentesco(event.target.value as string);
                          }}
                          disabled={dependente.pessoa_dependente?.[0]?.parentesco ? true : false}
                          value={dependente?.pessoa_dependente?.[0]?.parentesco || parentesco}
                          label="Parentesco"
                          classes={{
                            select: classes.menuItem,
                          }}
                        >
                          {listaParentesco.map((unity) => (
                            <MenuItem
                              key={unity.id}
                              className={classes.menuItem}
                              value={unity.option}
                            >
                              {unity.option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div id="input-date" className={classes.insideForm}>
                    <div className={classes.insideForm}>
                      <div id="input-deficiente">
                        <FormControl
                          classes={{ root: classes.root }}
                          variant="outlined"
                          required
                          className={classes.formControl}
                          error={isSave && isDeficiente == undefined && dependentes.length - 1 === index}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Portador de deficiência?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={(event): void => {
                              setIsDeficiente(event.target.value as string === "Sim" ? true : false);
                            }}
                            disabled={dependente.pcd !== undefined && dependente.pcd !== null}
                            value={dependente.pcd ? 'Sim' : dependente.pcd === false ? 'Não' : isDeficiente === true ? "Sim" : isDeficiente === null ? '' : "Não"}
                            label="Portador de deficiência?"
                            classes={{
                              select: classes.menuItem,
                            }}
                          >

                            <MenuItem
                              key={'Sim'}
                              className={classes.menuItem}
                              value={"Sim"}
                            >
                              Sim
                            </MenuItem>
                            <MenuItem
                              key={'Não'}
                              className={classes.menuItem}
                              value={"Não"}
                            >
                              Não
                            </MenuItem>

                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={classes.containerRemoveButton}>

              {dependente.id ? (<>
                <Button className={classes.removeButton}
                  onClick={() => (removeDependente(dependente.id))}
                >
                  <IoMdTrash size={25} />
                  REMOVER
                </Button>

              </>) : (<>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.buttonSave}
                  onClick={handleSaveAdditionalDatas}
                  style={{ width: matchesMobile && '100%' }}
                >
                  SALVAR
                </Button></>)}


            </div>

          </div>
        </Fragment>
      ))
      }

      {
        dependentes?.length === 0 && (<>
          <div className={classes.inputForm}>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div className={classes.insideForm}>
                    <FormControl required variant="outlined" fullWidth >
                      <TextField
                        id="outlined-adornment-cpf"
                        type="text"
                        required
                        label="CPF do dependente"
                        onBlur={() => handleCheckCPF()}
                        autoFocus
                        variant="outlined"
                        className={classes.colorInputText}
                        value={cpfMask(cpfDependente)}
                        onChange={(e) => {
                          const newCpf = removeCpfMask(e.target.value);

                          if (newCpf.length <= 11) {
                            setCpfDependente(newCpf);
                          }
                        }}
                        InputProps={{
                          endAdornment: <SearchButton />,
                          style: {
                            color: theme.palette.primary.main
                          },
                          classes: {
                            root: '#1D2939',
                            focused: '#1D2939',
                          },
                        }}
                        error={typeof errorCpfDependente === 'string' || isSave && !cpfDependente}
                        helperText={errorCpfDependente}
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.insideForm}>
                    <FormControl required variant="outlined" fullWidth
                    >
                      <TextField
                        id="outlined-full-width"
                        type="text"
                        label="Nome completo do dependente"
                        autoFocus
                        required
                        variant="outlined"
                        value={nomeDependente}
                        className={classes.colorInputText}
                        onChange={(e) => {
                          setNomeDependente(e.target.value);
                          setErrorNameDependente(undefined);
                        }}
                        InputProps={{
                          style: {
                            color: theme.palette.primary.main
                          },
                        }}
                        helperText={errorNameDependente}
                        error={typeof errorNameDependente === 'string' || isSave && !nomeDependente}
                      />
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={3}>
                  <div className={classes.insideForm}>
                    <FormControl
                      classes={{ root: classes.root }}
                      variant="outlined"
                      required
                      className={classes.formControl}
                    >
                      <StylesProvider generateClassName={generateClassName}>
                        <ThemeProvider theme={muiTheme}>
                          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              keyboardIcon={<MdEvent className={classes.icon} />}
                              InputProps={{
                                className: classes.menuItem,
                                classes: { adornedEnd: classes.adornedEnd },
                              }}
                              autoOk
                              maxDate={new Date()}
                              variant="inline"
                              inputVariant="outlined"
                              label="Data de nascimento"
                              minDateMessage="A data não pode ser superior ao mês atual"
                              invalidDateMessage="A data informada é inválida"
                              format="dd/MM/yyyy"
                              value={birthDateDependente}
                              InputAdornmentProps={{
                                position: "end",
                                color: "#000",
                              }}
                              onChange={(date) => {
                                if (date && isValid(date)) {
                                  setBirthDateDependente(date);
                                }
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </ThemeProvider>
                      </StylesProvider>
                    </FormControl>
                  </div>
                </Grid>


                <Grid item xs={6}>
                  <div className={classes.insideForm}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment">
                        Email
                      </InputLabel>
                      <OutlinedInput
                        id="outlined"
                        type="text"
                        label="Email"
                        autoFocus
                        className={classes.colorInputText}
                        value={emailDependente}
                        onChange={(e) => {
                          setEmailDependente(e.target.value);
                        }}
                        labelWidth={70}
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.insideForm}>
                    <div id="input-parentesco">
                      <FormControl
                        classes={{ root: classes.root }}
                        variant="outlined"
                        required
                        className={classes.formControl}
                        error={isSave && !parentesco}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Parentesco
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={(event): void => {
                            setParentesco(event.target.value as string);
                          }}
                          value={parentesco}
                          label="Parentesco"
                          classes={{
                            select: classes.menuItem,
                          }}
                        >
                          {listaParentesco.map((unity) => (
                            <MenuItem
                              key={unity.id}
                              className={classes.menuItem}
                              value={unity.option}
                            >
                              {unity.option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div id="input-date" className={classes.insideForm}>
                    <div className={classes.insideForm}>
                      <div id="input-deficiente">
                        <FormControl
                          classes={{ root: classes.root }}
                          variant="outlined"
                          required
                          className={classes.formControl}
                          error={isSave && isDeficiente == undefined}

                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Portador de deficiência?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={(event): void => {
                              setIsDeficiente(event.target.value as string === "Sim" ? true : false);
                            }}
                            value={isDeficiente}
                            label="Portador de deficiência?"
                            classes={{
                              select: classes.menuItem,
                            }}
                          >

                            <MenuItem
                              key={'Sim'}
                              className={classes.menuItem}
                              value={"Sim"}
                            >
                              Sim
                            </MenuItem>
                            <MenuItem
                              key={'Não'}
                              className={classes.menuItem}
                              value={"Não"}
                            >
                              Não
                            </MenuItem>

                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={classes.containerRemoveButton}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.buttonSave}
                onClick={handleSaveAdditionalDatas}
                style={{ width: matchesMobile && '100%' }}
              >
                SALVAR
              </Button>
            </div>

          </div>
        </>)
      }
      {
        dependentes.length > 0 && dependentes[dependentes.length - 1].id && (<>
          <Button
            variant="outlined"
            className={classes.addDependente}
            onClick={() => (addDependente())}
          >
            ADICIONAR NOVO DEPENDENTE
          </Button>
        </>)
      }

    </AccordionDetails >

  );
};

export default AccordionDependentes;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#d3d3d3",
      },
    },
    "& .MuiSelect-iconOutlined": {
      height: 24,
      width: 24,
    },
  },
  colorInputText: {
    color: theme.palette.primary.main,
  },
  inputForm: {
    margin: "24px 0px",
    color: "#DDD",
    width: "100%",
    display: "flex",
    border: '1px solid #DAE1EF',
    padding: '45px 15px',
    borderRadius: '15px',
    flexDirection: 'column'
  },
  insideForm: {
    marginRight: "6px",

    width: "100%",
  },
  icon: {
    width: 24,
    height: 24,
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  formControl: {
    width: "100%",
  },
  container: {
    background: "#FFFFFF",
    border: "1px solid #DAE1EF",
    borderRadius: 4,
    boxSizing: "border-box",
    boxShadow: "none",
    marginTop: 20,
    padding: '24px 0px',
  },
  accordionTitle: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: "Roboto",
    color: "#333333",
  },
  addDependente: {
    color: theme.palette.primary.main,
    border: 'none',
    width: ' fit-content',
    marginBottom: '-70px',
    justifyContent: 'start'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '50px',
  },
  containerRemoveButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    marginTop: '10px',
  },
  removeButton: {
    color: 'red',
    display: 'flex',
    justifyContent: 'center',
    width: ' fit-content',
  },
  buttonSave: {
    width: 83,
  },
}));
