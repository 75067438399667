interface ServiceProps {
  servicosUnidade: Array<{
    agendamento: boolean;
    atendimento: boolean;
    id: number;
    unidade: {
      ativo: boolean;
      bairro: string;
      complemento: string | null;
      endereco: string;
      nome: string;
      slug_unidade: string;
      source: string;
    };
  }>;
}

export function handleActiveServiceUnitCheck(service: ServiceProps): boolean {
  if (service && service?.servicosUnidade?.length > 0) {
    return (
      service.servicosUnidade.filter((unity) => unity.unidade.ativo).length ===
      0
    );
  } else {
    return true;
  }
}
