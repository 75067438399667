/* eslint-disable array-callback-return */
import React, { useMemo, useState } from "react";

import { MdCancel, MdForwardToInbox, MdMoreVert } from "react-icons/md";
import {
  Box,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
} from "@material-ui/core";
import { RestitutionSelected } from "../..";

type RestitutionData = {
  anexos_restituicao: [];
  historico: number;
  id: string;
  respondido: boolean;
  resposta: string | null;
  solicitacao: number;
}

interface Props {
  currentStatus: string;
  handleOpenModal: () => void;
  onOpenModalCancel: () => void;
  request: any;
  setRequestId: (value: any) => void;
  restitutionData: RestitutionData[] | null;
  setDataRestitution?: (value: RestitutionSelected) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxActionsIcon: {
    width: 40,
    height: 40,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 1,
  },
  icon: {
    height: 24,
    width: 24,
  },
  iconCancel: {
    height: 24,
    width: 24,
    color: '#f00'
  },
  links: {
    display: 'flex',
    gap: 10,
    fontFamily: 'Roboto',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    color: '#373F41',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(13),
    color: '#373F41',
    fontSize: theme.typography.pxToRem(16),
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  menuIcon: {
    fontSize: theme.typography.pxToRem(20),
  },
}));

export default function ButtonActions({
  currentStatus,
  onOpenModalCancel,
  request,
  setRequestId,
  handleOpenModal,
  restitutionData,
  setDataRestitution
}: Props): JSX.Element {
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActiveCancelButton = useMemo(() => (currentStatus === 'Finalizado') ||
    (currentStatus === 'Cancelado pelo atendente') ||
    (currentStatus === 'Cancelado pelo usuário'), [currentStatus]);

  return (
    <>
      {!isActiveCancelButton && (
        <Box>
          <Box className={classes.boxActionsIcon}>
            <IconButton
              aria-label="comments"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MdMoreVert className={classes.icon} style={{ color: "#fff" }} />
            </IconButton>
          </Box>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {restitutionData?.map((restituicao) => {
              if (!restituicao.respondido) {
                return (
                  <MenuItem
                    key={restituicao.id}
                    onClick={() => {
                      handleOpenModal();
                      setDataRestitution({
                        solicitacao: restituicao.solicitacao,
                        id: restituicao.id,
                      })
                    }}>
                    <Link href="#" onClick={preventDefault} className={classes.links}>
                      <MdForwardToInbox className={classes.icon} />
                      Responder
                    </Link>
                  </MenuItem>
                )
              }
            })}
            {!isActiveCancelButton && (
              <MenuItem
                onClick={() => {
                  onOpenModalCancel();
                  setRequestId(request.id);
                }}>
                <Link className={classes.links}>
                  <MdCancel className={classes.iconCancel} />
                  Cancelar solicitação
                </Link>
              </MenuItem>
            )}
          </Menu>
        </Box>
      )}
    </>
  );
}
