import React, { useEffect, useState } from "react";
import { Box, Card, Typography, makeStyles, Theme } from "@material-ui/core";
import TabActions from "./TabActions";
import { getAtividades } from "../../../../services/servico";
import { Atividade } from "../../../../interfaces/atividades";
import TabFavorite from "./TabFavorite";
import { actionType } from "../../store/reducer";
import { useStateValue } from "../../store/stateProvider";
import getCookie from "../../../../utils/getCookies";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxActions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '14px 14px 0px 14px',
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  textTitleSelected: {
    cursor: 'pointer',
    padding: '15px 0px',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
  },
  textTitle: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    padding: '15px 0px',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
  },
  boxActivy: {
    width: '100%',
    textAlign: 'center'
  },
  boxFavorite: {
    width: '100%',
    textAlign: 'center'
  }
}));


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function TabsActionsFavorite(): JSX.Element {
  const classes = useStyles();
  const [atividades, setAtividades] = useState<Array<Atividade> | undefined>();
  const [{ favorites }, dispatch] = useStateValue();
  const [
    serviceSelected,
    setServiceSelected,
  ] = useState<'atividades' | 'favoritos'>('atividades');

  const tokenSiseci: string | null = getCookie(
    "gov_access_token_sso"
  );

  const getAtividadesAll = async () => {
    if (tokenSiseci) {
      try {
        const { data } = await getAtividades(tokenSiseci);

        setAtividades(data.atividades);
        dispatch({
          type: actionType.GET_FAVORITES,
          favorites: data.favoritos
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    getAtividadesAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <Box className={classes.boxActions}>
        <Box
          className={classes.boxActivy}
          onClick={(): void => setServiceSelected('atividades')}
        >
          <Typography
            className={
              serviceSelected === 'atividades'
                ? classes.textTitleSelected
                : classes.textTitle
            }
          >
            Atividades
          </Typography>
        </Box>
        <Box
          className={classes.boxFavorite}
          onClick={(): void => setServiceSelected('favoritos')}
        >
          <Typography
            className={
              serviceSelected === 'favoritos'
                ? classes.textTitleSelected
                : classes.textTitle
            }
          >
            Favoritos
          </Typography>
        </Box>
      </Box>
      {serviceSelected === 'atividades' && (
        <TabPanel value={0} index={0}>
          <TabActions atividades={atividades} />
        </TabPanel>
      )}
      {serviceSelected === 'favoritos' && (
        <TabPanel value={0} index={0}>
          <TabFavorite favorites={favorites} />
        </TabPanel>
      )}
    </Card>
  );
}
