import { CircularProgress, Snackbar, Typography, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useToastValue } from "../../contexts/toastMessage";
import { useCallback } from "react";

const useStyles = makeStyles(() => ({
  alert: {
    marginTop: 90,
  }
}));

export default function ToastMessage(): JSX.Element {
  const { toast, setToast } = useToastValue();
  const classes = useStyles();

  const onCloseAlert = useCallback(() => {
    setToast({
      message: null,

      type: null,
      visible: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={toast.visible}
      autoHideDuration={6000}
      onClose={onCloseAlert}
    >
      <Alert
        onClose={onCloseAlert}
        severity={toast.type}
        className={classes.alert}
      >
        {toast.message}
        {toast.description &&
          <Typography 
            component='p' 
            style={{ fontWeight:400, fontSize:'0.8rem' }}>
              {toast.description}
          </Typography>
        }
        {toast.isLoading &&
          <CircularProgress size={15} style={{ marginLeft: '5px'}} />
        }
      </Alert>
    </Snackbar>
  )
}