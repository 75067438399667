import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { MdEvent } from "react-icons/md";
import { useState } from "react";
import { getTotalConsumo } from "../../../../services/servico";
import theme from "../../../../services/theme-service";
import { useHistory } from "react-router-dom";
import getCookie from "../../../../utils/getCookies";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    height: 140,
    color: "#373F41",
    padding: 10,
    cursor: 'pointer'
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    height: 40,
  },
  textHeader: {
    fontSize: theme.typography.pxToRem(14),
    textTransform: "uppercase",
    fontWeight: 700,
    fontFamily: "Roboto",
  },
  textCount: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: 500,
    fontFamily: "Roboto",
    color: theme.palette.primary.main,
  },
}));

export default function CountServices(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [countAll, setCountAll] = useState<number>(0);
  const tokenSiseci: string | null = getCookie(
    "gov_access_token_sso"
  );

  const getCount = async () => {
    if (tokenSiseci) {
      try {
        const { data } = await getTotalConsumo(tokenSiseci);
        setCountAll(data.online + data.presencial);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      onClick={() => history.push('/workspace/minhas_solicitacoes')}
      className={classes.main}>
      <CardContent style={{
        padding: 10,
      }}>
        <Box className={classes.header}>
          <Typography className={classes.textHeader}>
            Total de agendamentos
          </Typography>
          <MdEvent
            size={30}
            style={{ color: theme.palette.primary.main }}
          />
        </Box>
        <Typography className={classes.textCount}>{countAll}</Typography>
      </CardContent>
    </Card>
  );
}
