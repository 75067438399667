import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { sendFile } from "../../services/form-flow";

type Props = {
  id?: string;
  value?: string;
  setValue?: (value: string) => void;
  onChange?: (value: string) => void;
  modeEdit?: "basic" | "full";
  addPlugins?: string[];
  addConf?: object;
  addToolbar?: string[];
  disabled?: boolean;
  print?: boolean;
};

const MyEditor = (props: Props) => {
  const imageUploadHandle = async (blobInfo, success, failure) => {
    try {
      const { data } = await sendFile(blobInfo.blob());
      if (data) {
        success(data.arquivo || "");
      }
    } catch (error) {
      console.log(error);
      failure("error");
    }
  };

  return (
    <Editor
      tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
      value={props.value}
      id={props.id}
      onEditorChange={(content) => {
        props.setValue && props.setValue(content);
      }}
      init={{
        min_height: 700,
        language: "pt_BR",
        plugins: [
          "image",
          "accordion",
          "anchor",
          "media",
          "table",
          "link",
          "autolink",
          "autoresize",
          "charmap",
          "code",
          "codesample",
          "directionality",
          "emoticons",
          "fullscreen",
          "help",
          "importcss",
          "insertdatetime",
          "link",
          "lists",
          "nonbreaking",
          "pagebreak",
          "preview",
          "quickbars",
          "searchreplace",
          "visualblocks",
          "visualchars",
          "wordcount",
        ],
        images_upload_handler: imageUploadHandle,
        toolbar: props.print
          ? ["print"]
          : "undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | print | image | table | link  | wordcount | fullscreen",
        menubar: false,
        content_style: `
                @media print {
                    html, body {
                        height: 100%;
                    }
                    body {
                        -webkit-print-color-adjust: exact;
                        print-color-adjust: exact;
                    }
                    #content-main {
                        display: flex;
                        flex-direction: column;
                        min-height: 90vh;
                    }
                }
            `,
        ...props.addConf,
        ...props.addToolbar,
      }}
      disabled={props.disabled}
    />
  );
};

export default MyEditor;
