import React from 'react';
import {
  TextField,
} from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface ValidatorProps {
  validator?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
    },
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  input: {
    color: theme.palette.primary.main,
  },
}));

export default function TextNumber(props: TextFieldProps & ValidatorProps): JSX.Element {
  const classes = useStyles();
  const regex = new RegExp(props.validator, 'g');

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    const isValid = regex.test(value);

    if (!isValid) {
      (event.target as HTMLInputElement).setCustomValidity("É preciso que o formato corresponda ao exigido.");
      return;
    }
    (event.target as HTMLInputElement).setCustomValidity(""); // Limpa a mensagem personalizada quando o usuário começa a digitar
  };

  return (
    <TextField
      InputProps={{
        className: classes.input,
        inputProps: { min: 0, step: 0.01 }
      }}
      className={classes.textField}
      onInput={props.validator && handleInput}      
      type="number"
      variant="outlined"
      fullWidth
      {...props}
    />
  );
}