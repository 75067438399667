import DateFnsUtils from "@date-io/date-fns";
import {
  AccordionDetails,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
  createGenerateClassName,
  TextField,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import {
  makeStyles,
  StylesProvider,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { isValid } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { useEffect, useMemo } from "react";
import { MdEvent } from "react-icons/md";
import themeCalendarProvider from "../../../../services/calendarTheme";
import { DadosComplementares, UserData } from "../../../../interfaces/user";
import { cpfMask } from "../../../../utils/cpfFormat";
import { searchCep } from "../../../../services/ouvidoria";
import { MdSearch } from "react-icons/md";
import theme from "../../../../services/theme-service";
import GenderIdentityInput from "./components/GenderIdentityInput";
import GenderIdentityListModal from "./components/GenderIdentityModal";
// import { PiGenderNonbinary } from "react-icons/pi";

type Props = {
  userData: UserData | undefined;
  userDataAdditional: DadosComplementares | undefined;
  birthDate: Date;
  setBirthDate: React.Dispatch<React.SetStateAction<Date>>;
  raca: string;
  setRaca: React.Dispatch<React.SetStateAction<string>>;
  naturalidade: string;
  setNaturalidade: React.Dispatch<React.SetStateAction<string>>;
  nomeMae: string;
  setNomeMae: React.Dispatch<React.SetStateAction<string>>;
  genero: string;
  setGenero: React.Dispatch<React.SetStateAction<string>>;
  estadoCivil: string;
  setEstadoCivil: React.Dispatch<React.SetStateAction<string>>;
  nacionalidade: string;
  setNacionalidade: React.Dispatch<React.SetStateAction<string>>;
  nomePai: string;
  setNomePai: React.Dispatch<React.SetStateAction<string>>;
  nomeSocial: string;
  setNomeSocial: React.Dispatch<React.SetStateAction<string>>;
  apelido: string;
  setApelido: React.Dispatch<React.SetStateAction<string>>;
  escolaridade: string;
  setEscolaridade: React.Dispatch<React.SetStateAction<string>>;
  setOrientacaoSexual: React.Dispatch<React.SetStateAction<string>>;
  orientacaoSexual: string;
  setIdentidadeDeGenero: React.Dispatch<React.SetStateAction<string>>;
  identidadeDeGenero: string;
  setPcd: React.Dispatch<React.SetStateAction<boolean>>;
  pcd: boolean;
  setPcdValue: React.Dispatch<React.SetStateAction<string>>;
  pcdValue: string;
  setOtherPcd: React.Dispatch<React.SetStateAction<string>>;
  otherPcd: string;
  setShowPcd: React.Dispatch<React.SetStateAction<boolean>>;
  showPcd: boolean;
  listPcd: { id: number; option: string }[];
  blob: any;
  avatarUser: any;
  handleChangeImage: (e: any) => void;
  tipoLogradouro: string;
  setTipoLogradouro: React.Dispatch<React.SetStateAction<string>>;
  logradouro: string;
  setLogradouro: React.Dispatch<React.SetStateAction<string>>;
  numeroCasa: string;
  setNumeroCasa: React.Dispatch<React.SetStateAction<string>>;
  complemento: string;
  setComplemento: React.Dispatch<React.SetStateAction<string>>;
  bairro: string;
  setBairro: React.Dispatch<React.SetStateAction<string>>;
  cep: string;
  setCep: React.Dispatch<React.SetStateAction<string>>;
  municipio: string;
  setMunicipio: React.Dispatch<React.SetStateAction<string>>;
  uf: string;
  setUf: React.Dispatch<React.SetStateAction<string>>;
  telefone: string;
  setTelefone: React.Dispatch<React.SetStateAction<string>>;
  endereco: string;
  setEndereco: React.Dispatch<React.SetStateAction<string>>;
  setErrorCep: (value: string) => void;
  errorCep: string | null;
  isSave: boolean;
};

const listaGeneros = [
  {
    id: 0,
    option: "Masculino",
  },
  {
    id: 1,
    option: "Feminino",
  },
  {
    id: 3,
    option: "Não informado",
  },
];

const listaRaca = [
  {
    id: 0,
    option: "Branca",
  },
  {
    id: 1,
    option: "Preta",
  },
  {
    id: 2,
    option: "Parda",
  },
  {
    id: 3,
    option: "Amarela",
  },
  {
    id: 4,
    option: "Indígena",
  },
];

const listaEstadoCivil = [
  {
    id: 0,
    option: "Solteiro",
  },
  {
    id: 1,
    option: "Casado",
  },
  {
    id: 2,
    option: "Separado",
  },
  {
    id: 3,
    option: "Viúvo",
  },
  {
    id: 4,
    option: "Divorciado",
  },
  {
    id: 5,
    option: "Não informado",
  },
];

const listaEscolaridade = [
  {
    id: 0,
    option: "Fundamental Completo",
  },
  {
    id: 1,
    option: "Fundamental Incompleto",
  },
  {
    id: 2,
    option: "Ensino médio Completo",
  },
  {
    id: 3,
    option: "Ensino médio Incompleto",
  },
  {
    id: 4,
    option: "Ensino Superior Completo",
  },
  {
    id: 5,
    option: "Ensino Superior Incompleto",
  },
  {
    id: 6,
    option: "Analfabeto(a)",
  },
  {
    id: 7,
    option: "Não informado",
  },
];

const listaOrientacaoSexual = [
  {
    id: 0,
    option: "Heterossexual",
  },
  {
    id: 1,
    option: "Homossexual",
  },
  {
    id: 2,
    option: "Bisssexual",
  },
  {
    id: 3,
    option: "Outro",
  },
];

const listaIdentidadeDeGenero = [
  {
    id: 0,
    option: "Mulher Cisgênero",
  },
  {
    id: 1,
    option: "Homem Cisgênero",
  },
  {
    id: 2,
    option: "Mulher Transgênero",
  },
  {
    id: 3,
    option: "Homem Transgênero",
  },
  {
    id: 4,
    option: "Pessoal Não Binária",
  },
  {
    id: 5,
    option: "Outro",
  },
];

const AccordionDadosPessoais: React.FC<Props> = ({
  userData,
  birthDate,
  setBirthDate,
  raca,
  setRaca,
  naturalidade,
  setNaturalidade,
  nomeMae,
  setNomeMae,
  genero,
  setGenero,
  estadoCivil,
  setEstadoCivil,
  nacionalidade,
  setNacionalidade,
  nomePai,
  setNomePai,
  nomeSocial,
  setNomeSocial,
  apelido,
  setApelido,
  escolaridade,
  setEscolaridade,
  setOrientacaoSexual,
  orientacaoSexual,
  identidadeDeGenero,
  setIdentidadeDeGenero,
  pcdValue,
  setPcdValue,
  setLogradouro,
  numeroCasa,
  setNumeroCasa,
  complemento,
  setComplemento,
  bairro,
  setBairro,
  cep,
  setCep,
  municipio,
  setMunicipio,
  uf,
  setUf,
  telefone,
  setTelefone,
  endereco,
  setEndereco,
  errorCep,
  setErrorCep,
  isSave,
  setOtherPcd,
  otherPcd,
  showPcd,
  setShowPcd,
  listPcd,
  pcd,
  setPcd,
}) => {
  const classes = useStyles();
  const [openModalMoreInfo, setOpenModalMoreInfo] = React.useState(false);
  const [disableButtonEndereco, setDisableButtonEndereco] =
    React.useState(false);
  const [loadingAddress, setLoadingAddress] = React.useState(false);
  const [checkCEP, setCheckCEP] = React.useState(true);
  const [disableCepGenerico, setDisableCepGenerico] = React.useState(false);
  const generateClassName = createGenerateClassName({
    productionPrefix: "birthDate",
    disableGlobal: true,
  });
  const muiTheme = themeCalendarProvider();
  const matchesMobile = useMediaQuery("(max-width:500px)");

  const handleSearchAddress = async () => {
    setDisableButtonEndereco(false);
    setDisableCepGenerico(false);
    if (!cep) {
      setLoadingAddress(false);
      return;
    }
    try {
      setLoadingAddress(true);
      const { data } = await searchCep(cep, "", "BA");
      if (data.cidade === "Salvador") {
        setDisableButtonEndereco(true);
        setLoadingAddress(false);
      } else if (data.cidade && data.uf) {
        setDisableCepGenerico(true);
        setLoadingAddress(false);
      }
      setBairro(data.bairro);
      setComplemento(data?.complemento);
      setEndereco(data?.logradouro);
      setUf(data?.uf);
      setMunicipio(data?.cidade);
      setLoadingAddress(false);
    } catch (error) {
      if (error?.response?.data) {
        if (cep.length < 8) {
          setErrorCep("O CEP deve conter 8 números");
          setLoadingAddress(false);
        } else {
          setErrorCep(error?.response?.data?.message);
          setLoadingAddress(false);
        }
        setLoadingAddress(false);
      }
      console.error(error);
      setLoadingAddress(false);
    } finally {
      setLoadingAddress(false);
    }
    setCheckCEP(false);
  };

  const resetAddress = () => {
    setBairro("");
    setComplemento("");
    setEndereco("");
    setUf("");
    setMunicipio("");
    setNumeroCasa("");
    setLogradouro("");
  };

  function formatCEP(cep) {
    if (!cep) return "";

    cep = cep.replace(/\D/g, "");

    cep = cep.replace(/(\d{5})(\d{3})/, "$1-$2");

    return cep;
  }

  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return "";

    phoneNumber = phoneNumber.replace(/\D/g, "");

    phoneNumber = phoneNumber.replace(
      /(\d{2})(\d)(\d{4})(\d{4})/,
      "($1) $2 $3-$4"
    );

    return phoneNumber;
  }

  function formatLocalPhoneNumber(phoneNumber) {
    let rawNumber = phoneNumber?.replace(/\D/g, "");

    if (rawNumber < 10) return rawNumber;

    const parsedNumber = rawNumber?.replace(
      /(\d{2})(\d{4})(\d{4})/,
      "($1) $2-$3"
    );

    return parsedNumber;
  }

  useEffect(() => {
    setLoadingAddress(true);
    if (cep?.length === 8 && checkCEP) {
      handleSearchAddress();
    } else {
      setLoadingAddress(false);
    }
  }, [cep, checkCEP]);

  const isDisabled = useMemo(() => {
    return disableButtonEndereco || disableCepGenerico || !cep;
  }, [disableButtonEndereco, disableCepGenerico, cep]);

  const SearchButton = () => (
    <IconButton onClick={handleSearchAddress}>
      <MdSearch className={classes.icon} />
    </IconButton>
  );

  useEffect(() => {
    setShowPcd(pcdValue === "Outro" && pcd);
  }, [pcdValue, pcd]);

  return (
    <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* NOME E CPF */}
          <div
            className={classes.inputForm}
            style={{ display: matchesMobile ? "block" : "flex" }}
          >
            <div
              className={classes.insideForm}
              style={{ marginTop: matchesMobile && "24px" }}
            >
              <FormControl required variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment">
                  Nome completo
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment"
                  type="text"
                  label="Nome completo"
                  autoFocus
                  value={userData?.nome || "Não informado"}
                  disabled
                  labelWidth={70}
                />
              </FormControl>
            </div>

            <div
              className={classes.insideForm}
              style={{ marginTop: matchesMobile && "24px" }}
            >
              <FormControl required variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-cpf">CPF</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-cpf"
                  type="text"
                  label="CPF"
                  autoFocus
                  value={cpfMask(userData?.cpf) || "Não informado"}
                  disabled
                  labelWidth={70}
                />
              </FormControl>
            </div>
          </div>

          <div
            className={classes.inputForm}
            style={{ display: matchesMobile ? "block" : "flex" }}
          >
            <div
              className={classes.insideForm}
              style={{ marginTop: matchesMobile && "24px" }}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-nome-social">
                  Nome social
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-nome-social"
                  type="text"
                  label="Nome social"
                  className={classes.colorInputText}
                  value={nomeSocial}
                  onChange={(e) => {
                    setNomeSocial(e.target.value);
                  }}
                  labelWidth={70}
                />
              </FormControl>
            </div>

            <div
              className={classes.insideForm}
              style={{ marginTop: matchesMobile && "24px" }}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-apelido">
                  Apelido
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-apelido"
                  type="text"
                  label="Apelido"
                  className={classes.colorInputText}
                  value={apelido}
                  onChange={(e) => {
                    setApelido(e.target.value);
                  }}
                  labelWidth={70}
                />
              </FormControl>
            </div>
          </div>
          {/* DATA NASCIMENTO ; GENERO ; RACA/COR ; ESTADO CIVIL */}
          <div
            id="input-date"
            className={classes.inputForm}
            style={{ display: matchesMobile ? "block" : "flex" }}
          >
            <div
              className={classes.insideForm}
              style={{ marginTop: matchesMobile && "24px" }}
            >
              <FormControl
                classes={{ root: classes.root }}
                variant="outlined"
                className={classes.formControl}
                required
              >
                <StylesProvider generateClassName={generateClassName}>
                  <ThemeProvider theme={muiTheme}>
                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        error={isSave && birthDate === null}
                        keyboardIcon={<MdEvent className={classes.icon} />}
                        InputProps={{
                          className: classes.menuItem,
                          classes: { adornedEnd: classes.adornedEnd },
                        }}
                        autoOk
                        required
                        maxDate={new Date()}
                        variant="inline"
                        inputVariant="outlined"
                        label="Data de nascimento"
                        minDateMessage="A data não pode ser superior ao mês atual"
                        invalidDateMessage="A data informada é inválida"
                        format="dd/MM/yyyy"
                        value={birthDate ? birthDate : null}
                        InputAdornmentProps={{
                          position: "end",
                          color: "#000",
                        }}
                        onChange={(date) => {
                          if (date && isValid(date)) {
                            setBirthDate(date);
                          }
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </StylesProvider>
              </FormControl>
            </div>
            <div
              className={classes.insideForm}
              style={{ marginTop: matchesMobile && "24px" }}
            >
              <div id="input-raca">
                <FormControl
                  classes={{ root: classes.root }}
                  variant="outlined"
                  className={classes.formControl}
                  required
                  error={isSave && !raca}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Raça/Cor
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(event): void => {
                      setRaca(event.target.value as string);
                    }}
                    value={raca || ""}
                    label="Raça/Cor"
                    classes={{
                      select: classes.menuItem,
                    }}
                  >
                    {listaRaca.map((unity) => (
                      <MenuItem
                        key={unity.id}
                        className={classes.menuItem}
                        value={unity.option}
                      >
                        {unity.option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div
              className={classes.insideForm}
              style={{ marginTop: matchesMobile && "24px" }}
            >
              <div id="input-estado-civil">
                <FormControl
                  classes={{ root: classes.root }}
                  variant="outlined"
                  className={classes.formControl}
                  required
                  error={isSave && !estadoCivil}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Estado civil
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(event): void => {
                      setEstadoCivil(event.target.value as string);
                    }}
                    value={estadoCivil}
                    label="Estado civil"
                    classes={{
                      select: classes.menuItem,
                    }}
                  >
                    {listaEstadoCivil.map((unity) => (
                      <MenuItem
                        key={unity.id}
                        className={classes.menuItem}
                        value={unity.option}
                      >
                        {unity.option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          {/* Orientação Sexual, Identidade de Genero, PCDValue */}
          <div
            className={classes.inputForm}
            style={{ display: matchesMobile ? "block" : "flex" }}
          >
            <div className={classes.insideForm}>
              <GenderIdentityInput
                value={orientacaoSexual}
                label="Orientação sexual"
                defaultValue={orientacaoSexual}
                items={listaOrientacaoSexual}
                onChange={setOrientacaoSexual}
                showError={isSave && !orientacaoSexual}
              />
            </div>
            <div className={classes.insideForm}>
              <GenderIdentityInput
                showHelperText={true}
                value={identidadeDeGenero}
                defaultValue={identidadeDeGenero}
                label="Identidade de gênero"
                items={listaIdentidadeDeGenero}
                onChange={setIdentidadeDeGenero}
                onClickHelperText={() => setOpenModalMoreInfo(true)}
                showError={isSave && !identidadeDeGenero}
              />
            </div>
            <GenderIdentityListModal
              openModalMoreInfo={openModalMoreInfo}
              setOpenModalMoreInfo={setOpenModalMoreInfo}
            />
            <div className={classes.insideForm}>
              <GenderIdentityInput
                defaultValue={pcdValue}
                label="Pessoa com deficiência"
                items={listPcd}
                value={pcdValue}
                onChange={(value) => {
                  setPcdValue(value);
                  setOtherPcd("");
                }}
              />
            </div>
            {showPcd && (
              <FormControl variant="outlined" fullWidth color="primary">
                <TextField
                  id="outlined-pcd"
                  type="text"
                  label="Outro"
                  placeholder="Descreva"
                  className={classes.colorInputText}
                  autoFocus
                  value={otherPcd}
                  onChange={(e) => setOtherPcd(e.target.value)}
                  required
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      color: isSave && !otherPcd ? "red" : "#968f8f",
                      fontWeight: 500,
                      fontFamily: "Roboto",
                    },
                    classes: {
                      root: "#1D2939",
                      focused: "#1D2939",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: theme.palette.primary.main,
                    },
                  }}
                  error={isSave && !otherPcd}
                  helperText={isSave && !otherPcd ? "campo obrigatório" : ""}
                />
              </FormControl>
            )}
          </div>

          <div
            className={classes.inputForm}
            style={{ display: matchesMobile ? "block" : "flex" }}
          >
            <div className={classes.insideForm}>
              <div id="input-orientacao-sexual">
                <FormControl
                  classes={{ root: classes.root }}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Escolaridade
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(event): void => {
                      setEscolaridade(event.target.value as string);
                    }}
                    value={escolaridade}
                    label="Escolaridade"
                    classes={{
                      select: classes.menuItem,
                    }}
                  >
                    {listaEscolaridade.map((unity) => (
                      <MenuItem
                        key={unity.id}
                        className={classes.menuItem}
                        value={unity.option}
                      >
                        {unity.option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div
              className={classes.insideForm}
              style={{ marginTop: matchesMobile && "24px" }}
            >
              <FormControl required variant="outlined" fullWidth>
                <TextField
                  id="outlined"
                  type="text"
                  label="Nome da mãe"
                  className={classes.colorInputText}
                  autoFocus
                  value={nomeMae || ""}
                  onChange={(e) => {
                    setNomeMae(e.target.value);
                  }}
                  required
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      color: isSave && !nomeMae ? "red" : "#968f8f",
                      fontWeight: 500,
                      fontFamily: "Roboto",
                    },
                    classes: {
                      root: "#1D2939",
                      focused: "#1D2939",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: theme.palette.primary.main,
                    },
                  }}
                  error={isSave && !nomeMae}
                  helperText={isSave && !nomeMae ? "campo obrigatório" : ""}
                />
              </FormControl>
            </div>
            <div
              className={classes.insideForm}
              style={{ marginTop: matchesMobile && "24px" }}
            >
              <FormControl required variant="outlined" fullWidth>
                <TextField
                  id="outlined"
                  type="text"
                  label="Nome do pai"
                  className={classes.colorInputText}
                  autoFocus
                  value={nomePai || ""}
                  onChange={(e) => {
                    setNomePai(e.target.value);
                  }}
                  required
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      color: isSave && !nomePai ? "red" : "#968f8f",
                      fontWeight: 500,
                      fontFamily: "Roboto",
                    },
                    classes: {
                      root: "#1D2939",
                      focused: "#1D2939",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: theme.palette.primary.main,
                    },
                  }}
                  error={isSave && !nomePai}
                  helperText={isSave && !nomePai ? "campo obrigatório" : ""}
                />
              </FormControl>
            </div>
          </div>

          {/* NATURALIDADE E NACIONALIDADE */}
          <div
            className={classes.inputForm}
            style={{ display: matchesMobile ? "block" : "flex" }}
          >
            <div
              className={classes.insideForm}
              style={{ marginTop: matchesMobile && "24px" }}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment">
                  Naturalidade
                </InputLabel>
                <OutlinedInput
                  id="outlined"
                  type="text"
                  label="Naturalidade"
                  autoFocus
                  className={classes.colorInputText}
                  value={naturalidade}
                  onChange={(e) => {
                    setNaturalidade(e.target.value);
                  }}
                  labelWidth={70}
                />
              </FormControl>
            </div>
            <div
              className={classes.insideForm}
              style={{ marginTop: matchesMobile && "24px" }}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment">
                  Nacionalidade
                </InputLabel>
                <OutlinedInput
                  id="outlined"
                  type="text"
                  label="Nacionalidade"
                  autoFocus
                  className={classes.colorInputText}
                  value={nacionalidade}
                  onChange={(e) => {
                    setNacionalidade(e.target.value);
                  }}
                  labelWidth={70}
                />
              </FormControl>
            </div>
          </div>
        </Grid>
        <Grid
          style={{
            borderTop: `1px dashed #DAE1EF`,
            borderBottom: `1px dashed #DAE1EF`,
          }}
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <div
              className={classes.inputForm}
              style={{ display: matchesMobile ? "block" : "flex" }}
            >
              <div
                className={classes.insideForm}
                style={{ marginTop: matchesMobile && "24px" }}
              >
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-email">
                    Email
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email"
                    type="text"
                    label="Email"
                    autoFocus
                    value={userData?.contato?.email || "Não informado"}
                    disabled
                    labelWidth={70}
                  />
                </FormControl>
              </div>
              <div
                className={classes.insideForm}
                style={{ marginTop: matchesMobile && "24px" }}
              >
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-contact-cell">
                    Nº celular 1
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-adornment-contact-cell"
                    type="text"
                    label="Contato de Celular"
                    disabled
                    value={
                      formatPhoneNumber(userData?.contato?.celular) ||
                      "Não informado"
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </div>
              <div
                className={classes.insideForm}
                style={{ marginTop: matchesMobile && "24px" }}
              >
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-contact-cell">
                    Telefone
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-adornment-contact-cell"
                    type="text"
                    label="Telefone"
                    placeholder="(99) 9999-9999"
                    className={classes.colorInputText}
                    value={
                      formatLocalPhoneNumber(userData?.contato?.telefone) ||
                      formatLocalPhoneNumber(telefone)
                    }
                    inputProps={{ maxLength: 10 }}
                    labelWidth={70}
                    onChange={(e) => {
                      setTelefone(e.target.value);
                    }}
                  />
                  <Typography variant="subtitle2">
                    O campo telefone deve ter 10 caracteres
                  </Typography>
                </FormControl>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {loadingAddress ? (
            <>
              <div className={classes.containerLoadingAddress}>
                <CircularProgress />
              </div>{" "}
            </>
          ) : (
            <>
              <Grid item xs={12}>
                {/*CEP, MUNICÍPIO E UF */}
                <div
                  className={classes.inputForm}
                  style={{ display: matchesMobile ? "block" : "flex" }}
                >
                  <div
                    className={classes.insideForm}
                    style={{ marginTop: matchesMobile && "24px" }}
                  >
                    <FormControl variant="outlined" required fullWidth>
                      <TextField
                        id="outlined-full-width"
                        label="CEP"
                        required
                        fullWidth
                        value={formatCEP(cep) || ""}
                        onBlur={(event) => {
                          handleSearchAddress();
                        }}
                        onChange={(event) => {
                          setCep(event.target.value);
                          setErrorCep(null);
                          resetAddress();
                        }}
                        variant="outlined"
                        InputLabelProps={{
                          style: {
                            color:
                              errorCep || (isSave && !cep) ? "red" : "#968f8f",
                            fontWeight: 500,
                            fontFamily: "Roboto",
                          },
                          classes: {
                            root: "#1D2939",
                            focused: "#1D2939",
                          },
                        }}
                        InputProps={{
                          endAdornment: <SearchButton />,
                          style: {
                            color: theme.palette.primary.main,
                          },
                          classes: {
                            root: "#1D2939",
                            focused: "#1D2939",
                          },
                        }}
                        error={errorCep !== null || (isSave && !cep)}
                        helperText={
                          errorCep
                            ? errorCep
                            : isSave && !cep
                            ? "campo obrigatório"
                            : ""
                        }
                        inputProps={{ maxLength: 8 }}
                      />
                    </FormControl>
                  </div>
                  <div
                    className={classes.insideForm}
                    style={{ marginTop: matchesMobile && "24px" }}
                  >
                    <FormControl variant="outlined" required fullWidth>
                      <TextField
                        id="outlined"
                        type="text"
                        label="Município"
                        autoFocus
                        required
                        className={classes.colorInputText}
                        value={municipio}
                        onChange={(e) => {
                          setMunicipio(e.target.value);
                        }}
                        disabled={
                          disableButtonEndereco || disableCepGenerico || !cep
                        }
                        variant="outlined"
                        InputLabelProps={{
                          style: {
                            color: isSave && !municipio ? "red" : "#968f8f",
                            fontWeight: 500,
                            fontFamily: "Roboto",
                          },
                          classes: {
                            root: "#1D2939",
                            focused: "#1D2939",
                          },
                        }}
                        InputProps={{
                          style: {
                            color: isDisabled
                              ? "#968f8f"
                              : theme.palette.primary.main,
                          },
                        }}
                        error={isSave && !municipio}
                        helperText={
                          isSave && !municipio ? "campo obrigatório" : ""
                        }
                      />
                    </FormControl>
                  </div>
                  <div
                    className={classes.insideForm}
                    style={{ marginTop: matchesMobile && "24px" }}
                  >
                    <FormControl variant="outlined" required fullWidth>
                      <TextField
                        id="outlined"
                        type="text"
                        label="UF"
                        autoFocus
                        className={classes.colorInputText}
                        value={uf}
                        disabled={
                          disableButtonEndereco || disableCepGenerico || !cep
                        }
                        required
                        onChange={(e) => {
                          setUf(e.target.value);
                        }}
                        //labelWidth={70}
                        variant="outlined"
                        InputLabelProps={{
                          style: {
                            color: isSave && !uf ? "red" : "#968f8f",
                            fontWeight: 500,
                            fontFamily: "Roboto",
                          },
                          classes: {
                            root: "#1D2939",
                            focused: "#1D2939",
                          },
                        }}
                        InputProps={{
                          style: {
                            color: isDisabled
                              ? "#968f8f"
                              : theme.palette.primary.main,
                          },
                        }}
                        error={isSave && !uf}
                        helperText={isSave && !uf ? "campo obrigatório" : ""}
                      />
                    </FormControl>
                  </div>
                </div>
                {/* NUMERO E LOGRADOURO */}
                <div
                  className={classes.inputForm}
                  style={{ display: matchesMobile ? "block" : "flex" }}
                >
                  <div
                    className={classes.insideForm}
                    style={{ marginTop: matchesMobile && "24px" }}
                  >
                    <FormControl variant="outlined" required fullWidth>
                      <TextField
                        id="outlined-adornment"
                        type="text"
                        label="Logradouro"
                        autoFocus
                        value={endereco}
                        className={classes.colorInputText}
                        onChange={(e) => {
                          setEndereco(e.target.value);
                        }}
                        required
                        variant="outlined"
                        disabled={!cep}
                        InputLabelProps={{
                          style: {
                            color: isSave && !endereco ? "red" : "#968f8f",
                            fontWeight: 500,
                            fontFamily: "Roboto",
                          },
                          classes: {
                            root: "#1D2939",
                            focused: "#1D2939",
                          },
                        }}
                        InputProps={{
                          style: {
                            color: !cep
                              ? "#968f8f"
                              : theme.palette.primary.main,
                          },
                        }}
                        error={isSave && !endereco}
                        helperText={
                          isSave && !endereco ? "campo obrigatório" : ""
                        }
                      />
                    </FormControl>
                  </div>

                  <div
                    className={classes.insideForm}
                    style={{
                      marginTop: matchesMobile && "24px",
                      width: matchesMobile ? "auto" : "160px",
                    }}
                  >
                    <FormControl variant="outlined" required fullWidth>
                      <TextField
                        id="outlined-adornment"
                        required
                        type="text"
                        label="Número"
                        autoFocus
                        value={numeroCasa}
                        className={classes.colorInputText}
                        onChange={(e) => {
                          setNumeroCasa(e.target.value);
                        }}
                        variant="outlined"
                        disabled={!cep}
                        InputLabelProps={{
                          style: {
                            color: isSave && !numeroCasa ? "red" : "#968f8f",
                            fontWeight: 500,
                            fontFamily: "Roboto",
                          },
                          classes: {
                            root: "#1D2939",
                            focused: "#1D2939",
                          },
                        }}
                        InputProps={{
                          style: {
                            color: !cep
                              ? "#968f8f"
                              : theme.palette.primary.main,
                          },
                        }}
                        error={isSave && !numeroCasa}
                        helperText={
                          isSave && !numeroCasa ? "campo obrigatório" : ""
                        }
                        //labelWidth={70}
                      />
                    </FormControl>
                  </div>
                </div>
                {/* BAIRRO E COMPLEMENTO */}
                <div
                  className={classes.inputForm}
                  style={{ display: matchesMobile ? "block" : "flex" }}
                >
                  <div
                    className={classes.insideForm}
                    style={{ marginTop: matchesMobile && "24px" }}
                  >
                    <FormControl variant="outlined" required fullWidth>
                      {/*<InputLabel htmlFor="outlined-adornment">Bairro</InputLabel>*/}
                      <TextField
                        id="outlined"
                        type="text"
                        label="Bairro"
                        required
                        autoFocus
                        className={classes.colorInputText}
                        value={bairro}
                        disabled={disableButtonEndereco || !cep}
                        onChange={(e) => {
                          setBairro(e.target.value);
                        }}
                        variant="outlined"
                        InputLabelProps={{
                          style: {
                            color: isSave && !bairro ? "red" : "#968f8f",
                            fontWeight: 500,
                            fontFamily: "Roboto",
                          },
                          classes: {
                            root: "#1D2939",
                            focused: "#1D2939",
                          },
                        }}
                        InputProps={{
                          style: {
                            color:
                              disableButtonEndereco || !cep
                                ? "#968f8f"
                                : theme.palette.primary.main,
                          },
                        }}
                        error={isSave && !bairro}
                        helperText={
                          isSave && !bairro ? "campo obrigatório" : ""
                        }
                      />
                    </FormControl>
                  </div>

                  <div
                    className={classes.insideForm}
                    style={{ marginTop: matchesMobile && "24px" }}
                  >
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment">
                        Complemento
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment"
                        type="text"
                        label="Complemento"
                        disabled={!cep}
                        className={classes.colorInputText}
                        value={complemento}
                        onChange={(e) => {
                          setComplemento(e.target.value);
                        }}
                        labelWidth={70}
                      />
                    </FormControl>
                  </div>
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </AccordionDetails>
  );
};

export default AccordionDadosPessoais;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#d3d3d3",
      },
    },
    "& .MuiSelect-iconOutlined": {
      height: 24,
      width: 24,
    },
  },
  avatar: {
    height: 64,
    width: 64,
    backgroundColor: "#E1EDF6",
  },
  boxAvatar: {
    marginTop: 20,
    marginLeft: 16,
    border: `2px solid ${theme.palette.primary.main}`,
    width: 80,
    height: 80,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    minWidth: 161,
    height: 44,
    fontSize: "100%",
    borderRadius: 4,
    padding: "12px 16px",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  input: {
    display: "none",
  },
  inputPCD: {
    color: theme.palette.primary.main,
  },
  userName: {
    fontSize: 16,
    color: "#373F41",
    fontWeight: "normal",
    paddingTop: 16,
    fontFamily: "Roboto",
  },
  colorInputText: {
    color: theme.palette.primary.main,
  },
  inputForm: {
    margin: "24px 0px",
    color: "#DDD",
    width: "100%",
    display: "flex",
  },
  labelButton: {
    fontSize: 14,
    fontFamily: "Roboto",
  },
  divInstrucoes: {
    color: "#706E7A",
    marginLeft: 16,
    marginTop: 8,
  },
  insideForm: {
    marginRight: "6px",
    marginLeft: "6px",
    width: "100%",
  },
  icon: {
    width: 24,
    height: 24,
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  formControl: {
    width: "100%",
  },
  container: {
    background: "#FFFFFF",
    border: "1px solid #DAE1EF",
    borderRadius: 4,
    boxSizing: "border-box",
    boxShadow: "none",
  },
  accordionTitle: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: "Roboto",
    color: "#333333",
  },
  containerLoadingAddress: {
    display: "flex",
    justifyContent: "center",
    alignItens: "center",
    width: "100%",
    padding: "10px",
    margin: "50px",
  },
}));
