import { format, isValid, parse } from 'date-fns';

export const dateFormat = (date: string, withHour = false): string => {
  if (withHour) {
    const dateHourPart = date.split('T');
    const datePart = dateHourPart[0].split('-');
    const hourPart = dateHourPart[1].split('.');
    return `${datePart[2]}/${datePart[1]}/${datePart[0]} ${hourPart[0]}`;
  }
  const datePart = date.split('-');
  return `${datePart[2]}/${datePart[1]}/${datePart[0]}`;
};

export const extractDateFormat = (date: string): string => {
  const dateHourPart = date.split('T');
  const datePart = dateHourPart[0].split('-');
  return `${datePart[2]}/${datePart[1]}/${datePart[0]}`;
};
export const extractHourFormat = (date: string): string => {
  const dateHourPart = date.split('T');
  const hourPart = dateHourPart[1].split('.');
  return `${hourPart[0]}`;
};

export const extractStringUTCDateFormat = (date: string) => {
  const parts: any = date.split('-');
  const newDate = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]));

  return newDate;
};

export const entryDateReturnFormatted = (dataStr: string): Date => {
  const formatos = [
    'dd/MM/yyyy HH:mm:ss', // Formato "26/05/1968 00:00:00"
    'yyyy-MM-dd', // Formato "1968-05-26"
    'dd/MM/yyyy',
  ];
  for (const formato of formatos) {
    const data = parse(dataStr, formato, new Date());
    if (isValid(data)) {
      return new Date(`${format(data, 'yyyy-MM-dd')}T00:00:00.000-03:00`);
    }
  }

  throw new Error(`Formato de data não suportado: ${dataStr}`);
};

export const outPutDateReturnFormatted = (data: Date): string => {
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // getMonth() retorna o mês de 0 a 11
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
};

export default dateFormat;
