import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogContentText,
  Typography,
  Box,
  IconButton,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { HistoryI } from '../../../../interfaces/agendamentos';
import { MdClose } from 'react-icons/md';
import { BiCalendarEvent } from 'react-icons/bi';
import { format, isValid } from 'date-fns';
import { FieldDate, FieldLocal, FieldTime } from './components';
import { checarHorarios } from '../../../../services/agendamento';
import { SearchI } from '../../../../interfaces/servico';
import { getService, } from '../../../../services/servico';
import ToastMessage from '../../../../Components/ToastMessage';
import { useToastValue } from '../../../../contexts/toastMessage';
import { ToastMessageProvider } from '../../../../contexts/toastMessage';
import { sendReschedule } from '../../../../services/agendamento';
import { UserData } from '../../../../interfaces/user';
import getCookie from '../../../../utils/getCookies';

export interface Schedule {
  horarios: string[];
}

export interface SavedScheduleProps {
  cpf: string;
  data: string;
  data_solicitacao: string;
  guiche: number;
  hora: string;
  hora_inicio: string;
  id: number;
  para_dependente: boolean;
  prioridade: boolean;
  servico: number;
  setor: string;
  status: string;
  ticket: string;
  tipo: string;
  unidade: string;
  updated_at: string;
  user: number;
}

export interface SimpleDialogProps {
  open: boolean;
  schedule: HistoryI;
  handleClose: () => void
  handleCompletedScheduled: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    fontFamily: 'Roboto',
    fontWeight: 600,
    color: '#1F2937',
    padding: '24px 24px 8px 24px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      '& > h2': {
        fontSize: theme.typography.pxToRem(16)
      }
    }
  },
  contentText: {
    fontFamily: 'Roboto',
    padding: 0,
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      '& > p': {
        fontSize: theme.typography.pxToRem(12)
      }

    }
  },
  dialogActions: {
    padding: '8px 24px 24px 24px',
    gap: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  cancelBtn: {
    color: theme.palette.primary.main,
    padding: '12px 16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }

  },
  confirmBtn: {
    padding: '12px 16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  boxHeader: {
    backgroundColor: theme.palette.primary.main,
  },

  boxIconAndTitle: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center'
  },

  title: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '22.4px',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: '#ffff'
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.0),
    top: theme.spacing(0.0),
    color: '#FFFFFF',
  },
  boxDocuments: {
    padding: '1.5rem 2rem',
    marginBottom: '1.5rem',
    borderBottom: '1px solid #DAE1EF'
  },
  documentsTitle: {
    fontFamily: 'Roboto',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    textTransform: 'uppercase',
    marginBottom: '0.75rem',
  },
  documentsService: {
    fontFamily: 'Roboto',
    color: '#1F2937',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
  },
  documentsOrgan: {
    fontFamily: 'Roboto',
    color: '#1F2937A3',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '0 2rem 2rem 2rem',
  },
  formTitle: {
    textTransform: 'uppercase',
    color: '#1F2937',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: '0.5rem'
  },
  textField: {
    maxWidth: '33.5rem',
  },

  input: {
    color: theme.palette.primary.main
  }

}));

export default function RescheduleDialog(
  {
    open,
    schedule,
    handleClose,
    handleCompletedScheduled,
  }: SimpleDialogProps) {
  const { toast, setToast } = useToastValue()
  const [openAttendance, setOpenAttendance] = useState(false)
  const [service, setService] = useState<SearchI>();
  const [scheduleService, setScheduleService] = useState<
    string | Schedule | undefined
  >();
  const [scheduleServiceUser, setScheduleServiceUser] = useState<
    SavedScheduleProps | string | undefined
  >();
  const [selectedTime, setSelectedTime] = useState('');

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [unitySelected, setUnitySelected] = useState('');
  const [loading, setLoading] = useState(false)

  const classes = useStyles();

  const handleGetUnitySelected = (value: string) => {
    setUnitySelected(value)
  }

  const handleGetSeletedDate = (date: Date) => {
    setSelectedDate(date)
  }

  const handleGetSeletedTime = (time: string) => {
    setSelectedTime(time)
  }

  const getServiceEspecific = async () => {
    setService(undefined);

    try {
      const { data } = await getService(schedule.servico.slug);
      setService(data.results[0]);
    } catch (err) {
    }
  };

  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);

    if (isValid(date)) {
      try {
        const { data } = await checarHorarios(date, unity, service.id, 'Presencial');
        setScheduleService(data);
      } catch (err) {
        if (err.response) {
          setScheduleService(err.response.data.message);
        } else {
          setScheduleService('Algo inesperado ocorreu');
        }
      }
    } else {
      setScheduleService(undefined);
    }
  };

  const handleSendReschudle = async () => {
    setLoading(true)

    const token: string | null = getCookie(
      "gov_access_token_sso"
    );

    let dependentes = undefined
    if (schedule.dependentes) {

      const dataStorage: string | null = getCookie("gov_user_data");
      const userData: UserData | null = dataStorage
        ? JSON.parse(dataStorage)
        : null;

      const { cpf, nome, data_nascimento, grau_parentesco } = schedule.dependentes
      dependentes = {
        cpf,
        nome,
        data_nascimento: format(new Date(data_nascimento), 'yyyy-MM-dd'),
        cpf_solicitante: userData.cpf,
        grau_parentesco,
      }
    }
    const selectedDateFormated = format(new Date(selectedDate), 'yyyy-MM-dd')

    try {
      const { status } = await sendReschedule(
        token,
        String(schedule.id),
        schedule.servico.id,
        unitySelected,
        selectedDateFormated,
        selectedTime,
        "Presencial",
        !!schedule.dependentes,
        dependentes
      );

      if (status === 201) {
        setLoading(false)

        handleClose()
        setToast(
          {
            message: 'Concluído',
            description: 'Reagendamento realizado com sucesso!',
            type: 'success',
            visible: true
          })
        handleCompletedScheduled()
      }

    } catch (err) {
      setLoading(false)

      if (err?.response?.data?.message) {
        setScheduleServiceUser(err.response.data.message);
      } else if (err?.response?.data?.length > 0) {
        console.log(err.response.data);
        setScheduleServiceUser(err.response.data[0]);
      } else {
        setScheduleServiceUser('Algo inesperado ocorreu.');
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getServiceEspecific();
    setSelectedTime('');
    setUnitySelected(schedule.unidade.slug_unidade)
  }, []);

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth>
        <DialogTitle className={classes.dialogTitle}>Reagendar atendimento</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={classes.contentText}>
            Você tem certeza que deseja reagendar este atendimento?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant='text'
            onClick={handleClose}
            className={classes.cancelBtn}>
            não
          </Button>
          <Button variant='contained'
            color='primary'
            onClick={() => setOpenAttendance(true)}
            className={classes.confirmBtn}>
            sim, desejo reagendar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAttendance}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth>
        <DialogTitle
          id="alert-dialog-title"
          className={classes.boxHeader}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <MdClose style={{ width: '1.5rem', height: '1.5rem' }} />
          </IconButton>
          <Box component='header' className={classes.boxIconAndTitle} >
            <BiCalendarEvent style={{ width: '1.5rem', height: '1.5rem' }} />
            <Typography className={classes.title}>
              Reagendar atendimento
            </Typography>
          </Box>
        </DialogTitle>
        <Box className={classes.boxDocuments}>
          <Typography
            component='h2'
            className={classes.documentsTitle}>
            documentos
          </Typography>
          <Typography
            component='h4'
            className={classes.documentsService}>
            {schedule.servico.titulo}
          </Typography>
          <Typography
            component='p' className={classes.documentsOrgan}>
            {schedule.orgao_responsavel}
          </Typography>
        </Box>
        <Box className={classes.form}>
          <Typography
            component='h2'
            className={classes.formTitle}>
            Faça seu reagendamento
          </Typography>
          <FieldLocal
            defaultLocal={schedule.unidade}
            service={service}
            getSchedulers={getSchedulers}
            selectedDate={selectedDate}
            handleGetUnitySelected={handleGetUnitySelected}
          />
          <FieldDate
            scheduleService={scheduleService}
            scheduleServiceUser={scheduleServiceUser}
            handleGetSeletedDate={handleGetSeletedDate}
            getSchedulers={getSchedulers}
            unitySelected={unitySelected}
            selectedDate={selectedDate} />
          <FieldTime
            scheduleService={scheduleService}
            scheduleServiceUser={scheduleServiceUser}
            handleGetSeletedTime={handleGetSeletedTime}
            unitySelected={unitySelected}
            selectedDate={selectedDate} />
          <DialogActions
            className={classes.dialogActions}
            style={{ marginTop: '0.5rem', padding: 0 }}>
            <Button variant='text'
              className={classes.cancelBtn}
              onClick={handleClose}  >
              Desfazer reagendamento
            </Button>
            <Button
              type='button'
              variant='contained'
              color='primary'
              disabled={!selectedDate || !selectedTime}
              onClick={handleSendReschudle}
              className={classes.confirmBtn}>
              {loading ? <CircularProgress color='inherit' />
                : 'Concluir e reagendar'
              }
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <ToastMessageProvider>
        <ToastMessage />
      </ToastMessageProvider>
    </>
  );
}
