import { makeStyles, Theme } from "@material-ui/core/styles";
import { createStyles, withStyles } from "@material-ui/styles";
import { TableCell } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 10px",
    },
    boxIndicatorsBack: {
        width: "100%",
        margin: "20px 0px",
    },
    indicatorsBack: {
        width: "100%",
        color: "#373F41",
        maxWidth: "1250px",
        alignItems: "center",
        display: "flex",
    },
    textCard: {
        fontFamily: "Roboto",
        color: "#373F41",
        marginTop: 16,
        fontSize: 16,
        letterSpacing: 0.3,
        textTransform: "uppercase",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
            paddingTop: 0,
            textAlign: "center",
        },
    },
    button: {
        height: 44,
    },
    status: {
        fontSize: 14,
        fontFamily: "Roboto",
        fontWeight: 500,
        textTransform: "capitalize",
        padding: "5px 16px",
        borderRadius: 25,
        [theme.breakpoints.down("sm")]: {
            width: "fit-content",
            marginBottom: 24,
            alignSelf: "center",
        },
    },
    titleInfo: {
        fontFamily: "Roboto",
        fontSize: 20,
        fontWeight: 500,
        margin: "24px 0px",
    },
    textTable: {
        color: "#333333",
        textAlign: "center",
    },
    attachText: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 400,
        textDecoration: "none",
        color: "#0F6FB7",
    },
    indicator: {
        width: 0,
        height: 0,
    },
    selected: {
        background: theme.palette.primary.main,
        color: theme.palette.text.primary,
    },
    card: {
        marginTop: 20,
    },
    descriptionText: {
        fontSize: theme.typography.pxToRem(14),
        fontFamily: 'Roboto',
        fontWeight: 500,
    },
    value: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        color: '#1F2937',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    containerEvaluation: {
        display: 'flex',
        gap: 10,
    },
    boxStar: {
        display: 'flex',
        gap: 10,
        marginLeft: 24,
        marginRight: 24,
        marginBottom: 24,
        alignItems: 'center',
    },
    starIcon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(20)
    },
    starCountText: {
        fontFamily: 'Roboto',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 400,
        textAlign: 'left',
        color: '#373F41',
    },
    iconButton: {
        fontSize: theme.typography.pxToRem(25),
        color: '#fff',
        marginRight: '5px'
    },
    buttonAction: {
        position: 'absolute',
        marginTop: '-70px',
        padding: '5px 15px'
    },
    fixedButtonBox: {
        width: '100%',
        justifyContent: 'end',
        display: 'flex',
    },
    titleFixedButton: {
        fontSize: theme.typography.pxToRem(15),
        fontFamily: 'Roboto',
        fontWeight: 400,
        textTransform: 'uppercase'
    }
}));

export const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "rgba(15, 111, 183, 0.05)",
            color: theme.palette.primary.main,
            textTransform: "uppercase",
            fontWeight: 500,
            fontFamily: "Roboto",
        },
        body: {
            fontSize: 14,
            color: "#333333",
        },
    })
)(TableCell);