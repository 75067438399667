import {makeStyles, Theme} from '@material-ui/core/styles';
import { 
   FormControl,
   FormHelperText,
   InputLabel,
   MenuItem,
   Select 
  } from '@material-ui/core';
import { SavedAttendenceProps, Attendence } from '../..';

interface FieldTimeProps {
  attendenceService: string | Attendence | undefined
  attendenceServiceUser: SavedAttendenceProps | string | undefined
  handleGetSeletedTime: (time: string) => void
  unitySelected: string
  selectedDate:Date | null
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#d3d3d3',
      },
    },
    '& .MuiSelect-iconOutlined': {
      height: 24,
      width: 24,
    },
  },
  formControl: {
    width: '100%',
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  input: {
    color: theme.palette.primary.main,
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
}));

export default function FieldTime({
  attendenceService,
  attendenceServiceUser,
  handleGetSeletedTime,
  selectedDate,
  unitySelected,
  }:FieldTimeProps): JSX.Element {
  const classes = useStyles();

  return (
    <FormControl
      classes={{ root: classes.root }}
      disabled={
        !unitySelected
        || !selectedDate
        || typeof attendenceService === 'string'
      }
      variant="outlined"
      className={classes.formControl}
      error={typeof attendenceServiceUser === 'string'}
    >
      <InputLabel id="demo-simple-select-filled-label">
        Escolha o horário
      </InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        classes={{
          select: classes.menuItem,
        }}
        onChange={(event): void => {
          handleGetSeletedTime(
            event.target.value as string,
          );
        }}
        label="Escolha o horário"
      >
        {typeof attendenceService !== 'string'
          && attendenceService?.horarios.map((schedule) => (
            <MenuItem
              key={schedule}
              className={classes.menuItem}
              value={schedule}
            >
              {schedule}
            </MenuItem>
          ))}
      </Select>
      {typeof attendenceServiceUser === 'string' && (
        <FormHelperText>
          {attendenceServiceUser}
        </FormHelperText>
      )}
  </FormControl>
  );
}