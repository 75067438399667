const NonBinaryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      color="#706E7A"
      className="icon icon-tabler icons-tabler-outline icon-tabler-gender-genderqueer"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 11a5 5 0 1 1 0 10a5 5 0 0 1 0 -10z" />
      <path d="M12 11v-8" />
      <path d="M14.5 4.5l-5 3" />
      <path d="M9.5 4.5l5 3" />
    </svg>
  );
};

export default NonBinaryIcon;
