import React, { useEffect } from 'react';
import {
  Grid,
  Box,
  MenuItem,
  Typography,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiSelect-iconOutlined": {
        height: 24,
        width: 24,
      },
    },
    boxFilter: {
      marginTop: 56,
      width: '100vw',
      maxWidth: '1232px',
      padding: '0px 10px',
      marginBottom: 10,
    },
    btnFilter: {
      width: '250px',
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'space-between',
    },
    itemMenu: {
      borderRadius: '0',
    },
    textMenu: {
      width: '216px',
      textAlign: 'center',
      color: '#373F41',
      fontSize: '16px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
  })
);

interface Props {
  setFilter?: any;
}

export default function FilterAttendence({ setFilter }: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [selected, setSelected] = React.useState<string>('');

  useEffect(() => {
    if (selected) {
      switch (selected) {
        case 'Todos':
          setFilter(null);
          break;
        case 'Aguardando':
          setFilter({
            status: 'Aguardando',
          });
          break;
        case 'Concluídos':
          setFilter({
            status: 'Concluído',
          });
          break;
        case 'Cancelados':
          setFilter({
            status: 'Cancelado',
          });
          break;
        case 'Compareceu':
          setFilter({
            status: 'Compareceu',
          });
          break;
        case 'Não Compareceu':
          setFilter({
            status: 'Não Compareceu',
          });
          break;
        default:
          setFilter(null);
          break;
      }
    }
  }, [selected, setFilter]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      <Grid item>
        <Box className={classes.boxFilter}>
          <FormControl variant='outlined'>
            <InputLabel id='select-label'>Filtrar agendamento</InputLabel>
            <Select
              labelId='select-label'
              id='select-outlined'
              className={classes.btnFilter}
              aria-describedby={id}
              label='Filtrar agendamento'
            >
              <MenuItem
                className={classes.itemMenu}
                onClick={() => {
                  setSelected('Todos');
                  setAnchorEl(null);
                }}
                value={10}
              >
                <Typography
                  className={classes.textMenu}
                  style={
                    selected === 'Todos' || selected === ''
                      ? { color: '#0F6FB7' }
                      : {}
                  }
                >
                  Todos
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelected('Aguardando');
                  setAnchorEl(null);
                }}
                value={20}
              >
                <Typography
                  className={classes.textMenu}
                  style={selected === 'Aguardando' ? { color: '#0F6FB7' } : {}}
                >
                  Aguardando
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelected('Concluídos');
                  setAnchorEl(null);
                }}
                value={30}
              >
                <Typography
                  className={classes.textMenu}
                  style={selected === 'Concluídos' ? { color: '#0F6FB7' } : {}}
                >
                  Concluídos
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelected('Cancelados');
                  setAnchorEl(null);
                }}
                value={40}
              >
                <Typography
                  className={classes.textMenu}
                  style={selected === 'Cancelados' ? { color: '#0F6FB7' } : {}}
                >
                  Cancelados
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelected('Compareceu');
                  setAnchorEl(null);
                }}
                value={50}
              >
                <Typography
                  className={classes.textMenu}
                  style={selected === 'Compareceu' ? { color: '#0F6FB7' } : {}}
                >
                  Compareceu
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelected('Não Compareceu');
                  setAnchorEl(null);
                }}
                value={60}
              >
                <Typography
                  className={classes.textMenu}
                  style={selected === 'Não Compareceu' ? { color: '#0F6FB7' } : {}}
                >
                  Não Compareceu
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
}
