import React, { useState } from 'react';
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { MdClose, MdVpnKey } from "react-icons/md";
import { AllConcessionsI } from "../../../../interfaces/concessoes";
import { removeConcession } from '../../../../services/concessoes';
import getCookie from '../../../../utils/getCookies';

interface Props {
  open: boolean;
  handleCloseAll: () => void;
  concession: AllConcessionsI;
  setUpdatedStatus: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  textHeaderModal: {
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    width: "90%",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 700,
  },
  header: {
    background: theme.palette.primary.main,
  },
  buttonClose: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: "#fff",
  },
  textModal: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0.25,
    padding: 30,
  },
  buttonCancelAndConfirm: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 16,
  },
  buttonCancel: {
    marginRight: 16,
    borderRadius: 4,
    color: "#373F41",
    border: 0,
  },
}));

export default function PermissionDialog({
  open,
  concession,
  handleCloseAll,
  setUpdatedStatus
}: Props): JSX.Element {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleRemove = async () => {
    try {
      setLoading(true);
      const token: string | null = getCookie("gov_access_token_sso");
      await removeConcession(token, concession.id);

      setLoading(false);
      setUpdatedStatus(true);
      handleCloseAll();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleCloseAll}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton disabled={loading} onClick={handleCloseAll} className={classes.buttonClose}>
        <MdClose />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          <MdVpnKey style={{ marginRight: "10px", width: 25, height: 25 }} />
          Remover Aplicação
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.textModal}>
          Tem certeza que deseja remover a aplicação {
            <strong>{concession.aplicacao}?</strong>
          }
        </Typography>
        <div className={classes.buttonCancelAndConfirm}>
          <Button
            variant="outlined"
            className={classes.buttonCancel}
            onClick={handleCloseAll}
            disabled={loading}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleRemove}
            disabled={loading}
          >
            CONFIRMAR
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
