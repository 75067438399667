import { AxiosResponse } from "axios";
import { apiforFlow, apiouvidoria } from "./api";
import { apiatendimento156 } from "./api";
import { evaluationType } from "../Components/EvaluationDialog";
import getCookie from "../utils/getCookies";
const token: string | null = getCookie("gov_access_token_sso");

type TypeCancel =
  | "Manifestação"
  | "Solicitação"
  | "Esic"
  | "Simplifique"
  | "Agendamento";

const getUrlEvaluation = {
  simplifique: "avaliacao_simplifique",
  esic: "avaliacao_esic",
  demanda: "avaliacao_demanda",
  manifestacao: "avaliacao_manifestacao",
  solicitacao_servico: "avaliacao_demanda",
  formflow: "flow/avaliar-solicitacao",
  presencial: "avaliacao_presencial",
  online: "avaliacao_online",
  espontaneo: "diario",
  complementar: "complementar",
};

export const allManifestation = (
  page: number,
  filtro?: string,
  search?: string
): Promise<AxiosResponse> => {
  if (filtro && search) {
    return apiouvidoria.get(
      `/manifestacao-auth/?${filtro}=${search}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  return apiouvidoria.get(`/manifestacao-auth/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const responseManifestationRestituicao = (
  id: string,
  manifestacao_id: number,
  resposta: string
): Promise<AxiosResponse> =>
  apiouvidoria.put(
    `/responder_restituicao_manifestacao/${id}/?manifestacao_id=${manifestacao_id}`,
    {
      id,
      resposta,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const responseDemandRestituicao = (
  id: string,
  demand_id: number,
  resposta: string
): Promise<AxiosResponse> =>
  apiouvidoria.put(
    `/responder_restituicao_demanda/${id}/?demanda_id=${demand_id}`,
    {
      id,
      resposta,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const anexosManifestationRestituicao = (
  titulo: string,
  arquivo: File,
  restituicao: string
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append("titulo", titulo);
  formData.append("arquivo", arquivo);
  formData.append("restituicao", restituicao);
  return apiouvidoria.post("/anexo_restituicao_manifestacao/", formData, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const anexosDemandRestituicao = (
  titulo: string,
  arquivo: File,
  restituicao: string
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append("titulo", titulo);
  formData.append("arquivo", arquivo);
  formData.append("restituicao", restituicao);
  return apiouvidoria.post("/anexo_restituicao_demanda/", formData, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const AllDemandAuth = (
  page: number,
  filtro?: string,
  search?: string
): Promise<AxiosResponse> => {
  if (filtro && search) {
    return apiouvidoria.get(`demanda-auth/?${filtro}=${search}&page=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return apiouvidoria.get(`demanda-auth/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const AllTypeEsic = (): Promise<AxiosResponse> =>
  apiouvidoria.get("tipo_recurso_esic/");

export const ombudsmanCancel = (
  id: number,
  tipo: TypeCancel,
  para?: string
): Promise<AxiosResponse> => {
  if (tipo === "Solicitação" && para === "Digital") {
    return apiforFlow.put(
      `/flow/respostas-fluxo/${id}/`,
      {
        status: "Cancelado pelo usuário",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    return apiouvidoria.post(
      "cancelamento/",
      {
        id,
        tipo,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
};

export const searchManifestation = (
  codigo: string,
  numero: string,
  email?: string,
  cpf?: string
): Promise<AxiosResponse> =>
  apiouvidoria.get("manifestacao/", {
    params: {
      codigo,
      numero,
      email,
      cpf,
    },
  });

export const getOrgansList = (): Promise<AxiosResponse> =>
  apiouvidoria.get("orgaos-list/");

export const getManifestationTypeBySlug = (
  slug: string
): Promise<AxiosResponse> =>
  apiouvidoria.get("/tipos", {
    params: {
      slug,
    },
  });

export const updateCurrentEvaluation = (
  id: string,
  pendente: boolean,
  token: string,
  avaliacao_informacao: number,
  type: evaluationType,
  comentario_informacao?: string
): Promise<AxiosResponse> => {
  if (
    type === "manifestacao" ||
    type === "solicitacao_servico" ||
    type === "esic"
  ) {
    return apiatendimento156.put(
      `/avaliacao_cidadao/${id}/?id=${id}`,
      {
        id,
        pendente,
        avaliacao_consumo: avaliacao_informacao,
        comentario_consumo: comentario_informacao,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
};

export const getAllEvaluationManifestations = (
  id: string
): Promise<AxiosResponse> =>
  apiouvidoria.get("/avaliacao_manifestacao", {
    params: {
      id,
    },
  });

export const updatedEvaluationManifestation = (
  id: string,
  avaliacao_informacao: number,
  comentario_informacao?: string
): Promise<AxiosResponse> =>
  apiouvidoria.put(`/avaliacao_manifestacao/${id}/?id=${id}`, {
    avaliacao: avaliacao_informacao,
    comentario: comentario_informacao,
  });

export const getAllEvaluationDemands = (id: string): Promise<AxiosResponse> =>
  apiouvidoria.get("/avaliacao_demanda/", {
    params: {
      id,
    },
  });

export const updatedEvaluationDemand = (
  id: string,
  avaliacao_informacao: number,
  comentario_informacao?: string
): Promise<AxiosResponse> =>
  apiouvidoria.put(`/avaliacao_demanda/${id}/?id=${id}`, {
    avaliacao: avaliacao_informacao,
    comentario: comentario_informacao,
  });

export const getAllOptionsEvaluation = (
  avaliacao: number
): Promise<AxiosResponse> =>
  apiouvidoria.get(`/motivos-avaliacao/?avaliacao=${avaliacao}`);

export const searchCep = (
  cep: string,
  cidade?: string,
  uf?: string
): Promise<AxiosResponse> =>
  apiouvidoria.post("checar-cep/", {
    cep,
    cidade,
    uf,
  });
