import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography, IconButton, Grid, Container } from '@material-ui/core';
import { MdArrowBack } from "react-icons/md";
import { Breadcrumb, NavMenu, PaginationService } from '../../Components';
import { CardService } from './components';
import { useEffect } from 'react';
import { getEmployeeService } from '../../services/servico';
import { useState } from 'react';
import { SearchII } from "../../interfaces/servico";
import getCookie from '../../utils/getCookies';
import { UserData } from '../../interfaces/user';

type Props = {
  history: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  gridMain: {
    margin: 0,
  },
  boxIndicatorsBack: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: 'column',
    margin: "20px 0px",
  },
  indicatorsBack: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  boxTermsList: {
    backgroundColor: "#fff",
    borderRadius: 4,
    marginBottom: 22,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: "Roboto",
    textTransform: "uppercase",
    color: "#373F41",
  },
  boxDateInfo: {
    display: "flex",
    color: "#373F41",
    alignItems: "center",
    marginTop: 17,
    flexWrap: "wrap",
  },
  date: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    fontStyle: "normal",
    margin: "0px 4px",
    color: "#373F41",
    opacity: 0.6,
  },
}));



export default function EmployeeArea({ history }: Props): JSX.Element {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [employeeServices, setEmployeeServices] =
    useState<SearchII | undefined>();
  const linksBreadcrumb = [
    {
      name: "Área do servidor",
      redirectTo: "#",
    },
  ];
  const tokenSiseci: string | null = getCookie(
    "gov_access_token_sso"
  );

  const getEmployeeServices = async (page?: number) => {
    if (tokenSiseci) {
      try {
        const { data } = await getEmployeeService(tokenSiseci, page);

        if (data?.results) {
          setEmployeeServices(data);
        }
      } catch (error) {
        console.log(error);
      }

    }

  };

  useEffect(() => {
    const dataStorage: string | null = getCookie('gov_user_data');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    if (userData) {
      if (!(userData.servidor.length > 0)) {
        history.push('/workspace')
      }
    }

  }, [])
  

  useEffect(() => {
    if (currentPage) {
      getEmployeeServices(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, tokenSiseci]);
  return (
    <>
      <NavMenu />
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Breadcrumb links={linksBreadcrumb} />
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <IconButton onClick={() => history.push('/workspace')}>
                  <MdArrowBack />
                </IconButton>
                <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                  Área do servidor
                </Typography>
              </Box>
              <Box ml={2}>
                <Typography variant="subtitle2">
                  Realize operações específicas de servidores.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {employeeServices &&
            employeeServices.results &&
            employeeServices.results.map((service) => (
              <CardService service={service} key={service?.slug} />
            ))}
        </Grid>
      </Container>
      {employeeServices && employeeServices.results.length ? (
        <PaginationService
          setPage={setCurrentPage}
          listSearch={employeeServices}
        />
      ) : (
        ""
      )}
    </>
  );
}
