import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { FilterAttendence } from "./components";
import {
  Box,
  Typography,
  Grid,
  Container,
  CircularProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import PrintDialog from "./components/PrintDialog";
import CancelDialog from "./components/CancelDialog";
import {
  MdToday,
  MdSchedule,
  MdStar,
} from "react-icons/md";
import { IParamsHistory } from "../../services/agendamentoOrganico";
import { ScheduleHist, HistoryI } from "../../interfaces/agendamentos";
import dateFormatToBR from "../../utils/dateFormat";
import { isAfter, addDays } from "date-fns";
import { PaginationService } from "../../Components";
import HistoryDialog from "./components/HistoryDialog";
import DependentDataDialog from '../../Components/DependentDataDialog';
import { historico } from "../../services/agendamento";
import getCookie from "../../utils/getCookies";

const useStyles = makeStyles((theme: Theme) => ({
  boxIndicatorsBack: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  indicatorsBack: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  boxTermsList: {
    backgroundColor: "#fff",
    padding: 30,
    borderRadius: 4,
    margin: "20px 0px",
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      gap: 10,
      padding: '26px 24px',
    },
  },
  boxInformationTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      gap: 24,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Roboto",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  subTitle: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    margin: "16px 0px 12px 0px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  description: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    color: "#373F41",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  status: {
    fontSize: 14,
    fontFamily: "Roboto",
    fontWeight: 500,
    textTransform: "capitalize",
    padding: "5px 16px",
    borderRadius: 25,
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      alignSelf: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  boxInfo: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#373F41",
    margin: "46px 0px 17px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: 'center',
      gap: 18,
      marginTop: 25,
    },
  },
  textInfo: {
    display: "flex",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    fontStyle: "normal",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 26,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },

  infoNumberGuiche: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  infoTextGuiche: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    textTransform: "uppercase",
  },
  textButtonGuiche: {
    textTransform: "uppercase",
    fontFamily: "Roboto",
    borderRadius: 4,
    padding: "12px 24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  actionsButtonText: {
    textTransform: "uppercase",
    fontFamily: "Roboto",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  buttonCancel: {
    color: "#E53535",
    borderColor: "rgba(229,53,53, 0.7)",
    borderRadius: 4,
    padding: "12px 24px",
    "&:hover": {
      backgroundColor: "rgba(229,53,53, 0.1)",
      borderColor: "#E53535",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  notificationCounter: {
    position: "absolute",
    top: -10,
    right: -10,
    padding: "3px 9px",
    borderRadius: "50%",
    backgroundColor: "#F5365C",
    color: theme.palette.text.primary,
    fontSize: 13,
  },
  icon: {
    marginRight: 7,
    color: theme.palette.primary.main,
    width: 20,
    height: 20,
    [theme.breakpoints.down("xs")]: {
      width: 16,
      height: 16,
    },
  },
  boxButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 16,
      marginTop: 20,
    },
  },

  dependentsButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    fontStyle: "normal",
    marginRight: 26,
    borderRadius: '6px',
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  }

}));

export default function OrganicSchedulingRequest(): JSX.Element {
  const classes = useStyles();
  const [scheduleHistory, setScheduleHistory] = useState<ScheduleHist>();
  const [scheduleSelected, setScheduleSelected] = useState<HistoryI>();
  const [filterParams, setFilterParams] = useState<IParamsHistory | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDependentDialog, setOpenDependentDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState<number | null>(1);


  const history = useHistory();

  // const handleDependentDataDialog = (schedule: HistoryI) => {
  //   setOpenDependentDialog(true)
  //   setScheduleSelected(schedule);
  // }

  const handleCloseDependentDataDialog = () => {
    setOpenDependentDialog(false)
    setScheduleSelected(undefined);
  }

  const handlePrintDialog = (schedule: HistoryI) => {
    setOpenDialog(true);
    setScheduleSelected(schedule);
  };

  const handleCancelDialog = (schedule: HistoryI) => {
    setOpenCancelDialog(true);
    setScheduleSelected(schedule);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setScheduleSelected(undefined);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setScheduleSelected(undefined);
  };

  const handleHistoryDialog = (schedule: HistoryI) => {
    setOpenHistoryDialog(true);
    setScheduleSelected(schedule);
  };

  const handleCloseHistoryDialog = () => {
    setOpenHistoryDialog(false);
    setScheduleSelected(undefined);
  };

  const handleEvaluationDate = (schedule: HistoryI) => {
    if (!schedule?.avaliacao_diario) {
      return;
    }
    const date = new Date(schedule.avaliacao_diario.created_at);
    const now = new Date();
    const afterOneWeek = addDays(date, 7);
    if (isAfter(afterOneWeek, now) && schedule.avaliacao_diario.pendente) {
      return (
        <Button
          className={classes.textButtonGuiche}
          color="primary"
          variant="outlined"
          onClick={() => handleToEvaluetion(schedule.avaliacao_diario.id)}
        >
          <Typography className={classes.actionsButtonText}>Avaliar</Typography>
        </Button>
      );
    }
    return;
  };

  const getHistory = async (
    page: number,
    params?: IParamsHistory
  ): Promise<void> => {
    (window as any).scrollTo(0, 0);
    setScheduleHistory(undefined);
    const token: string | null = getCookie(
      "gov_access_token_sso"
    );
    setLoading(true);
    if (token) {
      try {
        const { data } = await historico(token, page, 'Espontâneo', params);
        setScheduleHistory(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (filterParams || currentPage) {
      getHistory(currentPage, filterParams);
    } else {
      getHistory(1);
    }
  }, [filterParams, currentPage]);

  const handleToEvaluetion = (ticket: string) => {
    history.push(`/workspace/avaliar_atendimento_diario/${ticket}`);
  };

  return (
    <>
      <Grid container>
        <FilterAttendence setFilter={setFilterParams} />
      </Grid>

      {loading ? (
        <Box
          marginBottom="60px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {scheduleHistory && scheduleHistory.results.length > 0 ? (
            scheduleHistory.results.map((schedule) => (
              <Fragment key={schedule.id}>
                <Container>
                  <Grid
                    key={schedule.id}
                    container
                    alignItems="flex-end"
                    justifyContent="space-between"
                    md={12}
                    className={classes.boxTermsList}
                  >
                    <Box className={classes.boxInformationTitle}>
                      <Typography className={classes.title}>
                        {schedule.unidade.nome}
                        {/* (Guichê {schedule.guiche.ordem}) */}
                      </Typography>
                      {(schedule.status === "Cancelado" ||
                        schedule.status === "Não Compareceu") && (
                          <Typography
                            className={classes.status}
                            style={{
                              color: "#F44336",
                              border: "1px solid rgba(248, 50, 69, 0.08)",
                              background: "rgba(248, 50, 69, 0.08)",
                            }}
                          >
                            {schedule.status}
                          </Typography>
                        )}
                      {schedule.status === "Concluído" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#43A047",
                            border: "1px solid rgba(67, 160, 71, 0.08)",
                            background: "rgba(67, 160, 71, 0.08)",
                          }}
                        >
                          Concluído
                        </Typography>
                      )}
                      {(schedule.status === "Aguardando" ||
                        schedule.status === "Em Andamento" ||
                        schedule.status === "Compareceu") && (
                          <Typography
                            className={classes.status}
                            style={{
                              color: "#0F6FB7",
                              border: "1px solid rgba(15, 111, 183, 0.08)",
                              background: "rgba(15, 111, 183, 0.08)",
                            }}
                          >
                            {schedule.status}
                          </Typography>
                        )}
                      {/* {schedule.status === "Não Compareceu" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#851d15",
                            border: "1px solid rgba(141, 18, 30, 0.08)",
                            background: "rgba(141, 18, 30, 0.08)",
                          }}
                        >
                          Não Compareceu
                        </Typography>
                      )} */}
                    </Box>
                    <Grid item xs={12} sm={12} md={7}>
                      <Typography className={classes.subTitle}>
                        {schedule.servico.titulo}
                      </Typography>
                      <Typography
                        className={classes.description}
                      >{`Órgão: ${schedule.orgao_responsavel}`}</Typography>
                      {schedule?.protocolo && (
                        <Typography className={classes.subTitle}>
                          {`Protocolo: ${schedule.protocolo}`}
                        </Typography>
                      )}
                      <Box display="flex" className={classes.boxInfo}>
                        <Typography className={classes.textInfo}>
                          <MdToday className={classes.icon} />
                          {` ${dateFormatToBR(schedule.data)}`}
                        </Typography>
                        <Typography className={classes.textInfo}>
                          <MdSchedule className={classes.icon} />
                          {` ${schedule.hora.slice(0, 5)}`}
                        </Typography>
                        {schedule?.avaliacao_diario?.pendente === false && (
                          <Typography className={classes.textInfo}>
                            <MdStar style={{ marginRight: 5 }} />
                            {`${schedule.avaliacao_diario?.avaliacao_consumo} estrelas`}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <Box className={classes.boxButton}>
                        {handleEvaluationDate(schedule)}
                        <Button
                          className={classes.textButtonGuiche}
                          color="primary"
                          variant="outlined"
                          onClick={() => handlePrintDialog(schedule)}
                        >
                          <Typography className={classes.actionsButtonText}>
                            Imprimir
                          </Typography>
                        </Button>
                        {/* {schedule.status === "Aguardando" && (
                          <>
                            <Button
                              className={classes.buttonCancel}
                              variant="outlined"
                              color="inherit"
                              onClick={() => handleCancelDialog(schedule)}
                            >
                              <Typography className={classes.actionsButtonText}>
                                cancelar
                              </Typography>
                            </Button>
                          </>
                        )} */}
                        {schedule.status === "Concluído" && (
                          <Button
                            className={classes.textButtonGuiche}
                            color="primary"
                            variant="outlined"
                            onClick={() => handleHistoryDialog(schedule)}
                          >
                            <Typography className={classes.actionsButtonText}>
                              Ver histórico
                            </Typography>
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Fragment>
            ))
          ) : (
            <Box
              marginBottom="60px"
              minHeight="100px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <Typography style={{ textAlign: "center" }} variant="h4">
                Nenhum resultado foi encontrado.
              </Typography>
            </Box>
          )}
          {scheduleSelected?.dependentes && (
            <DependentDataDialog
              open={openDependentDialog}
              schedule={scheduleSelected}
              handleClose={handleCloseDependentDataDialog}
            />
          )}
          {scheduleSelected && (
            <PrintDialog
              open={openDialog}
              schedule={scheduleSelected}
              handleClose={handleCloseDialog}
            />
          )}
          {scheduleSelected && (
            <CancelDialog
              open={openCancelDialog}
              schedule={scheduleSelected}
              handleClose={handleCloseCancelDialog}
              onReload={getHistory}
            />
          )}
          {scheduleSelected && (
            <HistoryDialog
              open={openHistoryDialog}
              schedule={scheduleSelected}
              handleClose={handleCloseHistoryDialog}
            />
          )}
        </>
      )}

      {scheduleHistory && scheduleHistory.results.length ? (
        <PaginationService
          setPage={setCurrentPage}
          listSearch={scheduleHistory}
        />
      ) : (
        ""
      )}
    </>
  );
}
