import {
  Dialog,
  DialogContent,
  Button,
  DialogContentText,
  Typography,
  Box,
  DialogActions,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { BsCheckCircleFill } from 'react-icons/bs';
import { BiCalendarEvent } from 'react-icons/bi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({

  contentText: {
    fontFamily: 'Roboto',
    padding: 0,
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      '& > p': {
        fontSize: theme.typography.pxToRem(12)
      }

    }
  },
  dialogActions: {
    padding: '8px 24px 24px 24px',
    gap: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  confirmBtn: {
    padding: '12px 16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '22.4px',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: '#ffff'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '2rem 2rem 2rem 2rem',
  },
  headerTitle: {
    color: '#333333',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(24),
    marginBottom: '1.5rem'
  },
  boxButtonInfo: {
    display: 'flex',
    width: '100%',
    alignItens: 'center',
    justifyContent: 'center',
  },
  headerSummary: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px'
  },
  boxContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  usuario: {
    backgroundColor: 'rgba(225, 230, 239, 0.3)',
    border: '1px solid #E1E6EF',
    display: 'flex',
    gap: '5px',
    color: 'black',
    marginBottom: '5px',
    borderRadius: '5px',
    width: '100%',
    padding: '15px 15px',
    fontSize: '18px'
  },
  detalhes: {
    backgroundColor: 'rgba(225, 230, 239, 0.3)',
    border: '1px solid #E1E6EF',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    color: 'black',
    marginBottom: '5px',
    borderRadius: '5px',
    width: '100%',
    padding: '15px 15px',
    fontSize: '18px'
  },
  textContent: {
    color: '#053396',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
  },
  boxInfo: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  }

}));


interface Props {
  openSummaryModal: boolean;
  handleClose: () => void
  orgao: string;
  servico: string;
  selectedDate?: Date;
  selectedTime?: string;
  paraDependente?: boolean;
  dependente?: string;
  user: string
  handleCompletedScheduled: () => void;
}

export default function SummaryModal({
  openSummaryModal, handleClose, orgao, servico,
  selectedDate, selectedTime, user,
  paraDependente, dependente, handleCompletedScheduled
}: Props) {

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={openSummaryModal}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={classes.contentText}>
            <Box className={classes.headerSummary}>

              <BsCheckCircleFill style={{ width: '3.5rem', height: '3.5rem', color: '#0BB07B' }} />
              <Typography className={classes.headerTitle}>
                Agendamento realizado com sucesso
              </Typography>
            </Box>
            <Box className={classes.boxContent}>
              <Box className={classes.usuario}>
                {paraDependente ? (<strong>Dependente :</strong>) : (<strong>Titular :</strong>)}
                <Typography className={classes.textContent}>
                  {paraDependente ? dependente : user}
                </Typography>
              </Box>

              <Box className={classes.detalhes}>
                <Box className={classes.boxInfo}>
                  <AiOutlineClockCircle style={{ width: '1.5rem', height: '1.5rem' }} />
                  {selectedTime}
                </Box>
                <Box className={classes.boxInfo}>
                  <BiCalendarEvent style={{ width: '1.5rem', height: '1.5rem' }} />
                  {format(new Date(selectedDate), 'yyyy-MM-dd')}
                </Box>
                <Box className={classes.boxInfo} >
                  <strong>Serviço: </strong>
                  <Typography className={classes.textContent}>
                    {servico}
                  </Typography>
                </Box>
                <Box className={classes.boxInfo}>
                  <strong>Orgão: </strong>
                  <Typography className={classes.textContent}>
                    {orgao}
                  </Typography>
                </Box>

              </Box>

            </Box>

          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Box className={classes.boxButtonInfo}>
            <Button variant='contained'
              color='primary'
              className={classes.confirmBtn}
              onClick={handleClose}
              onClickCapture={(() => {
                handleClose();
                handleCompletedScheduled()
              })}
            >
              OK
            </Button>
          </Box>

        </DialogActions>
      </Dialog>
    </>
  )
}

