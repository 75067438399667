import { AxiosResponse } from 'axios';
import { apiforFlow } from './api';
import getCookie from '../utils/getCookies';
const tokenSSO: string | null = getCookie('gov_access_token_sso');

export const responseRestituicao = (
  id: string,
  solicitacao_id: number,
  resposta: string
): Promise<AxiosResponse> =>
  apiforFlow.put(
    `flow/responder-restituicao/${id}/?solicitacao_id=${solicitacao_id}`,
    {
      id,
      resposta,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const anexosRestituicao = (
  arquivo: File,
  restituicao: string
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('arquivo', arquivo);
  formData.append('restituicao', restituicao);
  return apiforFlow.post('flow/anexo-restituicao/', formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${tokenSSO}`,
    },
  });
};
