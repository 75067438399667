import { AxiosResponse } from 'axios';
import { apisiseci } from './api';

interface EvaluationProps {
  tipo: 'Agendamento' | 'Formflow' | 'Solicitação' | 'Manifestação' | 'Esic'
  avaliacao_processo: number;
  comentario_processo: string;
  avaliacao_plataforma: number;
  comentario_plataforma: string;
  servico: string | null;
  orgao: number
  token: string
}

const evaluationMain = ({
  tipo,
  avaliacao_processo,
  comentario_processo,
  avaliacao_plataforma,
  comentario_plataforma,
  servico,
  orgao,
  token,
}: EvaluationProps):Promise<AxiosResponse> => apisiseci.post('/cms/avaliacao_generica/', {
  tipo,
  avaliacao_processo,
  comentario_processo,
  avaliacao_plataforma,
  comentario_plataforma,
  servico,
  orgao,
}, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export default evaluationMain;
