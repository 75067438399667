import { useEffect, useState } from 'react';
import { Box, Typography, Container, IconButton } from '@material-ui/core';
import { Breadcrumb, NavMenu } from '../../Components';
import { MdArrowBack } from "react-icons/md";
import { useHistory, useParams } from 'react-router';
import { allManifestation } from '../../services/ouvidoria';
import { allEsic } from '../../services/esic';
import EsicInfo from './components/EsicInfo';
import ManifestationInfo from './components/ManifestationInfo';
import DemandInfo from './components/DemandInfo';
import ScheduleInfo from './components/ScheduleInfo';
import { getAllRequests, getRequests } from '../../services/form-flow';
import { AllDemandAuth } from '../../services/ouvidoria';
import { Resultado, Dados } from '../AllMyRequests';
import getCookie from '../../utils/getCookies';
import { historico } from '../../services/agendamento';
import FlowInfo from './components/FlowInfo';
import { useStyles } from './styles';
import { TypeFilter, Params } from './types';

export default function InfoSolicitation(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const params: Params = useParams();
  const [list, setList] = useState<any>();
  const [request, setRequest] = useState<Resultado | null>(null);
  const [avaliacaoPendente, setAvaliacaoPendente] = useState<Dados | null>();

  const getManifestationList = async ({ page, filtro }: { page?: number, filtro?: TypeFilter }) => {
    try {
      const { data } = await allManifestation(page, filtro?.title, filtro?.value);
      setList(data);
    } catch (error) {
      console.log(error);
    }
  }

  const getEsicList = async ({ page, filtro }: { page?: number, filtro?: { title: string; value: string } }) => {
    try {
      const { data } = await allEsic(page, filtro?.title, filtro?.value);
      setList(data);
    } catch (error) {
      console.log(error);
    }
  }

  const getDemandsList = async ({ page, filtro }: { page?: number, filtro?: { title: string; value: string } }) => {
    try {
      const { data } = await AllDemandAuth(page, filtro?.title, filtro?.value);
      setList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFlowList = async ({ page, protocolo }: { page: number; protocolo: string; }) => {
    try {
      const { data } = await getRequests(page, protocolo);
      setList(data);
    } catch (error) {
      console.log(error);
    }
  }

  const getAllRequestsList = async ({
    page,
    filtro,
  }: {
    page?: number;
    filtro?: { title: string; value: string };
  }) => {
    try {
      let user = JSON.parse(getCookie("gov_user_data") || "{}");
      if (user?.cpf) {
        const { data } = await getAllRequests({
          page,
          filtro: filtro?.title,
          search: filtro?.value,
        });
        if (data) {
          getAvaliacoesPendentes(data?.results[0]?.avaliacao);
          setRequest(data?.results[0])
          if (data?.results[0]?.avaliacao.length > 0) {
            setAvaliacaoPendente(data?.results[0]?.avaliacao)
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (list) {
      const filterData = {
        title: 'protocolo',
        value: params.tipo === "Manifestação" ? list?.results[0]?.numero_processo : list?.results[0]?.protocolo,
      };
      getAllRequestsList({ page: 1, filtro: filterData });
    }
  }, [list])

  const getAvaliacoesPendentes = (avaliacoes) => {
    if (avaliacoes.length > 0) {
      return avaliacoes.filter(avaliacao => avaliacao.pendente === true);
    }
    return false;
  };

  const getMyAttendences = async (
    page: number,
    params?: any
  ): Promise<void> => {
    const token: string | null = getCookie("gov_access_token_sso");
    try {
      const { data } = await historico(token, page, '', params);
      setList(data);
    } catch (err) {
      console.log(err);
    }
  };

  const linksBreadcrumb = [
    {
      name: 'Minhas solicitações',
      redirectTo: '/workspace/minhas_solicitacoes',
    }
  ];

  useEffect(() => {
    switch (params.tipo) {
      case 'E-SIC':
        var filterData = {
          title: 'numero',
          value: params.protocolo
        }
        getEsicList({ page: 1, filtro: filterData })
        break;

      case 'Manifestação':
        var filterData = {
          title: 'numero_processo',
          value: params.protocolo
        }
        getManifestationList({ page: 1, filtro: filterData })
        break;

      case 'Solicitação':
        var filterData = {
          title: 'protocolo',
          value: params.protocolo
        }
        getDemandsList({ page: 1, filtro: filterData })
        break;

      case 'Solicitação-Flow':
        var filterData = {
          title: 'protocolo',
          value: params.protocolo
        }
        getFlowList({ page: 1, protocolo: filterData.value });
        break;

      case 'Agendamento':
        const filtro = {
          protocolo: params.protocolo
        }
        getMyAttendences(1, filtro);
        break;
    }
  }, [params?.protocolo, params?.tipo]);

  return (
    <>
      <NavMenu />
      <Container>
        <Box>
          <Breadcrumb links={linksBreadcrumb} />
          <Box className={classes.boxIndicatorsBack}>
            <Box className={classes.indicatorsBack}>
              <IconButton
                onClick={() =>
                  history.push("/workspace/minhas_solicitacoes")
                }
              >
                <MdArrowBack />
              </IconButton>
              <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                Detalhes da solicitação {params.tipo === 'Solicitação-Flow' ? 'digital' : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.boxInfo}>
          {params.tipo === 'E-SIC' && (
            <>
              <EsicInfo list={list} getEsicList={getEsicList} avaliacaoPendente={avaliacaoPendente} />
            </>
          )}
          {params.tipo === 'Manifestação' && (
            <>
              <ManifestationInfo list={list} getManifestationList={getManifestationList} avaliacaoPendente={avaliacaoPendente} />
            </>
          )}
          {params.tipo === 'Solicitação' && (
            <>
              <DemandInfo list={list} getDemandList={getDemandsList} avaliacaoPendente={avaliacaoPendente}/>
            </>
          )}
          {params.tipo === 'Solicitação-Flow' && (
            <>
              <FlowInfo list={list} getFlowList={getFlowList} avaliacaoPendente={avaliacaoPendente} requestFlow={request} />
            </>
          )}
          {params.tipo === 'Agendamento' && (
            <>
              <ScheduleInfo list={list} getMyAttendences={getMyAttendences} avaliacaoPendente={avaliacaoPendente} request={request} />
            </>
          )}
        </Box>
      </Container>
    </>
  );
}
