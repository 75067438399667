// função auxiliar para obter um cookie pelo nome
const getCookie = (cname: string): string => {
	const name = `${cname}=`;
	const ca = decodeURIComponent(document.cookie).split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return decodeURIComponent(c.substring(name.length, c.length));
		}
	}
	return '';
};

export default getCookie;
