import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#d3d3d3",
      },
    },
    "& .MuiSelect-iconOutlined": {
      height: 24,
      width: 24,
    },
  },
  header: {
    background: theme.palette.primary.main,
  },
  textHeader: {
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    width: "90%",
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: "16px",
    fontStyle: "Molish",
    color: "#373F41",
    marginTop: 10,
    fontWeight: 700,
    lineHeight: "20px",
  },
  buttonClose: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: "#fff",
  },
  textSchedule: {
    color: "#373F41",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    marginTop: 30,
  },
  textSubtitleSchedule: {
    color: "#373F41",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    marginTop: 20,
  },
  iconUpload: {
    color: "#9E9E9E",
  },
  rootUpload: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 140,
    flexDirection: "column",
  },
  adornedEnd: {
    paddingRight: 0,
  },
  inputSearch: {
    maxWidth: 582,
    width: "100%",
    height: 48,
    border: `1px solid ${theme.palette.primary.main}`,
    outline: "none",
    paddingLeft: 16,
    fontFamily: "Roboto",
    fontSize: 14,
    borderRadius: 0,
  },
  optionsList: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #DADCE0",
    maxWidth: "540px",
    height: "48px",
    borderLeftWidth: "2px",
    borderLeftColor: `${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionsListText: {
    fontSize: "14px",
    color: "#373F41",
    fontWeight: 600,
    fontFamily: "Roboto",
  },
  boxnNextOptions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
  },
  serviceSelectedTextTitle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontFamily: "Roboto",
    textTransform: "uppercase",
    fontSize: "14px",
  },
  titleBoxStep: {
    fontSize: theme.typography.pxToRem(12),
    color: "#706E7A",
    fontStyle: "medium",
    textTransform: "uppercase",
    fontFamily: "Roboto",
    margin: "10px 0px 10px 24px",
  },
  serviceSelectedText: {
    color: "#373F41",
    fontWeight: 600,
    fontFamily: "Roboto",
    fontSize: "16px",
    marginTop: "16px",
  },
  serviceSelectedSubText: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "16px",
  },
  buttonAddArchives: {
    backgroundColor: `${theme.palette.primary.main}`,
    height: "40px",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#0A5D9C",
    },
  },
  boxConfirmScheduling: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
  },
  buttonConfirmScheduling: {
    backgroundColor: "rgba(60, 210, 120, 0.7)",
    borderRadius: 0,
    width: "300px",
    "&:hover": {
      backgroundColor: "#3CD278",
    },
  },
  multilineColor: {
    color: "#000",
  },
  inputCheckbox: {
    color: "#333333",
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Roboto",
  },
  inputCheckboxText: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontstyle: "normal",
    fontWeight: 400,
    textAlign: "left",
    color: "rgba(55, 63, 65, 0.6)",
  },
  colorInputText: {
    color: theme.palette.primary.main,
  },
  text: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 16,
    color: "rgba(55, 63, 65, 0.6)",
  },
  infoText: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 400,
    color: "rgba(55, 63, 65, 0.6)",
    margin: "8px 0px",
  },
  box: {
    borderRadius: 4,
    border: "1px solid #E1E6EF",
    margin: "20px 32px",
  },
  titleBox: {
    fontSize: theme.typography.pxToRem(14),
    color: "#333333",
    fontWeight: 500,
    textTransform: "uppercase",
    fontFamily: "Roboto",
    margin: "10px 0px 30px 20px",
  },
  boxInfoConcluded: {
    margin: "20px 32px",
    borderRadius: 4,
    padding: "16px 24px",
    border: "1px solid #0BB07B",
    backgroundColor: "rgba(11, 176, 123, 0.05)",
  },
  infoConcluded: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textInfoUnity: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    color: "#706E7A",
    marginTop: 16,
    marginBottom: 8,
  },
  textInfoDateTime: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    color: "#706E7A",
    marginBottom: 4,
  },
  buttonPrimary: {
    margin: "10px 0px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    width: 186,
    height: 44,
    "&:hover": {
      backgroundColor: "rgba(15, 111, 183, 0.7)",
    },
  },
  textButtonPrimary: {
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    textTransform: "uppercase",
  },
  buttonOutlined: {
    width: 186,
    height: 44,
    padding: "14px 16px",
    marginLeft: "0px !important",
  },
  textButtonOutlined: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    fontWeight: 700,
    textTransform: "uppercase",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  textSubtitleScheduleBox: {
    width: 165,
    height: 30,
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(225, 230, 239, 0.3)",
    color: "#333333",
  },
  textError: {
    color: theme.palette.error.dark,
    fontSize: 12,
    fontWeight: 700,
    marginLeft: 10,
    fontFamily: "Roboto",
  },
  icon: {
    height: 24,
    width: 24,
  },
  iconLarge: {
    height: 80,
    width: 80,
  },
  errorTextStyle: {
    color: "#333333",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Roboto",
  },
  errorUploadCard: {
    width: "100%",
    border: `1px solid #F03D3D`,
    boxShadow: "0px 8px 16px rgba(11, 31, 77, 0.04)",
    backgroundColor: "#fff",
    display: "flex",
    borderRadius: 4,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px",
    marginBottom: 2,
    marginTop: 5,
  },
  boxCancel: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 20,
  },
  titleCancel: {
    color: "#333333",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(28),
  },
  boxActionsCancel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 24px",
    margin: "20px 0px",
  },
  textUnidade: {
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "23px",
    textTransform: "capitalize",
    color: "#737B7D",
    paddingBottom: "20px",
    paddingTop: "20px",
  },
  textService: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#373F41",
  },
  textOrgan: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#373F41",
    paddingBottom: "20px",
  },
  textDateTime: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#373F41",
  },
  boxConcludedMain: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#333",
  },
}));
