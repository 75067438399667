import React, { useEffect } from "react";
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { MdClose, MdDeleteForever } from 'react-icons/md';
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";

interface Props {
  open: boolean;
  handleClose: () => void;
  files: FileObject[];
  setFiles: (file: FileObject[]) => void;
  sendArquive: () => void;
  loadingFiles: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#d3d3d3",
      },
    },
  },
  header: {
    background: theme.palette.primary.main,
  },
  textHeader: {
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
  },
  formControl: {
    marginBottom: 20,
    margin: theme.spacing(1),
    width: "97%",
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  buttonClose: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: "#737B7D",
  },
  textSchedule: {
    color: "#373F41",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    marginTop: 30,
  },
  textSubtitleSchedule: {
    color: "#373F41",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    marginTop: 20,
  },
  iconUpload: {
    color: "#9E9E9E",
  },
  rootUpload: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 140,
    flexDirection: "column",
  },
  adornedEnd: {
    paddingRight: 0,
  },
  multilineColor: {
    color: "#000",
  },
  text: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 16,
    color: "rgba(55, 63, 65, 0.6)",
  },
  infoText: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 400,
    color: "rgba(55, 63, 65, 0.6)",
    margin: "8px 0px",
  },
  icon: {
    width: 24,
    height: 24,
  },
}));

export default function ModalSendFiles({
  open,
  handleClose,
  files,
  setFiles,
  sendArquive,
  loadingFiles,
}: Props): JSX.Element {
  const classes = useStyles();

  const removeFile = (file: FileObject) => {
    const fileFilter = files.filter(
      (fileFiltering) => fileFiltering.data !== file.data
    );
    setFiles(fileFilter);
  };

  useEffect(() => {
    setFiles([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <MdClose className={classes.icon} />
      </IconButton>
      <DialogContent>
        <div
          style={{
            color: "#9E9E9E",
            marginTop: 50,
            marginLeft: 10,
            width: "97%",
          }}
        >
          <DropzoneAreaBase
            onAdd={(filesUpload) => {
              setFiles(filesUpload);
            }}
            fileObjects={files}
            acceptedFiles={["image/jpeg", "image/png", ".pdf"]}
            showPreviewsInDropzone={false}
            dropzoneText="Faça upload ou solte arquivos aqui"
            showFileNames={false}
            filesLimit={30}
            showFileNamesInPreview={false}
            dropzoneParagraphClass={classes.text}
            showAlerts={false}
            classes={{
              icon: classes.iconUpload,
              root: classes.rootUpload,
            }}
          />
          <Typography className={classes.infoText}>
            Tamanho máximo: 30MB
          </Typography>
          {files.map((file) => (
            <Card
              key={file.data?.toString()}
              style={{
                width: "100%",
                color: "#373F41",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 10px",
                marginBottom: 2,
                border: "1px solid #E1E6EF",
                boxShadow: "0px 8px 16px rgba(11, 31, 77, 0.04)",
                marginTop: 5,
                borderRadius: 4,
              }}
            >
              {file.file.name}
              <IconButton onClick={(): void => removeFile(file)}>
                <MdDeleteForever className={classes.icon} style={{ color: "red" }} />
              </IconButton>
            </Card>
          ))}
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 15,
        }}
      >
        <Button
          disabled={files.length <= 0 || loadingFiles}
          style={{
            textTransform: "none",
            color: "#fff",
            borderRadius: 0,
            minWidth: 200,
            marginTop: 20,
          }}
          color="secondary"
          variant="contained"
          onClick={sendArquive}
        >
          {loadingFiles ? (
            <CircularProgress
              style={{ width: 25, height: 25 }}
              color="secondary"
            />
          ) : (
            "Confirmar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
