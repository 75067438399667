import React, { useState } from "react";
import { Typography, Card, Grid, Box, Button } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ModalShedulingPresential from "../ModalShedulingPresential";
import ModalAttendenceOnline from "../ModalAttendenceOnline";
import { MdEvent } from "react-icons/md";
const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    alignItems: "center",
    height: 430,
  },
  mainMobile: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    alignItems: "center",
    flexDirection: "column",
    height: "518px",
    marginTop: "70px",
  },
  cards: {
    margin: "0 21px",
    width: "337px",
    height: "162px",
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    color: `${theme.palette.primary.main}`,
    backgroundColor: "#fff",
    boxShadow:
      "0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.05)",
    borderRadius: 0,
    "&:hover": {
      color: "#fff",
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
      transition: "all 0.2s ease",
    },
  },
  cardMobile: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    width: "47%",
    height: 204,
    color: "#000",
    backgroundColor: "#fff",
    boxShadow:
      "0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.05)",
    borderRadius: 0,
    "&:hover": {
      color: "#fff",
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
      transition: "all 0.2s ease",
    },
  },
  title: {
    marginTop: "26px",
    fontSize: "32px",
    fontFamily: "Roboto",
    fontWeight: 700,
    display: "flex",
    marginBottom: "-56px",
    justifyContent: "center",
    color: "#000",
  },
  titleMobile: {
    marginTop: "96px",
    fontSize: "32px",
    fontFamily: "Roboto",
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    color: `${theme.palette.primary.main}`,
  },
  textCard: {
    fontSize: "16px",
    textTransform: "uppercase",
  },
  boxAgendamento: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap-reverse",
    gap: 30,
    marginTop: 40,
    [theme.breakpoints.down(980)]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      marginTop: 20,
    },
  },
  subBoxAgendamento: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: "Roboto",
    textTransform: "uppercase",
  },
  cardAgendamento: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 0,
    marginBottom: 10,
    height: "100%",
    color: "#fff",
    padding: 20,
    background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
  },
  textCardAgendamento: {
    fontWeight: 700,
    textAlign: "center",
    display: "-webkit-box",
    WebkitLineClamp: 4,
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    fontSize: theme.typography.pxToRem(14),
    textTransform: "uppercase",
    fontFamily: "Roboto",
  },
  subTextCardAgendamento: {
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
  },
  textTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 24,
    color: "#373F41",
  },
  styleIcons: {
    width: 50,
    height: 50,
    color: "#fff",
  },
  boxButton: {
    display: "flex",
    justifyContent: "center",
  },
  buttonAgendamento: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    marginTop: 46,
    width: 112,
    height: 44,
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
    "&:hover": {
      color: "#fff",
      background: theme.palette.primary.main,
      transition: "all 0.2s ease",
      border: `1px solid ${theme.palette.text.primary}`,
    },
  },
}));

export default function FlashAccess(): JSX.Element {
  const [openModalShedulingPresential, setOpenShedulingPresential] =
    useState<boolean>(false);
  const [openAttendenceOnline, setOpenAttendenceOnline] =
    useState<boolean>(false);
  const [typeAttendence, setTypeAttendence] = useState<"Chat" | "Video">(
    "Chat"
  );
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.textTitle}>
          Agendamento rápido
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <Card className={classes.cardAgendamento}>
              <Box className={classes.boxAgendamento}>
                <Box className={classes.subBoxAgendamento}>
                  <Typography className={classes.textCardAgendamento}>
                    Agendamento para atendimento presencial
                  </Typography>
                </Box>
                <MdEvent className={classes.styleIcons} />
              </Box>
              <Box className={classes.boxButton}>
                <Button
                  className={classes.buttonAgendamento}
                  onClick={() => setOpenShedulingPresential(true)}
                >
                  Agendar
                </Button>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Card className={classes.cardAgendamento}>
              <Box className={classes.boxAgendamento}>
                <Box className={classes.subBoxAgendamento}>
                  <Typography className={classes.textCardAgendamento}>
                    Agendamento para atendimento online
                  </Typography>
                </Box>
                <span style={{ fontSize: 50 }} className="material-icons">
                  support_agent
                </span>
              </Box>
              <Box className={classes.boxButton}>
                <Button
                  className={classes.buttonAgendamento}
                  onClick={() => {
                    setTypeAttendence("Chat");
                    setTypeAttendence("Video");
                    setOpenAttendenceOnline(true);
                  }}
                >
                  Agendar
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {/*<ModalShedulingPresential
        open={openModalShedulingPresential}
        handleClose={(): void => setOpenShedulingPresential(false)}
      />
      <ModalAttendenceOnline
        open={openAttendenceOnline}
        handleClose={(): void => setOpenAttendenceOnline(false)}
        type={typeAttendence}
                />*/}
    </>
  );
}
