import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import {
    clearStorage,
    getDataUserSso,
    getUserData as getUserDataRequest,
    saveTokens,
} from "../../services/auth";

export default function RedirectDocView(): JSX.Element {
    const { tokenSSO, uuid_doc, identificador_solcitacao
    } = useParams<{
        tokenSSO: string, uuid_doc: string; identificador_solcitacao: string;
    }>()
    const history = useHistory();
    const [message, setMessage] = useState<string | null>(null);
    const setUserDataLocal = (data: any) => {
        document.cookie = "gov_user_data=" + JSON.stringify(data) + "; path=/";
    };
    const getUserData = async (tokenSSO: string) => {
        try {
            setMessage("Carregando...");
            const { data } = await getDataUserSso(tokenSSO);
            data && clearStorage();
            data && saveTokens(tokenSSO);
            data && setUserDataLocal(data[0]);
            return data;
        } catch (err) {
            console.log(err);
            setMessage("Erro ao carregar dados do usuário");
            return null;
        }
    };

    useEffect(() => {
        const redirect = async () => {
            const data = await getUserData(tokenSSO);
            data && history.push(`/workspace/meus_servicos/mobile/view_doc/${uuid_doc}/${identificador_solcitacao}`);
        };

        redirect();
    }, [tokenSSO]);


    return (
        <h3>{message}</h3>
    );

}