import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography, IconButton, Grid, Container, CircularProgress } from '@material-ui/core';
import { MdArrowBack, MdCalendarToday, MdSchedule } from "react-icons/md";
import { extractDateFormat } from '../../utils/dateFormat';
import { Breadcrumb, NavMenu, PaginationService } from '../../Components';

import { allActivities } from '../../services/atividades';
import { AtividadeProps } from '../../interfaces/atividades';
import theme from '../../services/theme-service';
import format from 'date-fns/format';
import getCookie from '../../utils/getCookies';

type Props = {
  history: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
  },
  boxIndicatorsBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px'
  },
  indicatorsBack: {
    display: 'flex',
    width: '100%',
    color: '#373F41',
    maxWidth: '1250px',
    alignItems: 'center',
  },
  boxTermsList: {
    backgroundColor: '#fff',
    borderRadius: 4,
    marginBottom: 22,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    color: '#373F41'
  },
  boxDateInfo: {
    display: 'flex',
    color: '#373F41',
    alignItems: 'center',
    marginTop: 17,
    flexWrap: 'wrap',
  },
  date: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 16,
    fontStyle: 'normal',
    margin: '0px 4px',
    color: '#373F41',
    opacity: 0.6,
  },
  errorText: {
    fontSize: 22,
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  iconActivy: {
    height: 24,
    width: 24,
    opacity: 0.6,
  }
}));



export default function Activity({ history }: Props): JSX.Element {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const token: string | null = getCookie('gov_access_token_sso');
  const [activitiesData, setActivitiesData] = useState<AtividadeProps>();
  const isActivityValidData = activitiesData?.results?.length > 0;
  const isNotActivityValidData = activitiesData?.results?.length < 1;

  const linksBreadcrumb = [
    {
      name: "Atividades",
      redirectTo: "#",
    },
  ];

  useEffect(() => {
    const getAllActivities = async () => {
      try {
        setLoading(true);
        const { data } = await allActivities({ token, page });

        setActivitiesData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    getAllActivities();
  }, [page, token]);

  return (
    <>
      <NavMenu />
      <Breadcrumb links={linksBreadcrumb} />
      <Box className={classes.boxIndicatorsBack}>
        <Box className={classes.indicatorsBack}>
          <IconButton onClick={() => history.push('/workspace')}>
            <MdArrowBack />
          </IconButton>
          <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
            Atividades
          </Typography>
        </Box>
      </Box>
      <Container
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        {loading ? (
          <CircularProgress
            style={{
              width: 25,
              height: 25,
              color: `${theme.palette.primary}`
            }}
          />
        ) : (
          <>
            {isActivityValidData ? activitiesData.results.map((activiti) => (
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                md={5}
                direction="row"
                className={classes.boxTermsList}
                key={activiti.id}
              >
                <Grid item sm={8} xs={11} style={{ padding: 20 }}>
                  <Typography className={classes.title}>
                    {activiti.titulo}
                  </Typography>
                  <Box className={classes.boxDateInfo}>
                    <div style={{ display: "flex" }}>
                      <MdCalendarToday className={classes.iconActivy} />
                      <Typography className={classes.date}>{extractDateFormat(activiti.created_at)}</Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      <MdSchedule className={classes.iconActivy} />
                      <Typography className={classes.date}>{format(new Date(activiti.created_at), "HH:mm")}</Typography>
                    </div>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                </Grid>
              </Grid>
            )) : (
              <>
                {isNotActivityValidData && (
                  <Typography className={classes.errorText}>Nenhum resultado foi encontrado!</Typography>
                )}
              </>
            )}
          </>
        )}
      </Container>

      {isActivityValidData && !loading && (
        <PaginationService
          setPage={setPage}
          listSearch={activitiesData}
        />
      )}
    </>
  );
}
