import React from "react";
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import MoreLink from "../../../../../Components/SearchServices/components/MoreLink";
import { SearchI } from "../../../../../interfaces/servico";

interface Props {
  service: SearchI;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    marginBottom: 25,
  },
  cardContent: {
  },
  cardMedia: {
    height: 148,
    background: "#F3F8FB",
  },
  boxContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 100,
  },
  textTitle: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: 16,
    fontWeight: 500,
  },
  textSubTitle: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: 14,
    fontWeight: 400,
    opacity: 0.6,
    // overflow: "auto",
  },
}));

export default function CardRecommendation({
  service,
}:
  Props): JSX.Element {
  const classes = useStyles();
  return (
    <Card className={classes.main}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.boxContent}>
          <Typography className={classes.textTitle}>{service.titulo}</Typography>

          <Typography className={classes.textSubTitle}>
            Órgão: {service.orgao_nome}
          </Typography>
          <Box style={{
            display: "flex",
            flexDirection: "row",
            paddingBottom: 0,
            marginTop: 10,
          }}>
            <MoreLink service={service} setShowSearchResult={() => false} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
