/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton, makeStyles, Theme, Box, Link, CircularProgress, Typography } from "@material-ui/core";
import { MdMoreVert, MdEvent, MdDelete, MdVisibility } from "react-icons/md";
import { SearchI, ServicosFavorito, UnavailabilityI } from "../../../../../interfaces/servico";
import { getAtividades, getFavoriteService, getService, removeFavoriteService } from "../../../../../services/servico";
import ModalShedulingPresential from "../../../../../Components/SearchServices/components/ModalShedulingPresential";
import ModalAttendenceOnline from "../../../../../Components/SearchServices/components/ModalAttendenceOnline";
import getCookie from "../../../../../utils/getCookies";
import UnavailabilityModal from "../../../../../Components/UnavailabilityModal";



const useStyles = makeStyles((theme: Theme) => ({
  itemMenuAgendar: {
    display: "flex",
    letterSpacing: 0.3,
    textDecoration: "none",
    padding: '2px 0px',
    "&:hover": {
      textDecoration: "none",
    },
  },
  itemMenuRemover: {
    color: "#264259",
    fontFamily: "Roboto",
    letterSpacing: 0.3,
    fontWeight: "normal",
    paddingTop: 5,
    paddingBottom: 5,
  },
  boxActionsIcon: {
    width: 40,
    height: 40,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textLink: {
    fontSize: 16,
    fontWeight: 500,
    color: '#333333',
    fontFamily: "Roboto",
  },
  iconFavorite: {
    width: 24,
    height: 24,
  },
}));

interface Props {
  service_slug: string;
  favoriteId: number;
  agendavel: boolean;
  online: boolean;
  setFavorites: (favoritos: ServicosFavorito) => void;
  indisponibilidades?: UnavailabilityI[];
}

export default function Menus({ service_slug, favoriteId, agendavel, online, setFavorites, indisponibilidades }: Props) {
  const classes = useStyles();
  const tokenSiseci: string | null = getCookie(
    "gov_access_token_sso"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceSelected, setServiceSelected] = useState<SearchI | undefined>();
  const [showModalAttendenceOnline, setShowModalAttendenceOnline] = useState<boolean>(false);
  const [showModalShedulingPresential, setShowModalShedulingPresential] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [hasScheduled, setHasScheduled] = useState<boolean>(false);
  const [openUnavailabilityModal, setOpenUnavailabilityModal] = useState<boolean>(false);
  const [currentUnavailabilityData, setCurrentUnavailabilityData] = useState<UnavailabilityI>();
  const onGetHasScheduler = useCallback(() => {
    setHasScheduled(!hasScheduled);
  }, [hasScheduled]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAllFavorites = async (page?: number) => {
    if (tokenSiseci) {
      try {
        const { data } = await getFavoriteService(tokenSiseci, page);

        if (data?.results) {
          setFavorites(data);
        }
      } catch (error) {
        console.log(error);
      }

    }

  };


  const handeRemove = async (favoriteId: number) => {
    if (tokenSiseci) {
      try {
        setLoading(true);
        await removeFavoriteService(favoriteId, tokenSiseci);
        await getAllFavorites(1);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleModalOnline = async (slug_text: string) => {
    if (tokenSiseci) {
      setLoading(true);
      try {
        const { data } = await getService(slug_text);
        setServiceSelected(data.results[0]);
      } catch (error) {
        console.log(error);
      }
      setAnchorEl(null);
      setLoading(false);
    }
  }

  const handleSchedulingPresential = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const unavailabilityItem = checkUnavailabilityList(indisponibilidades || [])
    if (unavailabilityItem.agendamento) {
      setCurrentUnavailabilityData(unavailabilityItem)
      setOpenUnavailabilityModal(true);
      return;
    }
    setShowModalShedulingPresential(true);
    handleModalOnline(service_slug);
  }

  const handleSchedulingOnline = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const unavailabilityItem = checkUnavailabilityList(indisponibilidades || [])
    if (unavailabilityItem.agendamento) {
      setCurrentUnavailabilityData(unavailabilityItem)
      setOpenUnavailabilityModal(true);
      return;
    }
    setShowModalAttendenceOnline(true);
    handleModalOnline(service_slug);
  }
  
  const checkAvailableDate = (fromDate: string, toDate: string) => {
    const parsedFromDate = Date.parse(fromDate);
    const parsedToDate = Date.parse(toDate);
    const today = new Date().getTime();
    return (today <= parsedToDate && today >= parsedFromDate)
  }

  const checkUnavailabilityList = (data: UnavailabilityI[]) => {
    const filteredList = data.filter((item) => item.ativo)
    const isUnavailable = filteredList.find((item) => checkAvailableDate(item.data_hora_inicio, item.data_hora_fim))
    if (isUnavailable) {
      return isUnavailable;
    }
    return { agendamento: false, solicitacao: false } as UnavailabilityI
  }

  return (
    <Box>
      <Box className={classes.boxActionsIcon}>
        <IconButton
          aria-label="comments"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MdMoreVert className={classes.iconFavorite} style={{ color: '#fff' }} />
        </IconButton>
      </Box>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {loading ? (
          <MenuItem className={classes.itemMenuAgendar}>
            <CircularProgress
              color="primary"
              style={{ width: 25, height: 25 }}
            />
          </MenuItem>
        ) : (
          <React.Fragment>
            <MenuItem>
              <Link
                href={`/servico/${service_slug}`}
                target="_new"
                className={classes.itemMenuAgendar}
              >
                <MdVisibility className={classes.iconFavorite} style={{ marginRight: 10 }} />
                <Typography className={classes.textLink}>
                  Visualizar serviço
                </Typography>
              </Link>
            </MenuItem>
            {(process.env.REACT_APP_AGENDAMENTO_ATIVO === "true") && <>
              {(agendavel && process.env.REACT_APP_AGENDAMENTO_ONLINE_ATIVO === 'true') && (
                <MenuItem>
                  <Link
                    href="#"
                    className={classes.itemMenuAgendar}
                    onClick={(event: React.SyntheticEvent) => {
                      handleSchedulingPresential(event)
                    }}
                  >
                    <MdEvent className={classes.iconFavorite} style={{ marginRight: 10 }} />
                    <Typography className={classes.textLink}>
                      Agendamento presencial
                    </Typography>
                  </Link>
                </MenuItem>
              )}
              {(online && process.env.REACT_APP_AGENDAMENTO_ONLINE_ATIVO === 'true') && (
                <MenuItem>
                  <Link
                    href="#"
                    className={classes.itemMenuAgendar}
                    onClick={(event: React.SyntheticEvent) => {
                      handleSchedulingOnline(event)
                    }}
                  >
                    <span style={{ marginRight: 10 }} className="material-icons">
                      support_agent
                    </span>
                    <Typography className={classes.textLink}>
                      Agendamento online
                    </Typography>
                  </Link>
                </MenuItem>
              )}

            </>}

            <MenuItem
              onClick={async () => {
                await handeRemove(favoriteId);
                handleClose();
              }}
              className={classes.itemMenuRemover}
            >
              {loading ? (
                <CircularProgress color="primary" />
              ) : (
                <React.Fragment>
                  <MdDelete className={classes.iconFavorite} style={{ marginRight: 10, color: '#F03D3D' }} />
                  <Typography className={classes.textLink}>Remover favorito</Typography>
                </React.Fragment>
              )}
            </MenuItem>
          </React.Fragment>
        )}
      </Menu>

      {serviceSelected && (
        <ModalAttendenceOnline
          handleClose={() => {
            setShowModalAttendenceOnline(false);
          }}
          open={showModalAttendenceOnline}
          type="Online"
          serviceSelected={serviceSelected}
          setShowResult={() => false}
          agendavelDependente={serviceSelected.dependente}
          handleHasScheduler={onGetHasScheduler}
          hasScheduled={hasScheduled}
        />
      )}
      {serviceSelected && (
        <ModalShedulingPresential
          handleClose={() => {
            setShowModalShedulingPresential(false);
          }}
          open={showModalShedulingPresential}
          serviceSelected={serviceSelected}
          setShowResult={() => false}
          agendavelDependente={serviceSelected.dependente}
          handleHasScheduler={onGetHasScheduler}
          hasScheduled={hasScheduled}
        />
      )}
      <UnavailabilityModal
        currentUnavailabilityData={currentUnavailabilityData}
        handleClose={() => setOpenUnavailabilityModal(false)}
        openUnavailabilityModal={openUnavailabilityModal}
      />
    </Box>
  );
}
