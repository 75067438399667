import { AxiosResponse } from 'axios';
import { apiouvidoria } from './api';
import getCookie from '../utils/getCookies';
const token: string | null = getCookie('gov_access_token_sso');

export const allEsic = (
  page: number,
  filtro?: string,
  search?: string
): Promise<AxiosResponse> => {
  if (filtro && search) {
    return apiouvidoria.get(`/esic-auth/?${filtro}=${search}&page=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return apiouvidoria.get(`/esic-auth/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllEvaluationEsic = (id: string): Promise<AxiosResponse> =>
  apiouvidoria.get('/avaliacao_esic', {
    params: {
      id,
    },
  });

export const responseResourceData = (
  esic_id: number,
  justificativa: string,
  tipo: number
): Promise<AxiosResponse> =>
  apiouvidoria.post(
    `/recurso_esic/?esic_id=${esic_id}`,
    {
      justificativa,
      tipo,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const resourceAttachments = (
  titulo: string,
  arquivo: File,
  recurso: string
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('titulo', titulo);
  formData.append('arquivo', arquivo);
  formData.append('recurso', recurso);
  return apiouvidoria.post('/anexo_recurso_esic/', formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updatedEvaluationEsic = (
  id: string,
  avaliacao_informacao: number,
  avaliacao_atendimento: number,
  comentario_informacao?: string,
  comentario_atendimento?: string
): Promise<AxiosResponse> =>
  apiouvidoria.put(`/avaliacao_esic/${id}/?id=${id}`, {
    avaliacao_informacao,
    comentario_informacao,
    avaliacao_atendimento,
    comentario_atendimento,
  });

export const responseEsicRestituicao = (
  id: string,
  esic_id: number,
  resposta: string
): Promise<AxiosResponse> =>
  apiouvidoria.put(
    `/responder_restituicao_esic/${id}/?esic_id=${esic_id}`,
    {
      id,
      resposta,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const anexosEsicRestituicao = (
  titulo: string,
  arquivo: File,
  restituicao: string
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('titulo', titulo);
  formData.append('arquivo', arquivo);
  formData.append('restituicao', restituicao);
  return apiouvidoria.post('/anexo_restituicao_esic/', formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};
