/* eslint-disable react-hooks/exhaustive-deps */
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Grid,
  Container,
  Card,
  CardContent,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { MdArrowBack, MdDelete, MdSearch } from "react-icons/md";
import { Breadcrumb, NavMenu } from "../../Components";
import PaginationService from './components/PaginationService';
import { useCallback, useEffect, useState } from "react";
import { PermissionDialog } from "./components";
import { allConcessions } from '../../services/concessoes';
import { AllConcessionsII, AllConcessionsI } from '../../interfaces/concessoes';
import getCookie from "../../utils/getCookies";

type Props = {
  history: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxIndicatorsBack: {
    width: "100%",

    justifyContent: "center",
    margin: "50px 0px",
  },
  indicatorsBack: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  cardContent: {
    marginBottom: 16,
    padding: 0,
  },
  cardData: {
    maxWidth: 375,
    width: "100%",
    backgroundColor: "#FFFFFF",
    paddingLeft: 50,
    paddingRight: 50,
  },
  input: {
    display: "none",
  },
  informationName: {
    display: "inline-block",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    padding: 4,
    fontWeight: "normal",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  iconActive: {
    display: "inline-block",
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    color: theme.palette.primary.main,
    "&:hover svg": {
      fill: theme.palette.primary.main,
    },
  },
  textInformation: {
    display: "inline-block",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    padding: 4,
    fontWeight: "normal",
    margin: '0px 2px'
  },
  tableNameInformation: {
    color: theme.palette.primary.main,
    justifyContent: "center",
  },
  textAlert: {
    color: '#333333',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 22,
    textAlign: 'center'
  },
  iconPermission: {
    width: 24,
    height: 24
  }
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgba(15, 111, 183, 0.05)",
      color: theme.palette.primary.main,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {},
    },
  })
)(TableRow);

export default function Permission({ history }: Props): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [listConcessions, setListConcessions] = useState<AllConcessionsII>();
  const [concessionSelected, setConcessionSelected] = useState<AllConcessionsI>();
  const [page, setPage] = useState(1);
  const [updatedStatus, setUpdatedStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePage = useCallback((pageProps: number) => {
    setPage(pageProps);
  }, []);

  const getConcessions = async () => {
    setLoading(true);
    try {
      const token: string | null = getCookie("gov_access_token_sso");
      const { data } = await allConcessions(token, page);

      setLoading(false);
      setListConcessions(data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    getConcessions();

    setUpdatedStatus(false);
  }, [updatedStatus]);

  useEffect(() => {
    return () => {
      setListConcessions(null);
    }
  }, []);


  const linksBreadcrumb = [

    {
      name: "Permissões",
      redirectTo: "#",
    },
  ];

  const handleOpenModalRemove = (concession: AllConcessionsI) => {
    setOpen(true);
    setConcessionSelected(concession);
  };

  const handleCloseAll = () => {
    setOpen(false);
    setConcessionSelected(null);
  }

  const handleSharedConcessions = (concessions: string[]) => {
    return (
      <StyledTableCell
        align="center"
        className={classes.tableNameInformation}
      >
        {concessions.map((concession) => (
          <Typography key={`${concession}-key`} className={classes.textInformation}>
            {concession}
          </Typography>
        ))}
      </StyledTableCell>
    )
  };


  return (
    <>
      <NavMenu />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumb links={linksBreadcrumb} />
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <IconButton
                  onClick={() =>
                    history.push("/workspace/privacidade")
                  }
                >
                  <MdArrowBack />
                </IconButton>
                <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                  Permissões
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card style={{ width: 1240, padding: 50 }}>
              <CardContent className={classes.cardContent}>
                <div>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-search-service">
                      Buscar aplicação
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-search-service"
                      type="text"
                      label="Buscar aplicação"
                      style={{ color: "#373F41" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <MdSearch className={classes.iconPermission} color="primary" />
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
              </CardContent>
              {loading && (
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress color="primary" />
                </div>
              )}
              {listConcessions && listConcessions.results.length > 0 && !loading &&
                <>
                  <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>APLICAÇÃO</StyledTableCell>
                          <StyledTableCell align="center">
                            INFORMAÇÕES ACESSADAS
                          </StyledTableCell>
                          <StyledTableCell align="center">AÇÕES</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listConcessions?.results.map((concession) => (
                          <StyledTableRow key={concession.id}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{ color: "#373F41" }}
                            >
                              {concession.aplicacao}
                            </StyledTableCell>
                            {handleSharedConcessions(concession.compartilhando)}
                            <StyledTableCell align="center">
                              <IconButton
                                className={classes.iconActive}
                                aria-label="close"
                                onClick={() => handleOpenModalRemove(concession)}
                              >
                                <MdDelete className={classes.iconPermission} />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <PaginationService
                    setPage={handlePage}
                    listItem={listConcessions}
                  />
                </>
              }

              {listConcessions && listConcessions?.results.length === 0 && !loading && (
                <Typography className={classes.textAlert}>Nenhuma aplicação encontrada!</Typography>
              )}
            </Card>
            {concessionSelected && (
              <PermissionDialog
                setUpdatedStatus={setUpdatedStatus}
                concession={concessionSelected}
                open={open}
                handleCloseAll={handleCloseAll}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
