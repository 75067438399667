import React, { useEffect, useState } from "react";
import NavMenu from "../../Components/NavMenu";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid, Container, Typography } from "@material-ui/core";
import TabsActionsFavorite from "./components/TabsActionsFavorite";
// import DigitalServices from "./components/DigitalServices";
import { UserData } from "../../interfaces/user";
import { nameSplitOne } from "../../utils/nameFormat";
import { MdDashboard } from 'react-icons/md';
import FlashAccess from "./components/FlashAccess";
import CountServices from "./components/CountServices";
import CountMessage from "./components/CountMessage";
import RecommendationServices from "./components/RecommendationServices";
import { getUserData as getUserDataRequest } from "../../services/auth";
import { StateProvider } from "./store/stateProvider";
import { SchedulerStepProvider } from "../../contexts/ShedulerStepContext";
import { initialState, reducer } from "./store/reducer";
import getCookie from "../../utils/getCookies";


type Props = {
  history: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
  },
  gridMain: {
    margin: 0
  },
  textName: {
    paddingTop: 10,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: 28,
    fontWeight: 500,
    margin: '20px 0px',
    color: '#373F41',
    '& > span': {
      textTransform: 'capitalize',
      marginLeft: 5,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 18,
    },
  },
  iconDash: {
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    width: 40,
    height: 40,
    padding: 5,
    borderRadius: 4,
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      width: 30,
      height: 30,
    },
  },
  textTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 24,
    color: "#373F41",
    paddingBottom: 12,
  },
}));

export default function Home({ history }: Props): JSX.Element {
  const classes = useStyles();
  const [userData, setUserData] = useState<UserData | null>();
  const userDataLocal: string | null = getCookie("gov_user_data");
  const token: string | null = getCookie("gov_access_token_sso");
  const tokenSiseci: string | null = getCookie(
    "gov_access_token_sso"
  );

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive);
      setUserData(data);
      document.cookie = `gov_user_data=${JSON.stringify(data)};path=/`;
    } catch (err) {
      console.log(err);

    }
  };

  useEffect(() => {
    if (userDataLocal) {
      setUserData(JSON.parse(userDataLocal));
    } else {
      if (token && tokenSiseci) {
        getUserData(token, tokenSiseci);
      }
    }
  }, [userDataLocal, token, tokenSiseci]);

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <NavMenu />
      <Container>
        <Box className={classes.container}>
          <Grid container spacing={3} className={classes.gridMain}>
            <Grid item xs={12}>
              <Typography className={classes.textName}>
                <MdDashboard className={classes.iconDash} /> Bem-vindo(a),{" "}
                {userData?.nome && (
                  <span>
                    {`${nameSplitOne(userData?.nome?.toLowerCase())}!`}
                  </span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                className={classes.textTitle}>
                Serviços mais acessados
              </Typography>
              <RecommendationServices />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                className={classes.textTitle}>
                Agendamentos
              </Typography>
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={12} md={6}>
                  <CountServices />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CountMessage />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TabsActionsFavorite />
                </Grid>
              </Grid>
            </Grid>
            {/* <DigitalServices /> */}
            <FlashAccess />
          </Grid>
        </Box>
      </Container>
    </StateProvider>
  );
}
