import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { ScheduleHist } from '../interfaces/agendamentos';
import { apisiseci } from './api';

export const checarHorarios = (
  date: Date,
  unity: string,
  serviceID: number
): Promise<AxiosResponse> => {
  const dateScheduler = format(date, 'yyyy-MM-dd');

  return apisiseci.get(
    `diario/checar_horarios/?data=${dateScheduler}&servico=${serviceID}&unidade=${unity}`
  );
};

export const agendar = (
  token: string,
  cpf: string,
  servico: number,
  unidade: string,
  setor: string,
  tipo: string,
  date: Date,
  hora: string,
  para_dependente?: boolean
): Promise<AxiosResponse> => {
  const data = format(date, 'yyyy-MM-dd');
  return apisiseci.post(
    'diario/agendar/',
    {
      cpf,
      servico,
      unidade,
      setor,
      tipo,
      data,
      hora,
      para_dependente,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const incluirDependente = (
  id_agendamento: number,
  cpf: string,
  nome: string,
  data_nascimento: Date,
  cpf_solicitante: string,
  grau_parentesco: string,
  token: string
): Promise<AxiosResponse> => {
  const data = format(data_nascimento, 'yyyy-MM-dd');
  return apisiseci.post(
    'diario/dependente/',
    {
      agendamento: id_agendamento,
      cpf,
      nome,
      data_nascimento: data,
      cpf_solicitante,
      grau_parentesco,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export interface IParamsHistory {
  status: string;
}

export const historico = (
  token: string,
  page: number,
  params?: IParamsHistory
): Promise<AxiosResponse<ScheduleHist>> =>
  apisiseci.get<ScheduleHist>(`diario/historico/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const getHistorico = (
  token: string,
  id: number
): Promise<AxiosResponse> =>
  apisiseci.get(`agendamento/historico/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const cancelar = (token: string, id: number): Promise<AxiosResponse> =>
  apisiseci.put(
    `diario/cancelar/${id}/`,
    {
      id,
      status: 'Cancelado',
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const feriados = (): Promise<AxiosResponse> =>
  apisiseci.get('cms/feriados/');

export const getAvaliacaoAgendamentoOrganico = (hash: string): Promise<AxiosResponse> =>
  apisiseci.get(`diario/avaliacao/?id=${hash}`);

export const avaliarAgendamentoOrganico = (
  hash: string,
  pendente: boolean,
  avaliacao_consumo: number,
  avaliacao_atendimento: number,
  comentario_consumo: string,
  comentario_atendimento: string
): Promise<AxiosResponse> =>
  apisiseci.put(`diario/avaliar/${hash}/`, {
    id: hash,
    pendente,
    avaliacao_consumo,
    avaliacao_atendimento,
    comentario_consumo,
    comentario_atendimento,
  });

