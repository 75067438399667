import { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  TextField,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { MdCheck, MdStar } from "react-icons/md";
import clsx from 'clsx';
import { Rating } from "@material-ui/lab";
import { evaluationType } from "../EvaluationDialog";
import { getAllOptionsEvaluation } from "../../services/ouvidoria";



interface Props {
  isLoading: boolean;
  type: evaluationType;
  ratingService: number;
  ratingAttendance: number;
  handleSetRatingService: (value: number) => void;
  handleSetRatingAttendance: (value: number) => void;
  handleSetServiceComments: (event: any) => void;
  handleSetAttendanceComments: (event: any) => void;
  handleSaved: () => void;
  handleNegativeOptions?: Function;
  negativeOptions?: string[];
  withHeader?: boolean;
  data?: any;
}

interface ListProps {
  id: string;
  motivo: string;
  avaliacao: number;
  para: string;
};

const allTypes = {
  simplifique: 'Avaliação de Simplifique',
  esic: 'Avaliação de pedido de Informação',
  solicitacao_servico: 'Avaliação de Solicitação de Serviço',
  manifestacao: 'Avaliação de Manifestação',
  formflow: 'Avaliação de Solicitação'
}

const allSteps = [
  {
    key: "SERVICO",
    title: "Como você avalia sua experiência com consumo do serviço?"
  },
  {
    key: "ATENDENTE",
    title: "Como você avalia sua experiência com o atendimento?"
  },
];

const useColorLibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    color: '#ccc',
    padding: 5,
    textAlign: 'center',
    borderRadius: '50%',
    width: 30,
    height: 30,
  },
  active: {
    border: '1px solid #0BB07B',
    backgroundColor: '#0BB07B',
    color: '#fff',
  },
  completed: {
    border: '1px solid #0BB07B',
    backgroundColor: '#0BB07B',
    color: '#fff',
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  containerInfoTitle: {
    display: 'flex',
    width: '100%',
    marginTop: 40,
  },
  titleText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 24,
    color: '#333333'
  },
  iconCheck: {
    height: 20,
    width: 20
  },
  containerCardTitle: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  containerCard: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    width: 680,
    height: 'auto',
    borderRadius: 4,
    backgroundColor: theme.palette.text.primary
  },
  infoService: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    width: '42.5rem',
    height: '3.5rem',
    borderRadius: 4,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  iconStarChecked: {
    height: 24,
    width: 24,
    margin: '1rem'
  },
  evaluationService: {
    with: '100%',
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 18,
    color: '#333333',
    textAlign: 'center',
  },
  boxRating: {
    margin: '16px 0px 24px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  buttonRating: {
    padding: 14,
    margin: '0px 8px',
    borderRadius: 4,
    border: '1px solid #E1E6EF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
  buttonRatingOut: {
    padding: 14,
    margin: '0px 8px',
    borderRadius: 4,
    border: '1px solid #E1E6EF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff'
  },
  textInfoRating: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
    color: '#706E7A',
    marginTop: 20,
  },
  description: {
    width: '100%',
    marginTop: 20,
    maxWidth: 600,
    borderRadius: 4,
    minHeight: '142px auto',
    margin: '0px 10px',
    border: '1px solid #E1E6EF',
    fontFamily: 'Roboto',
  },
  buttonNext: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    width: 116,
    height: 42,
    borderRadius: 4,
    textTransform: 'uppercase'
  },
  buttonReturn: {
    fontFamily: 'Roboto',
    width: 79,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    height: 42,
    borderRadius: 4,
    textTransform: 'uppercase',
    backgroundColor: '#fff',
    marginRight: 10,
    color: theme.palette.primary.main
  },
  boxActions: {
    padding: '2rem',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: 'auto'
  },
  input: {
    color: '#333',
  },
  iconStar: {
    height: 24,
    width: 24,
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  iconStarOut: {
    height: 24,
    width: 24,
    color: '#BDBDBD',
  },
  cardAlert: {
    width: '100%',
    minHeight: '4.25rem',
    maxWidth: '42.5rem',
    height: 'auto',
    display: 'flex',
    gap: 10,
  },
  iconAlert: {
    width: 22,
    height: 22,
    color: theme.palette.primary.main,
  },
  infoAlert: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '20.02px',
    fontWeight: 400,
    color: '#00000099'
  },
  infoAlertStrong: {
    lineHeight: '20.02px',
    marginRight: 5,
    color: '#00000099',
    fontWeight: 'bolder',
    fontFamily: 'Roboto',
  },
  styleLegend: {
    fontSize: theme.typography.pxToRem(18),
    fontFamily: 'Roboto',
    lineHeight: '20.02px',
    marginRight: 5,
    color: '#00000099',
  },
  checkbox: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: 8,
    padding: 12,
    border: `1px solid #eeeeee`,
    margin: '5px 0px',
    borderRadius: 4,
  },
  checkboxLabel: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: 5,
    color: '#333',
  },
  contentLoading: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    color: '#BDBDBD',
    gap: 8,
  },
  contentDescriptionChecked: {
    marginTop: 25,
  }
}));

const ColorLibStepIcon = (props: StepIconProps) => {
  const classes = useColorLibStepIconStyles();
  const classeStyles = useStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <MdCheck className={classeStyles.iconCheck} /> : props.icon}
    </div>
  );
};


export default function EvaluationMainContent({
  type,
  ratingService,
  ratingAttendance,
  handleSetRatingService,
  handleSetRatingAttendance,
  handleSetAttendanceComments,
  handleSetServiceComments,
  handleSaved,
  handleNegativeOptions,
  negativeOptions,
  isLoading,
  withHeader = true,
  data
}: Props): JSX.Element {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [hasOptions, setHasOptions] = useState<boolean>(false);
  const [visibleDescription, setVisibleDescription] = useState<boolean>(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);
  const [listOptions, setListOptions] = useState<ListProps[]>();

  const classes = useStyles();

  const handleNext = useCallback(() => {
    setActiveStep((oldStep) => oldStep + 1);
  }, []);

  const onGetOptions = async (ratingSelected: number) => {
    try {
      if (negativeOptions?.length > 0) {
        handleNegativeOptions([]);
      }
      setIsLoadingOptions(true);
      const { data } = await getAllOptionsEvaluation(ratingSelected);

      if (data?.length > 0) {
        setListOptions(data);
        setHasOptions(true);
        setVisibleDescription(false);
      } else {
        setVisibleDescription(true);
        setHasOptions(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingOptions(false);
    }
  }

  const checkTypeEvaluations = useMemo(() =>
    (type === 'manifestacao') || (type === 'solicitacao_servico') || (type === 'esic')
    , [type]);


  const handleAddNewNegativeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    handleNegativeOptions((prevValue: string[]) =>
      prevValue.includes(value) ? prevValue.filter((oldValue) => oldValue !== value) : [...prevValue, value]
    )
  }

  const renderEvaluationDescription = useCallback(() => {
    if (!checkTypeEvaluations || (!isLoadingOptions &&
      checkTypeEvaluations &&
      visibleDescription)) {
      return (
        <TextField
          className={classes.description}
          id="outlined-multiline-static"
          label="Deixe seu comentário (opcional)"
          multiline
          variant="outlined"
          minRows={5}
          onChange={handleSetServiceComments}
          InputProps={{
            className: classes.input,
          }}
        />
      )
    }
  }, [checkTypeEvaluations, classes.description, classes.input, handleSetServiceComments, isLoadingOptions, visibleDescription]);

  const renderEvaluationOptions = useCallback(() => {
    if (checkTypeEvaluations &&
      !isLoadingOptions &&
      hasOptions &&
      !visibleDescription &&
      listOptions) {
      return (
        <>
          {listOptions?.map((list) => (
            <Box className={classes.checkbox}>
              <input
                type="checkbox"
                id={list.motivo}
                name={list.motivo}
                value={list.motivo}
                onChange={handleAddNewNegativeOption}
              />
              <label
                htmlFor={list.motivo}
                className={classes.checkboxLabel}
              >
                {list.motivo}
              </label>
            </Box>
          ))}
          <Box className={classes.contentDescriptionChecked}>
            <input
              type="checkbox"
              name="description"
              checked={visibleDescription}
              onChange={(event) => {
                setVisibleDescription(event.target.checked);
                handleNegativeOptions([]);
              }}
            />
            <label className={classes.checkboxLabel}>
              Outro motivo?
            </label>
          </Box>
        </>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkTypeEvaluations, classes, handleNegativeOptions, hasOptions, isLoadingOptions, listOptions, visibleDescription])
  return (
    <>
      {withHeader && (
        <>
          <Box className={classes.containerInfoTitle}>
            <Typography className={classes.titleText}>
              {allTypes[type]}
            </Typography>
          </Box>
          {(data?.orgao_nome || data?.setor_nome || data?.protocolo) && (
            <Box className={classes.cardAlert}>
              <Typography component={'div'} className={classes.infoAlert}>
                <h2 className={classes.infoAlertStrong}>
                  {`${data?.orgao_nome}`}
                </h2>
                {data?.servico_titulo && (
                  <h2 className={classes.styleLegend}>
                    {data?.servico_titulo}
                  </h2>
                )}
                {!checkTypeEvaluations && (
                  <h2 className={classes.styleLegend}>
                    {data?.setor_nome}
                  </h2>
                )}
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <h2 className={classes.styleLegend} style={{ marginTop: 0, marginBottom: 0 }}>
                    Protocolo:
                  </h2>
                  {data?.protocolo}
                </span>
              </Typography>
            </Box>
          )}
          <Box className={classes.containerCardTitle}>
            <Box className={classes.infoService}>
              <MdStar
                className={classes.iconStarChecked}
              />
              Avaliação
            </Box>
          </Box>
        </>
      )}
      <Box className={classes.containerCard}>
        <Box className={classes.card}>
          {!checkTypeEvaluations && (
            <Stepper activeStep={activeStep} alternativeLabel>
              {allSteps.map((label) => (
                <Step key={label.key}>
                  <StepLabel StepIconComponent={ColorLibStepIcon}></StepLabel>
                </Step>
              ))}
            </Stepper>
          )}

          {allSteps[activeStep].key === 'SERVICO' && (
            <Box className={classes.evaluationService}>
              <FormControl variant="outlined">
                <Typography className={classes.text}>
                  {!checkTypeEvaluations ? `${allSteps[activeStep].title}` : 'Como você avalia sua experiência?'}
                </Typography>
                <Box className={classes.boxRating}>
                  <Rating
                    size="large"

                    value={ratingService}
                    name="simple-controlled"
                    icon={(
                      <Box className={classes.buttonRating}>
                        <MdStar className={classes.iconStar} />
                      </Box>
                    )}
                    emptyIcon={(
                      <Box className={classes.buttonRatingOut}>
                        <MdStar className={classes.iconStarOut} />
                      </Box>
                    )}
                    onChange={(event, newValue) => {
                      if (newValue && checkTypeEvaluations) {
                        onGetOptions(newValue);
                        handleSetRatingService(newValue);
                      } else if (newValue) {
                        handleSetRatingService(newValue);
                      }
                    }}
                  />
                  <Typography className={classes.textInfoRating}>
                    Em uma escala de 1 a 5 estrelas
                  </Typography>
                </Box>
                <Box
                  width="100%"
                  display='flex'
                  justifyContent='center'
                  flexDirection={(hasOptions && !visibleDescription) ? 'column' : 'initial'}
                >
                  {renderEvaluationDescription()}
                  {renderEvaluationOptions()}
                  {isLoadingOptions &&
                    <Box className={classes.contentLoading}>
                      <CircularProgress size={40} />
                      Aguarde um momento...
                    </Box>
                  }
                </Box>
              </FormControl>
            </Box>
          )}
          {allSteps[activeStep].key === 'ATENDENTE' && (
            <Box className={classes.evaluationService}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <Typography className={classes.text}>
                  {allSteps[activeStep].title}
                </Typography>
                <Box className={classes.boxRating}>
                  <Rating
                    size="large"
                    value={ratingAttendance}
                    name="simple-controlled"
                    icon={(
                      <Box className={classes.buttonRating}>
                        <MdStar className={classes.iconStar} />
                      </Box>
                    )}
                    emptyIcon={(
                      <Box className={classes.buttonRatingOut}>
                        <MdStar className={classes.iconStarOut} />
                      </Box>
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleSetRatingAttendance(newValue);
                      }
                    }}
                  />
                  <Typography className={classes.textInfoRating}>
                    Em uma escala de 1 a 5 estrelas
                  </Typography>
                </Box>
                <Box
                  width="100%"
                  display='flex'
                  justifyContent='center'
                >
                  <TextField
                    className={classes.description}
                    id="outlined-multiline-static"
                    label="Deixe seu comentário (opcional)"
                    multiline
                    variant="outlined"
                    minRows={5}
                    onChange={handleSetAttendanceComments}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Box>
              </FormControl>
            </Box>
          )}

          <Box className={classes.boxActions}>
            {!checkTypeEvaluations && (
              <>
                {activeStep === 1 && (
                  <Button
                    variant='text'
                    size='small'
                    className={classes.buttonReturn}
                    onClick={() => setActiveStep(activeStep - 1)}
                  >Voltar</Button>
                )}
              </>
            )}
            {!checkTypeEvaluations && (
              <>
                {activeStep < allSteps.length - 1 && (
                  <Button className={classes.buttonNext}
                    variant='contained'
                    color='primary'
                    size='small'
                    disabled={!ratingService ? true : false}
                    onClick={handleNext}
                  >
                    Próximo
                  </Button>
                )}
              </>
            )}
            {(activeStep === allSteps.length - 1) && !checkTypeEvaluations && (
              <Button className={classes.buttonNext}
                variant='contained'
                color='primary'
                size='small'
                disabled={!ratingAttendance ? true : false}
                onClick={handleSaved}
              >
                {isLoading ? (
                  <CircularProgress
                    style={{
                      width: 25,
                      height: 25,
                      color: "#fff",
                    }}
                  />
                ) : 'Enviar'}
              </Button>
            )}
            {(activeStep === 0) && checkTypeEvaluations && (
              <Button className={classes.buttonNext}
                variant='contained'
                color='primary'
                size='small'
                disabled={
                  ((hasOptions && !negativeOptions?.length && !visibleDescription) ? true : false) ||
                    (!ratingService && !hasOptions) ? true : false}
                onClick={handleSaved}
              >
                {isLoading ? (
                  <CircularProgress
                    style={{
                      width: 25,
                      height: 25,
                      color: "#fff",
                    }}
                  />
                ) : 'Enviar'}
              </Button>
            )}
          </Box>
        </Box>
      </Box >
    </>
  )
}
