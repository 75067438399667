import {makeStyles,  Theme} from '@material-ui/core/styles';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { FormControl, FormHelperText } from '@material-ui/core';
import { MdEvent } from 'react-icons/md';
import { SavedAttendenceProps, Attendence } from '../..';
import { isValid } from 'date-fns';

interface FieldDateProps {
  attendenceService: string | Attendence | undefined
  attendenceServiceUser: SavedAttendenceProps | string | undefined
  handleGetSeletedDate: (date: Date) => void
  getAttendencers: (date: Date, value: string) => Promise<void>
  unitySelected: string
  selectedDate:Date | null
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#d3d3d3',
      },
    },
    '& .MuiSelect-iconOutlined': {
      height: 24,
      width: 24,
    },
  },
  formControl: {
    width: '100%',
  },
  icon: {
    width: 24,
    height: 24,
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  input: {
    color: theme.palette.primary.main,
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
}));

export default function FieldDate({
  attendenceService,
  attendenceServiceUser,
  handleGetSeletedDate,
  getAttendencers,
  unitySelected,
  selectedDate,
  }: FieldDateProps): JSX.Element {
  const classes = useStyles();

  return (
    <FormControl
        classes={{ root: classes.root }}
        error={
          typeof attendenceService === 'string'
          || typeof attendenceServiceUser === 'string'
        }
        disabled={!unitySelected}
        variant="outlined"
        className={classes.formControl}
      >
        <MuiPickersUtilsProvider
          locale={ptBR}
          utils={DateFnsUtils}
        >
          <KeyboardDatePicker
            keyboardIcon={
              <MdEvent className={classes.icon} />
            }
            disableFuture={false}
            error={
              typeof attendenceService === 'string'
              || typeof attendenceServiceUser === 'string'
            }
            minDateMessage="Você não pode agendar em dias anteriores"
            invalidDateMessage="A data informada é inválida"
            minDate={new Date()}
            InputProps={{
              className: classes.menuItem,
              classes: {
                adornedEnd: classes.adornedEnd,
              },
            }}
            autoOk
            disabled={!unitySelected}
            variant="inline"
            inputVariant="outlined"
            label="Selecione o dia"
            placeholder="00/00/0000"
            format="dd/MM/yyyy"
            value={selectedDate || null}
            InputAdornmentProps={{
              position: 'end',
              color: '#000',
            }}
            onChange={(date) => {
              if (date && isValid(date)) {
                handleGetSeletedDate(date);
                if (unitySelected) {
                  getAttendencers(date, unitySelected);
                }
              }
            }}
          />
        </MuiPickersUtilsProvider>
      {typeof attendenceService === 'string' && (
        <FormHelperText>{attendenceService}</FormHelperText>
      )}
    </FormControl>
  );
}