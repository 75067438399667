import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Link,
} from "@material-ui/core";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: "100%",
    height: 72,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
    background: "#FFFFFF",
  },
  navContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuItem: {
    color: "#737B7D",
    width: 200,
    height: 45,
  },
  textMenuItem: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "normal",
    marginLeft: 10,
    margin: "auto",
  },
  btnProfile: {
    height: 45,
    textTransform: "capitalize",
    textAlign: "left",
    justifyContent: "space-between",
    marginLeft: 10,
    color: theme.palette.text.primary,
  },
  textBtnProfile: {
    display: "flex",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    marginLeft: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  textLabelProfile: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: theme.typography.pxToRem(16),
    textTransform: "capitalize",
  },
  icon: {
    width: 24,
    height: 24,
  },
}));

export default function NavMenu(): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Container className={classes.navContent}>
        <Link href="/">Ir para o portal</Link>
        <Box>
          {/* <Typography variant="overline" className={classes.textLabelProfile}>
            Perfil:
          </Typography> */}
          {/* <Button
            aria-describedby={id}
            variant="contained"
            color="primary"
            onClick={handleClick}
            className={classes.btnProfile}
          >
            {dataMenuBtn()}
            <MdExpandMore className={classes.icon} />
          </Button> */}
          {/* <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box>
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  handleChangeProfile("Cidadão");
                }}
              >
                <MdFace className={classes.icon} />
                <Typography className={classes.textMenuItem}>
                  Cidadão
                </Typography>
              </MenuItem>
              <Divider />

              {userData && (
                <>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                      handleChangeProfile("Servidor");
                    }}
                  >
                    <MdBusinessCenter className={classes.icon} />
                    <Typography className={classes.textMenuItem}>
                      Servidor
                    </Typography>
                  </MenuItem>
                  <Divider />
                </>
              )}

              <MenuItem
                className={classes.menuItem}
                onClick={() => handleChangeProfile("Empresa")}
              >
                <MdBusiness className={classes.icon} />
                <Typography className={classes.textMenuItem}>
                  Empresa
                </Typography>
              </MenuItem>
            </Box>
          </Popover> */}
        </Box>
      </Container>
    </Box>
  );
}
