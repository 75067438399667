import React from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
  makeStyles,
  Theme,
  Box,
} from "@material-ui/core";
import { MdCalendarToday, MdSchedule } from "react-icons/md";
import { Favorito } from "../../../../../interfaces/servico";
import {
  extractDateFormat,
  extractHourFormat,
} from "../../../../../utils/dateFormat";
import Menus from "./Menus";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    color: "#373F41",
    height: "auto",
    minHeight: 96,
  },
  textActivit: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    marginBottom: 15,
  },
  subTextActivit: {
    display: "flex",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(15),
    alignContent: "center",
  },
  iconSubText: {
    padding: 5,
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
  },
  linkToAll: {
    fontFamily: "Roboto",
    letterSpacing: 0.3,
    fontWeight: 400,
    color: theme.palette.primary.main,
    fontSize: 16,
    textDecoration: "none",
  },
}));
interface Props {
  favorites?: Array<Favorito>;
}

export default function TabFavorite({ favorites }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <List
      style={{
        padding: 0,
      }}
    >
      {favorites &&
        favorites.map(
          ({ id, servico_slug, titulo, created_at, agendavel, online }) => (
            <React.Fragment>
              <ListItem className={classes.listItem}>
                <Box>
                  <Typography className={classes.textActivit}>
                    {titulo}
                  </Typography>
                  <Typography className={classes.subTextActivit}>
                    <MdCalendarToday className={classes.iconSubText} />{" "}
                    {extractDateFormat(created_at)}
                    <MdSchedule className={classes.iconSubText} />{" "}
                    {extractHourFormat(created_at)}
                  </Typography>
                </Box>
                <ListItemSecondaryAction>
                  <Menus
                    favoriteId={id}
                    agendavel={agendavel}
                    online={online}
                    service_slug={servico_slug}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          )
        )}
      <ListItem>
        <Link to="/workspace/favoritos" className={classes.linkToAll}>
          Ver todos
        </Link>
      </ListItem>
    </List>
  );
}
