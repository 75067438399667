import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { MdClose, MdDeleteForever, MdForwardToInbox } from "react-icons/md";
import { makeStyles } from "@material-ui/styles";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import { useToastValue } from "../../../../contexts/toastMessage";
import { AllTypeEsic } from "../../../../services/ouvidoria";
import {
  resourceAttachments,
  responseResourceData,
} from "../../../../services/esic";

export const acceptedFiles = [
  "audio/ogg",
  "audio/opus",
  "audio/mpeg",
  "audio/webm",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.oasis.opendocument.text",
  "application/pdf",
  "application/ogg",
  "image/png",
  "image/jpeg",
  "image/pjpeg",
  "audio/webm",
  "text/csv",
  "video/webm",
  "video/mp4",
  "video/ogg",
];
interface Props {
  open: boolean;
  handleClose: () => void;
  onReload: ({ page }) => void;
  esicId: number;
}

interface TypesProps {
  id: number;
  titulo: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#d3d3d3",
      },
    },
    "& .MuiSelect-iconOutlined": {
      height: 24,
      width: 24,
    },
  },
  header: {
    background: theme.palette.primary.main,
  },
  textHeader: {
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    width: "90%",
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: "16px",
    fontStyle: "Molish",
    color: "#373F41",
    marginTop: 10,
    fontWeight: 700,
    lineHeight: "20px",
  },
  buttonClose: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: "#fff",
  },
  textSchedule: {
    color: "#373F41",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    marginTop: 30,
  },
  textSubtitleSchedule: {
    color: "#373F41",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    marginTop: 20,
  },
  iconUpload: {
    color: "#9E9E9E",
  },
  rootUpload: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 140,
    flexDirection: "column",
  },
  adornedEnd: {
    paddingRight: 0,
  },
  inputSearch: {
    maxWidth: 582,
    width: "100%",
    height: 48,
    border: `1px solid ${theme.palette.primary.main}`,
    outline: "none",
    paddingLeft: 16,
    fontFamily: "Roboto",
    fontSize: 14,
    borderRadius: 0,
  },
  optionsList: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #DADCE0",
    maxWidth: "540px",
    height: "48px",
    borderLeftWidth: "2px",
    borderLeftColor: `${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionsListText: {
    fontSize: "14px",
    color: "#373F41",
    fontWeight: 600,
    fontFamily: "Roboto",
  },
  boxnNextOptions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
  },
  serviceSelectedTextTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontFamily: "Roboto",
    textTransform: "uppercase",
    fontSize: "14px",
    lineHeight: "18px",
  },
  serviceSelectedText: {
    color: "#373F41",
    fontWeight: 600,
    fontFamily: "Roboto",
    fontSize: "16px",
    marginTop: "16px",
  },
  serviceSelectedSubText: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "16px",
  },
  buttonAddArchives: {
    backgroundColor: `${theme.palette.primary.main}`,
    height: "40px",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#0A5D9C",
    },
  },
  boxConfirmScheduling: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
  },
  buttonConfirmScheduling: {
    backgroundColor: "rgba(60, 210, 120, 0.7)",
    borderRadius: 0,
    width: "300px",
    "&:hover": {
      backgroundColor: "#3CD278",
    },
  },
  multilineColor: {
    color: "#000",
  },
  inputCheckbox: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(14),
    margin: "32px 0px",
  },
  inputCheckboxText: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontstyle: "normal",
    fontWeight: 400,
    textAlign: "left",
    color: "rgba(55, 63, 65, 0.6)",
  },
  colorInputText: {
    color: theme.palette.primary.main,
  },
  text: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 16,
    color: "rgba(55, 63, 65, 0.6)",
  },
  infoText: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 400,
    color: "rgba(55, 63, 65, 0.6)",
    margin: "8px 0px",
  },
  box: {
    borderRadius: 4,
    marginTop: 20,
    border: "none",
  },
  titleBox: {
    fontSize: theme.typography.pxToRem(14),
    color: "#333333",
    fontWeight: 500,
    textTransform: "uppercase",
    fontFamily: "Roboto",
    margin: "10px 0px 30px 20px",
  },
  boxInfoConcluded: {
    margin: "20px 32px",
    borderRadius: 4,
    padding: "16px 24px",
    border: "1px solid #0BB07B",
    backgroundColor: "rgba(11, 176, 123, 0.05)",
    minHeight: 109,
  },
  infoConcluded: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textInfoUnity: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    color: "#706E7A",
    marginTop: 16,
    marginBottom: 8,
  },
  textInfoDateTime: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    color: "#706E7A",
    marginBottom: 2,
    marginTop: 5,
  },
  buttonPrimary: {
    margin: "10px 0px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    width: 186,
    height: 44,
    "&:hover": {
      backgroundColor: "rgba(15, 111, 183, 0.7)",
    },
  },
  textButtonPrimary: {
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    textTransform: "uppercase",
  },
  buttonOutlined: {
    width: 186,
    height: 44,
    padding: "14px 16px",
    marginLeft: "0px !important",
  },
  textButtonOutlined: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    fontWeight: 700,
    textTransform: "uppercase",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  textSubtitleScheduleBox: {
    width: 165,
    height: 30,
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(225, 230, 239, 0.3)",
    color: "#333333",
  },
  textError: {
    color: theme.palette.error.dark,
    fontSize: 12,
    fontWeight: 700,
    marginLeft: 10,
    fontFamily: "Roboto",
  },
  iconArrow: {
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
  },
  icon: {
    width: 24,
    height: 24,
  },
  iconToInbox: {
    width: 24,
    height: 24,
    marginRight: 15,
  },
  iconLarge: {
    width: 80,
    height: 80,
  },
  errorTextStyle: {
    color: "#333333",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Roboto",
  },
  errorUploadCard: {
    width: "100%",
    border: `1px solid #F03D3D`,
    boxShadow: "0px 8px 16px rgba(11, 31, 77, 0.04)",
    backgroundColor: "#fff",
    display: "flex",
    borderRadius: 4,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px",
    marginBottom: 2,
    marginTop: 5,
  },
  main: {
    width: "100%",
  },
  cardAlert: {
    width: "100%",
    minHeight: "4.25rem",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    gap: 10,
  },
  iconAlert: {
    width: 22,
    height: 22,
    color: "#FFA300",
  },
  infoAlertStrong: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "20.02px",
    marginRight: 5,
    color: "#00000099",
    fontWeight: 700,
  },
  infoAlert: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "20.02px",
    fontWeight: 400,
    color: "#00000099",
  },
  styleLegend: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "20.02px",
    marginRight: 5,
    color: "#00000099",
    fontWeight: 400,
  },
  description: {
    width: "100%",
    maxWidth: 600,
    borderRadius: 4,
    minHeight: "142px auto",
    border: "1px solid #E1E6EF",
    fontFamily: "Roboto",
  },
  input: {
    color: "#333",
  },
  buttonSend: {
    textTransform: "uppercase",
    color: "#fff",
    borderRadius: 4,
    marginTop: 20,
    marginBottom: 20,
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(15),
  },
  card: {
    width: "100%",
    color: "#373F41",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    marginBottom: 2,
    border: "1px solid #EAECF0",
    boxShadow: "0px 8px 16px rgba(11, 31, 77, 0.04)",
    marginTop: 5,
    borderRadius: 6,
    fontSize: 16,
    fontWeight: 400,
    height: 77,
  },
  containerDialogActions: {
    display: "flex",
    margin: "0px 32px",
  },
  boxDialogActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  circularProgress: {
    width: 25,
    height: 25,
  },
}));

export default function ModalResponse({
  open,
  handleClose,
  onReload,
  esicId,
}: Props): JSX.Element {
  const { setToast } = useToastValue();
  const [description, setDescription] = useState<string>("");
  const [openUploads, setOpenUploads] = useState<boolean>(false);
  const [messageErrorUpload, setMessageErrorUpload] = useState<
    undefined | string
  >(undefined);
  const [files, setFiles] = useState<FileObject[]>([]);
  const [isErrorInUpload, setIsErrorInUpload] = useState(false);
  const [hasFile, setHasFile] = useState<boolean>(false);
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);
  const [types, setTypes] = useState<TypesProps[]>();
  const [typeSelected, setTypeSelected] = useState<number | null>();
  const [isLoadingTypes, setIsLoadingTypes] = useState<boolean>(false);

  const handleUploadsAlert = useCallback((variant: string) => {
    if (variant === "error") {
      setIsErrorInUpload(true);
    } else {
      setIsErrorInUpload(false);
    }
  }, []);

  const removeFile = useCallback(
    (file: FileObject) => {
      const fileFilter = files.filter(
        (fileFiltering) => fileFiltering.data !== file.data
      );
      setFiles(fileFilter);
    },
    [files]
  );

  const handleCheckHasAnexos = () => {
    setHasFile(!hasFile);
  };

  const getAllTypes = async () => {
    try {
      setIsLoadingTypes(true);
      const { data } = await AllTypeEsic();

      setTypes(data?.results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingTypes(false);
    }
  };

  const handleSaveFile = async (files: FileObject[], id: string) => {
    try {
      setLoadingFiles(true);
      for (const fileUpload of files) {
        await resourceAttachments(fileUpload?.file?.name, fileUpload.file, id);
      }
      handleClose();
      setToast({
        visible: true,
        message: "Recurso enviado com sucesso!",
        type: "success",
      });
      onReload({ page: 1 });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingFiles(false);
    }
  };

  const handleSubmit = async () => {
    if (!esicId || !description || !typeSelected) return;
    try {
      setLoading(true);
      const { data } = await responseResourceData(
        esicId,
        description,
        typeSelected
      );

      if (files?.length > 0 && data && !hasFile) {
        handleSaveFile(files, data.id);
      } else {
        handleClose();
        setToast({
          visible: true,
          message: "Recurso enviado com sucesso!",
          type: "success",
        });
        onReload({ page: 1 });
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        setToast({
          visible: true,
          message: error?.response?.data?.detail,
          type: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const isDisabledButton = useMemo(
    () =>
      !hasFile ? !description || !files?.length : !description || !typeSelected,
    [description, files?.length, hasFile, typeSelected]
  );

  useEffect(() => {
    getAllTypes();
  }, []);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <MdClose className={classes.icon} />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeader}>
          <MdForwardToInbox className={classes.iconToInbox} />
          Enviar Recurso
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.main}>
          <Box className={classes.cardAlert}>
            {/* <InfoOutlined className={classes.iconAlert} />

            <Typography component={'div'} className={classes.infoAlert}>
              <div className={classes.infoAlertStrong}>
                Orientações:
                <p className={classes.styleLegend} dangerouslySetInnerHTML={{ __html: sanitizeHtml(legend) || '' }} />
              </div>
            </Typography> */}
            {isLoadingTypes ? (
              <CircularProgress />
            ) : (
              <FormControl
                classes={{ root: classes.root }}
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Selecione o tipo
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={typeSelected}
                  onChange={(event): void => {
                    setTypeSelected(event.target.value as any);
                  }}
                  label="Selecione o tipo"
                  classes={{
                    select: classes.menuItem,
                  }}
                  disabled={types?.length === 0}
                >
                  {types?.length > 0 &&
                    types.map((type) => (
                      <MenuItem
                        key={type.id}
                        className={classes.menuItem}
                        value={type.id}
                      >
                        {type.titulo}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Box>

          <TextField
            className={classes.description}
            id="outlined-multiline-static"
            label="Descreva sua justificativa"
            multiline
            variant="outlined"
            rows={5}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            InputProps={{
              className: classes.input,
            }}
          />

          <Box className={classes.box}>
            <Button
              onClick={(): void => setOpenUploads(true)}
              style={{
                borderRadius: 4,
                display: openUploads ? "none" : "block",
                textTransform: "uppercase",
              }}
              variant="outlined"
              color="primary"
            >
              Adicionar arquivos
            </Button>
            <div
              hidden={!openUploads}
              style={{
                color: "#9E9E9E",
                width: "100%",
              }}
            >
              <DropzoneAreaBase
                onAdd={(filesUpload) => {
                  setFiles(filesUpload);
                  setMessageErrorUpload(undefined);
                }}
                fileObjects={files}
                acceptedFiles={["image/jpeg", "image/png", ".pdf"]}
                showPreviewsInDropzone={false}
                dropzoneText="Faça upload ou solte arquivos aqui"
                showFileNames={false}
                showFileNamesInPreview={false}
                dropzoneParagraphClass={classes.text}
                showAlerts={false}
                maxFileSize={31457280}
                filesLimit={30}
                classes={{
                  icon: classes.iconUpload,
                  root: classes.rootUpload,
                }}
                onAlert={(_, variant) => handleUploadsAlert(variant)}
              />
              <Typography className={classes.infoText}>
                Tamanho máximo: 30MB
              </Typography>
              {files.map((file, index) => (
                <Card className={classes.card} key={index}>
                  {file?.file?.name}
                  <IconButton onClick={(): void => removeFile(file)}>
                    <MdClose
                      className={classes.icon}
                      style={{ color: "#333" }}
                    />
                  </IconButton>
                </Card>
              ))}
              {isErrorInUpload && (
                <Card className={classes.errorUploadCard}>
                  <Typography className={classes.errorTextStyle}>
                    Arquivo inválido, ou excede o limite de tamanho.
                  </Typography>
                  <IconButton onClick={(): void => setIsErrorInUpload(false)}>
                    <MdDeleteForever
                      style={{
                        color: "#F03D3D",
                        width: 24,
                        height: 24,
                      }}
                    />
                  </IconButton>
                </Card>
              )}
            </div>
            {typeof messageErrorUpload === "string" ? (
              <Typography className={classes.textError}>
                {messageErrorUpload}
              </Typography>
            ) : (
              ""
            )}
          </Box>
          <div id="input-check">
            <FormControlLabel
              className={classes.inputCheckbox}
              control={
                <Checkbox
                  checked={hasFile}
                  onChange={handleCheckHasAnexos}
                  name="hasAnexo"
                  color="primary"
                />
              }
              label="Marque caso não seja necessário anexar arquivos."
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions className={classes.containerDialogActions}>
        <Box className={classes.boxDialogActions}>
          {loading || loadingFiles ? (
            <CircularProgress className={classes.circularProgress} />
          ) : (
            <Button
              className={classes.buttonSend}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isDisabledButton}
            >
              Enviar
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
