import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { useToastValue } from '../../../../contexts/toastMessage';
import { removeIncompletForm } from '../../../../services/form-flow';


export interface SimpleDialogProps {
  open: boolean;
  handleClose: () => void;
  form: any
}

const useStyles = makeStyles((theme: Theme) => ({
  boxHeader: {
    backgroundColor: '#E53535',
  },
  textName: {
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  btnConfirm: {
    backgroundColor: '#3CD278',
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '0',
    padding: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#00a04b',
    },
  },
  btnClose: {
    backgroundColor: '#E53535',
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '0',
    padding: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#808e95',
    },
  },
}));


export default function CancelFormDialog({
  open,
  handleClose,
  form
}: SimpleDialogProps) {
  const { setToast } = useToastValue();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const handeRemoveForm = async (protocolo: number) => {
    try {
      setLoading(true);
      await removeIncompletForm(protocolo);
      setToast({
        visible: true,
        message: 'Formulário removido!',
        type: 'success'
      });
      handleClose()
    } catch (error) {
      setToast({
        visible: true,
        message: 'Não foi possível remover o formulário',
        type: 'error'
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }

  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth

    >
      <DialogTitle
        id="alert-dialog-title"
        className={classes.boxHeader}

      >
        <Typography className={classes.textName}>Deseja encerrar a solicitação?</Typography>
      </DialogTitle>
      <DialogContent style={{ borderRadius: '0' }}>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <Box
            marginBottom="60px"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnConfirm}
              onClick={() => { handeRemoveForm(form.protocolo) }}
            >
              Sim
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              className={classes.btnClose}
            >
              Não
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog >
  );
}
