import { createTheme } from "@material-ui/core/styles";
import getCookie from "../utils/getCookies";

const colorTheme = (): string => {
  if (getCookie("perfil")) {
    switch (getCookie("perfil")) {
      case "Cidadão":
        return "#053396";

      case "Servidor":
        return "#055052";

      case "Empresa":
        return "#512D6D";

      default:
        return "#053396";
    }
  }
  return "#053396";
};

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: colorTheme(),
      dark: colorTheme(),
    },
    secondary: {
      main: "#055052",
    },
    text: {
      primary: "#fff",
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        "&$active": {
          color: "#94C120",
        },
        "&$completed": {
          color: "#94C120",
        },
      },
    },
    MuiStepLabel: {
      label: {
        "&$active": {
          color: "#94C120",
        },
        "&$completed": {
          color: "#94C120",
        },
      },
    },
    MuiButton: {
      outlined: {
        borderRadius: 0,
        padding: "8px 8px",
        textTransform: "none",
      },
    },
  },
  typography: {
    body1: {
      fontFamily: "Roboto",
      textTransform: "none",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      textAlign: "center",
      textShadow: "0px 6px 14px rgba(24, 39, 75, 0.12)",
    },
    h3: {
      fontFamily: "Roboto",
    },
    h4: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      color: "#373F41",
    },
    h5: {
      fontFamily: "Roboto",
    },
    h6: {
      fontFamily: "Roboto",
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    subtitle1: {
      color: "#d3d3d3",
    },
  },
});

export default theme;
