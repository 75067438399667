import React, { useState, Fragment, useCallback } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Grid,
  Container,
  CircularProgress,
  Badge,
  Card,
} from "@material-ui/core";
import {
  MdToday,
  MdSchedule,
  MdSupervisorAccount,
  MdStar,
} from "react-icons/md";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { PrintDialog } from "../../../SchedulingRequest/components";
import { HistoryI } from "../../../../interfaces/agendamentos";
import dateFormatToBR from "../../../../utils/dateFormat";
import HistoryDialog from "../../../SchedulingRequest/components/HistoryDialog";
import DependentDataDialog from "../../../../Components/DependentDataDialog";
import { RescheduleDialog, CancelDialog } from "../../../AttendenceRequest/components";
import { Star } from '@material-ui/icons';
import { IMyAttendences, IRespost } from '../../../../interfaces/atendimento';




const useStyles = makeStyles((theme: Theme) => ({
  boxIndicatorsBack: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  indicatorsBack: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  boxTermsList: {
    backgroundColor: "#fff",
    padding: 30,
    borderRadius: 4,
    margin: "20px 0px",
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      gap: 10,
      padding: '26px 24px',
    },
  },
  boxInformationTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      gap: 24,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Roboto",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  subTitle: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    margin: "16px 0px 12px 0px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  description: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    color: "#373F41",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  status: {
    fontSize: 14,
    fontFamily: "Roboto",
    fontWeight: 500,
    textTransform: "capitalize",
    padding: "5px 16px",
    borderRadius: 25,
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      alignSelf: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  boxInfo: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#373F41",
    marginTop: "46px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: 'center',
      gap: 18,
      marginTop: 25,
    },
  },
  textInfo: {
    display: "flex",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    fontStyle: "normal",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 26,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: 7,
    width: 20,
    height: 20,
    [theme.breakpoints.down("xs")]: {
      width: 16,
      height: 16,
    },
  },
  star: {
    color: theme.palette.primary.main,
    marginRight: 5,
    width: 20,
    height: 20,
    [theme.breakpoints.down("xs")]: {
      width: 16,
      height: 16,
    },
  },
  infoNumberGuiche: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  infoTextGuiche: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    textTransform: "uppercase",
  },
  textButtonGuiche: {
    textTransform: "uppercase",
    fontFamily: "Roboto",
    borderRadius: 4,
    padding: "12px 24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  actionsButtonText: {
    textTransform: "uppercase",
    fontFamily: "Roboto",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  buttonCancel: {
    color: "#E53535",
    borderColor: "rgba(229,53,53, 0.7)",
    borderRadius: 4,
    padding: "12px 24px",
    "&:hover": {
      backgroundColor: "rgba(229,53,53, 0.1)",
      borderColor: "#E53535",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  notificationCounter: {
    position: "absolute",
    top: -10,
    right: -10,
    padding: "3px 9px",
    borderRadius: "50%",
    backgroundColor: "#F5365C",
    color: theme.palette.text.primary,
    fontSize: 13,
  },
  boxButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 16,
      marginTop: 20,
    },

  },

  dependentsButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    fontStyle: "normal",
    marginRight: 26,
    borderRadius: '6px',
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  containerEvaluation: {
    display: 'flex',
    gap: 40,
    paddingTop: '50px',
  },
  boxStar: {
    display: 'flex',
    gap: 10,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 24,
    alignItems: 'center',
  },
  starIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20)
  },
  starCountText: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    textAlign: 'left',
    color: '#373F41',
  },
  titleInfo: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    margin: '24px 0px',
    color: "#373F41",
},
}));

export default function ScheduleInfo({ list, getMyAttendences, request, avaliacaoPendente }): JSX.Element {
  const classes = useStyles();
  const [scheduleSelected, setScheduleSelected] = useState<HistoryI>();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDependentDialog, setOpenDependentDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [openRescheduleDialog, setOpenRescheduleDialog] = useState(false);
  const [completedReschedule, setCompletedReschedule] = useState(false)
  

  const history = useHistory();

  const handleDependentDataDialog = (schedule: HistoryI) => {
    setOpenDependentDialog(true)
    setScheduleSelected(schedule);
  }

  const handleCloseDependentDataDialog = () => {
    setOpenDependentDialog(false)
    setScheduleSelected(undefined);
  }

  const handlePrintDialog = (schedule: HistoryI) => {
    setOpenDialog(true);
    setScheduleSelected(schedule);
  };

  const handleCancelDialog = (schedule: HistoryI) => {
    setOpenCancelDialog(true);
    setScheduleSelected(schedule);
  };

  const handleRescheduleDialog = (schedule: HistoryI) => {
    setOpenRescheduleDialog(true);
    setScheduleSelected(schedule);
    setCompletedReschedule(false)
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setScheduleSelected(undefined);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setScheduleSelected(undefined);
  };

  const handleHistoryDialog = (schedule: HistoryI) => {
    setOpenHistoryDialog(true);
    setScheduleSelected(schedule);
  };

  const handleCloseHistoryDialog = () => {
    setOpenHistoryDialog(false);
    setScheduleSelected(undefined);
  };

  const handleCloseRescheduleDialog = () => {
    setOpenRescheduleDialog(false);
    setScheduleSelected(undefined);
  };

  const handleCompletedScheduled = () => {
    setCompletedReschedule(true)
  }

    const contentEvaluation = useCallback((avaliacaoPendente) => {
    return (
      <Fragment>
        {!!avaliacaoPendente ? (
          <>
            {!avaliacaoPendente?.[0]?.pendente ? (
              <>
                <Box className={classes.containerEvaluation}>
                  <Box>
                    <Typography className={classes.titleInfo}>
                      Avaliação do Serviço
                    </Typography>
                    <span className={classes.boxStar}>
                      <Star className={classes.starIcon} />
                      <Typography className={classes.starCountText}>
                        {avaliacaoPendente?.[0]?.avaliacao_consumo || '0'} estrelas
                      </Typography>
                    </span>
                  </Box>
                  <Box>
                    <Typography className={classes.titleInfo}>
                      Avaliação do Atendimento
                    </Typography>
                    <span className={classes.boxStar}>
                      <Star className={classes.starIcon} />
                      <Typography className={classes.starCountText}>
                        {avaliacaoPendente?.[0]?.avaliacao_atendimento || '0'} estrelas
                      </Typography>
                    </span>
                  </Box>
                </Box>
              </>
            ) : (
              <Typography className={classes.titleInfo}>
                Avaliação pendente
              </Typography>
            )}
          </>
        ) : (
          <Typography className={classes.titleInfo}>
            Avaliação indisponível
          </Typography>
        )}
      </Fragment>
    )
  }, [classes]);

  const countNotReadResp = (respostas: IRespost[], user_name: string) => {
    let count = 0;
    respostas.forEach(({ lido, user }) => {
      if (!lido && user.username !== user_name) {
        count += 1;
      }
    });
    return count;
  };

  const handleToAttendence = (id: string) => {
    history.push({
      pathname: `/workspace/atendimento_online/${id}`,
      state: { ticketNumber: request.protocolo }
    });
  };

  return (
    <>
      <Card>

        {!list ? (
          <Box
            marginBottom="60px"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (

          <>
            {list?.results?.map((schedule) => (
              <Fragment key={schedule.id}>
                <Container>
                  <Grid
                    key={schedule.id}
                    container
                    alignItems="flex-end"
                    justifyContent="space-between"
                    md={12}
                    className={classes.boxTermsList}
                  >
                    <Box className={classes.boxInformationTitle}>
                      <Typography className={classes.title}>
                        {schedule.unidade.nome}
                        {/* (Guichê {schedule?.guiche?.ordem}) */}
                      </Typography>
                      {schedule.status === "Cancelado" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#F44336",
                            border: "1px solid rgba(248, 50, 69, 0.08)",
                            background: "rgba(248, 50, 69, 0.08)",
                          }}
                        >
                          Cancelado
                        </Typography>
                      )}
                      {schedule.status === "Finalizado" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#43A047",
                            border: "1px solid rgba(67, 160, 71, 0.08)",
                            background: "rgba(67, 160, 71, 0.08)",
                          }}
                        >
                          Finalizado
                        </Typography>
                      )}
                      {schedule.status === "Aguardando" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#0F6FB7",
                            border: "1px solid rgba(15, 111, 183, 0.08)",
                            background: "rgba(15, 111, 183, 0.08)",
                          }}
                        >
                          Aguardando
                        </Typography>
                      )}
                      {schedule.status === "Reagendou" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#0F6FB7",
                            border: "1px solid rgba(15, 111, 183, 0.08)",
                            background: "rgba(15, 111, 183, 0.08)",
                          }}
                        >
                          Reagendou
                        </Typography>
                      )}
                      {schedule.status === "Compareceu" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#0F6FB7",
                            border: "1px solid rgba(15, 111, 183, 0.08)",
                            background: "rgba(15, 111, 183, 0.08)",
                          }}
                        >
                          Compareceu
                        </Typography>
                      )}
                      {schedule.status === "Não Compareceu" && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: "#851d15",
                            border: "1px solid rgba(141, 18, 30, 0.08)",
                            background: "rgba(141, 18, 30, 0.08)",
                          }}
                        >
                          Não Compareceu
                        </Typography>
                      )}
                    </Box>
                    <Grid item xs={12} sm={12} md={7}>
                      <Typography className={classes.subTitle}>
                        {schedule.servico.titulo}
                      </Typography>
                      <Typography
                        className={classes.description}
                      >{`Órgão: ${schedule.orgao_responsavel}`}</Typography>
                      {schedule?.protocolo && (
                        <Typography
                          className={classes.subTitle}
                        >{`Protocolo: ${schedule.protocolo}`}</Typography>
                      )}
                      <Box display="flex" className={classes.boxInfo}>
                        <Button
                          color="primary"
                          variant="outlined"
                          disabled={!schedule.dependentes}
                          onClick={() => handleDependentDataDialog(schedule)}
                          className={classes.dependentsButton}>
                          {schedule.dependentes ? (
                            <>
                              <MdSupervisorAccount className={classes.icon} />
                              <Typography className={classes.textInfo}>
                                Dependentes
                              </Typography>
                            </>
                          ) : (
                            <>
                              <MdSupervisorAccount className={classes.icon} style={{ color: '#e0e0e0' }} />
                              <Typography className={classes.textInfo}>
                                Sem dependentes
                              </Typography>
                            </>
                          )}
                        </Button>
                        <Typography className={classes.textInfo}>
                          <MdToday className={classes.icon} />
                          {` ${dateFormatToBR(schedule.data)}`}
                        </Typography>
                        <Typography className={classes.textInfo}>
                          <MdSchedule className={classes.icon} />
                          {` ${schedule.hora.slice(0, 5)}`}
                        </Typography>
                        {schedule?.avaliacao_atendimento?.pendente === false && (
                          <Typography className={classes.textInfo}>
                            <MdStar className={classes.star} />
                            {`${schedule.avaliacao_atendimento?.avaliacao_consumo} estrelas`}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <Box className={classes.boxButton}>
                        {schedule.status === "Aguardando" ? (
                          <>
                            <Button
                              className={classes.textButtonGuiche}
                              color="primary"
                              variant="outlined"
                              onClick={() => handlePrintDialog(schedule)}
                            >
                              <Typography className={classes.actionsButtonText}>
                                Imprimir
                              </Typography>
                            </Button>
                            <Button
                              className={classes.textButtonGuiche}
                              color="primary"
                              variant="outlined"
                              onClick={() => handleRescheduleDialog(schedule)}
                            >
                              <Typography className={classes.actionsButtonText}>
                                Reagendar
                              </Typography>
                            </Button>
                            <Button
                              className={classes.buttonCancel}
                              variant="outlined"
                              color="inherit"
                              onClick={() => handleCancelDialog(schedule)}
                            >
                              <Typography className={classes.actionsButtonText}>
                                Cancelar
                              </Typography>
                            </Button>
                          </>
                        ) : (
                          <>
                            {schedule.tipo === 'Online' && (<>
                              {countNotReadResp(
                                schedule.respostas,
                                schedule.user.username
                              ) ? (
                                <Badge
                                  badgeContent={countNotReadResp(
                                    schedule.respostas,
                                    schedule.user.username
                                  )}
                                  color="error"
                                >
                                  <Button
                                    className={classes.textButtonGuiche}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() =>
                                      handleToAttendence(schedule.id)
                                    }
                                  >
                                    <Typography className={classes.actionsButtonText}>
                                      Mensagem
                                    </Typography>
                                  </Button>
                                </Badge>
                              ) : (
                                <>
                                  {schedule.status !== ("Cancelado") && (
                                    <Button
                                      className={classes.textButtonGuiche}
                                      variant="outlined"
                                      color="primary"
                                      onClick={() =>
                                        handleToAttendence(schedule.id)
                                      }
                                    >
                                      <Typography className={classes.actionsButtonText}>
                                        Ver conversa
                                      </Typography>
                                    </Button>
                                  )}
                                </>
                              )}
                            </>)}
                          </>
                        )}

                        {schedule.status === "Finalizado" && (
                          <Button
                            className={classes.textButtonGuiche}
                            color="primary"
                            variant="outlined"
                            onClick={() => handleHistoryDialog(schedule)}
                          >
                            <Typography className={classes.actionsButtonText}>
                              Ver histórico
                            </Typography>
                          </Button>
                        )}
                        {schedule.status !==
                          ("Cancelado" && "Finalizado" && "Aguardando") && (
                            <>
                              <Button
                                className={classes.textButtonGuiche}
                                color="primary"
                                variant="outlined"
                                onClick={() => handlePrintDialog(schedule)}
                              >
                                <Typography className={classes.actionsButtonText}>
                                  Imprimir
                                </Typography>
                              </Button>
                            </>
                          )}
                      </Box>
                    </Grid>
                    {contentEvaluation(avaliacaoPendente)}
                  </Grid>
                </Container>
              </Fragment>
            ))}



            {scheduleSelected?.dependentes && (
              <DependentDataDialog
                open={openDependentDialog}
                schedule={scheduleSelected}
                handleClose={handleCloseDependentDataDialog}
              />
            )}
            {scheduleSelected && (
              <PrintDialog
                open={openDialog}
                schedule={scheduleSelected}
                handleClose={handleCloseDialog}
              />
            )}
            {scheduleSelected && (
              <CancelDialog
                open={openCancelDialog}
                attendence={scheduleSelected}
                handleClose={handleCloseCancelDialog}
                onReload={getMyAttendences}
              />
            )}
            {scheduleSelected && (
              <HistoryDialog
                open={openHistoryDialog}
                schedule={scheduleSelected}
                handleClose={handleCloseHistoryDialog}
              />
            )}
            {scheduleSelected && (
              <RescheduleDialog
                open={openRescheduleDialog}
                attendence={scheduleSelected}
                handleClose={handleCloseRescheduleDialog}
                handleCompletedScheduled={handleCompletedScheduled}
              />
            )}
          </>
        )}
      </Card>

    </>
  );
}
