import React, { useEffect } from "react";
import { CircularProgress, FormHelperText, Link } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { sendFile } from "../../../../services/form-flow";
import { MdDelete } from "react-icons/md";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#264259",
      },
      display: "block",
    },
  },
  input: {
    color: theme.palette.primary.main,
    border: "1px solid #264259",
    borderRadius: "4px",
    fontSize: "16px",
    padding: "2px",
    width: "100%",
  },
  label: {
    display: "block",
    marginBottom: theme.spacing(1),
  },
  boxFile: {
    display: "flex",
    border: "1px solid #e0e0e0",
    justifyContent: "space-between",
    padding: "10px",
    borderRadius: "10px",
  },
  button: {
    border: "none",
    background: "transparent",
    color: "#264259",
  },
  boxButton: {
    borderLeft: "1px solid #e0e0e0",
    paddingLeft: "10px",
  },
}));

type Props = {
  required?: boolean;
  confidencial?: boolean;
  serviceSlug?: string;
  id: string;
  label: string;
  value:
    | {
        url: string;
        nome: string;
        id: string;
      }
    | any;
  error?: boolean;
  onChange: (
    value:
      | ""
      | {
          url: string;
          nome: string;
          id: string;
        }
  ) => void;
  helperText?: string;
  disabled?: boolean;
  type?: string | null;
  arquiveType?: string | null;
};

export default function Arquivo(props: Props): JSX.Element {
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<File | null>(null);
  const [name, setName] = React.useState("");
  const [errorTypeFile, setErrorTypeFile] = React.useState<boolean>(false);

  const setBooleanValueForFunction = async (afile: File, value: boolean) => {
    const { data } = await sendFile(
      afile,
      value,
      props?.confidencial,
      props?.serviceSlug
    );

    if (data) {
      const dataSend = {
        url: data.arquivo,
        nome: afile.name,
        id: data.id,
      };

      setName(afile.name);
      props.onChange(dataSend);
      setFile(afile);
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true);
      setErrorTypeFile(false);

      const afile = event.target.files?.[0];

      if (props.type === "Arquivo" && props.arquiveType !== "") {
        if (
          props.arquiveType.includes(afile.type) ||
          props.arquiveType === null
        ) {
          setBooleanValueForFunction(afile, false);
        } else {
          throw Error;
        }
      } else {
        setBooleanValueForFunction(afile, true);
      }
    } catch (error) {
      console.error("ERROR: ", error);
      setErrorTypeFile(true);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = () => {
    setLoading(true);
    setFile(null);
    setLoading(false);
    props.onChange("");
  };

  useEffect(() => {
    if (!(Object.entries(props?.value).length === 0)) {
      const nomeArquivo =
        typeof props.value === "string" &&
        (props.value as any).substring(
          (props.value as any).lastIndexOf("/") + 1
        );
      setName(nomeArquivo || "");
      setFile(props.value as any);
    }
  }, [props?.value]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <label htmlFor={props.id} className={classes.label}>
            {props.label.replace(/(<([^>]+)>)/gi, "")}
          </label>
          {file ? (
            <div className={classes.boxFile}>
              <div>
                {props.value ? (
                  <Link
                    href={props?.value?.url || props?.value}
                    target="_blank"
                  >
                    {name ? name : props.value?.nome}
                  </Link>
                ) : (
                  <span>{file?.name} </span>
                )}
              </div>
              <div className={classes.boxButton}>
                <button className={classes.button} onClick={handleRemove}>
                  <MdDelete size={16} />
                </button>
              </div>
            </div>
          ) : (
            <>
              {props.disabled && props.value ? (
                <div className={classes.boxFile}>
                  <Link href={props.value?.url} target="_blank">
                    {props.value?.nome}
                  </Link>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    id={props.id}
                    accept={props.arquiveType}
                    className={classes.input}
                    name={props.id}
                    onChange={handleChange}
                    disabled={props.disabled}
                    required={props.required}
                  />
                  {props.helperText && !errorTypeFile && (
                    <FormHelperText error={props.error}>
                      {props.helperText}
                    </FormHelperText>
                  )}
                  {errorTypeFile && (
                    <FormHelperText error={errorTypeFile}>
                      Por favor, insira apenas arquivos no formato{" "}
                      <strong>
                        {props.arquiveType.includes(",")
                          ? props.arquiveType
                              .split(",")
                              .map((item) => item.split("/")[1])
                              .join(", ")
                          : props.arquiveType.split("/")[1]}
                      </strong>
                      . Arquivos de outros formatos não são suportados neste
                      campo.
                    </FormHelperText>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
