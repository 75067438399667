/* eslint-disable array-callback-return */
import DateFnsUtils from "@date-io/date-fns";
import { Alert } from "@material-ui/lab";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { format, isValid, subDays } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useContext, useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { MdCheckCircle, MdClose, MdEvent } from "react-icons/md";
import { useHistory } from "react-router-dom";
import {
  Holliday,
  Schedule,
  ScheduleUser,
} from "../../../../interfaces/agendamentos";
import { SearchI } from "../../../../interfaces/servico";
import { UserData } from "../../../../interfaces/user";
import {
  agendar,
  cancelar,
  checarHorarios,
  feriados,
  getCheckInterval,
} from "../../../../services/agendamento";
// import { checkCPF } from "../../../../services/auth";
import theme from "../../../../services/theme-service";
import {
  // cpfMask,
  removeCpfMask,
  validateCpf,
} from "../../../../utils/cpfFormat";
import dateFormat from "../../../../utils/dateFormat";
import DadosDependente from "../../../DadosDependente";
import getCookie from "../../../../utils/getCookies";
import { getService } from "../../../../services/servico";
import { handleActiveServiceUnitCheck } from "../../../../utils/handleActiveServiceUnitCheck";
import { SchedulerStepContext } from "../../../../contexts/ShedulerStepContext";
import { removeCnpjMask } from "../../../../utils/cnpjFormat";
import { Identification } from "../../../Identification";
import { useStyles } from "./styles";
import { getDependentes } from "../../../../services/auth";
import SchedulingAssessment from "../../../SchedulingAssessment";

interface Props {
  open: boolean;
  handleClose: () => void;
  serviceSelected: SearchI;
  setShowResult: (set: boolean) => void;
  agendavelDependente?: boolean;
  handleHasScheduler: () => void;
  hasScheduled: boolean;
}

interface SavedDependenteProps {
  agendamento: number;
  cpf: string;
  cpf_solicitante: string;
  data_nascimento: string;
  grau_parentesco: string;
  id: number;
  nome: string;
}

interface DependenteProps {
  cpf: string;
  data_nascimento: Date;
  email: string;
  id: number;
  nome_completo: string;
  pcd: boolean;
  pessoa_dependente: {
    parentesco: string;
  }[];
}

interface SavedScheduleProps {
  cpf: string;
  data: string;
  data_solicitacao: string;
  guiche: number;
  hora: string;
  hora_inicio: string;
  id: number;
  para_dependente: boolean;
  prioridade: boolean;
  servico: number;
  setor: string;
  status: string;
  ticket: string;
  tipo: string;
  unidade: string;
  updated_at: string;
  user: number;
}

export default function ModalAttendenceOnline({
  open,
  handleClose,
  serviceSelected: service,
  setShowResult,
  agendavelDependente,
  handleHasScheduler,
  hasScheduled,
}: Props): JSX.Element {
  const {
    currentStep,
    userProfile,
    cnpjNumber,
    companyCountry,
    isVisibleButton,
    handleCheckPublic,
    onChangeCNPJ,
    onChangeCountry,
    handleChangeProfile,
    handleChangeStep,
    handleResetSchedulerProviderValues,
  } = useContext(SchedulerStepContext);
  const classes = useStyles();
  const history = useHistory();
  const [unitySelected, setUnitySelected] = useState<string>("");
  const [selectedDate, handleDateChange] = useState<Date | null>(null);
  const [scheduleSelected, setScheduleSelected] = useState<string>("");
  const [scheduleService, setScheduleService] = useState<
    string | Schedule | undefined
  >();
  const [scheduleServiceUser, setScheduleServiceUser] = useState<
    ScheduleUser | string | undefined
  >();
  const [cpfDependente, setCpfDependente] = useState<string>("");
  const [checkedDependente, setCheckedDependente] = useState(false);
  const [errorCpfDependente, setErrorCpfDependente] = useState<
    undefined | string
  >(undefined);
  const [nameDependente, setNameDependente] = useState<string>("");
  const [errorNameDependente, setErrorNameDependente] = useState<
    undefined | string
  >(undefined);
  const [birthDateDependente, setBirthDateDependente] = useState<Date>(
    subDays(new Date(), 30)
  );
  const [dependenteSelected, setDependenteSelected] = useState<string>("");
  const [, setHolliday] = useState<Holliday>();
  const [loading, setLoading] = useState<boolean>(false);
  const [savedSchedule, setSavedSchedule] =
    useState<SavedScheduleProps>(undefined);
  const [savedDependente, setSavedDependente] =
    useState<SavedDependenteProps>(undefined);
  const [showBoxData, setShowBoxData] = useState(false);
  const [hiddenInfoShedule, setHiddenInfoSchedule] = useState(true);
  const [loadingDepente, setLoadingDepedente] = useState(false);
  const [, setOpenUploads] = useState<boolean>(false);
  const [hiddenCancelData, setHiddenCancelData] = useState(true);
  const [cancelSchedule, setCancelSchedule] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [serviceSelected, setServiceSelected] = useState<SearchI | undefined>();
  const [dependentesRegistrados, setDependentesRegistrados] = useState([]);
  const [dependenteSelecionado, setDependenteSelecionado] =
    useState<DependenteProps>();
  const [evaluationCompleted, setEvaluationCompleted] = useState(true);
  const [hiddenWaring, setHiddenWaring] = useState(false);
  const [availableDates, setAvailableDates] = useState<string[]>([]);

  const selectDependente = (id: number) => {
    const dependenteFiltrado = dependentesRegistrados.filter(
      (dependente) => dependente.id === id
    );
    return setDependenteSelecionado(dependenteFiltrado[0]);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const disableWeekends = (date: MaterialUiPickersDate): boolean =>
    date
      ? date.getDay() === 0 ||
        date.getDay() === 6 ||
        format(date, "yyyy-MM-dd") === "2021-02-16"
      : false;

  const getHollidays = async () => {
    const { data } = await feriados();
    setHolliday(data);
  };

  const handleCheckDependente = () => {
    setCheckedDependente(!checkedDependente);
  };

  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    if (isValid(date) && serviceSelected) {
      try {
        const { data } = await checarHorarios(
          date,
          unity,
          serviceSelected.id,
          "Presencial"
        );
        setScheduleService(data);
      } catch (err) {
        if (err.response) {
          setScheduleService(err.response.data.message);
        } else {
          setScheduleService("Algo inesperado ocorreu");
        }
      }
    } else {
      setScheduleService(undefined);
    }
  };

  const schedulerService = async (): Promise<void> => {
    setLoading(true);
    const token: string | null = getCookie("gov_access_token_sso");
    const dataStorage: string | null = getCookie("gov_user_data");
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    if (token && userData && serviceSelected) {
      try {
        let dependentes = undefined;

        if (checkedDependente) {
          const cpfDependenteWithoutMask = removeCpfMask(cpfDependente);

          if (nameDependente && nameDependente.length < 6) {
            setErrorNameDependente("Nome deve ter no mínimo 6 caracteres.");
            return;
          }

          if (cpfDependenteWithoutMask) {
            if (
              !validateCpf(cpfDependenteWithoutMask) ||
              cpfDependenteWithoutMask.length < 11
            ) {
              setErrorCpfDependente("CPF inválido.");
              return;
            }
          }
          dependentes = {
            cpf: cpfDependente,
            nome: nameDependente,
            data_nascimento: birthDateDependente,
            cpf_solicitante: userData?.cpf,
            grau_parentesco: dependenteSelected,
          };
        }
        const cnpj = cnpjNumber && removeCnpjMask(cnpjNumber);

        const { status, data } = await agendar(
          token,
          userData.cpf,
          serviceSelected.id,
          unitySelected,
          serviceSelected.setor_id,
          "Presencial",
          selectedDate,
          scheduleSelected,
          cnpjNumber ? "cnpj" : undefined,
          cnpj,
          null,
          null,
          checkedDependente,
          dependentes
        );
        setScheduleServiceUser(data);

        if (status === 201) {
          setSavedSchedule(data);
          handleResetSchedulerProviderValues();
          setHiddenInfoSchedule(false);
          setShowBoxData(true);
          handleHasScheduler();
        }

        setLoading(false);
      } catch (err) {
        if (err.response?.data.length > 0) {
          setScheduleServiceUser(err.response?.data[0]);
        } else if (err?.response?.data) {
          setScheduleServiceUser(
            err.response?.data?.message ||
              err.response?.data?.detail ||
              "Algo inesperado ocorreu no agendamento."
          );
          setErrorNameDependente(
            err.response?.data?.detail ||
              "Algo inesperado ocorreu no agendamento do dependente."
          );
        } else {
          setScheduleServiceUser("Algo inesperado ocorreu.");
        }
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancelAttendance = async (id: string | number) => {
    setLoadingCancel(true);
    const token: string | null = getCookie("gov_access_token_sso");
    const dataStorage: string | null = getCookie("gov_user_data");
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    if (token && userData) {
      await cancelar(token, id);
      setLoadingCancel(false);
      setShowBoxData(true);
      setCancelSchedule(true);
    }
  };

  const handleClearHistoryInfo = () => {
    setUnitySelected("");
    setScheduleSelected("");
    setCheckedDependente(false);
    setSavedSchedule(undefined);
    setSavedDependente(undefined);
    setScheduleServiceUser(undefined);
    handleDateChange(null);
    setUnitySelected("");
    setHiddenCancelData(true);
    handleClose();
    handleResetSchedulerProviderValues();
  };

  const onReturn = () => {
    handleClose();
    handleResetSchedulerProviderValues();
    handleClearHistoryInfo();
    setShowResult(false);
    history.go(0);
  };

  const handleActiveDriveCheck =
    serviceSelected && handleActiveServiceUnitCheck(serviceSelected);

  useEffect(() => {
    const dataStorage: string | null = getCookie("gov_user_data");
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    setUnitySelected("");
    setScheduleSelected("");
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    setOpenUploads(false);
    getHollidays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getUserDependentes(userData?.cpf);
  }, [open]);
  const getUserDependentes = async (cpf: string) => {
    const token: string | null = getCookie("gov_access_token_sso");

    try {
      const data = await getDependentes(token, cpf);
      setDependentesRegistrados(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setNameDependente(dependenteSelecionado?.nome_completo);
    setCpfDependente(dependenteSelecionado?.cpf);
    setBirthDateDependente(dependenteSelecionado?.data_nascimento);
    setDependenteSelected(
      dependenteSelecionado?.pessoa_dependente[0]?.parentesco
    );
  }, [dependenteSelecionado]);

  useEffect(() => {
    async function handleGetService() {
      try {
        setLoading(true);
        const { data } = await getService(service.slug);
        setServiceSelected(data?.results[0]);
        handleCheckPublic(data?.results[0].publico_especifico);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (service) {
      handleGetService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  const closeModal = () => {
    handleClose();
    handleClearHistoryInfo();
    history.push("/");
  };

  const isDateAvailable = (date: Date) => {
    const dateFormatted = date.toISOString().split("T")[0];
    return !availableDates.includes(dateFormatted);
  };

  const handleCheckInterval = async (date?: Date) => {
    const token: string | null = getCookie("gov_access_token_sso");
    const servico = serviceSelected?.id;
    const unidade = unitySelected;
    const dataInicio = !date
      ? new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).toISOString()
      : date.toISOString();
    const dataFim = !date
      ? new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).toISOString()
      : new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();
    const tipo = "Presencial";

    const response = await getCheckInterval(
      token,
      servico,
      unidade,
      dataInicio.split("T")[0],
      dataFim.split("T")[0],
      tipo
    );

    if (response.status === 200) {
      setAvailableDates(response.data);
    }
  };

  useEffect(() => {
    if (serviceSelected && unitySelected) {
      handleCheckInterval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceSelected, unitySelected]);

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={() => {
          !hasScheduled && closeModal();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!hasScheduled && (
          <>
            <IconButton
              onClick={() => {
                !hasScheduled && closeModal();
              }}
              className={classes.buttonClose}
              style={showBoxData ? { color: "#666" } : {}}
            >
              <MdClose className={classes.icon} />
            </IconButton>
          </>
        )}
        <div hidden={showBoxData}>
          <DialogTitle className={classes.header}>
            <Typography className={classes.textHeader}>
              <MdEvent className={classes.icon} style={{ marginRight: 15 }} />
              Agendamento rápido
            </Typography>
          </DialogTitle>
          <DialogContent>
            {currentStep === 1 && (
              <Box
                className={classes.boxnNextOptions}
                style={{ margin: "10px 32px" }}
              >
                <Box>
                  <Typography className={classes.serviceSelectedTextTitle}>
                    {serviceSelected?.categoria_nome}
                  </Typography>
                  <Typography className={classes.serviceSelectedText}>
                    {serviceSelected?.titulo}
                  </Typography>
                  <Typography className={classes.serviceSelectedSubText}>
                    Órgão:
                    {serviceSelected?.orgao_nome}
                  </Typography>
                </Box>
              </Box>
            )}
            {loading ? (
              <Box style={{ marginTop: 20, textAlign: "center" }}>
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <>
                {!savedSchedule ? (
                  <Box className={classes.box}>
                    {currentStep === 0 && (
                      <DialogContent>
                        <Identification.Root>
                          <Identification.Header contentLogo={false} />
                          <Identification.Content>
                            <Identification.Option
                              controlRadio={handleChangeProfile}
                              profile={userProfile}
                              listOptions={serviceSelected?.publico_especifico}
                              cnpjNumber={cnpjNumber}
                              handleChangeValueCnpj={onChangeCNPJ}
                              companyCountry={companyCountry}
                              onCompanyCountry={onChangeCountry}
                            />
                          </Identification.Content>
                          <Identification.ContentAction>
                            <Identification.Action
                              handleSubmit={onReturn}
                              isDisabled={false}
                              text="VOLTAR"
                              variant="outlined"
                            />
                            <Identification.Action
                              handleSubmit={() => handleChangeStep("next")}
                              isDisabled={false}
                              text="SELECIONAR"
                              variant="contained"
                              isVisible={isVisibleButton}
                            />
                          </Identification.ContentAction>
                        </Identification.Root>
                      </DialogContent>
                    )}
                    {currentStep === 1 && (
                      <DialogContent>
                        <Typography className={classes.titleBox}>
                          Faça seu agendamento
                        </Typography>
                        {!agendavelDependente ? (
                          <div id="input-check">
                            <FormControlLabel
                              className={classes.inputCheckbox}
                              control={
                                <Checkbox
                                  checked={checkedDependente}
                                  onChange={handleCheckDependente}
                                  name="dependente"
                                  color="primary"
                                />
                              }
                              label="Dependente"
                            />
                            <Typography className={classes.inputCheckboxText}>
                              Selecione caso este agendamento seja para seu
                              dependente.
                            </Typography>
                          </div>
                        ) : (
                          <Typography className={classes.text}>
                            Agendamento apenas para o titular.
                          </Typography>
                        )}
                        <div id="input-unidade">
                          <FormControl
                            classes={{ root: classes.root }}
                            variant="outlined"
                            className={classes.formControl}
                            disabled={handleActiveDriveCheck}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Selecione a unidade
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              disabled={handleActiveDriveCheck}
                              onChange={(event): void => {
                                setUnitySelected(event.target.value as string);
                                getSchedulers(
                                  selectedDate,
                                  event.target.value as string
                                );
                                getSchedulers(
                                  new Date(),
                                  event.target.value as string
                                );
                              }}
                              label="Selecione a unidade"
                              classes={{
                                select: classes.menuItem,
                              }}
                            >
                              {serviceSelected?.servicosUnidade
                                ?.filter((unity) => unity.unidade.ativo)
                                ?.map((unity) => (
                                  <MenuItem
                                    key={unity.id}
                                    className={classes.menuItem}
                                    value={unity.unidade.slug_unidade}
                                  >
                                    {unity.unidade.nome}
                                  </MenuItem>
                                ))}
                            </Select>
                            {handleActiveDriveCheck && (
                              <FormHelperText error>
                                Não há unidades disponíveis.
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div id="input-data">
                          <FormControl
                            classes={{ root: classes.root }}
                            error={
                              typeof scheduleService === "string" ||
                              typeof scheduleServiceUser === "string"
                            }
                            disabled={!unitySelected}
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <MuiPickersUtilsProvider
                              locale={ptBR}
                              utils={DateFnsUtils}
                            >
                              <KeyboardDatePicker
                                disableFuture={false}
                                error={
                                  typeof scheduleService === "string" ||
                                  typeof scheduleServiceUser === "string"
                                }
                                minDateMessage="Você não pode agendar em dias anteriores"
                                invalidDateMessage="A data informada é inválida"
                                minDate={new Date()}
                                InputProps={{
                                  className: classes.menuItem,
                                  classes: { adornedEnd: classes.adornedEnd },
                                }}
                                autoOk
                                disabled={!unitySelected}
                                variant="inline"
                                inputVariant="outlined"
                                label="Selecione o dia"
                                format="dd/MM/yyyy"
                                shouldDisableDate={(date) =>
                                  isDateAvailable(date)
                                }
                                onMonthChange={async (date) =>
                                  handleCheckInterval(date)
                                }
                                placeholder="00/00/0000"
                                value={selectedDate ? selectedDate : null}
                                InputAdornmentProps={{
                                  position: "end",
                                  color: "#000",
                                }}
                                onChange={(date) => {
                                  if (date && isValid(date)) {
                                    handleDateChange(date);
                                    if (unitySelected) {
                                      getSchedulers(date, unitySelected);
                                    }
                                  }
                                }}
                              />
                            </MuiPickersUtilsProvider>
                            {typeof scheduleService === "string" && (
                              <FormHelperText>{scheduleService}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div id="input-horario">
                          <FormControl
                            classes={{ root: classes.root }}
                            disabled={
                              !unitySelected ||
                              !selectedDate ||
                              typeof scheduleService === "string"
                            }
                            variant="outlined"
                            className={classes.formControl}
                            error={typeof scheduleServiceUser === "string"}
                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              Escolha o horário
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              classes={{
                                select: classes.menuItem,
                              }}
                              // value={age}
                              onChange={(event): void => {
                                setScheduleSelected(
                                  event.target.value as string
                                );
                              }}
                              label="Escolha o horário"
                            >
                              {typeof scheduleService !== "string" &&
                                scheduleService?.horarios.map((schedule) => (
                                  <MenuItem
                                    key={schedule}
                                    className={classes.menuItem}
                                    value={schedule}
                                  >
                                    {schedule}
                                  </MenuItem>
                                ))}
                            </Select>
                            {typeof scheduleServiceUser === "string" && (
                              <FormHelperText>
                                {scheduleServiceUser}
                              </FormHelperText>
                            )}
                          </FormControl>
                          {checkedDependente && (
                            <>
                              {loadingDepente ? (
                                <Box
                                  style={{ marginTop: 20, textAlign: "center" }}
                                >
                                  <CircularProgress color="primary" />
                                </Box>
                              ) : (
                                <>
                                  {!savedDependente && (
                                    <>
                                      <DialogContent
                                        style={{ padding: "16px 10px" }}
                                      >
                                        <Typography
                                          className={classes.titleBox}
                                        >
                                          dados do dependente
                                        </Typography>
                                        <FormControl
                                          classes={{ root: classes.root }}
                                          variant="outlined"
                                          className={classes.formControl}
                                        >
                                          <InputLabel id="demo-simple-select-outlined-label">
                                            Dependentes
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            onChange={(event): void => {
                                              selectDependente(
                                                event.target.value as number
                                              );
                                            }}
                                            // disabled={!!dependente.pessoa_dependente?.[0]?.parentesco}
                                            //value={dependente?.pessoa_dependente?.[0]?.parentesco || parentesco}
                                            label="Dependentes"
                                            classes={{
                                              select: classes.menuItem,
                                            }}
                                          >
                                            {dependentesRegistrados &&
                                              dependentesRegistrados?.map(
                                                (unity) => (
                                                  <MenuItem
                                                    key={unity.id}
                                                    className={classes.menuItem}
                                                    value={unity.id}
                                                  >
                                                    {unity.nome_completo}
                                                  </MenuItem>
                                                )
                                              )}
                                          </Select>
                                        </FormControl>
                                        {/* <DadosDependente
                                          nameDependente={nameDependente}
                                          setNameDependente={setNameDependente}
                                          errorNameDependente={errorNameDependente}
                                          setErrorNameDependente={setErrorNameDependente}
                                          cpfDependente={cpfDependente}
                                          setCpfDependente={setCpfDependente}
                                          errorCpfDependente={errorCpfDependente}
                                          setErrorCpfDependente={setErrorCpfDependente}
                                          scheduleService={scheduleService}
                                          scheduleServiceUser={scheduleServiceUser}
                                          birthDateDependente={birthDateDependente}
                                          setBirthDateDependente={setBirthDateDependente}
                                          dependenteSelected={dependenteSelected}
                                          setDependenteSelected={setDependenteSelected}
                                          // handleDependente={handleDependente}
                                        />*/}
                                      </DialogContent>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: 20,
                            marginBottom: 20,
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={handleClearHistoryInfo}
                            style={{
                              backgroundColor: theme.palette.error.dark,
                              color: "#fff",
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            style={{
                              textTransform: "uppercase",
                              color: "#fff",
                              borderRadius: 4,
                              marginTop: 20,
                              marginBottom: 20,
                            }}
                            disabled={
                              !unitySelected ||
                              !selectedDate ||
                              !scheduleSelected ||
                              typeof scheduleService === "string" ||
                              typeof scheduleService === "undefined"
                            }
                            color="primary"
                            id="salvar-agendamento"
                            variant="contained"
                            onClick={schedulerService}
                          >
                            Salvar
                          </Button>
                        </Box>
                      </DialogContent>
                    )}
                  </Box>
                ) : (
                  <Box className={classes.boxInfoConcluded}>
                    <Box className={classes.infoConcluded}>
                      <Typography
                        className={classes.titleBox}
                        style={{ margin: 0 }}
                      >
                        Agendamento Salvo com sucesso
                      </Typography>
                      <MdCheckCircle
                        className={classes.icon}
                        style={{ color: "#0BB07B" }}
                      />
                    </Box>
                    <Box>
                      <Typography className={classes.textInfoDateTime}>
                        {dateFormat(savedSchedule?.data, false)} -{" "}
                        {savedSchedule?.hora}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </>
            )}
            <Alert style={{ marginBottom: 10 }} severity="warning">
              {" "}
              <strong>Orientações: </strong>
              Mantenha seus dados atualizados para não deixar de receber alertas
              de seu agendamento!
            </Alert>
          </DialogContent>
          {savedSchedule && (
            <DialogActions
              style={{
                display: "flex",
                margin: "0px 32px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 20,
                  marginBottom: 20,
                  width: "100%",
                }}
              >
                <Button
                  onClick={() => {
                    setShowBoxData(true);
                    setHiddenCancelData(false);
                  }}
                  variant="contained"
                  style={{
                    backgroundColor: theme.palette.error.dark,
                    color: "#fff",
                  }}
                >
                  Cancelar Agendamento
                </Button>
                <Button
                  style={{
                    textTransform: "uppercase",
                    color: "#fff",
                    borderRadius: 4,
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  disabled={
                    !unitySelected || !savedSchedule || checkedDependente
                      ? !checkedDependente ||
                        !nameDependente ||
                        !cpfDependente ||
                        !birthDateDependente ||
                        !dependenteSelected ||
                        !savedDependente
                      : false
                  }
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleClose();
                    handleClearHistoryInfo();
                    history.push("/");
                  }}
                >
                  Finalizar
                </Button>
              </Box>
            </DialogActions>
          )}
        </div>
        <div
          hidden={hiddenInfoShedule}
          className={!hiddenInfoShedule && classes.boxConcludedMain}
        >
          <div hidden={hiddenWaring}>
            <DialogContent style={{ textAlign: "center" }}>
              <Box>
                <MdCheckCircle
                  className={classes.iconLarge}
                  style={{ color: "#3CD278" }}
                />
              </Box>
              <Typography className={classes.textSchedule}>
                Agendamento concluído, enviamos para você um comprovante,
                verifique no seu email
              </Typography>
              <Typography className={classes.textSubtitleSchedule}>
                Não esqueça de levar os documentos solicitados no ato do
                agendamento.
              </Typography>
              <Typography className={classes.textSubtitleSchedule}>
                Caso não possa comparecer, realizar o cancelamento do
                agendamento no portal até 1h antes do horário previsto do
                atendimento.
              </Typography>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  className={classes.textSubtitleScheduleBox}
                  style={{ fontWeight: 600, marginTop: 30 }}
                >
                  {typeof selectedDate?.getMonth === "function" &&
                    format(selectedDate, "dd/MM/yyyy")}{" "}
                  às {scheduleSelected}
                </Typography>
              </Box>
            </DialogContent>
          </div>
          <SchedulingAssessment
            serviceSelected={service}
            evaluationCompleted={evaluationCompleted}
            setEvaluationCompleted={setEvaluationCompleted}
            onPermittedCloseModal={handleHasScheduler}
            setHiddenWarning={setHiddenWaring}
            tipoAvaliacao="Agendamento"
          />
          <div hidden={evaluationCompleted}>
            <DialogActions
              style={{
                margin: "10px 0px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                className={classes.buttonPrimary}
                onClick={(): void => {
                  setShowResult(false);
                  history.push("/workspace/minhas_solicitacoes");
                }}
              >
                <Typography className={classes.textButtonPrimary}>
                  Meus agendamentos
                </Typography>
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                  handleClearHistoryInfo();
                  history.push("/");
                }}
                className={classes.buttonOutlined}
              >
                <Typography className={classes.textButtonOutlined}>
                  Voltar para o início
                </Typography>
              </Button>
            </DialogActions>
          </div>
        </div>
        <div
          hidden={hiddenCancelData}
          className={classes.boxCancel}
          style={{
            display: !hiddenCancelData ? "flex" : "none",
          }}
        >
          {cancelSchedule ? (
            <DialogContent style={{ textAlign: "center" }}>
              <Box>
                <IoMdCloseCircle
                  style={{
                    color: theme.palette.error.dark,
                    width: 80,
                    height: 80,
                    fontSize: 24,
                  }}
                />
              </Box>
              <Typography className={classes.textSchedule}>
                Agendamento cancelado com sucesso!
              </Typography>
              <Typography className={classes.textSubtitleSchedule}>
                Seu atendimento presencial marcado para o dia{" "}
                {typeof selectedDate?.getMonth === "function" &&
                  format(selectedDate, "dd/MM/yyyy")}{" "}
                às {scheduleSelected} foi cancelado.
              </Typography>
              <Button
                onClick={() => {
                  handleClose();
                  handleClearHistoryInfo();
                  history.push("/");
                }}
                style={{
                  marginTop: 40,
                }}
                className={classes.buttonOutlined}
              >
                <Typography className={classes.textButtonOutlined}>
                  Voltar para o início
                </Typography>
              </Button>
            </DialogContent>
          ) : (
            <>
              {savedSchedule && (
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gridGap="20px"
                    marginTop="20px"
                  >
                    <IoMdCloseCircle
                      style={{
                        color: theme.palette.error.dark,
                        width: 80,
                        height: 80,
                        fontSize: 24,
                      }}
                    />
                    <Typography className={classes.titleCancel}>
                      Deseja cancelar seu atendimento?
                    </Typography>
                  </Box>
                  <DialogContent style={{ textAlign: "left" }}>
                    {serviceSelected?.servicosUnidade
                      ?.filter((unity) => unity.unidade.ativo)
                      ?.map(({ unidade }) => {
                        if (unidade.slug_unidade === unitySelected)
                          return (
                            <Typography className={classes.textUnidade}>
                              {unidade.nome}
                            </Typography>
                          );
                      })}

                    <Typography className={classes.textService}>
                      {`Serviço: ${serviceSelected?.titulo}`}
                    </Typography>

                    <Typography className={classes.textOrgan}>
                      {`Órgão: ${serviceSelected.orgao_nome}`}
                    </Typography>

                    <Typography className={classes.textDateTime}>
                      Data da solicitação:
                      <Typography component="strong">
                        {" "}
                        {format(new Date(savedSchedule.data), "dd/MM/yyyy")}
                      </Typography>
                    </Typography>

                    <Typography className={classes.textDateTime}>
                      Horário da solicitação:
                      <Typography component="strong">
                        {" "}
                        {savedSchedule.hora}
                      </Typography>
                    </Typography>
                  </DialogContent>
                  {loadingCancel ? (
                    <Box style={{ margin: "30px 0px", textAlign: "center" }}>
                      <CircularProgress color="primary" />
                    </Box>
                  ) : (
                    <DialogActions
                      classes={{
                        root: classes.boxActionsCancel,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleCancelAttendance(savedSchedule.id)}
                        style={{
                          backgroundColor: theme.palette.error.dark,
                          color: "#fff",
                        }}
                      >
                        Sim, eu quero cancelar
                      </Button>
                      <Button
                        onClick={() => {
                          setHiddenCancelData(true);
                          setShowBoxData(false);
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Não, eu não quero cancelar
                      </Button>
                    </DialogActions>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Dialog>
    </>
  );
}
