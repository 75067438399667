import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Props } from "./types";
import { Alert } from "@material-ui/lab";

const GenderIdentityInput = ({
  label,
  value,
  onChange,
  items,
  defaultValue,
  onClickHelperText,
  showHelperText,
  showError,
}: Props) => {
  const classes = useStyles();

  return (
    <div id={`input-${label}`}>
      <FormControl
        required
        classes={{ root: classes.root }}
        variant="outlined"
        className={classes.formControl}
        error={showError}
      >
        <InputLabel id={`input-label-${label}`}>{label}</InputLabel>
        <Select
          id={`input-select-${label}`}
          labelId={`input-label-${label}`}
          required
          onChange={(event) => {
            onChange(event.target.value as string);
          }}
          value={value || defaultValue}
          label={label}
          classes={{
            select: classes.menuItem,
          }}
        >
          {items.map((item) => (
            <MenuItem
              key={item.id}
              value={item.option}
              className={classes.menuItem}
            >
              {item.option}
            </MenuItem>
          ))}
          {showHelperText && (
            <Alert severity="info">
              Para mais informações,{" "}
              <a
                href="#"
                style={{ textDecoration: "none", color: "blue" }}
                onClick={onClickHelperText}
              >
                clique aqui!
              </a>
            </Alert>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default GenderIdentityInput;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#d3d3d3",
      },
    },
    "& .MuiSelect-iconOutlined": {
      height: 24,
      width: 24,
    },
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  formControl: {
    width: "100%",
  },
}));
