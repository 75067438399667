import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MyEditor from "../../Components/MyEditor";
import { getDocumentosResposta } from "../../services/form-flow";
import dateFormat from "../../utils/dateFormat";
import getCookie from "../../utils/getCookies";
import { cpfMask } from "../../utils/cpfFormat";

export default function DocRequestView(): JSX.Element {
  const { uuid_doc, identificador_solcitacao } = useParams<{
    uuid_doc: string;
    identificador_solcitacao: string;
  }>();
  const [dataDoc, setDataDoc] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getDoc = async () => {
      setLoading(true);
      let user = JSON.parse(getCookie("gov_user_data") || "{}");
      try {
        if (uuid_doc && identificador_solcitacao && user.cpf) {
          const { data } = await getDocumentosResposta(uuid_doc, {
            identificador_solicitacao: identificador_solcitacao || "",
            cpf: user.cpf || "",
          });
          setDataDoc(data);
          console.log(data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    getDoc();
  }, [uuid_doc, identificador_solcitacao]);

  const getQRCodeImageURL = (value) =>
    `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      value
    )}&size=80x80`;
  const qrCodeValue = `${process.env.REACT_APP_URL_CONSULTAR_DOC}/${dataDoc?.hash}/${dataDoc?.id}`;
  const qrCodeImgURL = getQRCodeImageURL(qrCodeValue);

  const addInfoSign = () => {
    return `
        <div style="text-align: left; background: #F3F4F7; position: absolute; bottom: 0; width: 100%; margin-top: 10px; font-size: 11px; display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;">
            <div style="flex: 1; padding: 10px; word-break: break-word;">
                ${
                  dataDoc?.assinado_sistema
                    ? `Autenticidade gerada automaticamente pelo sistema`
                    : `Assinado eletronicamente por: ${
                        cpfMask(dataDoc?.user?.username) || ``
                      } • ${dataDoc?.user?.first_name || ``} `
                }
                em ${
                  dataDoc?.updated_at && dateFormat(dataDoc?.updated_at, true)
                }
                <br/>
                Hash de verificação: ${dataDoc?.hash}
                <br/>
                Identificação:  ${dataDoc?.id}
                <br/>
                “Essa assinatura tem respaldo legal de acordo com a lei federal n° 14.129”<br />
                Consulte a autenticidade do documento em: ${
                  process.env.REACT_APP_URL_CONSULTAR_DOC
                }
            </div>
            <div style="flex-shrink: 0; padding: 10px; display: flex; justify-content: center;">
                <img src="${qrCodeImgURL}" alt="QRCode" style="image-rendering: crisp-edges; -ms-interpolation-mode: nearest-neighbor; margin-left: 10px; margin-right: 10px; width: 80px; height: auto;" />
            </div>
        </div>`;
  };

  return (
    <Paper
      style={{
        color: "#000",
      }}
    >
      {loading && !dataDoc ? (
        <div>Carregando...</div>
      ) : (
        <div
          style={{
            marginRight: "10px",
          }}
        >
          <MyEditor
            value={`
                <div id="content-main">
                    ${dataDoc?.cabecalho?.conteudo}
                    ${dataDoc?.conteudo}
                </div>
                    ${addInfoSign()}

                    `}
            print={true}
            addConf={{
              height: 800,
            }}
          />
        </div>
      )}
    </Paper>
  );
}
