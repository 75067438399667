import React, { useState } from 'react';
// import {TextFieldProps} from '@material-ui/core/TextField';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardDatePickerProps } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

interface ValidatorProps {
  validator?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
    },
  },
  input: {
    color: theme.palette.primary.main,
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
}));

export default function TextDate(props: KeyboardDatePickerProps & ValidatorProps): JSX.Element {
  const classes = useStyles();
  const { onChange, ...rest } = props;
  const [selectedDate, handleDateChange] = useState<Date>(new Date());

  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        autoOk
        variant="inline"
        inputVariant="outlined"
        label="With keyboard"
        format="dd/MM/yyyy"
        mask="__/__/____"
        InputProps={{
          className: classes.input,
        }}
        inputProps={{
          pattern: props.validator,
        }}
        className={classes.textField}
        value={selectedDate}
        InputAdornmentProps={{ position: 'end' }}
        onChange={(date: Date) => {
          handleDateChange(date);
          onChange(date);
        }}
        {...rest}
      // rifmFormatter={(str) => str}
      />
    </MuiPickersUtilsProvider>
    // <TextField
    //   InputProps={{
    //     className: classes.inputColor,
    //   }}
    //   type="date"
    //   variant="outlined"
    //   fullWidth
    //   {...props}
    // />
  );
}