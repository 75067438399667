import { AxiosResponse } from "axios";
import { apisiseci } from "./api";

interface ParamsServices {
  page: number;
  items_size: number;
  ativo: boolean;
  publico_especifico: string;
  categoria_slug?: string;
  search?: string;
}

export const getService = (slug: string): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/servicos_busca/?slug=${slug}`);

export const getServiceWithParams = (
  params: ParamsServices,
  token: string
): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/servicos_busca/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const searchServiceWithParams = (
  params: ParamsServices,
  token: string
): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/servicos_busca/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const searchServiceOld = (
  slug: string,
  page: number
): Promise<AxiosResponse> =>
  apisiseci.get(
    `cms/servicos/?search=${slug.toUpperCase()}&ativo=true&page=${page}`
  );

export const searchServiceNew = (
  slug: string,
  page: number
): Promise<AxiosResponse> =>
  apisiseci.get(
    `cms/servicos_busca/?search=${slug.toUpperCase()}&page=${page}`
  );

export const searchServiceForOrgan = (
  slug: string,
  page: number
): Promise<AxiosResponse> =>
  apisiseci.get(`cms/servicos/?orgao_slug=${slug}&ativo=true&page=${page}`);

export const serviceForCategory = (
  slug: string,
  page: number
): Promise<AxiosResponse> =>
  apisiseci.get(`cms/servicos/?categoria_slug=${slug}&ativo=true&page=${page}`);

export const serviceForCategoryExternal = (
  slug: string,
  page: number
): Promise<AxiosResponse> =>
  apisiseci.get(
    `cms/servicos/?categoria_slug=${slug}&acesso_externo=true&ativo=true&page=${page}`
  );

export const getServiceTypeUser = (
  userType: "Cidadão" | "Servidor" | "Empresa"
): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/serv_filter/?publico=${userType}`);

export const favoriteService = (
  servico: number,
  token: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    "/cms/servicos_favoritos/",
    {
      servico,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const getFavoriteService = (
  token: string,
  page: number = 1
): Promise<AxiosResponse> =>
  apisiseci.get("/cms/servicos_favoritos/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page,
    },
  });

export const getEmployeeService = (
  token: string,
  page: number = 1
): Promise<AxiosResponse> =>
  apisiseci.get("/cms/servicos_busca/?ativo=true&publico_especifico=Servidor", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page,
    },
  });

export const removeFavoriteService = (
  servico: number,
  token: string
): Promise<AxiosResponse> =>
  apisiseci.delete(`/cms/servicos_favoritos/${servico}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getTotalConsumo = (token: string): Promise<AxiosResponse> =>
  apisiseci.get("/core/total-consumo/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getAtividades = (token: string): Promise<AxiosResponse> =>
  apisiseci.get("/core/carregar-atividade/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
