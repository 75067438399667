import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
} from "@material-ui/core";
import {
  Text,
  Area,
  TextEmail,
  TextNumber,
  TextDate,
  Phone,
  Select,
  Multiple,
  MultCheckboxe,
  Mapa,
} from "../Inputs";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Formulario, Campo } from "../../../interfaces/form-flow";
import Alert from "@material-ui/lab/Alert";
import { answerForm, getStatusFormFlow } from "../../../services/form-flow";
import Arquivo from "../Inputs/Arquivo";
import SelectAPI from "../Inputs/SelectAPI";
import MultipleAPI from "../Inputs/MultipleAPI";
import getCookie from "../../../utils/getCookies";
import { getAdditionalDataUserSso } from "../../../services/auth";
import { DadosComplementares } from "../../../interfaces/user";
import { cpfMask } from "../../../utils/cpfFormat";
import SubSelect from "../Inputs/SubSelect";
import { searchCep } from "../../../services/ouvidoria";
import { useToastValue } from "../../../contexts/toastMessage";
import { format, parseISO } from "date-fns";
import { useParams } from "react-router-dom";
import {
  entryDateReturnFormatted,
  outPutDateReturnFormatted,
} from "../../../utils/dateFormat";
import { getGeolocationByCEP } from "../../../services/geoLocation";
import { sanitizeHtml } from "../../../utils/sanitizeHtml";

interface Props {
  page: number;
  formData: Formulario[];
  handleStep: (pageOrStep: number) => void;
  handleSubmit: (values: any, errors?: any) => void;
  formsAnswerData?: any[];
  requestMyData: boolean;
  incompletedAnswer: any[];
  serviceSlug?: string;
  loadIncompletedAnswer?: (count?: number, uploadAnwser?: boolean) => void;
}

interface AddressFields<T> {
  bairro?: T;
  município?: T;
  uf?: T;
  logradouro?: T;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#264259",
      },
    },
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#264259",
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 16,
    padding: 20,
  },
  subtitle: {
    opacity: 0.6,
    textAlign: "center",
    fontWeight: "normal",
    fontSize: 16,
  },
  formControl: {
    minWidth: 120,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  buttonForm: {
    backgroundColor: "#264259",
  },
  buttonBack: {
    display: "flex",
    "@media (max-width: 660px)": {
      justifyContent: "center",
      order: 2,
    },
  },
  imageInput: {
    display: "none",
  },
  imageButton: {
    marginRight: theme.spacing(2),
  },
  imageName: {
    display: "inline-block",
    marginLeft: theme.spacing(2),
  },
  buttonStyleBase: {
    padding: "5px 10px",
    border: "none",
    borderRadius: "5px",
    fontSize: "12px",
    cursor: "pointer",
    transition: "0.3s",
    marginTop: "5px",
    color: "#fff",
  },
  boxMap: {
    display: "flex",
    flexFlow: "column",
    alignItens: "center",
    justifyContent: "center",
    margin: "15px 10px",
  },
}));

export default function Flow({
  page,
  handleStep,
  formData,
  handleSubmit,
  formsAnswerData,
  requestMyData,
  incompletedAnswer,
  serviceSlug,
  loadIncompletedAnswer,
}: Props): JSX.Element {
  const { slug, protocolo } = useParams<{ slug: string; protocolo: string }>();
  const classes = useStyles();
  const [currentPageData, setCurrentPageData] = useState<Formulario>(
    formData[page]
  );
  const [currentFormsAnswer, setCurrentFormsAnswer] = useState(
    formsAnswerData[page]
  );
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [statusFormFlow, setStatusFormFlow] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDataUsers, setLoadingDataUsers] = useState(false);
  const [DadosComplementares, setDadosComplementares] =
    useState<DadosComplementares>({} as DadosComplementares);
  const [replicationCounts, setReplicationCounts] = useState({});
  const [valuesPattern, setValuesPattern] = useState<any>({});
  const [optionsValue, setOptionsValue] = useState<any>({});
  const [showFieldsMatriz, setShowFieldsMatriz] = useState<any>({});
  const [cepLoading, setCepLoading] = useState(false);
  const [cepNotFound, setCepNotFound] = useState(false);
  const [addressFieldsId, setAddressFieldsId] = useState<AddressFields<number>>(
    { bairro: 0, município: 0, uf: 0, logradouro: 0 }
  );
  const [addressMapa, setAddressMapa] = useState({
    bairro: "",
    município: "",
    uf: "BA",
    logradouro: "",
  });
  const [geolocation, setGeolocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [precision, setPrecision] = useState<boolean>(false);
  const [cepNumber, setCepNumber] = useState("");

  const { setToast } = useToastValue();
  const matchesMobile = useMediaQuery("(min-width:660px)");
  const getUserDataComplementar = async () => {
    try {
      setLoadingDataUsers(true);
      let token = getCookie("gov_access_token_sso");
      let user = JSON.parse(getCookie("gov_user_data") || "{}");
      if (token && user?.cpf) {
        const response = await getAdditionalDataUserSso(token, user.cpf);
        setAddressMapa({
          bairro: response.data.complementar?.bairro,
          município: response.data.complementar?.cidade,
          uf: response.data.complementar?.estado,
          logradouro: response.data.complementar?.endereco,
        });
        setDadosComplementares(response.data);
        if (response.data) {
          getGeolocation(response.data.complementar?.cep);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDataUsers(false);
    }
  };

  useEffect(() => {
    if (requestMyData) {
      getUserDataComplementar();
    }
  }, [requestMyData]);

  useEffect(() => {
    const upcomingPageData = formData[page];
    const upcomingAnswerData = formsAnswerData[page];
    if (upcomingAnswerData) {
      setCurrentFormsAnswer(upcomingAnswerData);
    }
    if (upcomingPageData) {
      setCurrentPageData(upcomingPageData);

      if (upcomingPageData.campos.length) {
        // VALUES
        setValues((currentValues) => {
          const newValues = upcomingPageData.campos.reduce((obj, field) => {
            if (upcomingAnswerData) {
              obj[field.id] =
                upcomingAnswerData?.resposta[field.id].resposta || "";
            } else {
              obj[field.id] = "";
            }
            return obj;
          }, {});

          return Object.assign({}, currentValues, newValues);
        });

        // SET ERRORS
        setErrors((currentValues) => {
          const newValues = upcomingPageData.campos.reduce((obj, field) => {
            obj[field.id] = "";
            return obj;
          }, {});
          return Object.assign({}, newValues, currentValues);
        });
      }
    }
  }, [page, formData, formsAnswerData]);

  useEffect(() => {
    const campoCEP = formData[page]?.campos.find(
      (campo) => campo.tipo === "CEP"
    );
    if (cepNumber) {
      handleSearchAddress(campoCEP, cepNumber, false);
    }
  }, [
    addressMapa.bairro,
    addressMapa.município,
    addressMapa.logradouro,
    addressMapa.uf,
  ]);

  const fillIncompletedForm = async () => {
    setLoading(true);
    try {
      if (!formData[page]?.campos) {
        console.error("Dados de campos não encontrados.");
        return;
      }
      incompletedAnswer?.forEach((item) => {
        setValues((currentValues) => {
          const dadosInput = formData[page]?.campos.find(
            (obj) => obj.id === item.id
          );
          const updatedValues = { ...currentValues };
          updatedValues[item.id] = item.resposta;
          if (
            dadosInput?.tipo === "Data" &&
            typeof item.resposta === "string"
          ) {
            const dateFormatted = entryDateReturnFormatted(item.resposta);

            if (dateFormatted) {
              updatedValues[item.id] = dateFormatted;
            } else {
              updatedValues[item.id] = "";
            }
          }
          if (dadosInput?.tipo === "Alternativas_API") {
            const itemAPI = { label: item.resposta, value: item.value };
            updatedValues[item.id] = itemAPI;
          }
          if (dadosInput?.tipo === "Multiplos") {
            const multItem = filterMultipleInput(dadosInput, item);
            updatedValues[item.id] = multItem;
          }
          if (dadosInput?.tipo === "Matriz") {
            const multItem = filterMultipleMatriz(dadosInput, item);

            for (let index = 0; index < multItem?.length; index++) {
              handleReplicate(dadosInput.id, index);
            }

            setShowFieldsMatriz((showMatriz) => {
              return {
                ...showMatriz,
                [dadosInput.id]: multItem?.length > 0,
              };
            });
            const list = [];
            const extradata_list = [];
            if (multItem?.length > 0) {
              for (let item of multItem[0]) {
                for (let data of dadosInput.alternativas) {
                  if (data.nome_campo === item.titulo) {
                    if (data.campo === "Alternativas_JSON") {
                      for (let value of data?.value) {
                        if (value.id === item.resposta) {
                          value.nome_campo = item.titulo;
                          if (value.subvalue_name) {
                            extradata_list.push(value);
                          }
                          list.push(value);
                        }
                      }
                    } else {
                      list.push(data);
                    }
                  } else {
                    const verification = list.find(
                      (verify) => verify.nome_campo === item.titulo
                    );
                    if (!verification) {
                      for (let extradata of extradata_list) {
                        if (extradata.subvalue_name === item.titulo) {
                          let check = extradata.subvalue_data.find(
                            (extra) => extra.value === item.resposta
                          );

                          if (check) {
                            check.nome_campo = item.titulo;
                            check.resposta = item.resposta;
                            check.id = item.id;

                            list.push(check);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            const newListFormatted = list.find(
              (field) => field?.subvalue_data?.length > 0
            );
            const getValeuGroup = multItem?.[0]?.find(
              (mult) => mult.titulo === newListFormatted?.subvalue_name
            );

            if (newListFormatted && getValeuGroup) {
              setOptionsValue((options) => {
                return {
                  ...options,
                  [`${newListFormatted.subvalue_name}-${getValeuGroup?.value_group}`]:
                    newListFormatted?.subvalue_data,
                };
              });
            }

            updatedValues[item.id] = multItem;
          }
          if (
            dadosInput?.tipo === "Alternativas" &&
            dadosInput?.tipo_alternativa === "RadioButton"
          ) {
            if (item.resposta == "True" || item.resposta == "True") {
              updatedValues[item.id] = item.resposta ? "True" : "False";
            } else {
              updatedValues[item.id] = item.resposta;
            }
          }
          return updatedValues;
        });
        if (
          item?.titulo === "Status da Declaração" &&
          item?.resposta === "Retificado"
        ) {
          setToast({
            visible: true,
            message:
              "Esta é uma retificação da solicitação já realizada. Por favor, revise e atualize as informações.",
            type: "warning",
          });
        }
      });
    } catch (error) {
      console.error("Erro ao obter dados de campos:", error);
    } finally {
      setLoading(false);
    }
  };
  const titles = [
    "NOME DA MÃE",
    "NOME DO PAI",
    "GÊNERO",
    "RAÇA/COR",
    "ESCOLARIDADE",
    "ESTADO CIVIL",
    "NATURALIDADE",
    "NACIONALIDADE",
    "MUNICÍPIO",
    "UF",
    "CEP",
    "LOGRADOURO",
    "NÚMERO",
    "COMPLEMENTO",
    "BAIRRO",
  ];
  const missingTitles = useMemo(() => {
    const incompletedTitles = incompletedAnswer?.map((item) =>
      item.titulo.toUpperCase()
    );
    return titles.filter((title) => !incompletedTitles?.includes(title));
  }, [incompletedAnswer]);
  useEffect(() => {
    fillIncompletedForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incompletedAnswer, formData[page]?.campos]);
  function filterMultipleInput(dadosInput, item) {
    if (dadosInput?.alternativas?.length > 0) {
      const multItem = dadosInput.alternativas
        .filter((alt) => item?.resposta?.includes(alt.valor))
        .map((altIt) => ({ value: altIt.valor, label: altIt.titulo }));

      return multItem;
    }

    return null;
  }

  function filterMultipleMatriz(dadosInput, item) {
    if (item?.resposta && dadosInput?.alternativas?.length > 0) {
      const multItem = item?.resposta?.filter((responses) => {
        return responses.filter((response) =>
          dadosInput?.alternativas?.map(
            (alt) => alt?.nome_campo === response?.titulo
          )
        );
      });

      return multItem;
    }

    return null;
  }

  // Valida campos, para tratamento externo
  const validate = useCallback(() => {
    let invalids = [];
    let currentValues = {};

    if (currentPageData?.campos?.length) {
      currentPageData.campos.forEach((field) => {
        let fieldId = field.id;
        let value = values[fieldId];
        // Marca os erros
        if (field.obrigatorio && !value) {
          currentValues[fieldId] = "Campo obrigatório";
        }
        if (field.validador) {
          let reg = new RegExp(field.validador, "g").test(value);
          if (!reg) {
            currentValues[fieldId] = "Campo no formato inválido";
          }
        }

        if (currentValues[fieldId]) {
          invalids.push(currentValues[fieldId]);
        }

        setErrors(currentValues);
      });
    }

    return !invalids.length;
  }, [currentPageData?.campos, values]);

  const handleSave = async (slug) => {
    let formDataUser = JSON.parse(getCookie("gov_user_form_started") || "{}");
    let user = JSON.parse(getCookie("gov_user_data") || "{}");
    if (formDataUser) {
      let forResposta = {};

      currentPageData.campos.forEach((field) => {
        let fieldId = field.id;
        let value = values[fieldId];
        if (field.tipo === "Data" && value && value instanceof Date) {
          const dateStr = outPutDateReturnFormatted(value);
          value = dateStr;
        }

        if (field.tipo === "Alternativas_API") {
          forResposta[fieldId] = {
            id: fieldId,
            titulo: field?.titulo,
            resposta: value.label,
            value: Number(value.value),
          };
        } else if (field.tipo === "CEP") {
          forResposta[fieldId] = {
            id: fieldId,
            titulo: field?.titulo,
            resposta: value,
            latitude: geolocation.latitude,
            longitude: geolocation.longitude,
            lat_long_precisa: precision,
          };
        } else if (field.tipo === "Matriz") {
          const formattedValue = Array.isArray(value)
            ? value.map((response) =>
                response.map((res) => ({
                  ...res,
                  nome_campo: res.nome_campo || res.titulo,
                }))
              )
            : value;

          forResposta[fieldId] = {
            id: fieldId,
            titulo: field?.titulo,
            tipo: "Matriz",
            resposta: formattedValue,
          };
        } else {
          forResposta[fieldId] = {
            id: fieldId,
            titulo: field?.titulo,
            resposta: value,
          };
        }
      });

      let formatData = {
        flow_resposta: formDataUser.id,
        formulario: slug,
        cpf: user.cpf,
        resposta: forResposta,
      };
      console.log({
        formatData,
      });

      try {
        const response = await answerForm(formatData);

        if (response) {
          handleStep(page + 1);
        }
      } catch (error) {
        if (error.response.status === 406) {
          handleStep(page + 1);
        }
        console.log("error ao salvar resposta", error);
      }
    }
  };

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const isValid = validate();
    if (isValid) {
      const totalPage = formData.length - 1;
      //const nextPage = page + 1;
      if (page <= totalPage) {
        await handleSave(currentPageData.slug);
        // handleStep(nextPage);
      }

      if (page === totalPage) {
        await checkStatusFormFlow();
        handleSubmit(values, errors);
      }
    }
  };

  const checkStatusFormFlow = async () => {
    let formDataUser = JSON.parse(getCookie("gov_user_form_started") || "{}");
    let user = JSON.parse(getCookie("gov_user_data") || "{}");
    if (formDataUser?.identificador && user?.cpf) {
      try {
        const { data } = await getStatusFormFlow({
          identificador: formDataUser.identificador,
          cpf: user.cpf,
        });
        setStatusFormFlow(data);
      } catch (error) {
        console.log("error ao verificar status form flow", error);
      }
    }
  };

  // set os Inputs
  const fieldChanged = (campo: Campo, value: any) => {
    setValues((currentValues) => {
      currentValues[campo.id] = value;
      return currentValues;
    });

    setCurrentPageData((currentPageData) => {
      return Object.assign({}, currentPageData);
    });

    if (errors) {
      setErrors((currentError) => (currentError[campo.id] = ""));
    }
  };

  // determina a proxima pagina (Navegação entre forms)
  const handlePrev = () => {
    const pagePrev = page - 1;
    if (pagePrev >= 0) {
      handleStep(pagePrev);
    }
  };

  // Testa a proxima pagina
  const hasPageNext = () => {
    return (
      (page >= 0 && page < formData.length - 1) || formData.length - 1 === page
    );
  };
  const getValueFromUser = useCallback(() => {
    const user = JSON.parse(getCookie("gov_user_data") || "{}");
    return formData[page]?.campos
      ?.filter((field) => field.tipo !== "Matriz")
      ?.map((campo) => {
        switch (campo.titulo.toUpperCase()) {
          case "CPF":
            setValues((currentValues) => {
              currentValues[campo.id] = cpfMask(user.cpf);
              return currentValues;
            });
            setValuesPattern((currentValues) => {
              currentValues[campo.id] = cpfMask(user.cpf);
              return currentValues;
            });
            break;

          case "NOME":
          case "NOME COMPLETO":
            setValues((currentValues) => {
              currentValues[campo.id] = user?.nome;
              return currentValues;
            });
            setValuesPattern((currentValues) => {
              currentValues[campo.id] = user.nome;
              return currentValues;
            });
            break;
          case "DATA DE NASCIMENTO":
            setValues((currentValues) => {
              const formattedDate =
                DadosComplementares?.data_nascimento &&
                parseISO(DadosComplementares?.data_nascimento);
              currentValues[campo.id] = formattedDate;

              return currentValues;
            });
            break;
          case "NOME DA MÃE":
            if (missingTitles.includes("NOME DA MÃE")) {
              setValues((currentValues) => {
                currentValues[campo.id] = DadosComplementares?.nome_mae;
                return currentValues;
              });
            }
            break;

          case "NOME DO PAI":
            if (missingTitles.includes("NOME DO PAI")) {
              setValues((currentValues) => {
                currentValues[campo.id] = DadosComplementares?.nome_pai;
                return currentValues;
              });
            }
            break;

          case "GÊNERO":
            if (missingTitles.includes("GÊNERO")) {
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares?.sexo?.toLowerCase();
                return currentValues;
              });
            }
            break;

          case "RAÇA/COR":
            if (missingTitles.includes("RAÇA/COR")) {
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares.complementar?.raca?.toLowerCase();
                return currentValues;
              });
            }
            break;

          case "ESCOLARIDADE":
            if (missingTitles.includes("ESCOLARIDADE")) {
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares.complementar?.escolaridade?.toLowerCase();
                return currentValues;
              });
            }
            break;

          case "ESTADO CIVIL":
            if (missingTitles.includes("ESTADO CIVIL")) {
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares.complementar?.estado_civil?.toLowerCase();
                return currentValues;
              });
            }
            break;

          case "NATURALIDADE":
            if (missingTitles.includes("NATURALIDADE")) {
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares.complementar?.naturalidade;
                return currentValues;
              });
            }
            break;

          case "NACIONALIDADE":
            if (missingTitles.includes("NACIONALIDADE")) {
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares.complementar?.nacionalidade;
                return currentValues;
              });
            }
            break;

          case "MUNICÍPIO":
            if (missingTitles.includes("MUNICÍPIO")) {
              if (
                !DadosComplementares?.complementar?.cidade &&
                !loadingDataUsers
              ) {
                return "";
              }
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares?.complementar?.cidade;
                return currentValues;
              });
            }
            break;

          case "UF":
            if (missingTitles.includes("UF")) {
              if (
                !DadosComplementares?.complementar?.estado &&
                !loadingDataUsers
              )
                return "";
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares?.complementar?.estado;
                return currentValues;
              });
            }
            break;

          case "CEP":
            if (missingTitles.includes("CEP")) {
              if (!DadosComplementares?.complementar?.cep && !loadingDataUsers)
                return "";
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares?.complementar?.cep;
                return currentValues;
              });
            }
            break;

          case "LOGRADOURO":
            if (missingTitles.includes("LOGRADOURO")) {
              if (
                !DadosComplementares?.complementar?.endereco &&
                !loadingDataUsers
              )
                return "";
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares.complementar?.endereco;
                return currentValues;
              });
            }
            break;

          case "NÚMERO":
            if (missingTitles.includes("NÚMERO")) {
              if (
                !DadosComplementares?.complementar?.numero &&
                !loadingDataUsers
              )
                return "";
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares?.complementar?.numero;
                return currentValues;
              });
            }
            break;

          case "COMPLEMENTO":
            if (missingTitles.includes("COMPLEMENTO")) {
              if (
                !DadosComplementares?.complementar?.complemento &&
                !loadingDataUsers
              )
                return "";
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares?.complementar?.complemento;
                return currentValues;
              });
            }
            break;

          case "BAIRRO":
            if (missingTitles.includes("BAIRRO")) {
              if (
                !DadosComplementares?.complementar?.bairro &&
                !loadingDataUsers
              )
                return "";
              setValues((currentValues) => {
                currentValues[campo.id] =
                  DadosComplementares?.complementar?.bairro;
                return currentValues;
              });
            }
            break;
          default:
            return "";
        }
        fillIncompletedForm();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    DadosComplementares?.complementar,
    formData,
    loadingDataUsers,
    page,
    protocolo,
  ]);

  const getEmailFromUser = (fieldId: number) => {
    if (!requestMyData) return "";
    const user = JSON.parse(getCookie("gov_user_data") || "{}");
    setValues((currentValues) => {
      currentValues[fieldId] = user?.contato?.email;
      return currentValues;
    });
  };

  const getTelefoneFromUser = (fieldId: number) => {
    if (!requestMyData) return "";
    const user = JSON.parse(getCookie("gov_user_data") || "{}");
    setValues((currentValues) => {
      currentValues[fieldId] = user?.contato?.celular;
      return currentValues;
    });
  };

  function formatAlternativesForMapping(alternativas) {
    return alternativas?.map((item) => ({
      label: item?.name,
      value: String(item?.value),
    }));
  }

  function valueToLoweCase(campo: Campo): any[] {
    const data = campo.alternativas.map((item) => {
      return {
        ...item,
        valor: item?.valor?.toLowerCase(),
      };
    });

    return data;
  }

  const getValueSubSelect = useCallback(
    (campo: Campo, idx: number, subvalue_name: string, index: number) => {
      if (!Array.isArray(values[campo.id])) {
        return values[campo.id] || "";
      }

      const respostaArray = values[campo.id]?.[idx];

      if (!Array.isArray(respostaArray)) return null;

      const obj = respostaArray?.filter(
        (item) =>
          item?.nome_campo === subvalue_name || item?.titulo === subvalue_name
      );

      return obj?.[0]?.resposta?.toString() || "";
    },
    [values]
  );

  const getValueSelect = useCallback(
    (
      campo: Campo,
      idx: number,
      nome_campo: string,
      index_alternativa?: number
    ) => {
      if (!Array.isArray(values[campo.id])) {
        return values[campo.id] || "";
      }
      const respostaArray = values[campo.id]?.[idx];

      if (!Array.isArray(respostaArray)) return null;

      const obj = respostaArray
        ?.filter(
          (item) =>
            item?.nome_campo === nome_campo || item?.titulo === nome_campo
        )
        .map((response) => ({
          id: response.id,
          label: response.titulo,
          resposta: response.resposta,
        }));

      return obj[0]?.resposta?.toString() || "";
    },
    [values]
  );

  function valueToLoweCaseSubSelect(campo: Campo): any[] {
    const data = campo?.alternativas
      .filter((item) => item.campo === "Alternativas_JSON")
      .map((item: any) => {
        return item?.value?.map((data) => ({
          ...data,
          label: data.label,
        }));
      });

    return data;
  }

  const handleReplicate = (campoId, limite) => {
    setReplicationCounts((prevCounts) => {
      const newCount =
        prevCounts[campoId] < limite + 1
          ? prevCounts[campoId] + 1
          : prevCounts[campoId];
      return { ...prevCounts, [campoId]: newCount };
    });
  };

  const handleDeleteField = (campoId: number, idx: number) => {
    setReplicationCounts((prevCounts) => {
      const newCount = prevCounts[campoId] > 1 ? prevCounts[campoId] - 1 : 1;
      return { ...prevCounts, [campoId]: newCount };
    });

    setValues((oldValues) => {
      const updatedValues = { ...oldValues };

      delete updatedValues[campoId][idx];

      return updatedValues;
    });
  };

  const findValueForInput = (
    campoId: number,
    idx: number,
    nome_campo?: string
  ) => {
    if (!Array.isArray(values[campoId])) {
      return values[campoId] || "";
    }

    const respostaArray = values[campoId]?.[idx];

    if (!Array.isArray(respostaArray)) return null;

    const obj = respostaArray?.find(
      (item) => item?.nome_campo === nome_campo || item?.titulo === nome_campo
    );

    return obj?.resposta || null;
  };

  const renderActionButton = (campoId, idx, limite) => {
    if (limite === 0) return null;

    if (idx === 0 && replicationCounts[campoId] < limite + 1) {
      return (
        <button
          type="button"
          onClick={() => handleReplicate(campoId, limite)}
          className={classes.buttonStyleBase}
          style={{ backgroundColor: "#007BFF", padding: 8, margin: "10px 0px" }}
        >
          Adicionar
        </button>
      );
    } else if (idx > 0) {
      return (
        <button
          type="button"
          onClick={() => handleDeleteField(campoId, idx)}
          className={classes.buttonStyleBase}
          style={{ backgroundColor: "red", padding: 8, margin: "10px 0px" }}
        >
          Excluir
        </button>
      );
    }
    return null;
  };

  function convertToLongFormat(dateString: string | null | undefined) {
    if (!dateString) {
      return;
    }

    const [day, month, year] = dateString.split("/").map(Number);

    const date = new Date(year, month - 1, day, 11, 11);

    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
      timeZone: "America/Sao_Paulo",
    };
    const longDate =
      date.toLocaleString("en-US", options) + " (Horário Padrão de Brasília)";

    return longDate;
  }

  const matrixFieldChanged = useCallback(
    (
      campoId: number,
      ordem: string,
      value: string,
      label: string,
      idx: number,
      nome_campo: string
    ) => {
      setValues((prevValues) => {
        let updatedValues = { ...prevValues };

        if (!updatedValues[campoId]) {
          updatedValues[campoId] = [];
        }

        if (!updatedValues[campoId][idx]) {
          updatedValues[campoId][idx] = [];
        }

        const getFieldAlreadyFilledByIndex = updatedValues[campoId][
          idx
        ]?.findIndex(
          (field) =>
            (field?.titulo === nome_campo ||
              field?.nome_campo === nome_campo) &&
            field.value_group === idx
        );

        if (getFieldAlreadyFilledByIndex > -1) {
          updatedValues[campoId][idx][getFieldAlreadyFilledByIndex].resposta =
            value;
        } else {
          updatedValues[campoId][idx].push({
            id: ordem,
            resposta: value,
            label: label,
            nome_campo,
            value_group: idx,
          });
        }

        return updatedValues;
      });
    },
    []
  );

  const matrixFieldSubSelectChanged = (
    campo: Campo,
    value: number,
    idx: number,
    index_alternativa: number,
    resposta_id: string
  ) => {
    setValues((prevValues) => {
      let updatedValues = { ...prevValues };

      const subValueLabel =
        campo.alternativas[index_alternativa]?.value?.[0]?.subvalue_label;

      const subValueName =
        campo.alternativas[index_alternativa].value?.[0]?.subvalue_name;

      const selected = optionsValue[`${subValueName}-${idx}`].find(
        (option) => option.value === value
      );

      const newData = {
        id: `${value}_${idx}_S`,
        titulo: subValueName,
        label: subValueLabel,
        resposta: selected.value,
        resposta_id: resposta_id,
        value_group: idx,
        nome_campo: subValueName,
        resposta_label: selected.label,
      };
      const findItem = updatedValues[campo.id][idx]?.findIndex((field) => {
        if (field?.titulo && field?.value_group) {
          return field.nome_campo === subValueName && field.value_group === idx;
        } else {
          return field.titulo === subValueName && field.value_group === idx;
        }
      });

      if (findItem > -1) {
        updatedValues[campo.id][idx][findItem] = newData;
      } else {
        updatedValues[campo.id][idx].push(newData);
      }

      return updatedValues;
    });
  };

  const matrixFieldSelectChanged = (
    campo: Campo,
    value: number,
    idx: number,
    index_alternativa: number,
    nome_campo: string
  ) => {
    if (!campo?.alternativas?.length) return;
    setValues((prevValues) => {
      let updatedValues = { ...prevValues };

      if (!updatedValues[campo.id]) {
        updatedValues[campo.id] = [];
      }

      if (!updatedValues[campo.id][idx]) {
        updatedValues[campo.id][idx] = [];
      }

      const list = campo.alternativas
        .map((alternativaData) => {
          if (Array.isArray(alternativaData.value)) {
            return alternativaData?.value?.find((altr) => altr.value === value);
          }
        })
        .filter(Boolean);

      const nameField = campo?.alternativas[index_alternativa];
      const newListFormatted = list.map((item) => ({
        id: `${value}_${idx}_M`,
        titulo: nameField?.nome_campo,
        resposta: item.value,
        label: nameField?.titulo,
        resposta_id: null,
        value_group: idx,
        nome_campo: nameField?.nome_campo,
        resposta_label: item?.label,
      }));

      if (list?.length > 0) {
        setOptionsValue((value) => {
          return {
            ...value,
            [`${list[0]?.subvalue_name}-${idx}`]: list[0]?.subvalue_data,
          };
        });

        // const findItem = updatedValues[campo.id][idx]?.findIndex((field) => field.id === `${value}_${index_alternativa}_M`);
        const findItem = updatedValues[campo.id][idx]?.findIndex((field) => {
          if (field?.titulo && field?.value_group) {
            return field.nome_campo === nome_campo && field.value_group === idx;
          } else {
            return field.titulo === nome_campo && field.value_group === idx;
          }
        });

        if (findItem > -1) {
          updatedValues[campo.id][idx][findItem] = newListFormatted[0];
        } else {
          updatedValues[campo.id][idx].push(newListFormatted[0]);
        }
      }

      return updatedValues;
    });
  };

  function convertDateFormat(dateString: Date): string {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    const initialCounts = {};
    formData?.forEach((form) => {
      form?.campos?.forEach((campo) => {
        if (campo.tipo === "Matriz") {
          initialCounts[campo.id] = 1;
        }
      });
    });
    setReplicationCounts(initialCounts);
  }, [formData]);
  const handleSearchAddress = async (
    campo: Campo,
    cep: string,
    searchGeolocation?: boolean
  ) => {
    const bahiaRegion = campo.tipo_regiao_cep_permitida === "Bahia" ? "BA" : "";
    const salvadorRegion =
      campo.tipo_regiao_cep_permitida === "Salvador" ? "Salvador" : "";
    const unmaskedCep = cep?.replace(/[^0-9]/g, "");
    setCepNotFound(false);
    fieldChanged(campo, unmaskedCep);
    const isInvalid =
      (campo.obrigatorio && !cep) ||
      (campo.obrigatorio && unmaskedCep.length < 8) ||
      (!campo.obrigatorio && unmaskedCep.length >= 0 && unmaskedCep.length < 8);
    if (isInvalid) {
      setCepNotFound(true);
      setCepLoading(false);
      return;
    }

    try {
      setToast({
        visible: true,
        message: "Buscando CEP",
        type: "info",
        isLoading: true,
      });
      setCepLoading(true);
      setValues((currentValues) => {
        currentValues[addressFieldsId.bairro] = "";
        currentValues[addressFieldsId.uf] = "";
        currentValues[addressFieldsId.município] = "";
        currentValues[addressFieldsId.logradouro] = "";
        return currentValues;
      });

      const { data } =
        unmaskedCep.length === 8 &&
        (await searchCep(unmaskedCep, salvadorRegion, bahiaRegion));

      setValues((currentValues) => {
        currentValues[addressFieldsId.bairro] = precision
          ? addressMapa?.bairro
          : data?.bairro;
        currentValues[addressFieldsId.uf] = precision
          ? addressMapa?.uf
          : data?.uf;
        currentValues[addressFieldsId.município] = precision
          ? addressMapa?.município
          : data?.cidade;
        currentValues[addressFieldsId.logradouro] = precision
          ? addressMapa?.logradouro
          : data?.logradouro;
        return currentValues;
      });

      setToast({
        visible: false,
        message: "",
        type: "info",
      });
      setCepLoading(false);
      if (searchGeolocation && !isInvalid) {
        getGeolocation(cep);
      }
    } catch (error) {
      if (error?.response?.data) {
        setToast({
          visible: true,
          message: "CEP não encontrado.",
          type: "error",
        });
        setErrors((currentErrors) => {
          return { ...currentErrors, [campo.id]: "CEP inválido" };
        });
        setCepLoading(false);
        setCepNotFound(true);
      }
      console.error(error);
      setCepLoading(false);
    } finally {
      setCepLoading(false);
    }
  };
  const getGeolocation = async (cep) => {
    try {
      const { data } = await getGeolocationByCEP(cep);
      if (data) {
        setGeolocation({ latitude: data[0].lat, longitude: data[0].lon });
      }
    } catch (error) {
      console.log(error);
    }
  };

  function getAddressFieldId(campo: Campo) {
    if (!Object.keys(addressFieldsId)?.includes(campo.titulo.toLowerCase()))
      return;

    setAddressFieldsId((currentIdList) => {
      currentIdList[campo.titulo.toLowerCase()] = campo.id;
      return currentIdList;
    });
  }

  useEffect(() => {
    if (
      (requestMyData && DadosComplementares && !loadingDataUsers) ||
      protocolo
    ) {
      getValueFromUser();
    }
  }, [
    requestMyData,
    DadosComplementares,
    loadingDataUsers,
    getValueFromUser,
    page,
  ]);

  useEffect(() => {
    if (!requestMyData && !protocolo) {
      setValues({});
    }
  }, [requestMyData, protocolo]);

  return (
    <>
      {currentPageData && (
        <>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                {currentPageData.titulo}
              </Typography>
            </Grid>
            <Grid
              style={{ textAlign: "center", marginBottom: 20 }}
              item
              xs={12}
            >
              <Typography className={classes.subtitle}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(currentPageData.descricao),
                  }}
                />
              </Typography>
            </Grid>
          </Grid>

          <form onSubmit={onSubmit} className={classes.root}>
            {!currentPageData.campos.length ? (
              <Alert severity="warning" style={{ marginBottom: 10 }}>
                Nenhum campo disponível
              </Alert>
            ) : (
              <Grid container={matchesMobile ? true : false} spacing={2}>
                {loading ? (
                  <>
                    <Box
                      marginBottom="60px"
                      width="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <CircularProgress />
                    </Box>
                  </>
                ) : (
                  <>
                    {currentPageData.campos.map((campo: Campo) => (
                      <Fragment key={campo.id}>
                        {campo.tipo === "Texto" && (
                          <Grid
                            sm={12}
                            md={6}
                            item
                            style={{ marginTop: matchesMobile ? "" : "20px" }}
                          >
                            {addressFieldsId[campo.titulo.toLowerCase()] ===
                              0 && getAddressFieldId(campo)}
                            <Text
                              required={campo.obrigatorio}
                              id={`${campo.tipo}-${campo.id}`}
                              value={values[campo.id] ? values[campo.id] : ""}
                              label={campo.titulo}
                              disabled={
                                currentFormsAnswer?.formulario ===
                                  currentPageData.slug || !campo.editavel
                              }
                              onChange={(e) =>
                                fieldChanged(campo, e.target.value)
                              }
                              fieldSize={campo.tamanho_campo}
                              error={errors[campo.id] ? true : false}
                              helperText={
                                errors[campo.id]
                                  ? errors[campo.id]
                                  : campo.texto_ajuda
                              }
                              mask={
                                campo.mascara !== "mascara" ? campo.mascara : ""
                              }
                            />
                          </Grid>
                        )}
                        {campo.tipo === "Matriz" && (
                          <>
                            <div style={{ padding: "8px", width: "100%" }}>
                              <Typography variant="h6" component="h3">
                                {campo.titulo}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {campo.texto_ajuda}
                              </Typography>
                            </div>
                            {campo.condicional &&
                              campo?.titulo_condicional?.length > 0 && (
                                <Grid sm={12} md={12} item>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={!!showFieldsMatriz[campo.id]}
                                        onChange={() => {
                                          setShowFieldsMatriz((matriz) => {
                                            return {
                                              ...matriz,
                                              [campo.id]:
                                                !showFieldsMatriz[campo.id],
                                            };
                                          });
                                        }}
                                        name="condicional"
                                        color="primary"
                                        disabled={!campo.editavel}
                                      />
                                    }
                                    label={campo?.titulo_condicional}
                                  />
                                  {showFieldsMatriz[campo.id]}
                                </Grid>
                              )}
                          </>
                        )}
                        {Array.from({
                          length: replicationCounts[campo.id],
                        }).map(
                          (
                            _,
                            idx // idx === indices das replicas
                          ) => (
                            <Fragment key={idx}>
                              {campo.tipo === "Matriz" && (
                                <>
                                  {/* {idx} */}
                                  {(showFieldsMatriz[campo.id] ||
                                    !campo.condicional) && (
                                    <>
                                      {campo.alternativas.map(
                                        (alternativa, index) => {
                                          // index === indices das alternativas
                                          let dateValue = findValueForInput(
                                            campo.id,
                                            idx,
                                            alternativa.nome_campo
                                          );
                                          switch (alternativa.campo) {
                                            case "Data":
                                              return (
                                                <Grid
                                                  xs={12}
                                                  md={6}
                                                  item
                                                  key={`${campo.id}-${index}-${idx}`}
                                                >
                                                  <TextDate
                                                    required={campo.obrigatorio}
                                                    id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                                    label={alternativa.titulo}
                                                    value={
                                                      dateValue
                                                        ? convertToLongFormat(
                                                            dateValue
                                                          )
                                                        : null
                                                    }
                                                    error={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(dateEvent) => {
                                                      matrixFieldChanged(
                                                        campo.id,
                                                        alternativa.ordem,
                                                        convertDateFormat(
                                                          dateEvent
                                                        ),
                                                        alternativa.titulo,
                                                        idx,
                                                        alternativa.nome_campo
                                                      );
                                                    }}
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                    helperText={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? errors[campo.id][
                                                            index
                                                          ]
                                                        : alternativa.texto_ajuda
                                                    }
                                                    disabled={
                                                      currentFormsAnswer?.formulario ===
                                                        currentPageData.slug ||
                                                      !!Object.keys(
                                                        valuesPattern
                                                      ).find(
                                                        (chave) =>
                                                          valuesPattern[
                                                            chave
                                                          ] === values[campo.id]
                                                      ) ||
                                                      !campo.editavel
                                                    }
                                                    validator={
                                                      alternativa?.validador ||
                                                      null
                                                    }
                                                  />
                                                </Grid>
                                              );
                                            case "Texto":
                                              return (
                                                <Grid
                                                  xs={12}
                                                  md={6}
                                                  item
                                                  key={`${campo.id}-${index}-${idx}`}
                                                >
                                                  <Text
                                                    required={
                                                      alternativa.obrigatorio
                                                    }
                                                    id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                                    label={alternativa.titulo}
                                                    value={findValueForInput(
                                                      campo.id,
                                                      idx,
                                                      alternativa.nome_campo
                                                    )}
                                                    error={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(data) => {
                                                      matrixFieldChanged(
                                                        campo.id,
                                                        alternativa.ordem,
                                                        data.target.value,
                                                        alternativa.titulo,
                                                        idx,
                                                        alternativa.nome_campo
                                                      );
                                                    }}
                                                    mask={alternativa.mascara}
                                                    fieldSize={
                                                      campo.tamanho_campo
                                                    }
                                                    helperText={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? errors[campo.id][
                                                            index
                                                          ]
                                                        : alternativa.texto_ajuda
                                                    }
                                                    disabled={
                                                      currentFormsAnswer?.formulario ===
                                                        currentPageData.slug ||
                                                      !!Object.keys(
                                                        valuesPattern
                                                      ).find(
                                                        (chave) =>
                                                          valuesPattern[
                                                            chave
                                                          ] === values[campo.id]
                                                      ) ||
                                                      !campo.editavel
                                                    }
                                                    validator={
                                                      alternativa?.validador ||
                                                      null
                                                    }
                                                  />
                                                </Grid>
                                              );
                                            case "Telefone":
                                              return (
                                                <Grid
                                                  xs={12}
                                                  md={6}
                                                  item
                                                  key={`${campo.id}-${index}-${idx}`}
                                                >
                                                  <Phone
                                                    required={
                                                      alternativa.obrigatorio
                                                    }
                                                    id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                                    label={alternativa.titulo}
                                                    value={findValueForInput(
                                                      campo.id,
                                                      idx,
                                                      alternativa.nome_campo
                                                    )}
                                                    error={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(data) => {
                                                      matrixFieldChanged(
                                                        campo.id,
                                                        alternativa.ordem,
                                                        data.target.value,
                                                        alternativa.titulo,
                                                        idx,
                                                        alternativa.nome_campo
                                                      );
                                                    }}
                                                    mask={campo.mascara}
                                                    helperText={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? errors[campo.id][
                                                            index
                                                          ]
                                                        : alternativa.texto_ajuda
                                                    }
                                                    disabled={
                                                      currentFormsAnswer?.formulario ===
                                                        currentPageData.slug ||
                                                      !!Object.keys(
                                                        valuesPattern
                                                      ).find(
                                                        (chave) =>
                                                          valuesPattern[
                                                            chave
                                                          ] === values[campo.id]
                                                      ) ||
                                                      !campo.editavel
                                                    }
                                                    validator={
                                                      alternativa?.validador ||
                                                      null
                                                    }
                                                  />
                                                </Grid>
                                              );
                                            case "Email":
                                              return (
                                                <Grid
                                                  xs={12}
                                                  md={6}
                                                  item
                                                  key={`${campo.id}-${index}-${idx}`}
                                                >
                                                  <TextEmail
                                                    required={
                                                      alternativa.obrigatorio
                                                    }
                                                    id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                                    label={alternativa.titulo}
                                                    value={findValueForInput(
                                                      campo.id,
                                                      idx,
                                                      alternativa.nome_campo
                                                    )}
                                                    error={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e) =>
                                                      matrixFieldChanged(
                                                        campo.id,
                                                        alternativa.ordem,
                                                        e.target.value,
                                                        alternativa.titulo,
                                                        idx,
                                                        alternativa.nome_campo
                                                      )
                                                    }
                                                    helperText={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? errors[campo.id][
                                                            index
                                                          ]
                                                        : alternativa.texto_ajuda
                                                    }
                                                    disabled={
                                                      currentFormsAnswer?.formulario ===
                                                        currentPageData.slug ||
                                                      !!Object.keys(
                                                        valuesPattern
                                                      ).find(
                                                        (chave) =>
                                                          valuesPattern[
                                                            chave
                                                          ] === values[campo.id]
                                                      ) ||
                                                      !campo.editavel
                                                    }
                                                    validator={
                                                      alternativa?.validador ||
                                                      null
                                                    }
                                                  />
                                                </Grid>
                                              );
                                            case "Area":
                                              return (
                                                <Grid
                                                  xs={12}
                                                  md={6}
                                                  item
                                                  key={`${campo.id}-${index}-${idx}`}
                                                >
                                                  <Area
                                                    required={
                                                      alternativa.obrigatorio
                                                    }
                                                    id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                                    label={alternativa.titulo}
                                                    value={findValueForInput(
                                                      campo.id,
                                                      idx,
                                                      alternativa.nome_campo
                                                    )}
                                                    error={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(data) =>
                                                      matrixFieldChanged(
                                                        campo.id,
                                                        alternativa.ordem,
                                                        data.target.value,
                                                        alternativa.titulo,
                                                        idx,
                                                        alternativa.nome_campo
                                                      )
                                                    }
                                                    helperText={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? errors[campo.id][
                                                            index
                                                          ]
                                                        : alternativa.texto_ajuda
                                                    }
                                                    disabled={
                                                      currentFormsAnswer?.formulario ===
                                                        currentPageData.slug ||
                                                      !!Object.keys(
                                                        valuesPattern
                                                      ).find(
                                                        (chave) =>
                                                          valuesPattern[
                                                            chave
                                                          ] === values[campo.id]
                                                      ) ||
                                                      !campo.editavel
                                                    }
                                                    validator={
                                                      alternativa?.validador ||
                                                      null
                                                    }
                                                  />
                                                </Grid>
                                              );
                                            case "Numero":
                                              return (
                                                <Grid
                                                  xs={12}
                                                  md={6}
                                                  item
                                                  key={`${campo.id}-${index}-${idx}`}
                                                >
                                                  <TextNumber
                                                    id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                                    label={alternativa.titulo}
                                                    value={findValueForInput(
                                                      campo.id,
                                                      idx,
                                                      alternativa.nome_campo
                                                    )}
                                                    required={
                                                      alternativa.obrigatorio
                                                    }
                                                    onChange={(data) =>
                                                      matrixFieldChanged(
                                                        campo.id,
                                                        alternativa.ordem,
                                                        data.target.value,
                                                        alternativa.titulo,
                                                        idx,
                                                        alternativa.nome_campo
                                                      )
                                                    }
                                                    helperText={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? errors[campo.id][
                                                            index
                                                          ]
                                                        : alternativa.texto_ajuda
                                                    }
                                                    disabled={
                                                      currentFormsAnswer?.formulario ===
                                                        currentPageData.slug ||
                                                      !!Object.keys(
                                                        valuesPattern
                                                      ).find(
                                                        (chave) =>
                                                          valuesPattern[
                                                            chave
                                                          ] === values[campo.id]
                                                      ) ||
                                                      !campo.editavel
                                                    }
                                                    validator={
                                                      alternativa?.validador ||
                                                      null
                                                    }
                                                  />
                                                </Grid>
                                              );
                                            case "Alternativas_JSON":
                                              return (
                                                <>
                                                  <Grid sm={12} md={6} item>
                                                    <SubSelect
                                                      required={
                                                        alternativa.obrigatorio
                                                      }
                                                      id={`${campo.tipo}-${campo.id}`}
                                                      label={alternativa.titulo}
                                                      error={
                                                        errors[campo.id]
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e: any) =>
                                                        matrixFieldSelectChanged(
                                                          campo,
                                                          e.target.value,
                                                          idx,
                                                          index,
                                                          alternativa.nome_campo
                                                        )
                                                      }
                                                      value={getValueSelect(
                                                        campo,
                                                        idx,
                                                        alternativa.nome_campo,
                                                        index
                                                      )}
                                                      helperText={
                                                        errors[campo.id]
                                                          ? errors[campo.id]
                                                          : campo.texto_ajuda
                                                      }
                                                      itens={valueToLoweCaseSubSelect(
                                                        campo
                                                      )}
                                                      getOptionLabel={(
                                                        option
                                                      ) => option.value}
                                                      disabled={
                                                        currentFormsAnswer?.formulario ===
                                                          currentPageData.slug ||
                                                        !campo.editavel
                                                      }
                                                    />
                                                  </Grid>
                                                  {optionsValue &&
                                                    optionsValue?.[
                                                      `${alternativa?.value?.[0].subvalue_name}-${idx}`
                                                    ]?.length && (
                                                      <Grid sm={12} md={6} item>
                                                        <SubSelect
                                                          onChange={(e: any) =>
                                                            matrixFieldSubSelectChanged(
                                                              campo,
                                                              e.target.value,
                                                              idx,
                                                              index,
                                                              `${alternativa?.value?.[0]?.value}_${index}_M`
                                                            )
                                                          }
                                                          label={
                                                            alternativa
                                                              ?.value?.[0]
                                                              ?.subvalue_label
                                                          }
                                                          value={getValueSubSelect(
                                                            campo,
                                                            idx,
                                                            alternativa
                                                              ?.value?.[0]
                                                              ?.subvalue_name,
                                                            index
                                                          )}
                                                          itens={
                                                            [
                                                              optionsValue?.[
                                                                `${alternativa?.value?.[0]?.subvalue_name}-${idx}`
                                                              ],
                                                            ] || []
                                                          }
                                                          getOptionLabel={(
                                                            option
                                                          ) => option.value}
                                                          disabled={
                                                            currentFormsAnswer?.formulario ===
                                                              currentPageData.slug ||
                                                            !campo.editavel
                                                          }
                                                          helperText={
                                                            errors[campo.id]
                                                              ? errors[campo.id]
                                                              : campo.texto_ajuda
                                                          }
                                                        />
                                                      </Grid>
                                                    )}
                                                </>
                                              );
                                            default:
                                              return (
                                                <Grid
                                                  xs={12}
                                                  md={6}
                                                  item
                                                  key={`${campo.id}-${index}-${idx}`}
                                                >
                                                  <Text
                                                    required={
                                                      alternativa.obrigatorio
                                                    }
                                                    id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                                    label={alternativa.titulo}
                                                    value={findValueForInput(
                                                      campo.id,
                                                      idx,
                                                      alternativa.nome_campo
                                                    )}
                                                    error={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(data) => {
                                                      matrixFieldChanged(
                                                        campo.id,
                                                        alternativa.ordem,
                                                        data.target.value,
                                                        alternativa.titulo,
                                                        idx,
                                                        alternativa.nome_campo
                                                      );
                                                    }}
                                                    mask={alternativa.mascara}
                                                    helperText={
                                                      errors[campo.id] &&
                                                      errors[campo.id][index]
                                                        ? errors[campo.id][
                                                            index
                                                          ]
                                                        : alternativa.texto_ajuda
                                                    }
                                                    disabled={
                                                      currentFormsAnswer?.formulario ===
                                                        currentPageData.slug ||
                                                      !!Object.keys(
                                                        valuesPattern
                                                      ).find(
                                                        (chave) =>
                                                          valuesPattern[
                                                            chave
                                                          ] === values[campo.id]
                                                      ) ||
                                                      !campo.editavel
                                                    }
                                                    validator={
                                                      alternativa?.validador ||
                                                      null
                                                    }
                                                  />
                                                </Grid>
                                              );
                                          }
                                        }
                                      )}
                                      {campo.editavel && (
                                        <Grid
                                          item
                                          xs={12}
                                          md={12}
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          {renderActionButton(
                                            campo.id,
                                            idx,
                                            campo.limite_replicacoes
                                          )}
                                        </Grid>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              <Grid style={{ marginBottom: 12 }} sm={12}>
                                <hr />
                              </Grid>
                            </Fragment>
                          )
                        )}
                        {campo.tipo === "Area" && (
                          <Grid sm={12} md={12} item>
                            <Area
                              required={campo.obrigatorio}
                              id={`${campo.tipo}-${campo.id}`}
                              label={campo.titulo}
                              value={values[campo.id] ? values[campo.id] : ""}
                              error={errors[campo.id] ? true : false}
                              onChange={(e) =>
                                fieldChanged(campo, e.target.value)
                              }
                              helperText={
                                errors[campo.id]
                                  ? errors[campo.id]
                                  : campo.texto_ajuda
                              }
                              disabled={
                                currentFormsAnswer?.formulario ===
                                  currentPageData.slug || !campo.editavel
                              }
                            />
                          </Grid>
                        )}
                        {campo.tipo === "Email" && (
                          <Grid sm={12} md={4} item>
                            <TextEmail
                              required={campo.obrigatorio}
                              id={`${campo.tipo}-${campo.id}`}
                              label={campo.titulo}
                              value={
                                values[campo.id]
                                  ? values[campo.id]
                                  : getEmailFromUser(campo.id)
                              }
                              error={errors[campo.id] ? true : false}
                              onChange={(e) =>
                                fieldChanged(campo, e.target.value)
                              }
                              helperText={
                                errors[campo.id]
                                  ? errors[campo.id]
                                  : campo.texto_ajuda
                              }
                              disabled={
                                currentFormsAnswer?.formulario ===
                                  currentPageData.slug || !campo.editavel
                              }
                            />
                          </Grid>
                        )}
                        {campo.tipo === "Numero" && (
                          <Grid
                            sm={12}
                            md={6}
                            item
                            style={{ marginTop: matchesMobile ? "" : "20px" }}
                          >
                            <TextNumber
                              id={`${campo.tipo}-${campo.id}`}
                              label={campo.titulo}
                              value={values[campo.id] ? values[campo.id] : ""}
                              required={campo.obrigatorio}
                              onChange={(e) =>
                                fieldChanged(campo, e.target.value)
                              }
                              helperText={
                                errors[campo.id]
                                  ? errors[campo.id]
                                  : campo.texto_ajuda
                              }
                              disabled={
                                currentFormsAnswer?.formulario ===
                                  currentPageData.slug || !campo.editavel
                              }
                            />
                          </Grid>
                        )}
                        {campo.tipo === "Imagem" && (
                          <Grid
                            sm={12}
                            md={6}
                            item
                            style={{ marginTop: matchesMobile ? "" : "20px" }}
                          >
                            <Arquivo
                              type="image"
                              required={campo.obrigatorio}
                              confidencial={campo.anexo_confidencial}
                              serviceSlug={serviceSlug}
                              id={`${campo.tipo}-${campo.id}`}
                              label={campo.titulo}
                              value={values[campo.id] ? values[campo.id] : ""}
                              error={errors[campo.id] ? true : false}
                              onChange={(value) => {
                                if (
                                  typeof value !== "string" &&
                                  campo?.tipo_anexo === "Formulário"
                                ) {
                                  fieldChanged(campo, value);
                                } else if (
                                  typeof value !== "string" &&
                                  campo?.tipo_anexo === "Documento"
                                ) {
                                  fieldChanged(campo, value.url);
                                }
                              }}
                              helperText={
                                errors[campo.id]
                                  ? errors[campo.id]
                                  : campo.texto_ajuda
                              }
                              disabled={
                                currentFormsAnswer?.formulario ===
                                  currentPageData.slug || !campo.editavel
                              }
                            />
                          </Grid>
                        )}
                        {campo.tipo === "Telefone" && (
                          <Grid
                            sm={12}
                            md={3}
                            item
                            style={{ marginTop: matchesMobile ? "" : "20px" }}
                          >
                            <Phone
                              required={campo.obrigatorio ? true : false}
                              id={`${campo.tipo}-${campo.id}`}
                              label={campo.titulo}
                              value={
                                values[campo.id]
                                  ? values[campo.id]
                                  : getTelefoneFromUser(campo.id)
                              }
                              error={errors[campo.id] ? true : false}
                              onChange={(e) =>
                                fieldChanged(campo, e.target.value)
                              }
                              mask={campo.mascara}
                              helperText={
                                errors[campo.id]
                                  ? errors[campo.id]
                                  : campo.texto_ajuda
                              }
                              disabled={
                                currentFormsAnswer?.formulario ===
                                  currentPageData.slug || !campo.editavel
                              }
                            />
                          </Grid>
                        )}
                        {campo.tipo === "Data" && (
                          <Grid
                            sm={12}
                            md={3}
                            item
                            style={{ marginTop: matchesMobile ? "" : "20px" }}
                          >
                            <TextDate
                              required={campo.obrigatorio}
                              label={campo.titulo}
                              value={values[campo.id] ? values[campo.id] : null}
                              error={errors[campo.id] ? true : false}
                              onChange={(data) => fieldChanged(campo, data)}
                              InputLabelProps={{ shrink: true }}
                              helperText={
                                errors[campo.id]
                                  ? errors[campo.id]
                                  : campo.texto_ajuda
                              }
                              disabled={
                                currentFormsAnswer?.formulario ===
                                  currentPageData.slug || !campo.editavel
                              }
                            />
                          </Grid>
                        )}
                        {campo.tipo === "Alternativas" &&
                          campo.tipo_alternativa === "RadioButton" && (
                            <Grid
                              sm={12}
                              md={12}
                              item
                              style={{ marginTop: matchesMobile ? "" : "20px" }}
                            >
                              <MultCheckboxe
                                required={campo.obrigatorio}
                                id={`${campo.tipo}-${campo.id}`}
                                label={campo.titulo}
                                value={values[campo.id] ? values[campo.id] : ""}
                                error={errors[campo.id] ? true : false}
                                onChange={(e) =>
                                  fieldChanged(campo, e.target.value)
                                }
                                helperText={
                                  errors[campo.id]
                                    ? errors[campo.id]
                                    : campo.texto_ajuda
                                }
                                itens={campo.alternativas}
                                disabled={
                                  currentFormsAnswer?.formulario ===
                                    currentPageData.slug || !campo.editavel
                                }
                              />
                            </Grid>
                          )}
                        {campo.tipo === "Alternativas" &&
                          campo.tipo_alternativa === "Select" && (
                            <Grid
                              sm={12}
                              md={6}
                              item
                              style={{ marginTop: matchesMobile ? "" : "20px" }}
                            >
                              <Select
                                required={campo.obrigatorio}
                                id={`${campo.tipo}-${campo.id}`}
                                label={campo.titulo}
                                value={values[campo.id] ? values[campo.id] : ""}
                                error={errors[campo.id] ? true : false}
                                onChange={(e) =>
                                  fieldChanged(campo, e.target.value)
                                }
                                helperText={
                                  errors[campo.id]
                                    ? errors[campo.id]
                                    : campo.texto_ajuda
                                }
                                itens={valueToLoweCase(campo)}
                                getOptionLabel={(option) => option.titulo}
                                disabled={
                                  currentFormsAnswer?.formulario ===
                                    currentPageData.slug || !campo.editavel
                                }
                              />
                            </Grid>
                          )}
                        {campo.tipo === "Alternativas_API" && (
                          <Grid
                            sm={12}
                            md={6}
                            item
                            style={{ marginTop: matchesMobile ? "" : "20px" }}
                          >
                            <SelectAPI
                              required={campo.obrigatorio}
                              id={`${campo.tipo}-${campo.id}`}
                              label={campo.titulo}
                              value={values[campo.id] ? values[campo.id] : ""}
                              error={errors[campo.id] ? true : false}
                              onChange={(value) => fieldChanged(campo, value)}
                              helperText={
                                errors[campo.id]
                                  ? errors[campo.id]
                                  : campo.texto_ajuda
                              }
                              itens={formatAlternativesForMapping(
                                campo.alternativas
                              )}
                              getOptionLabel={(option) => option.label}
                              disabled={
                                currentFormsAnswer?.formulario ===
                                  currentPageData.slug || !campo.editavel
                              }
                            />
                          </Grid>
                        )}
                        {campo.tipo === "Multiplos" && (
                          <Grid
                            sm={12}
                            md={12}
                            item
                            style={{ marginTop: matchesMobile ? "" : "20px" }}
                          >
                            <Multiple
                              required={campo.obrigatorio}
                              label={campo.titulo}
                              values={values[campo.id] ? values[campo.id] : []}
                              error={errors[campo.id] ? true : false}
                              onChange={(value) => fieldChanged(campo, value)}
                              InputLabelProps={{ shrink: true }}
                              helperText={
                                errors[campo.id]
                                  ? errors[campo.id]
                                  : campo.texto_ajuda
                              }
                              itens={campo.alternativas}
                              getOptionLabel={(option) => option.label}
                              disabled={
                                currentFormsAnswer?.formulario ===
                                  currentPageData.slug || !campo.editavel
                              }
                            />
                          </Grid>
                        )}
                        {campo.tipo === "Multiplos_API" && (
                          <Grid
                            sm={12}
                            md={6}
                            item
                            style={{ marginTop: matchesMobile ? "" : "20px" }}
                          >
                            <MultipleAPI
                              required={campo.obrigatorio}
                              label={campo.titulo}
                              values={values[campo.id] ? values[campo.id] : []}
                              error={errors[campo.id] ? true : false}
                              onChange={(value) => fieldChanged(campo, value)}
                              InputLabelProps={{ shrink: true }}
                              helperText={
                                errors[campo.id]
                                  ? errors[campo.id]
                                  : campo.texto_ajuda
                              }
                              itens={campo.alternativas}
                              getOptionLabel={(option) => option.label}
                              disabled={
                                currentFormsAnswer?.formulario ===
                                  currentPageData.slug || !campo.editavel
                              }
                            />
                          </Grid>
                        )}

                        {campo.tipo === "Arquivo" && (
                          <Grid
                            sm={12}
                            md={6}
                            item
                            style={{ marginTop: matchesMobile ? "" : "20px" }}
                          >
                            <Arquivo
                              required={campo.obrigatorio}
                              id={`${campo.tipo}-${campo.id}`}
                              confidencial={campo.anexo_confidencial}
                              type={campo.tipo}
                              arquiveType={campo.tipo_arquivo}
                              serviceSlug={serviceSlug}
                              label={campo.titulo}
                              value={values[campo.id] ? values[campo.id] : ""}
                              error={errors[campo.id] ? true : false}
                              onChange={(value) => fieldChanged(campo, value)}
                              helperText={
                                errors[campo.id]
                                  ? errors[campo.id]
                                  : campo.texto_ajuda
                              }
                              disabled={
                                currentFormsAnswer?.formulario ===
                                  currentPageData.slug || !campo.editavel
                              }
                            />
                          </Grid>
                        )}
                        {campo.tipo === "CEP" && campo.exibir_mapa && (
                          <Grid
                            sm={12}
                            md={12}
                            item
                            style={{ marginTop: matchesMobile ? "" : "20px" }}
                          >
                            <Box className={classes.boxMap}>
                              <Mapa
                                geolocation={geolocation}
                                setCepNumber={setCepNumber}
                                editavel={campo?.editavel}
                                precision={precision}
                                setPrecision={setPrecision}
                                setGeolocation={setGeolocation}
                                setAddressMapa={setAddressMapa}
                              />
                            </Box>
                          </Grid>
                        )}
                        {campo.tipo === "CEP" && (
                          <Grid
                            sm={12}
                            md={6}
                            item
                            style={{ marginTop: matchesMobile ? "" : "20px" }}
                          >
                            <Text
                              required={campo.obrigatorio}
                              id={`${campo.tipo}-${campo.id}`}
                              value={values[campo.id] || ""}
                              label={campo.titulo}
                              disabled={
                                cepLoading ||
                                currentFormsAnswer?.formulario ===
                                  currentPageData.slug ||
                                !campo.editavel
                              }
                              onChange={(e) =>
                                handleSearchAddress(campo, e.target.value, true)
                              }
                              error={errors[campo.id] ? true : false}
                              helperText={
                                errors[campo.id]
                                  ? errors[campo.id]
                                  : campo.texto_ajuda
                              }
                              mask={
                                campo.mascara !== "mascara" ? campo.mascara : ""
                              }
                            />
                          </Grid>
                        )}
                      </Fragment>
                    ))}
                  </>
                )}
              </Grid>
            )}

            {/* Navegação padrão */}
            <Grid
              container
              direction={matchesMobile ? "row" : "column"}
              justifyContent="space-between"
            >
              <Grid
                item
                xs={matchesMobile ? 3 : 12}
                className={classes.buttonBack}
              >
                {page > 0 && (
                  <Button
                    style={{ color: "#264259" }}
                    onClick={() => {
                      handlePrev();
                      loadIncompletedAnswer(0, true);
                    }}
                  >
                    VOLTAR
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={matchesMobile ? 3 : 12}
                style={{ order: matchesMobile ? 2 : 1 }}
              >
                {hasPageNext() && (
                  <Button
                    className={classes.buttonForm}
                    style={{ marginTop: matchesMobile ? "0px" : "15px" }}
                    variant="contained"
                    fullWidth
                    color="primary"
                    type="submit"
                    disabled={
                      (statusFormFlow?.pendentes?.length &&
                        page === formData.length - 1) ||
                      cepLoading ||
                      cepNotFound
                    }
                  >
                    {formData.length - 1 === page
                      ? "SALVAR"
                      : "SALVAR / AVANÇAR"}
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  );
}
