import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  makeStyles,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ptBR from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import { MdEvent } from "react-icons/md";
import { format, isValid } from "date-fns";
import {
  getManifestationTypeBySlug,
  getOrgansList
} from "../../services/ouvidoria";
import { TypeFilter } from "../../Pages/MyManifestation";
import ReactSelect, { StylesConfig } from 'react-select';
import theme from "../../services/theme-service";
import { DataProps } from "../AsyncSelect";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";



interface FilterProps {
  onSearch: (type?: string, value?: string) => void;
  onLoadList: ({ page }) => void;
  currentFilter: (filter: TypeFilter) => void;
  filters: {
    protocolo?: boolean;
    status?: boolean;
    date?: boolean;
    organ?: boolean;
    tipo_manifestacao?: boolean;
    demanda?: boolean;
    date_schedule?: boolean;
    data?: boolean;
  }
  onSearchProtocol?: (value: boolean) => void;
  listStatus: Array<{
    id: number;
    title: string;
    value: string;
  }>
  setSelected?:(number: number) => void;
}

type TypeFilterAll = 'protocolo' | 'status' | 'data' | 'orgao' | 'tipo_manifestacao' | 'demanda' | 'date_schedule' | 'data';

interface OrgansListProps {
  nome: string;
  sigla: string;
  slug: string;
}

interface ManifestationType {
  id: number;
  titulo: string;
  slug: 'elogio'
  | 'reclamacao'
  | 'denuncia'
  | 'sugestao';
}

interface SelectOrgan {
  name: string;
  value: string;
  label: string;
}




const listDemands = [
  {
    id: 0,
    title: 'Agendamento',
    value: 'Agendamento'
  },
  {
    id: 1,
    title: 'E-SIC',
    value: 'E-SIC',
  },
  {
    id: 2,
    title: 'Solicitação',
    value: 'Solicitação',
  },
  {
    id: 3,
    title: 'Manifestação',
    value: 'Manifestação'
  }
];
const listManifestations = [
  {
    id: 0,
    title: 'Elogio',
    value: 'elogio'
  },
  {
    id: 1,
    title: 'Reclamação',
    value: 'reclamacao',
  },
  {
    id: 2,
    title: 'Denuncia',
    value: 'denuncia',
  },
  {
    id: 3,
    title: 'Sugestão',
    value: 'sugestao'
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#d3d3d3 ",
      },
    },
    "& .MuiSelect-iconOutlined": {
      height: 24,
      width: 24,
    },
  },
  formControlSelect: {
    marginTop: 20,
    marginBottom: 20,
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxIndicatorsBack: {
    width: "100%",
    margin: "20px 0px",
  },
  indicatorsBack: {
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
    display: 'flex',
  },
  boxAccess: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    height: 225,
    [theme.breakpoints.down("xs")]: {
      height: 294,
    },
  },
  cardContent: {
    padding: 30,
    display: "flex",
    width: '100%',
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      padding: "20px 10px 0px 10px",
    },
  },
  iconAccess: {
    color: theme.palette.primary.main,
    marginBottom: 20,
    marginTop: 8,
    fontSize: 50,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      marginTop: 0,
      marginBottom: 0,
    },
  },
  textCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 16,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      textAlign: "center",
    },
  },
  subTextCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 5,
    fontSize: 16,
    letterSpacing: 0.3,
    fontWeight: "normal",
    marginRight: 147.5,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 16,
      marginRight: 0,
      textAlign: "center",
    },
  },
  boxButton: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  boxIndicatorsBackHome: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  indicatorsBackHome: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  status: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 500,
    textTransform: 'capitalize',
    padding: '5px 16px',
    borderRadius: 25,
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
      marginBottom: 24,
      alignSelf: 'center',
    },
  },
  titleInfo: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    margin: '24px 0px'
  },
  textTable: {
    color: '#333333',
  },
  attachText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textDecoration: 'none',
    color: '#0F6FB7',
  },
  textLink: {
    margin: '5px 0px 5px 14px',
    textDecoration: 'none',
    color: '#0F6FB7'
  },
  buttonCancel: {
    color: '#E53535',
    borderColor: 'rgba(229,53,53, 0.7)',
    borderRadius: 4,
    margin: '0px 7px',
    padding: '12px 24px',
    height: 48,
    '&:hover': {
      backgroundColor: 'rgba(229,53,53, 0.1)',
      borderColor: '#E53535',
    },
  },
  actionsButtonText: {
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
  },
  textButtonGuiche: {
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    margin: '0px 7px',
    width: 131,
    height: 48,
    borderRadius: 4,
    padding: '12px 24px',
  },
  boxActions: {
    margin: '30px 0px',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  boxInfoCancel: {
    width: '100%',
  },
  infoCancelText: {
    display: 'flex',
    alignItems: 'center',
    margin: '30px 0px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 16,
  },
  iconRequests: {
    height: 24,
    width: 24,
  },
  formControl: {
    width: "100%",
  },
  contentFilter: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  filter: {
    width: 250,
    color: "#373F41",
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  icon: {
    width: 24,
    height: 24,
  },
  adornedEnd: {
    paddingRight: 0,
    fontWeight: 500,
    fontFamily: "Roboto"
  },
  button: {
    height: 56,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.7',
    }
  },
  buttonEmpty: {
    height: 56,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.7',
    }
  },
  input: {
    color: '#333',
  },
  containerEvaluation: {
    display: 'flex',
    gap: 10,
  },
  boxStar: {
    display: 'flex',
    gap: 10,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 24,
    alignItems: 'center',
  },
  starIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20)
  },
  textLabelEvaluation: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    textAlign: 'left'
  },
  starCountText: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    textAlign: 'left',
    color: '#373F41',
  }
}));

export default function Filter({
  onLoadList,
  onSearch,
  onSearchProtocol,
  currentFilter,
  filters,
  listStatus,
  setSelected
}: FilterProps): JSX.Element {
  const classes = useStyles();
  const [isSearch, setIsSearch] = useState(false);
  const [filter, setFilter] = useState<TypeFilterAll | 'select' | null>();
  const [status, setStatus] = useState<string | null>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedDateSchedule, setSelectedDateSchedule] = useState<Date | null>(null);
  const [data, setData] = useState<Date | null>(null);
  const [tipoData, setTipoData] = useState<any>(null);
  const [searchData, setSearchData] = useState<string>('');
  const [listOrgans, setListOrgans] = useState<SelectOrgan[]>();
  const [loadingOrgans, setLoadingOrgans] = useState<boolean>(false);
  const [loadingManifestationType, setLoadingManifestationType] = useState<boolean>(false);
  const [slugType, setSlugType] = useState<string | null>();
  const [demandType, setDemandType] = useState<string | null>();
  const [manifestationType, setManifestationType] = useState<ManifestationType>();
  const [queryOrgan, setQueryOrgan] = useState<SelectOrgan>();

  const selectStyles: StylesConfig<DataProps, true> = {
    container: (provided) => ({
      ...provided,
      flexGrow: 1,
      minWidth: 350,
    }),
    option: (styles, {
      data, isDisabled, isFocused, isSelected,
    }) => ({
      ...styles,
      color: isDisabled
        ? '#2e2e2e'
        : isSelected
          ? theme.palette.primary.main
          : isFocused
            ? 'black'
            : '#444',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#848484',
    }),
    control: (provided) => ({
      ...provided,
      padding: '10px',
      borderRadius: '4px',
      backgroundColor: 'transparent'
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#fff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#1F2937',
    }),
  };
  const themeCalendar = createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  });

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value;
    setSearchData(value);
  }

  const getManifestationType = async () => {
    if (slugType) {
      try {
        setLoadingManifestationType(true);
        const { data } = await getManifestationTypeBySlug(slugType);

        setManifestationType(data.results[0]);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingManifestationType(false);
      }
    }
  };

  const buttonIsDisabled = useMemo(() => (
    (filter === 'status' && !status) ||
    (filter === 'protocolo' && !searchData?.length) ||
    (filter === 'demanda' && !demandType) ||
    (filter === 'date_schedule' && !selectedDateSchedule) ||
    (filter === 'data' && !data) ||
    (filter === 'tipo_manifestacao' && (!manifestationType || loadingManifestationType)) ||
    (filter === 'orgao' && (!queryOrgan || loadingOrgans))
  ), [filter, loadingManifestationType, loadingOrgans, manifestationType, queryOrgan, searchData?.length, selectedDate, status, selectedDateSchedule, data, demandType]);

  const handleResetInputSearch = () => {
    setFilter(null);
    setSearchData("");
    setSelectedDate(null);
    setSelectedDateSchedule(null);
    setData(null);
    setIsSearch(false);
    setStatus(null);
    setTipoData(null);
    setQueryOrgan(null);
    onSearchProtocol(false);
    setSlugType(null);
    setDemandType(null);
    setManifestationType(null);
    onLoadList({ page: 1 });
    currentFilter(null);
  }

  const getOrgans = async () => {
    try {
      setLoadingOrgans(true);
      const { data } = await getOrgansList();
      const newData = data.map((organ: OrgansListProps) => ({
        value: organ.slug,
        name: organ.nome,
        label: organ.nome,
      }));
      setListOrgans(newData);
    } catch (error) {
      setListOrgans([]);
    } finally {
      setLoadingOrgans(false);
    }
  };

  const handleQueryOrgan = (value: any) => {
    setQueryOrgan(value);
  }

  useEffect(() => {
    if (filter === 'status' && status && isSearch) {
      onSearch('status', status);
    }
    if (filter === 'protocolo' && isSearch) {
      onSearch('protocolo', searchData);
    }
    if (filter === 'data' && selectedDate && isSearch) {
      let formattedData = format(selectedDate, 'yyyy-MM-dd');
      onSearch('data', formattedData);
    }
    if (filter === 'data' && data && isSearch) {
      let formattedData = format(data, 'yyyy-MM-dd');
      if (tipoData === 'data-agendamento') { onSearch('date_schedule', formattedData); }
      if (tipoData === 'data-solicitacao') { onSearch('data', formattedData); }
      //onSearch('data', formattedData);
    }
    if (filter === 'date_schedule' && selectedDateSchedule && isSearch) {
      let formattedData = format(selectedDateSchedule, 'yyyy-MM-dd');
      onSearch('date_schedule', formattedData);
    }
    if (filter === 'orgao' && queryOrgan && isSearch) {
      onSearch('orgao', queryOrgan.value);
    }
    if (filter === 'tipo_manifestacao' && manifestationType?.id && isSearch) {
      onSearch('tipo_manifestacao', manifestationType.id.toString());
    }
    if (filter === 'demanda' && demandType && isSearch) {
      onSearch('tipo', demandType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, isSearch, manifestationType?.id, queryOrgan, searchData, selectedDate, selectedDateSchedule, status, demandType, data]);

  useEffect(() => {
    if (filters.organ) {
      getOrgans();
    }
  }, [filters?.organ]);

  useEffect(() => {
    if (slugType) {
      getManifestationType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slugType]);
  useEffect(() => {
    if (demandType) {
      getManifestationType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demandType]);

  return (
    <Box className={classes.contentFilter}>
      <FormControl
        classes={{ root: classes.root }}
        variant="outlined"
        className={classes.formControlSelect}
      >
        <InputLabel id="demo-simple-select-outlined-label">
          Filtrar por
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          onChange={(event): void => setFilter(event.target.value as TypeFilterAll)}
          className={classes.filter}
          label="Filtrar por"
          value={filter || ''}
          classes={{
            select: classes.menuItem,
          }}
        >
          <MenuItem value="" className={classes.menuItem}>
            <em>Filtrar por</em>
          </MenuItem>
          {filters.protocolo && (
            <MenuItem value="protocolo" className={classes.menuItem} selected={filter === 'protocolo'}>
              Protocolo
            </MenuItem>
          )}
          {filters.status && (
            <MenuItem value="status" className={classes.menuItem} selected={filter === 'status'}>
              Status
            </MenuItem>
          )}
          {filters.date && (
            <MenuItem value="data" className={classes.menuItem} selected={filter === 'data'}>
              Data de solicitação
            </MenuItem>
          )}
          {filters.date && (
            <MenuItem value="date_schedule" className={classes.menuItem} selected={filter === 'date_schedule'}>
              Data do agendamento
            </MenuItem>
          )}
          {filters.data && (
            <MenuItem value="data" className={classes.menuItem} selected={filter === 'data'}>
              Data
            </MenuItem>
          )}
          {filters.organ && (
            <MenuItem value="orgao" className={classes.menuItem} selected={filter === 'orgao'}>
              Órgão
            </MenuItem>
          )}
          {filters.tipo_manifestacao && (
            <MenuItem value="tipo_manifestacao" className={classes.menuItem} selected={filter === 'tipo_manifestacao'}>
              Tipo da manifestação
            </MenuItem>
          )}
          {filters.demanda && (
            <MenuItem value="demanda" className={classes.menuItem} selected={filter === 'demanda'}>
              Tipo da demanda
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <Box>
        {(filter === 'status') && (
          <FormControl
            classes={{ root: classes.root }}
            variant="outlined"
            className={classes.formControlSelect}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={(event): void => setStatus(event.target.value as string)}
              className={classes.filter}
              label="status"
              value={status || ''}
              classes={{
                select: classes.menuItem,
              }}
            >
              <MenuItem value="" className={classes.menuItem}>
                <em>Selecione o status</em>
              </MenuItem>
              {listStatus.map((status) => (
                <MenuItem
                  value={status.value}
                  key={status.value}
                  className={classes.menuItem}
                >
                  {status.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {(filter === 'protocolo') && (
          <TextField
            id='filter'
            fullWidth
            label="Digite sua busca"
            variant="outlined"
            value={searchData}
            InputProps={{
              className: classes.input,
            }}
            onChange={handleChangeSearch}
          // disabled={filter === 'select'}
          />
        )}
        {(filter === 'data') && (
          <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '25%' }}>
            <FormControl
              classes={{ root: classes.root }}
              variant="outlined"
              className={classes.formControlSelect}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Tipo data
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                onChange={(event): void => setTipoData(event.target.value as string)}
                className={classes.filter}
                label="tipo data"
                value={tipoData || ''}
                classes={{
                  select: classes.menuItem,
                }}
              >
                <MenuItem value="" className={classes.menuItem}>
                  <em>Selecione o tipo da data</em>
                </MenuItem>

                <MenuItem
                  value={'data-solicitacao'}
                  className={classes.menuItem}
                >
                  Data de solicitação
                </MenuItem>
                <MenuItem
                  value={'data-agendamento'}
                  className={classes.menuItem}
                >
                  Data do agendamento
                </MenuItem>

              </Select>
            </FormControl>
            {tipoData && (<>
              <FormControl
                classes={{ root: classes.root }}
                variant="outlined"
                className={classes.formControl}
              >
                <MuiPickersUtilsProvider
                  locale={ptBR}
                  utils={DateFnsUtils}
                >
                  <ThemeProvider theme={themeCalendar}>
                    <KeyboardDatePicker
                      keyboardIcon={
                        <MdEvent className={classes.icon} />
                      }
                      disableFuture={false}
                      //maxDate={new Date()}
                      InputProps={{
                        className: classes.menuItem,
                        classes: { adornedEnd: classes.adornedEnd },
                      }}
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Selecione o dia"
                      placeholder="00/00/0000"
                      format="dd/MM/yyyy"
                      value={data ? data : null}
                      InputAdornmentProps={{
                        position: "end",
                        color: "#000",
                      }}
                      onChange={(date) => {
                        if (date && isValid(date)) {
                          setData(date);
                        }
                      }}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </FormControl>
            </>)}
          </Box>
        )}
        {(filter === 'orgao') && (
          <ReactSelect
            options={listOrgans}
            isLoading={loadingOrgans}
            value={queryOrgan}
            styles={selectStyles}
            onChange={handleQueryOrgan}
            placeholder="Procurar órgão"
            noOptionsMessage={() => 'Nenhum órgão encontrado'}
            isDisabled={listOrgans?.length === 0}
            isClearable
          />
        )}
        {(filter === 'tipo_manifestacao') && (
          <FormControl
            classes={{ root: classes.root }}
            variant="outlined"
            className={classes.formControlSelect}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Tipo de manifestação
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={(event): void => setSlugType(event.target.value as string)}
              className={classes.filter}
              label="Tipo de manifestação"
              value={slugType || ''}
              classes={{
                select: classes.menuItem,
              }}
            >
              <MenuItem value="" className={classes.menuItem}>
                <em>Selecione o tipo de manifestação</em>
              </MenuItem>
              {listManifestations.map((tipo) => (
                <MenuItem
                  value={tipo.value}
                  key={tipo.title}
                  className={classes.menuItem}
                >
                  {tipo.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {(filter === 'demanda') && (
          <FormControl
            classes={{ root: classes.root }}
            variant="outlined"
            className={classes.formControlSelect}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Tipo da demanda
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={(event): void => setDemandType(event.target.value as string)}
              className={classes.filter}
              label="Tipo da demanda"
              value={demandType || ''}
              classes={{
                select: classes.menuItem,
              }}
            >
              <MenuItem value="" className={classes.menuItem}>
                <em>Selecione o tipo da demanda</em>
              </MenuItem>
              {listDemands.map((tipo) => (
                <MenuItem
                  value={tipo.value}
                  key={tipo.title}
                  className={classes.menuItem}
                >
                  {tipo.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      {
        !isSearch ? (
          <Button
            type="button"
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={(buttonIsDisabled || !filter)}
            onClick={() => {
              setIsSearch(true);
              setSelected(2)
            }}
          >
            Buscar
          </Button>
        ) : (
          <Button
            variant="contained"
            className={classes.buttonEmpty}
            onClick={handleResetInputSearch}
          >
            Limpar
          </Button>
        )
      }
    </Box >
  )
}