import React from 'react';
import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
    },
  },
  inputColor: {
    color: theme.palette.primary.main,
  },
  inputRoot: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  tag: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
}));

interface TextSelectProps {
  itens: { ordem: string; titulo: string }[];
  getOptionLabel: (option: any) => string;
  onChange: (value: any) => void;
  values?: any[];
}

const MultipleAPI: React.FC<TextFieldProps & TextSelectProps> = (props) => {
  const classes = useStyles();

  const makeLabelOptions = (itens: any[]): Array<any> => {
    return itens.map((item) => ({
      label: item.name,
      value: String(item?.value)
    }))
  }

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={makeLabelOptions(props.itens ?? [])}
      classes={{
        inputRoot: classes.inputRoot,
        tag: classes.tag,
        option: classes.tag,
      }}
      getOptionLabel={(option) => props.getOptionLabel(option)}
      onChange={(e, v) => {
        let op = [];
        v.forEach(item => {
          op.push(item.value);
        });
        props.onChange(op);
      }}
      defaultValue={props.values ?? []}
      filterSelectedOptions
      disabled={props.disabled}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          placeholder="..."
          InputProps={{
            className: classes.inputColor,
          }}
          error={props.error}
          label={props.label}
          helperText={props.helperText}
          fullWidth
          {...params}
        />
      )}
    />
  );
}

export default MultipleAPI;
