import { makeStyles } from '@material-ui/core/styles';
import theme from '../../services/theme-service';

export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    boxHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
    },
    textName: {
        fontSize: '24px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
    },
    textUnidade: {
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '23px',
        textTransform: 'capitalize',
        color: '#737B7D',
        paddingBottom: '20px',
        paddingTop: '20px',
    },
    btnConfirm: {
        backgroundColor: theme.palette.primary.main,
        border: 'none',
        color: '#FFFFFF',
        borderRadius: '2',
        padding: '10px',
        boxShadow: 'none',
    },
    container: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[200],
    }
}));